import React from "react";
import PropTypes from "prop-types";
import { withLocalize, Translate } from "react-localize-redux";
import { Link } from "react-router-dom";
import LanguageSelector from "../../../common/presentational/LanguageSelector";

const Header = ({ event }) => {
    const homeUrl = event
        ? `/${encodeURIComponent(event.slug)}/media`
        : "/";

    return (
        <nav id="header" className="navbar navbar-expand">
            <div className="container header-inner">
                <Link className="navbar-brand align-middle" to={homeUrl}>
                    <div id="logotype" className="d-inline-block align-top"/>
                </Link>
                <div className="navbar-nav mr-auto">
                    <strong>
                        <Translate id="media.title" />
                    </strong>
                </div>
                <div className="navbar-nav ml-auto">
                    <LanguageSelector />
                </div>
            </div>
        </nav>
    );
};

Header.propTypes = {
    event: PropTypes.object
};

export default withLocalize(Header);
