import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setupVisibilityChangeHandler } from "../../logic/BrowserUtil";
import * as browserActions from "../../actions/browserActions";

const BrowserVisibilityChangeDetector = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        setupVisibilityChangeHandler(handleBrowserVisibilityChange);
    }, []);

    const handleBrowserVisibilityChange = visible => {
        dispatch(browserActions.browserWindowVisibilityChanged(visible));
    };

    return null;
};

export default BrowserVisibilityChangeDetector;
