import SplitTime from "./SplitTime";
import SplitTimeHydrator from "../logic/SplitTimeHydrator";
import CourseSplitTimeCalculator from "../logic/CourseSplitTimeCalculator";
import PerformanceIndexCalculator from "../logic/PerformanceIndexCalculator";
import TimeType from "./TimeType";

class Competitor {

    constructor(options) {
        // person
        //   firstName
        //   lastName
        // team
        // organisation
        //   name
        // startTime, seconds since epoch
        // status
        // splitTimes
        //   code
        //   timeSinceStart
        // numberOfTimeLossLegs
        // coursePercentualTimeLoss

        if(!options) {
            throw "Competitor data must not be null.";
        }

        if(options.person) {
            this.person = {...options.person};
        }
        if(options.team) {
            this.team = {...options.team};
        }
        if(options.organisation) {
            this.organisation = {...options.organisation};
        }

        this.startTime = options.startTime;
        
        if(options.splitTimes) {
            // we can handle both object literal and SplitTime instances
            this.splitTimes = options.splitTimes.map(st => {
                if (st instanceof SplitTime) {
                    return st;
                }
                return new SplitTime({
                    code: st.code,
                    timeSinceStart: st.timeSinceStart
                });
            });
            const hydrator = new SplitTimeHydrator();
            hydrator.hydrate(this.splitTimes, this.startTime);
        }

        this.numberOfTimeLossLegs = options.numberOfTimeLossLegs;
    }

    calculateCourseSplitTimes(controls) {
        const calculator = new CourseSplitTimeCalculator();
        this.courseSplitTimes = calculator.calculateCourseSplitTimes(this.splitTimes, this.startTime, controls);
    }

    calculatePerformanceIndices(legTopTimes) {
        if (!this.courseSplitTimes) {
            throw "Course split times must be set for competitor before calculating performance indices.";
        }
        const calculator = new PerformanceIndexCalculator();
        const legTimes = this.courseSplitTimes.map(st => st.times[TimeType.leg]);
        const performanceIndicesCollection = calculator.calculatePerformanceIndices(legTimes, legTopTimes);
        performanceIndicesCollection.forEach((performanceIndices, i) => 
            this.courseSplitTimes[i].performanceIndices = performanceIndices
        );
    }
}

export default Competitor;