import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";

const SearchTextBox = React.forwardRef(({ id, name, placeholder, showButton, onSelected }, ref) => {
    const handleKeyPress = (field, domEvent) => {
        if(domEvent.key === "Enter") {
            onSelected(field.value);
        }
    };

    return (
        <Field name={name}>
            {
                ({ field }) => {
                    return (
                        <div className="input-group">
                            <input
                                id={id}
                                name={name}
                                type="text"
                                value={field.value}
                                onChange={field.onChange}
                                className="form-control"
                                placeholder={placeholder ?? ""}
                                onKeyPress={domEvent => handleKeyPress(field, domEvent)}
                                ref={ref}
                            />
                            {
                                showButton && onSelected && field.value &&
                                (
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" type="button" onClick={() => onSelected(field.value)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16"><path fillRule="evenodd" d="M10 8L4 3v3H0v4h4v3l6-5z"/></svg>
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                    );
                }
            }
        </Field>
    );
});

SearchTextBox.displayName = "SearchTextBox";

SearchTextBox.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    showButton: PropTypes.bool,
    onSelected: PropTypes.func.isRequired,
    placeholder: PropTypes.string
};

export default SearchTextBox;