import { getOrganisationKey } from "../../logic/OrganisationUtil";
import { getUrlForList, setHighlightedResultId } from "../../logic/ListUtil";

const emptyString = "";

const createOrganisationCell = ({ result, event, list }) => {
    const url = getUrlForList(
        event, 
        { ...list, classIds: undefined, organisationKey: getOrganisationKey(result.organisation), entryIds: undefined, tag: undefined }
    ).url;

    const div = document.createElement("div");
    div.className = "organisation";

    if(result.organisation) {
        const name = result.organisation.name || emptyString;
        const countryCode = result.organisation.countryCode;
        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.addEventListener("click", () => setHighlightedResultId(result.resultId));
        const icon = document.createElement("span");
        icon.className = "organisation-icon";
        if(result.organisation.organisationExternalId) {
            icon.classList.add(`oi-${result.organisation.organisationExternalId}`);
        } 
        a.appendChild(icon);
        a.insertAdjacentText("beforeend", name);
        div.appendChild(a);
        if(countryCode) {
            const countryCodeElement = document.createElement("span");
            countryCodeElement.className = "country-code";
            countryCodeElement.innerText = countryCode;
            div.appendChild(countryCodeElement);
        }
    }
    return div;
};

export default createOrganisationCell;
