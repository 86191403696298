export const Event = {
    addFavorite: "AddFavorite",
    removeFavorite: "RemoveFavorite",
    print: "Print",
    createFavoriteSubscription: "CreateFavoriteSubscription",
    updateFavoriteSubscription: "UpdateFavoriteSubscription",
    deleteFavoriteSubscription: "DeleteFavoriteSubscription",
    redirectToLivelox: "RedirectToLivelox",
    redirectToEventor: "RedirectToEventor",
    redirectToWinSplits: "RedirectToWinSplits",
};

export const Page = {
    classStartList: "ClassStartList",
    organisationStartList: "OrganisationStartList",
    favoriteStartList: "FavoriteStartList",
    tagStartList: "TagStartList",
    classRaceResultList: "ClassRaceResultList",
    organisationRaceResultList: "OrganisationRaceResultList",
    favoriteRaceResultList: "FavoriteRaceResultList",
    tagRaceResultList: "TagRaceResultList",
    classOverallResultList: "ClassOverallResultList",
    organisationOverallResultList: "OrganisationOverallResultList",
    favoriteOverallResultList: "FavoriteOverallResultList",
    tagOverallResultList: "TagOverallResultList",
    classStartListAllRaces: "ClassStartListAllRaces",
    organisationStartListAllRaces: "OrganisationStartListAllRaces",
    favoriteStartListAllRaces: "FavoriteStartListAllRaces",
    tagStartListAllRaces: "TagStartListAllRaces",
    classResultListAllRaces: "ClassResultListAllRaces",
    organisationResultListAllRaces: "OrganisationResultListAllRaces",
    favoriteResultListAllRaces: "FavoriteResultListAllRaces",
    tagResultListAllRaces: "TagResultListAllRaces",
    splitTimes: "SplitTimes",
    competitors: "Competitors",
    tags: "Tags",
    singleCompetitor: "SingleCompetitor"
};

export const logEvent = eventName => {
    if (window && window.appInsights) {
        window.appInsights.trackEvent({ name: eventName });
    }
};

export const logPageView = pageName => {
    if (window && window.appInsights) {
        window.appInsights.trackPageView({ name: pageName });
    }
};
