import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import LanguageSelector from "../../../common/presentational/LanguageSelector";
import HamburgerIcon from "../presentational/HamburgerIcon";
import ListSelector from "./ListSelector";
import LogModal from "./LogModal";
import SponsorBanner from "../../../common/presentational/SponsorBanner";

import "../../../styles/header.scss";

const Header = ({ event, showMenuButton }) => { 
    const searchDropdownRef = useRef();

    const handleSearchDropdownClose = () => {
        searchDropdownRef.current.classList.remove("show");
    };

    const homeUrl = event
        ? `/${encodeURIComponent(event.slug)}`
        : "/";
    const showYear = event?.slug < "2022";

    return (
        <div>
            <nav id="header" className="navbar navbar-expand">
                <div className="container header-inner">
                    <Link className="navbar-brand align-middle" to={homeUrl}>
                        <div id="logotype" className="d-inline-block align-top"/>
                        {
                            showYear && <div id="year" className="d-inline-block align-top">{event.slug}</div>
                        }
                    </Link>
                    <div className="navbar-nav ml-auto">
                        <LanguageSelector />
                        {
                            showMenuButton && event && 
                            (
                                <div id="menu-button" className="nav-item dropdown ml-1">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <HamburgerIcon/>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" ref={searchDropdownRef}>
                                        <ListSelector event={event} onClose={handleSearchDropdownClose} />
                                        <LogModal/>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </nav>
            <SponsorBanner event={event} />
        </div>
    );
};

Header.propTypes = {
    event: PropTypes.object,
    showMenuButton: PropTypes.bool
};

export default Header;
