import { sum }  from "./Util";

class NormalizedLengthCalculator {
    calculateNormalizedLengths(topTimes) {
        let topTimeSum = 0;
        let count = 0;
        topTimes.forEach(topTime => {
            if(topTime) {
                topTimeSum += topTime;
                count++;
            }
        });
    
        const normalizedLengths = topTimes.map(topTime => topTime || topTimeSum / count || 1);
    
        const lengthSum = sum(normalizedLengths);
    
        return normalizedLengths.map(normalizedLength =>
            lengthSum === 0 
                ? 1 
                : normalizedLength /= lengthSum
        );
    }
}

export default NormalizedLengthCalculator;