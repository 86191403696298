import React from "react";
import PropTypes from "prop-types";
import { Translate, withLocalize } from "react-localize-redux";
import ListType from "../../../logic/models/ListType";
import { isMultiRace } from "../../../logic/EventUtil";

const columnKeys = [
    "primaryPlace",
    "secondaryPlace",
    "primaryOverallPlaceDifferenceSinceStart",
    "secondaryOverallPlaceDifferenceSinceStart",
    "person",
    "organisation",
    "organisationName",
    "organisationCountryCode",
    "class",
    "primaryElapsedTime",
    "secondaryElapsedTime",
    "primaryTimeBehind",
    "secondaryTimeBehind",
    "elapsedTimePerKilometer",
    "courseLength",
    "startTime",
    "punchingCardNumber",
    "bibNumber"
];

const columnWithPeerKeys = [
    "primaryPlace",
    "secondaryPlace",
    "primaryElapsedTime",
    "secondaryElapsedTime",
    "primaryTimeBehind",
    "secondaryTimeBehind"
];

const returnUndefined = () => undefined;

const ListHeader = ({ event, numberOfResults, listType, orderBy, direction, onColumnClick, translate }) => {
    const handleClick = e => { 
        if (e.target && e.target.closest) {
            onColumnClick(e.target.closest("[data-key]").attributes["data-key"].value);
        }
    };

    const getClassNames = columnKey => {
        const atoms = [columnKey];
        if(columnKey === orderBy) {
            atoms.push("selected");
            atoms.push(direction === 1 ? "ascending" : "descending");
        }
        return atoms.join(" ");
    };

    const getTitleTranslateId = columnKey => {
        let suffix = "";
        if(columnWithPeerKeys.indexOf(columnKey) !== -1) {
            suffix = listType === ListType.overallResultList
                ? ".overall"
                : (isMultiRace(event) ? ".race" : ".singleRace");
        }
        return `eventPage.columns.${columnKey}${suffix}`;
    };

    const getTooltipTranslateId = columnKey => {
        let suffix = "";
        if(columnWithPeerKeys.indexOf(columnKey) !== -1) {
            suffix = listType === ListType.overallResultList
                ? ".overall"
                : (isMultiRace(event) ? ".race" : ".singleRace");
        }
        return `eventPage.columnTooltips.${columnKey}${suffix}`;
    };

    const getTooltip = (columnKey, numberOfResults) =>
        translate(getTooltipTranslateId(columnKey), { numberOfResults }, { onMissingTranslation: returnUndefined }) ?? 
        translate(getTitleTranslateId(columnKey), { numberOfResults });

    const isSelected = columnKey => columnKey === orderBy;

    const filteredColumnKeys = columnKeys
        .filter(o => o !== "punchingCardNumber" || !event.properties.hidePunchingCardNumbers );

    return (   
        <div id="list-header" className="grid-list-header">
            {
                filteredColumnKeys.map(columnKey => 
                    (
                        <div key={columnKey} data-key={columnKey} className={getClassNames(columnKey)} onClick={handleClick}>
                            <div className="grid-list-header-button">
                                <div className="title" title={getTooltip(columnKey, numberOfResults)}>
                                    <Translate id={getTitleTranslateId(columnKey)} data={{ numberOfResults }} />
                                </div>
                                {
                                    isSelected(columnKey) &&
                                    <div className="direction"/>
                                }
                            </div>                            
                        </div>
                    )
                )
            }
        </div>
    );
};

ListHeader.propTypes = {
    event: PropTypes.object,
    numberOfResults: PropTypes.number,
    listType: PropTypes.number,
    orderBy: PropTypes.string,
    direction: PropTypes.number,
    onColumnClick: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired
};     

export default withLocalize(ListHeader);