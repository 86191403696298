import React from "react";
import PropTypes from "prop-types";

const getClassName = organisation => {
    let className = "organisation-icon";
    if(organisation.organisationExternalId) {
        className += " oi-" + organisation.organisationExternalId;
    }
    return className;
};


const OrganisationIcon = ({ organisation }) => 
(
    <span className={getClassName(organisation)} />
);

OrganisationIcon.propTypes = {
    organisation: PropTypes.object
};     

export default OrganisationIcon;