import { useState, useEffect } from "react";
import settings from "../settings";
import runtime from "serviceworker-webpack-plugin/lib/runtime";

export const fireTimerWhenWindowIsVisibleOnly = (timerSetter, onTimerElapsed, timeoutInMilliseconds) => {
    let lastRenderTime = 0;
    const handleTimerElapsedWrapper = () => {
        const now = getCurrentTime();
        if(isWindowVisible() && lastRenderTime + 0.8 * timeoutInMilliseconds < now) {
            onTimerElapsed();
            lastRenderTime = now;
        }
    };
    handleTimerElapsedWrapper();
    const timer = window.setInterval(handleTimerElapsedWrapper, timeoutInMilliseconds);
    timerSetter(timer);

    setupVisibilityChangeHandler(handleTimerElapsedWrapper);
};

export const getCurrentTime = () => new Date().getTime();

export const emptyDomElement = domElement => {
    while (domElement && domElement.firstChild) {
        if (domElement.firstChild.remove) {
            domElement.firstChild.remove();
        } else {
            // IE fix
            domElement.removeChild(domElement.firstChild);
        }
    }    
};

export const setLocalStorageItem = (key, value) => {
    if(window.localStorage) {
        window.localStorage.setItem(key, value);
    }
};

export const getLocalStorageItem = key => {
    if(window.localStorage) {
        return window.localStorage.getItem(key);
    }
    return undefined;
};

let hiddenPropertyName, visibilityChangeEventName;
// https://developer.mozilla.org/en-US/docs/Web/API/Document/hidden
// https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilityState

if (typeof document.visibilityState !== "undefined") {
    hiddenPropertyName = "visibilityState";
    visibilityChangeEventName = "visibilitychange";
} else if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support 
    hiddenPropertyName = "hidden";
    visibilityChangeEventName = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
    hiddenPropertyName = "msHidden";
    visibilityChangeEventName = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
    hiddenPropertyName = "webkitHidden";
    visibilityChangeEventName = "webkitvisibilitychange";
}

export const setupVisibilityChangeHandler = handler => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API

    const handleVisibilityChange = () => {
        const visible = isWindowVisible();
        handler(visible);
    };

    // make sure the browser supports addEventListener and the Page Visibility API
    if (typeof document.addEventListener !== "undefined" && hiddenPropertyName !== undefined) {
        // Handle page visibility change   
        document.addEventListener(visibilityChangeEventName, handleVisibilityChange, false);
    }      
};

const isWindowVisible = () => {
    const value = document[hiddenPropertyName];

    let visible;
    if(hiddenPropertyName === "visibilityState") {
        visible = value !== 'hidden';
    } else { // hiddenPropertyName === *hidden
        visible = value !== false;
    }
    return visible;
};

export const supportsWebPush = () => 'serviceWorker' in navigator && 'PushManager' in window;

export const registerServiceWorker = () => {
    if(supportsWebPush()) {
        return runtime.register();
    }
    return undefined;
};

export const requestNotificationPermission = () => window.Notification.requestPermission();

// urlB64ToUint8Array is a magic function that will encode the base64 public key
// to Array buffer which is needed by the web push subscription option
const urlB64ToUint8Array = base64String => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};

export const getWebPushSubscriptionOptions = () => {
    const applicationServerKey = urlB64ToUint8Array(settings.webPushApplicationServerKey);
    return { 
        applicationServerKey,
        userVisibleOnly: true
    };
};

export const isDeprecatedBrowser = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");

    if (msie !== -1 || !!ua.match(/Trident.*rv:11\./)) {
        return true;
    }

    return false;
};

export const useIntersection = (element, rootMargin) => {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
        const current = element.current;
        if(current) {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    setState(entry.isIntersecting);
                }, { rootMargin }
            );
    
            current && observer.observe(current);
            
            return () => observer.unobserve(current);
        }
        return () => {};
    }, [element.current]);

    return isVisible;
};
