import DefaultSplitTimeTableConfiguration from "./DefaultSplitTimeTableConfiguration";
import SimpleBar from "simplebar";
import "simplebar/dist/simplebar.css";


// class responsible for rendering a split time table to a DOM element
export default class SplitTimeTable {
    constructor(options) {
        if(!options.course) {
            throw "A course object is required."; 
        }

        this.course = options.course;
        this.reflow = this.reflow.bind(this);
    }

    render(element) {
        this.parentElement = element;
        const config = new DefaultSplitTimeTableConfiguration();
        const competitors = config.getSortedCompetitors(this.course.competitors);
        const controls = this.course.controls;
        let clickedCompetitorIndex = undefined;

        // render to a document fragment that is not part of the DOM
        const fragment = document.createDocumentFragment();

        this.tableContainer = document.createElement("div");
        this.tableContainer.className = "winsplits-table";
        fragment.appendChild(this.tableContainer);

        let i, j;

        // *** HEADER ***
        this.headerContainer = document.createElement("div");
        this.headerContainer.className = "winsplits-table-area winsplits-header-container";
        this.tableContainer.appendChild(this.headerContainer);

        config.headerCells.forEach(headerCellRow => {
            const headerCellRowContainer = document.createElement("div");
            headerCellRowContainer.className = "winsplits-header-cell-row";
            this.headerContainer.appendChild(headerCellRowContainer);
    
            headerCellRow.forEach(headerCell => {
                headerCellRowContainer.appendChild(headerCell.createElement());
            });
        });

        // *** CONTROLS ***
        this.controlScrollContainer = document.createElement("div");
        this.controlScrollContainer.className = "winsplits-table-area winsplits-control-scroll-container";
        this.tableContainer.appendChild(this.controlScrollContainer);

        this.controlContainer = document.createElement("div");
        this.controlContainer.className = "winsplits-control-container";
        this.controlScrollContainer.appendChild(this.controlContainer);
        
        for (i=1; i<controls.length; i++) {
            const control = controls[i];
            const controlElement = document.createElement("div");
            controlElement.className = "winsplits-control";

            config.controlHeaderCellTemplates.forEach(controlHeaderCellTemplate => {
                const cell = controlHeaderCellTemplate.createCell(control);
                controlElement.appendChild(cell.createElement());
            });
            this.controlContainer.appendChild(controlElement);
        }

        // *** COMPETITORS ***
        this.competitorScrollContainer = document.createElement("div");
        this.competitorScrollContainer.className = "winsplits-table-area winsplits-competitor-scroll-container";
        this.tableContainer.appendChild(this.competitorScrollContainer);

        this.competitorContainer = document.createElement("div");
        this.competitorContainer.className = "winsplits-competitor-container";
        this.competitorScrollContainer.appendChild(this.competitorContainer);

        for (i=0; i<competitors.length; i++) {
            const competitor = competitors[i];
            const competitorElement = document.createElement("div");
            competitorElement.className = "winsplits-competitor";
            competitorElement.setAttribute("data-rowIndex", i);

            config.competitorHeaderCellTemplates.forEach(competitorHeaderCellTemplate => {
                const cell = competitorHeaderCellTemplate.createCell(competitor);
                competitorElement.appendChild(cell.createElement());
            });
            this.competitorContainer.appendChild(competitorElement);
        }

        // *** SPLIT TIMES ***
        this.splitTimeScrollContainer = document.createElement("div");
        this.splitTimeScrollContainer.className = "winsplits-table-area winsplits-split-time-scroll-container";
        this.tableContainer.appendChild(this.splitTimeScrollContainer);

        this.splitTimeContainer = document.createElement("div");
        this.splitTimeContainer.className = "winsplits-split-time-container";
        this.splitTimeScrollContainer.appendChild(this.splitTimeContainer);

        for (i=0; i<competitors.length; i++) {
            const competitor = competitors[i];
            const splitTimeRowElement = document.createElement("div");
            splitTimeRowElement.className = "winsplits-split-time-row";
            splitTimeRowElement.setAttribute("data-rowIndex", i);
            for (j=1; j<controls.length; j++) {
                const cell = config.splitTimeCellTemplate.createCell(competitor.courseSplitTimes[j]);
                splitTimeRowElement.appendChild(cell.createElement());
            }
            this.splitTimeContainer.appendChild(splitTimeRowElement);
        }

        // add document fragment to DOM
        element.appendChild(fragment);

        // setup scroll handling
        const scrollOptions = { 
            autoHide: true
        };

        this.controlSimpleBar = new SimpleBar(this.controlScrollContainer, scrollOptions);
        this.controlScrollElement = this.controlSimpleBar.getScrollElement();
        
        this.competitorSimpleBar = new SimpleBar(this.competitorScrollContainer, scrollOptions);
        this.competitorScrollElement = this.competitorSimpleBar.getScrollElement();

        this.splitTimeSimpleBar = new SimpleBar(this.splitTimeScrollContainer, scrollOptions);
        this.splitTimeScrollElement = this.splitTimeSimpleBar.getScrollElement();

        let scrollLeft, scrollTop;

        const scrollCallback = () => {
            this.controlScrollElement.scrollLeft = scrollLeft; 

            this.competitorScrollElement.scrollTop = scrollTop; 
    
            this.splitTimeScrollElement.scrollLeft = scrollLeft;
            this.splitTimeScrollElement.scrollTop = scrollTop;
        };

        // EVENT HANDLERS

        // define event handler functions

        this.controlScrollElementOnScroll = () => {
            scrollLeft = this.controlScrollElement.scrollLeft;
            window.requestAnimationFrame(scrollCallback);
        };

        this.competitorScrollElementOnScroll = () => {
            scrollTop = this.competitorScrollElement.scrollTop;
            window.requestAnimationFrame(scrollCallback);
        };

        this.splitTimeScrollElementOnScroll = () => {
            scrollLeft = this.splitTimeScrollElement.scrollLeft;
            scrollTop = this.splitTimeScrollElement.scrollTop;
            window.requestAnimationFrame(scrollCallback);
        };

        this.competitorRowElementOnMouseOver = e => {
            if(clickedCompetitorIndex !== undefined) {
                return;
            }
            const rowIndex = parseInt(e.currentTarget.getAttribute("data-rowIndex"));
            [this.competitorContainer, this.splitTimeContainer].forEach(element => element.children[rowIndex].classList.add("winsplits-hover"));
        };

        this.competitorRowElementOnMouseOut = e => {
            if(clickedCompetitorIndex !== undefined) {
                return;
            }
            const rowIndex = parseInt(e.currentTarget.getAttribute("data-rowIndex"));
            [this.competitorContainer, this.splitTimeContainer].forEach(element => element.children[rowIndex].classList.remove("winsplits-hover"));
        };

        this.competitorRowElementOnClicked = e => {
            const rowIndex = parseInt(e.currentTarget.getAttribute("data-rowIndex"));
            [this.competitorContainer, this.splitTimeContainer].forEach(element => {
                for(let i=0; i<element.children.length; i++) {
                    element.children.item[i]?.classList?.remove("winsplits-hover");
                }
            });
            if (clickedCompetitorIndex !== rowIndex) {
                // clicking on a new row
                clickedCompetitorIndex = rowIndex;
                [this.competitorContainer, this.splitTimeContainer].forEach(element => element.children[rowIndex].classList.add("winsplits-hover"));
            } else {
                clickedCompetitorIndex = undefined;
            }
        };

        // bind event handlers
        this.controlScrollElement.addEventListener("scroll", this.controlScrollElementOnScroll);
        this.competitorScrollElement.addEventListener("scroll", this.competitorScrollElementOnScroll);
        this.splitTimeScrollElement.addEventListener("scroll", this.splitTimeScrollElementOnScroll);

        const addCompetitorHoverEventHandlers = rowElement => {
            rowElement.addEventListener("mouseover", this.competitorRowElementOnMouseOver);
            rowElement.addEventListener("mouseout", this.competitorRowElementOnMouseOut);
            rowElement.addEventListener("click", this.competitorRowElementOnClicked);
        };        
        
        for (i=0; i<competitors.length; i++) {
            addCompetitorHoverEventHandlers(this.competitorContainer.children[i]);
            addCompetitorHoverEventHandlers(this.splitTimeContainer.children[i]);
        }

        this.reflow();

        window.addEventListener("resize", this.reflow);
    }

    reflow() {
        const tableHorizontalBorderWidth = this.tableContainer.offsetWidth - this.tableContainer.clientWidth; 
        const tableVerticalBorderWidth = this.tableContainer.offsetHeight - this.tableContainer.clientHeight; 
        const availableWidth = this.parentElement.clientWidth;
        const availableHeight = this.parentElement.clientHeight;
        const tableWidth = this.headerContainer.clientWidth + this.controlContainer.clientWidth + tableHorizontalBorderWidth;
        const tableHeight = this.headerContainer.clientHeight + this.competitorContainer.clientHeight + tableVerticalBorderWidth;

        const widths = [
            this.headerContainer.clientWidth,
            Math.min(availableWidth - this.headerContainer.clientWidth - tableHorizontalBorderWidth, this.controlContainer.clientWidth)
        ];
        const heights = [
            this.headerContainer.clientHeight,
            Math.min(availableHeight - this.headerContainer.clientHeight - tableVerticalBorderWidth, this.competitorContainer.clientHeight)
        ];

        this.controlScrollContainer.style.left = `${widths[0]}px`;
        this.controlScrollContainer.style.width = `${widths[1]}px`;
        this.competitorScrollContainer.style.top = "0";
        this.competitorScrollContainer.style.height = `${heights[0]}px`;

        this.competitorScrollContainer.style.left = "0";
        this.competitorScrollContainer.style.width = `${widths[0]}px`;
        this.competitorScrollContainer.style.top = `${heights[0]}px`;
        this.competitorScrollContainer.style.height = `${heights[1]}px`;

        this.splitTimeScrollContainer.style.left = `${widths[0]}px`;
        this.splitTimeScrollContainer.style.width = `${widths[1]}px`;
        this.splitTimeScrollContainer.style.top = `${heights[0]}px`;
        this.splitTimeScrollContainer.style.height = `${heights[1]}px`;

        this.tableContainer.style.width = Math.min(tableWidth, availableWidth) + "px";
        this.tableContainer.style.height = Math.min(tableHeight, availableHeight) + "px";

        [ this.controlSimpleBar, this.competitorSimpleBar, this.splitTimeSimpleBar]
            .forEach(simpleBar => simpleBar.recalculate());
    }

    destroy() {
        this.controlScrollElement.removeEventListener("scroll", this.controlScrollElementOnScroll);
        this.competitorScrollElement.removeEventListener("scroll", this.competitorScrollElementOnScroll);
        this.splitTimeScrollElement.removeEventListener("scroll", this.splitTimeScrollElementOnScroll);

        const removeCompetitorHoverEventHandlers = rowElement => {
            rowElement.removeEventListener("mouseover", this.competitorRowElementOnMouseOver);
            rowElement.removeEventListener("mouseout", this.competitorRowElementOnMouseOut);
            rowElement.removeEventListener("click", this.competitorRowElementOnClicked);
        };
        for (let i=0; i<this.competitorContainer.children.length; i++) {
            removeCompetitorHoverEventHandlers(this.competitorContainer.children[i]);
            removeCompetitorHoverEventHandlers(this.splitTimeContainer.children[i]);
        }


        window.removeEventListener("resize", this.reflow);
    }
}
