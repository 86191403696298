// see https://redux.js.org/advanced/async-actions
import * as types from "./actionTypes";
import { getFavoriteEntryIds, setFavoriteEntryIds, setHasFavoriteSubscription } from "../logic/ListUtil";
import { sortResults } from "../logic/AllRacesUtil";

import { structureResultsForAllRaces } from "../logic/ResultUtil";
import Api from "../logic/Api";
import { logEvent, Event } from "../logic/LoggingUtil";
import { registerServiceWorker, requestNotificationPermission, getWebPushSubscriptionOptions } from "../logic/BrowserUtil";
import { isEmbeddedInMobileApp, getMobileAppPushNotificationHandle } from "../logic/MobileAppUtil";

export const requestEvent = eventSlug => ({
    type: types.REQUEST_EVENT,
    eventSlug
});
  
export const receiveEventSuccess = event => ({
    type: types.RECEIVE_EVENT_SUCCESS,
    event
});

export const receiveEventFailure = (eventSlug, error) => ({
    type: types.RECEIVE_EVENT_FAILURE,
    eventSlug,
    error
});

export const fetchEvent = eventSlug => {
    return dispatch => {
        dispatch(requestEvent(eventSlug));
        return Api.getEvent(
            eventSlug,
            event => dispatch(receiveEventSuccess(event)),
            error => dispatch(receiveEventFailure(eventSlug, error))
        );
    };
};

export const requestResults = list => ({
    type: types.REQUEST_RESULTS,
    list
});
  
export const receiveResultsSuccess = (results, list) => ({
    type: types.RECEIVE_RESULTS_SUCCESS,
    results,
    list
});

export const receiveResultsFailure = error => ({
    type: types.RECEIVE_RESULTS_FAILURE,
    error
});

export const setList = list => ({
    type: types.SET_LIST,
    list
});

export const fetchResults = (list, treatAsLiveResults) => {
    // treatAsLiveResults is set to true when the user re-activates a tab that has been hidden
    // in this case results should be loaded, but no loading indicator should be shown

    return (dispatch, getState) => {
        const state = getState();
        const event = state.eventPage.event;
        const successCallback = treatAsLiveResults
            ? receiveLiveResultsSuccess
            : receiveResultsSuccess;
        // only update UI to be in loading state etc. when the fetch is a non-live result fetch
        const dispatchRequestResults = () => {
            if (!treatAsLiveResults) {
                dispatch(requestResults(list));
            }
        };

        if(list.classIds && list.classIds.length) {
            dispatchRequestResults();
            return Api.getClassResults(
                event, list.raceId, list.classIds,
                results => dispatch(successCallback(results, list)),
                error => dispatch(receiveResultsFailure(error))
            );
        } else if (list.organisationKey) {
            dispatchRequestResults();
            return Api.getOrganisationResults(
                event, list.raceId, list.organisationKey, 
                results => dispatch(successCallback(results, list)),
                error => dispatch(receiveResultsFailure(error))
            );
        } else if (list.entryIds) {
            dispatchRequestResults();
            return Api.getResultsForEntryIds(
                event, list.raceId, list.entryIds, 
                results => dispatch(successCallback(results, list)),
                error => dispatch(receiveResultsFailure(error))
            );
        } else if (list.tag) {
            dispatchRequestResults();
            return Api.getResultsForTag(
                event, list.raceId, list.tag, 
                results => dispatch(successCallback(results, list)),
                error => dispatch(receiveResultsFailure(error))
            );
        } else {
            dispatch(successCallback(null));
        }        
    };
};

export const requestResultsForEntry = entryId => ({
    type: types.REQUEST_RESULTS_FOR_ENTRY,
    entryId
});
  
export const receiveResultsForEntrySuccess = (results, entryId) => ({
    type: types.RECEIVE_RESULTS_FOR_ENTRY_SUCCESS,
    results,
    entryId
});

export const setResultsForEntry = (results, entryId) => ({
    type: types.SET_RESULTS_FOR_ENTRY,
    results,
    entryId
});

const receiveResultsForEntryFailure = () => ({
    type: types.RECEIVE_RESULTS_FOR_ENTRY_FAILURE
});

export const fetchResultsForEntry = (entryId) => {
    return (dispatch, getState) => {
        const state = getState();
        const event = state.eventPage.event;
        dispatch(requestResultsForEntry(entryId));
        return Api.getResultsForEntryId(
            event, entryId, 
            results => dispatch(receiveResultsForEntrySuccess(results, entryId)),
            error => dispatch(receiveResultsForEntryFailure())
        );
    };
};

export const requestResultsForAllRaces = list => ({
    type: types.REQUEST_RESULTS_FOR_ALL_RACES,
    list
});
  
export const receiveResultsForAllRacesSuccess = (results, list, event) => {
    const structuredResults = structureResultsForAllRaces(results);
    sortResults(structuredResults, list, event);
    return {
        type: types.RECEIVE_RESULTS_FOR_ALL_RACES_SUCCESS,
        results: structuredResults,
        list
    };
};

export const setResultsForAllRaces = (results) => ({
    type: types.SET_RESULTS_FOR_ALL_RACES,
    results
});

const receiveResultsForAllRacesFailure = () => ({
    type: types.RECEIVE_RESULTS_FOR_ALL_RACES_FAILURE
});

export const fetchResultsForAllRaces = (list) => {
    return (dispatch, getState) => {
        const state = getState();
        const event = state.eventPage.event;
        dispatch(requestResultsForAllRaces(list));
        if(list.classIds) {
            return Api.getClassResultsForAllRaces(
                event,
                list.classIds[0],
                results => dispatch(receiveResultsForAllRacesSuccess(results, list, event)),
                error => dispatch(receiveResultsForAllRacesFailure())
            );
        } else if (list.organisationKey) {
            return Api.getOrganisationResultsForAllRaces(
                event,
                list.organisationKey,
                results => dispatch(receiveResultsForAllRacesSuccess(results, list, event)),
                error => dispatch(receiveResultsForAllRacesFailure())
            );
        } else if (list.entryIds) {
            return Api.getResultsForEntryIdsForAllRaces(
                event,
                list.entryIds,
                results => dispatch(receiveResultsForAllRacesSuccess(results, list, event)),
                error => dispatch(receiveResultsForAllRacesFailure())
            );
        }
    };
};

export const receiveLiveResultsSuccess = results => ({
    type: types.RECEIVE_LIVE_RESULTS_SUCCESS,
    results
});

const requestSampleUpdatesForList = list => ({
    type: types.REQUEST_SAMPLE_UPDATES_FOR_LIST,
    list
});
  
const receiveSampleUpdatesForListSuccess = (sampleUpdateResponse, list) => ({
    type: types.RECEIVE_SAMPLE_UPDATES_FOR_LIST_SUCCESS,
    sampleUpdateResponse,
    list
});

const receiveSampleUpdatesFailure = () => ({
    type: types.RECEIVE_SAMPLE_UPDATES_FOR_LIST_FAILURE
});

export const fetchSampleUpdatesForList = list => {
    return (dispatch, getState) => {
        const state = getState();
        const event = state.eventPage.event;
        if (list.organisationKey) {
            dispatch(requestSampleUpdatesForList(list));
            return Api.getSampleUpdatesForOrganisation(
                event, list.raceId, list.organisationKey, state.eventPage.lastSampleUpdatesServerTimestamp,
                sampleUpdateResponse => dispatch(receiveSampleUpdatesForListSuccess(sampleUpdateResponse, list)),
                error => dispatch(receiveSampleUpdatesFailure())
            );
        } else if (list.entryIds) {
            dispatch(requestSampleUpdatesForList(list));
            return Api.getSampleUpdatesForEntries(
                event, list.raceId, list.entryIds, state.eventPage.lastSampleUpdatesServerTimestamp,
                sampleUpdateResponse => dispatch(receiveSampleUpdatesForListSuccess(sampleUpdateResponse, list)),
                error => dispatch(receiveSampleUpdatesFailure())
            );
        } else if (list.tag) {
            dispatch(requestSampleUpdatesForList(list));
            return Api.getSampleUpdatesForTag(
                event, list.raceId, list.tag, state.eventPage.lastSampleUpdatesServerTimestamp,
                sampleUpdateResponse => dispatch(receiveSampleUpdatesForListSuccess(sampleUpdateResponse, list)),
                error => dispatch(receiveSampleUpdatesFailure())
            );
        } else {
            dispatch(receiveSampleUpdatesForListSuccess(null));
        }        
    };    
};

const requestSampleUpdatesForEntry = entryId => ({
    type: types.REQUEST_SAMPLE_UPDATES_FOR_ENTRY,
    entryId
});
  
const receiveSampleUpdatesForEntrySuccess = (sampleUpdateResponse, entryId) => ({
    type: types.RECEIVE_SAMPLE_UPDATES_FOR_ENTRY_SUCCESS,
    sampleUpdateResponse,
    entryId
});

const receiveSampleUpdatesForEntryFailure = () => ({
    type: types.RECEIVE_SAMPLE_UPDATES_FOR_ENTRY_FAILURE
});

export const fetchSampleUpdatesForEntry = entryId => {
    return (dispatch, getState) => {
        const state = getState();
        const event = state.eventPage.event;
        dispatch(requestSampleUpdatesForEntry(entryId));
        return Api.getSampleUpdatesForEntry(
            event, entryId, state.eventPage.lastSampleUpdatesServerTimestamp,
            results => dispatch(receiveSampleUpdatesForEntrySuccess(results, entryId)),
            error => dispatch(receiveSampleUpdatesForEntryFailure())
        );
    };
};

export const addFavoriteEntryId = (event, entryId, activeLanguage) => {
    let favoriteEntryIds = getFavoriteEntryIds(event.eventId);

    favoriteEntryIds = favoriteEntryIds.filter(o => o !== entryId);
    favoriteEntryIds.push(entryId);

    setFavoriteEntryIds(event.eventId, favoriteEntryIds);
    updateFavoriteSubscription(event, favoriteEntryIds, activeLanguage);

    logEvent(Event.addFavorite);
    
    return {
        type: types.FAVORITES_CHANGED,
        favoriteEntryIds: favoriteEntryIds
    };
};

export const removeFavoriteEntryId = (event, entryId, activeLanguage) => {
    let favoriteEntryIds = getFavoriteEntryIds(event.eventId);

    favoriteEntryIds = favoriteEntryIds.filter(o => o !== entryId);

    setFavoriteEntryIds(event.eventId, favoriteEntryIds);
    updateFavoriteSubscription(event, favoriteEntryIds, activeLanguage);

    logEvent(Event.removeFavorite);

    return {
        type: types.FAVORITES_CHANGED,
        favoriteEntryIds: favoriteEntryIds
    };
};

export const createFavoriteSubscription = (event, favoriteEntryIds, activeLanguage) => {
    // TODO: make more robust with regard to push technology

    return dispatch => {
        if(isEmbeddedInMobileApp()) {
            // experimental
            updateFavoriteSubscription(event, favoriteEntryIds, activeLanguage, dispatch);
            return;
        }
    
        // first, we must ask the user for notification sending authority
        requestNotificationPermission().then(result => {
            if (result === "granted") {
                // then, register the service worker (should probably be made at application start)
                const serviceWorker = registerServiceWorker();
                if (serviceWorker) {
                    serviceWorker.then(registration => {
                        if (registration) {
                            // there might be a subscription already
                            registration.pushManager.getSubscription().then(subscription => {
                                if (subscription) {
                                    // a subscription already exists
                                    // update it
                                    persistSubscription(
                                        "web",
                                        subscription.endpoint,
                                        createWebPushFavoriteSubscriptionConfiguration(subscription),
                                        event,
                                        favoriteEntryIds,
                                        activeLanguage,
                                        dispatch,
                                        "updateFavoriteSubscription"
                                    );
                                    logEvent(Event.updateFavoriteSubscription);
                                } else {
                                    // no subscription exists
                                    // create it
                                    const options = getWebPushSubscriptionOptions();
                                    registration.pushManager.subscribe(options).then(newSubscription => {
                                        if (newSubscription) {
                                            persistSubscription(
                                                "web",
                                                newSubscription.endpoint,
                                                createWebPushFavoriteSubscriptionConfiguration(newSubscription),
                                                event,
                                                favoriteEntryIds,
                                                activeLanguage,
                                                dispatch,
                                                "createFavoriteSubscription"
                                            );
                                            logEvent(Event.createFavoriteSubscription);
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            }
        });
    };
};

const persistSubscription = (pushNotificationType, identifier, pushNotificationConfiguration, event, favoriteEntryIds, activeLanguage, dispatch, method) => {
    Api[method](
        event.eventId,
        favoriteEntryIds,
        pushNotificationType,
        identifier,
        pushNotificationConfiguration,
        activeLanguage.code,
        result => {
            setHasFavoriteSubscription(event.eventId, true);
            if (dispatch) {
                dispatch({
                    type: types.FAVORITE_SUBSCRIPTION_CREATED,
                    event,
                    favoriteEntryIds,
                    activeLanguage,
                    pushNotificationType,
                    identifier,
                    pushNotificationConfiguration
                });
            }
        },
        // eslint-disable-next-line no-console
        error => console.error("Failed"));
};

export const deleteFavoriteSubscription = event => {
    return dispatch => {
        const serviceWorker = registerServiceWorker();
        if(serviceWorker) {
            serviceWorker.then(registration => {
                registration.pushManager.getSubscription().then(subscription => {
                    if (subscription) {
                        subscription.unsubscribe().then(successful => {
                            if (successful) {
                                Api.deleteFavoriteSubscription(
                                    event.eventId,
                                    "web",
                                    subscription.endpoint,
                                    result => {
                                        setHasFavoriteSubscription(event.eventId, false);
                                        logEvent(Event.deleteFavoriteSubscription);
                                        dispatch({
                                            type: types.FAVORITE_SUBSCRIPTION_DELETED,
                                            subscription
                                        });
                                    },
                                    // eslint-disable-next-line no-console
                                    error => console.error("Failed"));
                            }
                        });
                    }
                });
            });
        }
    };
};

const updateFavoriteSubscription = (event, favoriteEntryIds, activeLanguage, dispatch) => {
    if(isEmbeddedInMobileApp()) {
        // experimental
        const { handle, platform } = getMobileAppPushNotificationHandle();

        // example of how to send data to the mobile app
        // NOTE: the method name is case-sentitive!
        // NOTE: only primitive types as string, int, etc. can be used as parameter types
        const result = window.WebAppToMobileAppChannel.MyMethod('param1', 2);
        alert("Result: " + JSON.stringify(result));

        if(handle) {
            persistSubscription(
                "mobileApp",
                handle,
                { platform },
                event,
                favoriteEntryIds,
                activeLanguage,
                dispatch,
                "createFavoriteSubscription" // always call create; if subscription already exists it is updated automatically
            );
        }
        return;
    }
    
    // if there is a favorite subscription, post to backend
    const serviceWorker = registerServiceWorker();
    if (serviceWorker) {
        serviceWorker.then(registration => {
            if (registration) {
                registration.pushManager.getSubscription().then(subscription => {
                    if(subscription) {
                        Api.updateFavoriteSubscription(
                            event.eventId,
                            favoriteEntryIds,
                            "web",
                            subscription.endpoint,
                            createWebPushFavoriteSubscriptionConfiguration(subscription),
                            activeLanguage.code,
                            // eslint-disable-next-line no-console
                            result => {},
                            // eslint-disable-next-line no-console
                            error => console.error("Failed"));
                    }
                });
            }
        });
    }
};

export const updateLandingPageSelectedRaceId = raceId => ({
    type: types.UPDATE_LANDING_PAGE_SELECTED_RACE_ID,
    raceId
});

export const writeLogItem = logItem => ({
    type: types.WRITE_LOG_ITEM,
    logItem
});

const createWebPushFavoriteSubscriptionConfiguration = subscription => {
    // need to serialize and deserialize to handle ArrayBuffers correctly - JSON.stringify does some magic!
    const roundtrippedSubscription = JSON.parse(JSON.stringify(subscription));
    // only the keys property is of interest
    return {
        keys: roundtrippedSubscription.keys
    };
};