import ClassJsonReader from "./ClassJsonReader";
import OrganisationJsonReader from "./OrganisationJsonReader";
import RaceJsonReader from "./RaceJsonReader";
import ClassCategoryJsonReader from "./ClassCategoryJsonReader";
import { getOrganisationKey } from "../OrganisationUtil";
import { parseDateString } from "./DateHelpers";

// kind of ugly
import moment from "moment";
import "../../lib/moment-timezone/moment-timezone";

const classReader = new ClassJsonReader();
const organisationReader = new OrganisationJsonReader();
const raceReader = new RaceJsonReader();
const classCategoryReader = new ClassCategoryJsonReader();

export default class EventJsonReader {
    read(json) {
        const ev = {};
        ev.eventId = json.i;
        ev.slug = json.s;
        ev.name = json.n;
        ev.startTime = parseDateString(json.st);
        ev.endTime = parseDateString(json.et);
        ev.timeZone = json.t;
        if(json.tc && !moment.tz.zone(ev.timeZone)) {
            // this Moment.js time zone has not been loaded previously
            // json.tc contains the time zone configuration that is required to load it
            // we don't add it to the event object though, as it is not needed elsewhere
            moment.tz.add(json.tc);
        }
        ev.useMemoryDataAccessFrom = parseDateString(json.mdf);
        ev.useMemoryDataAccessTo = parseDateString(json.mdt);
        ev.organisationIconSpriteBaseUrl = json.oisbu;
        ev.useTags = json.ut;
        ev.testingRule = json.tr 
            ? {
                zeroTime: parseDateString(json.tr.z),
                timeWindow: json.tr.w
            }
            : undefined;

        if (json.c) {
            ev.classes = [];
            json.c.forEach(o => {
                const cl = classReader.read(o);
                ev.classes[cl.classId] = cl;
            });
        }

        if (json.r) {
            ev.races = [];
            json.r.forEach(o => {
                const race = raceReader.read(o);
                ev.races[race.raceId] = race;
            });
        }

        if (json.cc) {
            ev.classCategories = [];
            json.cc.forEach(o => {
                const classCategory = classCategoryReader.read(o);
                ev.classCategories[classCategory.classCategoryId] = classCategory;
            });
        }

        if (json.o) {
            ev.organisations = [];
            json.o.forEach(o => {
                const organisation = organisationReader.read(o);
                ev.organisations[getOrganisationKey(organisation)] = organisation;
            });
        }

        if (json.p) {
            ev.properties = {};
            json.p.forEach(o => {
                try {
                    ev.properties[toCamelCase(o.k)] = JSON.parse(o.v);
                } catch(e) {
                    // if JSON.parse fails, just swallow the exception
                }
            });
        }

        return ev;
    }
}

const toCamelCase = s => s.substring(0, 1).toLowerCase() + s.substring(1);