import AccumulationType from "../model/AccumulationType";

class PerformanceIndexCalculator {
    calculatePerformanceIndices(times, topTimes) {
        validatePreconditions(times, topTimes);
        
        const performanceIndices = times.map((time, i) => ({
            [AccumulationType.leg]: time && topTimes[i] ? topTimes[i] / time : undefined
        }));

        let timeSum = 0;
        let topTimeSum = 0;
        times.forEach((time, i) => {
            if (time && topTimes[i]) {
                timeSum += time;
                topTimeSum += topTimes[i];
                performanceIndices[i][AccumulationType.sinceStart] = topTimeSum / timeSum;
            } else {
                performanceIndices[i][AccumulationType.sinceStart] = undefined;
            }
        });

        return performanceIndices;
    }
}

export default PerformanceIndexCalculator;

const validatePreconditions = (times, topTimes) => {
    if (!times) {
        throw "Times array must be set.";
    }
    if (!topTimes) {
        throw "Top times array must be set.";
    }
    if (times.length !== topTimes.length) {
        throw "The times and top times array must contain the same number of elements.";
    }
};
