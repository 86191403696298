// returns number of milliseconds since 1970-01-01T00:00:00Z.
export const parseDateString = dateString => {
    if(!dateString) {
        return undefined;
    }
    // all datetimes are considered to be UTC, regardless of whether the Z suffix is present or not
    if(dateString.substr(-1) !== "Z") {
        dateString += "Z";
    }
    return Date.parse(dateString);
};
