import ControlType from "../model/ControlType";

export const formatElapsedTime = (seconds, accuracy) => {
    // TODO: handle accuracy
    if (seconds === undefined) {
        return "";
    }

    const sign = seconds < 0 ? "-" : "";
    const absSeconds = Math.abs(seconds);

    const h = Math.floor(absSeconds / 3600),
        m = Math.floor(absSeconds / 60) % 60,
        s = Math.floor(absSeconds % 60);

    if (h > 0) {
        return sign + h + ":" +
            (m < 10 ? "0" : "") + m + ":" + 
            (s < 10 ? "0" : "") + s;
    }

    return sign + m + ":" +
        (s < 10 ? "0" : "") + s;
};

export const formatTimeBehind = (seconds, accuracy) => {
    if (seconds === undefined) {
        return "";
    }
    const sign = seconds < 0 ? "" : "+";

    return sign + formatElapsedTime(seconds, accuracy);
};

export const formatPlace = (place, encloseInParantheses = true) => {
    if (!place) {
        return "";
    }
    if (encloseInParantheses) {
        return `(${place})`;
    }
    return place;
};

export const formatControlName = (control, strings) => {
    switch (control.type) {
        case ControlType.start: return strings.startAbbreviated;
        case ControlType.finish: return strings.finishAbbreviated;
        default: return control.number;
    }
};

export const formatControlCode = (control, strings, encloseInParantheses = true) => {
    let text;
    switch (control.type) {
        case ControlType.start: text = strings.startAbbreviated; break;
        case ControlType.finish: text = strings.finishAbbreviated; break;
        default: text = control.code; break;
    }
    if (encloseInParantheses) {
        return `(${text})`;
    }
    return text;
};
