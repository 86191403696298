import React, { useEffect } from "react";
import { useIntersection } from "../../../logic/BrowserUtil";
import { Typeahead, Highlighter } from "react-bootstrap-typeahead";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import OrganisationIcon from "../presentational/OrganisationIcon";
import { Field } from "formik";
import { getOrganisationKey, formatOrganisationName } from "../../../logic/OrganisationUtil";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";

const OrganisationBox = ({ name, organisations, onChange, translate, autoFocus = false, focusOnVisible = false }) => {
    const typeaheadRef = React.createRef();
    const visibilityRef = React.createRef();
    const visible = useIntersection(visibilityRef, '0px');

    useEffect(() => {
        if(visible && focusOnVisible) {
            typeaheadRef.current.focus();
        }
    }, [visible, focusOnVisible]);

    const renderMenuItemChildren = (organisation, { text }) => {
        return [
            <OrganisationIcon key="icon" organisation={organisation}/>,
            <Highlighter key="name" search={text}>
                {formatOrganisationName(organisation)}
            </Highlighter>
        ];
    };

    const handleChange = (fieldProps, newValue) => {
        fieldProps.form.setFieldValue(name, getOrganisationKey(newValue[0]));
        if(onChange) {
            onChange(newValue[0]);
        }
    };

    return (
        <Field name={name}>
            {
                fieldProps => {
                    const selectedOrganisation = organisations.find(o => getOrganisationKey(o) == fieldProps.field.value);
                    return (
                        <div ref={visibilityRef}>
                            <Typeahead
                                id="organisation-box-typeahead"
                                placeholder={translate("eventPage.organisationBox.placeholder")}
                                emptyLabel={translate("typeahead.emptyLabel")}
                                paginationText={translate("typeahead.paginationText")}
                                highlightOnlyResult
                                options={organisations}
                                selected={selectedOrganisation ? [selectedOrganisation] : []}
                                renderMenuItemChildren={renderMenuItemChildren}
                                labelKey={formatOrganisationName}
                                onChange={newValue => handleChange(fieldProps, newValue)}
                                clearButton
                                bodyContainer
                                autoFocus={autoFocus}
                                ref={typeaheadRef}
                            />
                        </div>
                    );
                }
            }
        </Field>            
    );
};

OrganisationBox.propTypes = {
    name: PropTypes.string.isRequired,
    organisations: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    autoFocus: PropTypes.bool,
    focusOnVisible: PropTypes.bool,
    translate: PropTypes.func.isRequired
};

export default withLocalize(OrganisationBox);