import React from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import { Field } from "formik";
import { getListTypeLabelKey } from "../../../logic/ListUtil";

const ListTypeBox = ({ id, name, listTypes, isMultiRace, onChange, translate }) => {
    const handleChange = (fieldProps, domEvent) => {
        fieldProps.form.setFieldValue(name, domEvent.target.value); // always a string value
        if(onChange) {
            onChange(parseInt(domEvent.target.value));
        }
    };

    return (
        <Field name={name}>
            {
                fieldProps => (
                    <select id={id} name={name} className="form-control" value={fieldProps.field.value} onChange={domEvent => handleChange(fieldProps, domEvent)}>
                        {
                            listTypes.map(listType =>
                                (
                                    <option key={listType} value={listType}>
                                        {translate(getListTypeLabelKey(listType, isMultiRace))}
                                    </option>
                                )   
                            )
                        }
                    </select>
                )
            }
        </Field>
    );
};

ListTypeBox.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    listTypes: PropTypes.array.isRequired,
    isMultiRace: PropTypes.bool,
    onChange: PropTypes.func,
    translate: PropTypes.func.isRequired
};

export default withLocalize(ListTypeBox);