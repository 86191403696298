import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getUrlForEntry } from "../../../logic/ListUtil";

const ResultListItem = ({ personResultGroup }) => {
    const getYear = time => new Date(time).getFullYear();

    const personResult = personResultGroup[0];
    const organisationName = (personResult.organisation ?? {}).name;

    return (
        <tr>
            <td className="year">
                {getYear(personResult.event.startTime)}
            </td>
            <td className="person-name">
                <div>
                    <Link to={getUrlForEntry(personResult.event.slug, personResult.entryId)}>
                        {personResult.person.firstName} {personResult.person.lastName}
                    </Link>
                </div>
                <div className="mobile-organisation-name">
                    {organisationName}
                </div>
            </td>
            <td className="organisation-name">
                {organisationName}
            </td>
            <td className="class-name">
                {personResult.cl.name}
            </td>
        </tr>
    );
};

ResultListItem.propTypes = {
    personResultGroup: PropTypes.array.isRequired,
};

export default ResultListItem;