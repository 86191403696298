import React, { useEffect } from "react";
import { useIntersection } from "../../../logic/BrowserUtil";
import { Typeahead } from "react-bootstrap-typeahead";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import { Field } from "formik";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";

const ClassBox = ({ name, id = "class-box-typeahead", classes, labelRenderer, onChange, translate, autoFocus = false, focusOnVisible = false }) => {
    const typeaheadRef = React.createRef();
    const visibilityRef = React.createRef();
    const visible = useIntersection(visibilityRef, '0px');

    useEffect(() => {
        if(visible && focusOnVisible) {
            typeaheadRef.current.focus();
        }
    }, [visible, focusOnVisible]);
    
    const handleChange = (fieldProps, newValue) => {
        fieldProps.form.setFieldValue(name, newValue[0]?.classId);
        if(onChange) {
            onChange(newValue[0]);
        }
    };

    return (
        <Field name={name}>
            {
                fieldProps => {
                    const selectedClass = classes.find(o => o.classId == fieldProps.field.value);
                    return (
                        <div ref={visibilityRef}>
                            <Typeahead
                                id={id}
                                placeholder={translate("eventPage.classBox.placeholder")}
                                emptyLabel={translate("typeahead.emptyLabel")}
                                highlightOnlyResult
                                paginate={false}
                                maxResults={1000}
                                options={classes}
                                selected={selectedClass ? [selectedClass] : []}
                                renderMenuItemChildren={labelRenderer}
                                labelKey={labelRenderer}
                                onChange={newValue => handleChange(fieldProps, newValue)}
                                clearButton
                                bodyContainer
                                autoFocus={autoFocus}
                                ref={typeaheadRef}
                            />
                        </div>
                    );
                }
            }
        </Field>            
    );
};

ClassBox.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    classes: PropTypes.array.isRequired,
    labelRenderer: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    autoFocus: PropTypes.bool,
    focusOnVisible: PropTypes.bool,
    translate: PropTypes.func.isRequired
};

export default withLocalize(ClassBox);