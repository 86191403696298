import EventJsonReader from "./EventJsonReader";
import { parseDateString } from "./DateHelpers";
import ResultStatus from "../models/ResultStatus";

const eventJsonReader = new EventJsonReader();

export default class PersonResultJsonReader {
    read(json, includeDeleted = false) {
        if(Array.isArray(json)) {
            return json.map(o=>this.read(o, includeDeleted)).filter(o=>o);
        }

        if(json.d && !includeDeleted) {
            return undefined;
        }

        const personResult = {};
        personResult.resultId = json.re;
        personResult.event = eventJsonReader.read(json.ev);
        personResult.raceId = json.r;
        personResult.externalRaceId = json.er;
        personResult.entryId = json.e;
        if (json.c) {
            personResult.cl = {
                classId: json.c.i,
                externalClassId: json.c.ei,
                name: json.c.n,
                sequence: json.c.s,
                timePresentation: json.c.t
            };
        }
        if (json.p) {
            personResult.person = {
                personId: json.p.i,
                firstName: json.p.f,
                lastName: json.p.l,
                birthYear: json.p.b,
                sex: json.p.s,
                nationalityCode: json.p.n
            };
        }
        if (json.o) {
            personResult.organisation = {
                organisationId: json.o.i,
                organisationExternalId: json.o.ei,
                name: json.o.n,
                countryCode: json.o.c
            };
        }
        personResult.bibNumber = json.b;
        personResult.punchingCardNumber = json.pc;
        personResult.startTime = parseDateString(json.st);
        personResult.overallElapsedTimeAtStart = json.ot;
        personResult.timePresentation = json.tp;
        personResult.isDeleted = json.d || false;
        personResult.modifiedTime = parseDateString(json.m);
        personResult.databaseModifiedTime = parseDateString(json.dm);
        personResult.samples = (json.s || [])
            .map(o => {
                const sample = createSample(o);
                sample.resultId = personResult.resultId;
                return sample;
            })
            .filter(o => includeDeleted || o.status !== ResultStatus.deleted);
        personResult.punches = createPunches(json.pd);
        personResult.tags = json.t;
        personResult.matchesResultSelectionFilter = json.mrsf;

        return personResult;
    }
}

const createPunches = jsonPunches => {
    const punches = [];
    if(jsonPunches) {
        for(let i=0; i<jsonPunches.length; i+=2) {
            const punch = {};
            punch.code = jsonPunches[i];
            if(punch.code === null) {
                punch.code = undefined;
            }
            punch.elapsedTime = jsonPunches[i+1];
            if(punch.elapsedTime === null) {
                punch.elapsedTime = undefined;
            }
            punches.push(punch);
        }
    }
    return punches;
};

const createSample = jsonSample => {
    const sample = {};
    sample.sampleId = jsonSample.si;
    sample.resultId = jsonSample.r;
    sample.accumulationType = jsonSample.a;
    sample.controlType = jsonSample.ct;
    sample.controlName = jsonSample.cn;
    sample.elapsedTime = jsonSample.et;
    sample.timeBehind = jsonSample.tb;
    sample.passedTime = parseDateString(jsonSample.pt);
    sample.place = jsonSample.p;
    sample.overallPlaceDifferenceSinceStart = jsonSample.d;
    sample.status = jsonSample.s;
    sample.modifiedTime = parseDateString(jsonSample.m);
    sample.databaseModifiedTime = parseDateString(jsonSample.dm);
    return sample;
};
