import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Header from "./Header";
import { withLocalize } from "react-localize-redux";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import { Button, Alert } from "reactstrap";
import { Formik, Form, Field } from "formik";
import Api from "../../../logic/Api";

const ClearCachePage = ({ translate }) => {
    const [executing, setExecuting] = useState(false);
    const [clearCacheStatus, setClearCacheStatus] = useState(undefined);
    const event = useSelector(state => state.eventPage.event);
    const loading = useSelector(state => state.eventPage.loading.event) || executing;

    const handleSubmit = formValues => {
        setExecuting(true);
        Api.clearCache(
            event.eventId,
            formValues.apiKey,
            () => {
                setClearCacheStatus("success");
                setExecuting(false);
            },
            () => {
                setClearCacheStatus("failure");
                setExecuting(false);
            },
        );
    };

    const initialValues = {
        apiKey: ""
    };

    return (
        <div id="clear-cache-page" className="page">
            <Header 
                event={event}
            />

            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader />}
                text={translate("loading")} />

            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                { form => 
                    (
                        <Form>
                            <div className="form-group">
                                <label htmlFor="apiKey">API key</label>
                                <Field 
                                    type="password"
                                    id="apiKey"
                                    name="apiKey"
                                    className="form-control"
                                />
                            </div>

                            <Button color="primary" type="submit" disabled={loading}>Clear cache</Button>
                        </Form>
                    )
                }
            </Formik>

            <br/>
            <br/>

            {
                clearCacheStatus === "success" && !loading &&
                (
                    <Alert color="success">
                        Cache cleared successfully.
                    </Alert>
                )
            }

            {
                clearCacheStatus === "failure" && !loading &&
                (
                    <Alert color="danger">
                        Failed clearing cache. Invalid API key?
                    </Alert>
                )
            }

        </div>
    );
};

ClearCachePage.propTypes = {
    translate: PropTypes.func.isRequired
};
  
export default withLocalize(ClearCachePage);