import React from "react";
import { withLocalize, Translate } from "react-localize-redux";
import PropTypes from "prop-types";

const InactivityCountdown = ({ stepsLeftToReset }) => {
    if (!stepsLeftToReset) {
        return null;
    }

    return (
        <div id="inactivity-countdown">
            <Translate id={stepsLeftToReset === 1 ? "kiosk.returningToClassSelectionInXSecond" : "kiosk.returningToClassSelectionInXSeconds"} data={{ stepsLeftToReset }} />
        </div>
    );
};

InactivityCountdown.propTypes = {
    stepsLeftToReset: PropTypes.number
};

export default withLocalize(InactivityCountdown);