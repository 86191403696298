import React from "react";
import { withLocalize } from "react-localize-redux";
import PropTypes from "prop-types";
import LanguageButton from "../../pages/event/presentational/LanguageButton";

const LanguageSelector = ({ languages, activeLanguage, setActiveLanguage }) => 
(
    <div className="language-selector">
    {
        languages.map(language => 
            (
                <LanguageButton
                    key={language.code}
                    language={language} 
                    selected={activeLanguage && language.code === activeLanguage.code} 
                    onClick={setActiveLanguage} 
                />
            )
        )
    }
    </div>
);

LanguageSelector.propTypes = {
    languages: PropTypes.array.isRequired,
    activeLanguage: PropTypes.object,
    setActiveLanguage: PropTypes.func.isRequired
};

export default withLocalize(LanguageSelector);