import React from "react";
import PropTypes from "prop-types";
import ListLink from "./ListLink";
import { getUrlForList } from "../../../logic/ListUtil";

const ClassCell = ({ event, result, list }) => {
    const getClassUrlResult = () => {
        if(!list) {
            return undefined;
        }
        return list.classIds && list.classIds.length > 0
            ? undefined
            : getUrlForList(
                event, 
                { ...list, classIds: [result.cl.classId], organisationKey: undefined, entryIds: undefined, tag: undefined }
            );
    };

    const classUrlResult = getClassUrlResult();

    const text = result.cl.name;
    return (
        <div className="class">
            {
                classUrlResult &&
                (
                    <ListLink url={classUrlResult.url} isExternal={classUrlResult.isExternal}>
                        {text}
                    </ListLink>
                )
            }
            {
                !classUrlResult && text
            }
        </div>
    );
};
    
ClassCell.propTypes = {
    event: PropTypes.object.isRequired,
    result: PropTypes.object.isRequired,
    list: PropTypes.object
};     

export default ClassCell;