import { parseDateString } from "./DateHelpers";

export default class RaceJsonReader {
    read(json) {
        const race = {};
        race.raceId = json.i;
        race.externalRaceId = json.ei;
        race.raceNumber = json.rn;
        race.startTime = parseDateString(json.st);
        race.endTime = parseDateString(json.et);
        return race;
    }
}
