import { getUrlForList, setHighlightedResultId } from "../../logic/ListUtil";
import { emptyDomElement } from "../../logic/BrowserUtil";
import setMenuIcon from "./setMenuIcon";
import * as jquery from "jquery";

const emptyString = "";

const createMenuButtonCell = ({ event, result, list, translate, favoriteEntryIds }) => {
    const div = document.createElement("div");
    div.className = "menu";

    const buttonGroup = document.createElement("div");
    buttonGroup.className = "btn-group";

    const menuButton = document.createElement("button");
    menuButton.className = "menu-button";
    menuButton.setAttribute("type", "button");
    menuButton.setAttribute("data-toggle", "dropdown");
    menuButton.setAttribute("data-boundary", "viewport");
    menuButton.setAttribute("aria-haspopup", "true");
    menuButton.setAttribute("aria-expanded", "false");
    menuButton.dataset.isFavorite = (favoriteEntryIds || []).indexOf(result.entryId) !== -1;
    setMenuIcon(menuButton, result, favoriteEntryIds);

    const dropdownMenu = document.createElement("div");
    dropdownMenu.className = "dropdown-menu dropdown-menu-right";

    buttonGroup.appendChild(menuButton);
    buttonGroup.appendChild(dropdownMenu);

    div.appendChild(buttonGroup);

    jquery(buttonGroup).on("show.bs.dropdown", () => {
        // place parent li element on top to make the dropdown visible
        div.parentNode.dataset.previousZIndex = div.parentNode.style.zIndex;
        div.parentNode.style.zIndex = 100000;
        
        // title
        const personName = `${result.person.firstName || emptyString} ${result.person.lastName || emptyString}`;
        const menuTitle = document.createElement("h6");
        menuTitle.className = "dropdown-header";
        menuTitle.innerText = personName;

        // toggle favorite button
        const toggled = menuButton.dataset.isFavorite === "true";
        const toggleFavoriteButton = document.createElement("button");
        toggleFavoriteButton.className = "dropdown-item toggle-favorite-button btn btn-sm";
        toggleFavoriteButton.setAttribute("type", "button");
        toggleFavoriteButton.innerText = toggled 
            ? translate("removeFavorite") 
            : translate("addFavorite");
        toggleFavoriteButton.dataset.toggled = toggled;
        toggleFavoriteButton.dataset.entryId = result.entryId;

        dropdownMenu.appendChild(menuTitle);
        dropdownMenu.appendChild(createDivider());
        dropdownMenu.appendChild(toggleFavoriteButton);

        if(event.useTags) {
            // tag links
            const tagLinks = (result.tags || []).map(tag => {
                const element = document.createElement("a");
                element.className = "dropdown-item btn btn-sm";
                element.setAttribute("href", getUrlForList(event, { ...list, classIds: undefined, organisationKey: undefined, entryIds: undefined, tag }).url);
                element.addEventListener("click", () => setHighlightedResultId(result.resultId));
                element.innerText = "#" + tag;
                return element;
            });
    
            // manage tags button
            const manageTagsButton = document.createElement("button");
            manageTagsButton.className = "dropdown-item manage-tags-button btn btn-sm";
            manageTagsButton.setAttribute("type", "button");
            manageTagsButton.innerText = tagLinks.length
                ? translate("addOrRemoveTag")
                : translate("addTag");
            manageTagsButton.dataset.entryId = result.entryId;
            dropdownMenu.appendChild(manageTagsButton);

            if(tagLinks.length) {
                dropdownMenu.appendChild(createDivider());
                tagLinks.forEach(tagLink => dropdownMenu.appendChild(tagLink));
            }
        }
    });

    jquery(buttonGroup).on("hidden.bs.dropdown", () => {
        // restore zIndex of parent li element
        div.parentNode.style.zIndex = div.parentNode.dataset.previousZIndex;
        emptyDomElement(dropdownMenu);
    });

    return div;
};

export default createMenuButtonCell;

const createDivider = () => {
    const divider = document.createElement("div");
    divider.className = "dropdown-divider";
    return divider;
};
