/*
structure:
- classMappings: array
  - raceNumber: number or undefined
  - classNameRegex: string or undefined
  - eventorUrl: string

example: 
{
    classMappings: [
        { raceNumber: 1, classNameRegex: "^MtbO", eventorUrl: "https://eventor.orientering.se/Events/Show/1234" },
        { raceNumber: 1, eventorUrl: "https://eventor.orientering.se/Events/Show/5678" }
    ]
}
*/

export default class EventorManager {
    isEnabledForEvent(event) {
        return event && event.properties && event.properties.eventorOptions;
    }

    getRedirectUrl(event, race, cl) {
        if (!this.isEnabledForEvent(event) ||
            !race ||
            !cl) {
            return undefined;
        }

        const classMappings = (event.properties.eventorOptions.classMappings || []);

        for (let i=0; i<classMappings.length; i++) {
            const classMapping = classMappings[i];
            const classNameMatches = 
                !classMapping.classNameRegex || 
                new RegExp(classMapping.classNameRegex, "i").test(cl.name);
            const raceNumberMatches = classMapping.raceNumber === race.raceNumber;

            if (classNameMatches && raceNumberMatches) {
                return classMapping.eventorUrl;
            }
        }

        return undefined;
    }
}
