import React from "react";
import PropTypes from "prop-types";
import { formatTimeBehind } from "../../../logic/TimeUtil";
import { isElapsedTimeSample } from "../../../logic/ResultUtil";

const TimeBehindCell = ({ sample, sampleImportance }) => {
    let className = `${sampleImportance}TimeBehind`;

    return (
        <div className={className} data-sampleid={sample.sampleId}>
            {
                sample && isElapsedTimeSample(sample) && sample.timeBehind != null && formatTimeBehind(sample.timeBehind)
            }
        </div>
    );
};
    
TimeBehindCell.propTypes = {
    sample: PropTypes.object,
    sampleImportance: PropTypes.string
};

export default TimeBehindCell;