import EventJsonReader from "./readers/EventJsonReader";
import { createResultsFromJson, createResultsFromBuffer, createSplitTimesResponseFromJson, createSampleUpdateResponseFromJson } from "./ResultUtil";
import settings from "../settings";

const Api = {
    getEvent: (eventSlug, success, error) => {
        return fetch(`${settings.apiBaseUrl}/events/slugs/${encodeURIComponent(eventSlug)}?includeOrganisations=true&includeClassCategories=true&includeProperties=true&includeIntermediateControls=true`)
            .then(
                response => {
                    if(response.ok) {
                        return response.json().then(json => {
                            const reader = new EventJsonReader();
                            const event = reader.read(json);
                            success(event);
                        });
                    } else {
                        error(response);
                    }
                });
    },

    getEvents: (success, error) => {
        return fetch(`${settings.apiBaseUrl}/events`)
            .then(
                response => {
                    if(response.ok) {
                        return response.json().then(jsonArray => {
                            const reader = new EventJsonReader();
                            const events = jsonArray.map(json => reader.read(json));
                            success(events);
                        });
                    } else {
                        error(response);
                    }
                });
    },

    getClassResults: (event, raceId, classIds, success, error) => {
        if(settings.useBinaryProtocol) {
            return getBinaryResults(`${settings.apiBaseUrl}/races/${raceId}/classes/results/binary?classIds=${encodeURIComponent(classIds.join(","))}`, event, success, error);
        }
        return getJsonResults(`${settings.apiBaseUrl}/races/${raceId}/classes/results/json?classIds=${encodeURIComponent(classIds.join(","))}`, event, success, error);
    },

    getOrganisationResults: (event, raceId, organisationKey, success, error) => {
        if(settings.useBinaryProtocol) {
            return getBinaryResults(`${settings.apiBaseUrl}/races/${raceId}/organisations/results/binary?organisationKey=${encodeURIComponent(organisationKey)}`, event, success, error);
        }
        return getJsonResults(`${settings.apiBaseUrl}/races/${raceId}/organisations/results/json?organisationKey=${encodeURIComponent(organisationKey)}`, event, success, error);
    },

    getClassResultsForAllRaces: (event, classId, success, error) => {
        if(settings.useBinaryProtocol) {
            return getBinaryResults(`${settings.apiBaseUrl}/events/${event.eventId}/classes/${classId}/results/binary`, event, success, error);
        }
        return getJsonResults(`${settings.apiBaseUrl}/events/${event.eventId}/classes/${classId}/results/json`, event, success, error);
    },

    getOrganisationResultsForAllRaces: (event, organisationKey, success, error) => {
        if(settings.useBinaryProtocol) {
            return getBinaryResults(`${settings.apiBaseUrl}/events/${event.eventId}/organisations/results/binary?organisationKey=${encodeURIComponent(organisationKey)}`, event, success, error);
        }
        return getJsonResults(`${settings.apiBaseUrl}/events/${event.eventId}/organisations/results/json?organisationKey=${encodeURIComponent(organisationKey)}`, event, success, error);
    },

    getResultsForEntryIdsForAllRaces: (event, entryIds, success, error) => {
        const entryIdsString = "entryIds=" + entryIds.join(",");
        if(settings.useBinaryProtocol) {
            return getBinaryResults(`${settings.apiBaseUrl}/events/${event.eventId}/entries/results/binary?${entryIdsString}`, event, success, error);
        }
        return getJsonResults(`${settings.apiBaseUrl}/events/${event.eventId}/entries/results/json?${entryIdsString}`, event, success, error);
    },

    getCompetitorsByPersonName: (event, personName, success, error) => {
        const processedSuccess = o => success(groupResultsByEventAndEntryId(o));
        if(settings.useBinaryProtocol) {
            return getBinaryResults(`${settings.apiBaseUrl}/events/${event.eventId}/results/binary?personName=${encodeURIComponent(personName)}`, event, processedSuccess, error);
        }
        return getJsonResults(`${settings.apiBaseUrl}/events/${event.eventId}/results/json?personName=${encodeURIComponent(personName)}`, event, processedSuccess, error);
    },

    getTags: (eventId, tagText, success, error) => {
        return fetch(`${settings.apiBaseUrl}/events/${eventId}/tags?tagText=${encodeURIComponent(tagText)}&includeCompetitorNames=true`)
        .then(
            response => {
                if(response.ok) {
                    response.json().then(json => success(json));
                } else {
                    error(response);
                }
            });
    },

    getResultsForEntryId: (event, entryId, success, error) => {
        if(settings.useBinaryProtocol) {
            return getBinaryResults(`${settings.apiBaseUrl}/events/${event.eventId}/entries/${entryId}/results/binary`, event, success, error);
        }
        return getJsonResults(`${settings.apiBaseUrl}/events/${event.eventId}/entries/${entryId}/results/json`, event, success, error);
    },

    getResultsForEntryIds: (event, raceId, entryIds, success, error) => {
        const entryIdsString = "entryIds=" + entryIds.join(",");
        if(settings.useBinaryProtocol) {
            return getBinaryResults(`${settings.apiBaseUrl}/races/${raceId}/entries/results/binary?${entryIdsString}`, event, success, error);
        }
        return getJsonResults(`${settings.apiBaseUrl}/races/${raceId}/entries/results/json?${entryIdsString}`, event, success, error);
    },

    getResultsForTag: (event, raceId, tag, success, error) => {
        if(settings.useBinaryProtocol) {
            return getBinaryResults(`${settings.apiBaseUrl}/races/${raceId}/tags/${encodeURIComponent(tag)}/results/binary`, event, success, error);
        }
        return getJsonResults(`${settings.apiBaseUrl}/races/${raceId}/tags/${encodeURIComponent(tag)}/results/json`, event, success, error);
    },

    searchResults: (fromEventTime, toEventTime, personName, organisationName, success, error) => {
        const processedSuccess = o => success(groupResultsByEventAndEntryId(o));
        const params = [];
        if(fromEventTime) {
            params.push(`fromEventTime=${encodeURIComponent(fromEventTime)}`);
        }
        if(toEventTime) {
            params.push(`toEventTime=${encodeURIComponent(toEventTime)}`);
        }
        if(personName) {
            params.push(`personName=${encodeURIComponent(personName)}`);
        }
        if(organisationName) {
            params.push(`organisationName=${encodeURIComponent(organisationName)}`);
        }

        return getJsonResults(`${settings.apiBaseUrl}/results?${params.join("&")}`, undefined /* event */, processedSuccess, error);
    },

    getSplitTimes: (raceId, classIds, success, error) => {
        const classIdsString = "classIds=" + classIds.join(",");
        return getSplitTimesResponse(`${settings.apiBaseUrl}/races/${raceId}/classes/splitTimes/json?${classIdsString}`, success, error);
    },

    getSampleUpdatesForOrganisation: (event, raceId, organisationKey, modifiedSince, success, error) => {
        return getSampleUpdatesResponse(`${settings.apiBaseUrl}/races/${raceId}/organisations/sampleUpdates?organisationKey=${encodeURIComponent(organisationKey)}&${getModifiedSinceQuerystring(modifiedSince)}`, event, success, error);
    },

    getSampleUpdatesForTag: (event, raceId, tag, modifiedSince, success, error) => {
        return getSampleUpdatesResponse(`${settings.apiBaseUrl}/races/${raceId}/tags/${encodeURIComponent(tag)}/sampleUpdates?${getModifiedSinceQuerystring(modifiedSince)}`, event, success, error);
    },

    getSampleUpdatesForEntries: (event, raceId, entryIds, modifiedSince, success, error) => {
        const entryIdsString = "entryIds=" + entryIds.join(",");
        return getSampleUpdatesResponse(`${settings.apiBaseUrl}/races/${raceId}/entries/sampleUpdates?${entryIdsString}&${getModifiedSinceQuerystring(modifiedSince)}`, event, success, error);
    },

    getSampleUpdatesForEntry: (event, entryId, modifiedSince, success, error) => {
        return getSampleUpdatesResponse(`${settings.apiBaseUrl}/events/${event.eventId}/entries/${entryId}/sampleUpdates?${getModifiedSinceQuerystring(modifiedSince)}`, event, success, error);
    },

    validateEntryReference: (resultId, entryReferenece, success, error) => {
        return fetch(`${settings.apiBaseUrl}/results/${resultId}/entryReferences/${encodeURIComponent(entryReferenece)}`)
            .then(response => {
                if(response.ok) {
                    success();
                } else {
                    error(response);
                }
            });
    },

    updateResultTags: (resultId, tags, entryReference, success, error) => {
        const options = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                tags, 
                entryReference
            })
        };

        return fetch(`${settings.apiBaseUrl}/results/${resultId}/tags`, options)
            .then(response => {
                if(response.ok) {
                    response.json().then(json => success(json));
                } else {
                    error(response);
                }
            });
    },

    getCountries: (eventSlug, success, error) => {
        return fetch(`${settings.apiBaseUrl}/countries/${encodeURIComponent(eventSlug)}`)
            .then(response => {
                if(response.ok) {
                    response.json().then(json => success(json));
                } else {
                    error(response);
                }
            });
    },

    getRegions: (eventSlug, success, error) => {
        return fetch(`${settings.apiBaseUrl}/regions/${encodeURIComponent(eventSlug)}`)
            .then(response => {
                if(response.ok) {
                    response.json().then(json => success(json));
                } else {
                    error(response);
                }
            });
    },

    getResultSelection: (guid, success, error) => {
        return fetch(`${settings.apiBaseUrl}/resultSelections/${guid}`)
            .then(response => {
                if(response.ok) {
                    response.json().then(json => success(json));
                } else {
                    error(response);
                }
            });
    },

    createResultSelection: (resultSelection, success, error) => {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(resultSelection)
        };

        return fetch(`${settings.apiBaseUrl}/resultSelections`, options)
            .then(response => {
                if(response.ok) {
                    response.json().then(json => success(json));
                } else {
                    error(response);
                }
            });
    },

    updateResultSelection: (resultSelection, success, error) => {
        const options = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(resultSelection)
        };

        return fetch(`${settings.apiBaseUrl}/resultSelections/${resultSelection.guid}`, options)
            .then(response => {
                if(response.ok) {
                    response.json().then(json => success(json));
                } else {
                    error(response);
                }
            });
    },

    deleteResultSelection: (resultSelectionGuid, success, error) => {
        const options = {
            method: "DELETE"
        };

        return fetch(`${settings.apiBaseUrl}/resultSelections/${resultSelectionGuid}`, options)
            .then(response => {
                if(response.ok) {
                    success();
                } else {
                    error(response);
                }
            });
    },

    getResultSelectionExtractAsJson: (guid, raceId, accumulationType, success, error) => {
        return fetch(`${settings.apiBaseUrl}/resultSelections/${guid}/extracts/${raceId}/${accumulationType}/json`)
            .then(response => {
                if(response.ok) {
                    response.json().then(json => success(json));
                } else {
                    error(response);
                }
            });
    },

    getResultSelectionExtract: (guid, raceId, accumulationType, outputType, resources, success, error) => {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(resources)
        };

        return fetch(`${settings.apiBaseUrl}/resultSelections/${guid}/extracts/${raceId}/${accumulationType}/${outputType}`, options)
            .then(response => {
                if(response.ok) {
                    response.blob().then(blob => success(blob));
                } else {
                    error(response);
                }
            });
    },

    createFavoriteSubscription: (eventId, entryIds, pushNotificationType, identifier, pushNotificationConfiguration, language, success, error) => {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                eventId,
                entryIds,
                pushNotificationType,
                identifier,
                pushNotificationConfiguration,
                language
            })
        };

        return fetch(`${settings.apiBaseUrl}/favoriteSubscriptions`, options)
            .then(response => {
                if(response.ok) {
                    response.json().then(json => success(json));
                } else {
                    error(response);
                }
            });
    },

    updateFavoriteSubscription: (eventId, entryIds, pushNotificationType, identifier, pushNotificationConfiguration, language, success, error) => {
        const options = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                eventId,
                entryIds,
                pushNotificationType,
                identifier,
                pushNotificationConfiguration,
                language
            })
        };

        return fetch(`${settings.apiBaseUrl}/favoriteSubscriptions?pushNotificationType=${encodeURIComponent(pushNotificationType)}&identifier=${encodeURIComponent(identifier)}&eventId=${eventId}`, options)
            .then(response => {
                if(response.ok) {
                    response.json().then(json => success(json));
                } else {
                    error(response);
                }
            });
    },

    deleteFavoriteSubscription: (eventId, pushNotificationType, identifier, success, error) => {
        const options = {
            method: "DELETE"
        };

        return fetch(`${settings.apiBaseUrl}/favoriteSubscriptions?pushNotificationType=${encodeURIComponent(pushNotificationType)}&identifier=${encodeURIComponent(identifier)}&eventId=${eventId}`, options)
            .then(response => {
                if(response.ok) {
                    success();
                } else {
                    error(response);
                }
            });
    },

    clearCache: (eventId, apiKey, success, error) => {
        acquireToken(
            eventId, 
            apiKey,
            successResponse => {
                const options = {
                    method: "PUT",
                    headers: {
                        "Authorization": `Bearer ${successResponse.token}`
                    }
                };
        
                return fetch(`${settings.apiBaseUrl}/events/${eventId}/clearCache`, options)
                    .then(response => {
                        if(response.ok) {
                            success();
                        } else {
                            error(response);
                        }
                    });
            },
            errorResponse => error(errorResponse)
        );
    }
};

const getBinaryResults = (url, event, success, error) => {
    return fetch(url)
        .then(response => {
            if (response.ok) {
                response.arrayBuffer().then(buffer => {
                    const results = createResultsFromBuffer(buffer, event);
                    success(results);
                });
            } else {
                error(response);
            }
        });
}; 

const getJsonResults = (url, event, success, error) => {
    return fetch(url)
        .then(response => {
            if (response.ok) {
                response.json().then(json => {
                    const results = createResultsFromJson(json, event);
                    success(results);
                });
            } else {
                error(response);
            }
        });
};

const getSplitTimesResponse = (url, success, error) => {
    return fetch(url)
        .then(response => {
            if (response.ok) {
                response.json().then(json => {
                    const splitTimesResponse = createSplitTimesResponseFromJson(json);
                    success(splitTimesResponse);
                });
            } else {
                error(response);
            }
        });
};

const getSampleUpdatesResponse = (url, event, success, error) => {
    return fetch(url)
        .then(response => {
            if (response.ok) {
                response.json().then(json => {
                    const sampleUpdateResponse = createSampleUpdateResponseFromJson(json, event);
                    success(sampleUpdateResponse);
                });
            } else {
                error(response);
            }
        });
};

const groupResultsByEventAndEntryId = results => {
    const resultsByEventAndEntryId = [];
    // we wish to keep the sort order from the API, thus using an { index, list } object
    results.forEach((result, index) => {
        const key = `${result.event.eventId}-${result.entryId}`;
        resultsByEventAndEntryId[key] = resultsByEventAndEntryId[key] || { index, list: [] };
        resultsByEventAndEntryId[key].list.push(result);
    });
    const sortedList = Object.values(resultsByEventAndEntryId);
    sortedList.sort((a, b) => a.index - b.index);
    return sortedList.map(item => item.list);
};

const getModifiedSinceQuerystring = modifiedSince => {
    if(!modifiedSince) {
        return "";
    }
    return `modifiedSince=${encodeURIComponent(new Date(modifiedSince).toISOString())}`;
};

export const acquireToken = (eventId, apiKey, success, error) => {
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            eventId,
            apiKey 
        })
    };

    return fetch(`${settings.apiBaseUrl}/authentication`, options)
        .then(response => {
            if (response.ok) {
                response.json().then(json => success(json));
            } else {
                error(response);
            }
        });
};

export default Api;