import React from "react";
import { withLocalize, Translate } from "react-localize-redux";
import PropTypes from "prop-types";

const ResultLoader = ({ selectedClass }) =>
(
    <div id="result-loader">
        <Translate id="kiosk.loadingResultsForClassX" data={{ className: (selectedClass ?? {}).name }}/>
    </div>
);

ResultLoader.propTypes = {
    selectedClass: PropTypes.object
};

export default withLocalize(ResultLoader);