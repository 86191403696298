import TimeType from "../model/TimeType";

const timeTypes = [
    TimeType.leg,
    TimeType.sinceStart,
    TimeType.passing
];

const timeSorter = (a, b) => {
    if(a !== undefined && b !== undefined) {
        return a - b;
    }
    if(a === undefined) {
        return 1;
    }
    if(b === undefined) {
        return -1;
    }
    return 0;
};

class PlaceAndTimeBehindCalculator {
    calculatePlacesAndTimesBehind(controls, competitors) {
        for (let i=0; i<controls.length; i++) {
            // init places and timesBehind dictionaries
            competitors.forEach(competitor => {
                competitor.courseSplitTimes[i].timesBehind = {};
                competitor.courseSplitTimes[i].places = {};
            });

            timeTypes.forEach(timeType => {
                // create sorted times
                const sortedTimes = competitors.map(competitor => competitor.courseSplitTimes[i].isValid[timeType] ? competitor.courseSplitTimes[i].times[timeType] : undefined);
                sortedTimes.sort(timeSorter);
                
                // build time => place dictionary
                const placeForTime = {};
                const timeBehindForTime = {};
                let lastTime = undefined;
                let place = 0;
                sortedTimes.forEach((time, i) => {
                    if (time !== lastTime) {
                        place = i+1;
                    }
                    if(time !== undefined) {
                        placeForTime[time] = place;
                        if(place === 1) {
                            timeBehindForTime[time] = sortedTimes[1] === undefined
                                ? undefined
                                : time - sortedTimes[1];
                        } else {
                            timeBehindForTime[time] = time - sortedTimes[0];
                        }
                    }
                    lastTime = time;
                });

                // populate places
                competitors.forEach(competitor =>  {
                    if (competitor.courseSplitTimes[i].isValid[timeType]) {
                        competitor.courseSplitTimes[i].places[timeType] = placeForTime[competitor.courseSplitTimes[i].times[timeType]];
                        competitor.courseSplitTimes[i].timesBehind[timeType] = timeBehindForTime[competitor.courseSplitTimes[i].times[timeType]];
                    }
                });

            });
        }
    }
}

export default PlaceAndTimeBehindCalculator;
