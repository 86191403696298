import React from "react";
import PropTypes from "prop-types";
import { withLocalize, Translate } from "react-localize-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";

const ResultSelectionCreatedModal = ({ resultSelection, eventSlug, isOpen, onClosed }) => {
    if(!resultSelection) {
        return null;
    }

    const url = `${window.location.origin}/${encodeURIComponent(eventSlug)}/media/extract/${resultSelection.guid}`;
    return (
        <Modal id="result-selection-created-modal" isOpen={isOpen} onClosed={onClosed}>
            <ModalHeader>
                <Translate id="media.resultSelectionCreatedModalTitle" />
            </ModalHeader>
            <ModalBody>
                <p>
                    <Translate id="media.resultSelectionCreatedModalBody" />
                </p>
                <Alert color="success">
                    <a id="result-selection-url" href={url}>{url}</a>
                </Alert>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onClosed}>
                    <Translate id="ok" />
                </Button>
            </ModalFooter>
        </Modal>
   );
};

ResultSelectionCreatedModal.propTypes = {
    resultSelection: PropTypes.object,
    eventSlug: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onClosed: PropTypes.func.isRequired
};

export default withLocalize(ResultSelectionCreatedModal);