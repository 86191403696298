import SubCell from "./SubCell";

export default class Cell {
    constructor(options) {
        options = options ?? {};
        this.className = options.className;
        this.subCells = options.subCells;
        this.tooltips = options.tooltips;
        this.mode = options.mode;
    }

    createElement() {
        const domElement = document.createElement("div");
        if (this.className) {
            domElement.className = this.className;
        }
        if (this.mode) {
            domElement.classList.add(`winsplits-mode-${this.mode}`);
        }

        const numberOfSubRows = getNumberOfSubRows(this);
        const numberOfSubColumns = getNumberOfSubColumns(this);

        for (let subRow=0; subRow<numberOfSubRows; subRow++) {
            const subRowElement = document.createElement("div");
            for (let subColumn=0; subColumn<numberOfSubColumns; subColumn++) {
                const subCell = getSubCell(this, subRow, subColumn);
                const tooltip = getTooltip(this, subRow, subColumn);
                const cellElement = subCell.createElement();
                if (tooltip) {
                    tooltip.applyToElement(cellElement);
                }
                subRowElement.appendChild(cellElement);
            }
            const subRowTooltip = getTooltip(this, subRow, undefined);
            if (subRowTooltip) {
                subRowTooltip.applyToElement(subRowElement);
            }
            domElement.appendChild(subRowElement);
        }

        return domElement;
    }
}

const getNumberOfSubRows = cell => {
    const subRows = cell.subCells.map(sc => sc.subRow);
    return Math.max(...subRows) + 1;
};

const getNumberOfSubColumns = cell => {
    const subColumns = cell.subCells.map(sc => sc.subColumn);
    return Math.max(...subColumns) + 1;
};

const getSubCell = (cell, subRow, subColumn) => {
    let subCell = cell.subCells.find(sc => sc.subRow === subRow && sc.subColumn === subColumn);
    if(!subCell) {
        subCell = new SubCell();
    }
    return subCell.subCell;
};

const getTooltip = (cell, subRow, subColumn) => {
    if (!cell.tooltips) {
        return undefined;
    }
    const tooltip = cell.tooltips.find(t => t.subRow === subRow && t.subColumn === subColumn);
    return tooltip
        ? tooltip.tooltip
        : undefined;
};