import { formatClockTime } from "../../logic/TimeUtil";
import { getExternalUrlForClasses } from "../../logic/EventUtil";
import ListType from "../../logic/models/ListType";
import { createExternalLinkIconElement } from "../../common/presentational/IconUtil";

const createStartTimeCell = ({ result, event }) => {
    const externalUrl = getExternalUrlForClasses(event, result.cl?.classId, result.raceId, ListType.startList);
    const div = document.createElement("div");
    div.className = "startTime";
    if(externalUrl) {
        const a = document.createElement("a");
        a.setAttribute("href", externalUrl);
        a.setAttribute("target", "_blank");
        a.appendChild(createExternalLinkIconElement());
        div.appendChild(a);
    } else {
        div.innerText = formatClockTime(result.startTime, event.timeZone);
    }
    return div;
};

export default createStartTimeCell;