import Course from "../../lib/winsplits/model/Course";
import ControlType from "../../lib/winsplits/model/ControlType";
import "../../lib/winsplits/styles/winsplits.scss";

// class acting as a link between Ores and the WinSplits package, containing factory methods
export default class SplitTimeManager {
    createCourse(splitTimeResponse) {
        try {
            const raceClass = splitTimeResponse.raceClasses[0]; // TODO: make it possible to handle multiple classes

            const courseOptions = {
                controls: raceClass.controls.map(createControl),
                competitors: splitTimeResponse.results.map(result => ({
                    person: result.person
                        ? {
                            firstName: result.person.firstName,
                            lastName: result.person.lastName
                        }
                        : undefined,
                    organisation: result.organisation
                        ? {
                            name: result.organisation.name
                        }
                        : undefined,
                    startTime: result.startTime,
                    splitTimes: result.punches.map(punch => ({
                        code: punch.code,
                        timeSinceStart: punch.elapsedTime
                    }))
                }))
            };

            const course = new Course(courseOptions);

            return course;
    
        } catch(e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    }
}

const createControl = control => ({ 
    code: control.code,
    type: getControlType(control.type)
});

const getControlType = ct => {
    switch (ct) {
        case 0: return ControlType.start;
        case 1: return ControlType.control;
        case 2: return ControlType.finish;
        default: throw `Unknown control type: ${ct}.`;
    }
};