class Control {
    constructor(options) {
        // the ordinal number for this control, starting with 0
        this.number = options.number;

        // the control code for this control
        this.code = options.code;

        // the type of control: start, control or finish. 
        this.type = options.type;
        
        // the times
        // topTimes [AccumulationType] (only AccumulationType.leg)
        this.topTimes = options.topTimes;
        
        // the normaized length for the leg starting with the previous control and ending with this control
        // normalizedLengths [AccumulationType]
        this.normalizedLengths = options.normalizedLengths;
    }
}

export default Control;
