import * as types from "../actions/actionTypes";
import initialState from "./initialState";
import { getFavoriteEntryIds } from "../logic/ListUtil";
import { getCurrentTime } from "../logic/BrowserUtil";

const eventPageReducer = (state = initialState.eventPage, action) => {
    const now = getCurrentTime();

    switch (action.type) {
        case types.REQUEST_EVENT:
            return {
                ...state,
                event: undefined,
                loading: { event: true }
            };

        case types.RECEIVE_EVENT_SUCCESS:
            return { 
                ...state,
                event: action.event,
                favoriteEntryIds: getFavoriteEntryIds(action.event.eventId),
                loading: { event: false }
            };

        case types.RECEIVE_EVENT_FAILURE:
            return { 
                ...state,
                event: undefined,
                eventNotFound: action.error.status === 404,
                serverError: action.error,
                loading: { event: false }
            };

        case types.REQUEST_RESULTS:
            return {
                ...state,
                results: undefined,
                loading: { results: true }
            };

        case types.RECEIVE_RESULTS_SUCCESS:
            return { 
                ...state,
                results: action.results,
                lastResultsReceivedTime: now,
                loading: { results: false },
                // TODO: set lastSampleUpdatesServerTimestamp the time when the list was created at the server
                lastSampleUpdatesServerTimestamp: now
            };

        case types.RECEIVE_RESULTS_FAILURE:
            return { 
                ...state,
                results: undefined,
                serverError: action.error,
                loading: { results: false }
            };

        case types.SET_LIST:
            return {
                ...state,
                list: action.list
            };

        case types.REQUEST_RESULTS_FOR_ENTRY:
            return {
                ...state,
                resultsForEntry: undefined,
                loading: { resultsForEntry: true }
            };

        case types.RECEIVE_RESULTS_FOR_ENTRY_SUCCESS:
            return { 
                ...state,
                resultsForEntry: action.results,
                loading: { resultsForEntry: false },
                // TODO: set lastSampleUpdatesServerTimestamp the time when the list was created at the server
                lastSampleUpdatesServerTimestamp: now
            };

        case types.RECEIVE_RESULTS_FOR_ENTRY_FAILURE:
            return { 
                ...state,
                resultsForEntry: undefined,
                serverError: action.error,
                loading: { resultsForEntry: false }
            };

        case types.SET_RESULTS_FOR_ENTRY:
            return { 
                ...state,
                resultsForEntry: action.results,
                loading: { resultsForEntry: false }
            };

        case types.REQUEST_RESULTS_FOR_ALL_RACES:
            return {
                ...state,
                resultsForAllRaces: undefined,
                loading: { resultsForAllRaces: true }
            };

        case types.RECEIVE_RESULTS_FOR_ALL_RACES_SUCCESS:
            return { 
                ...state,
                resultsForAllRaces: action.results,
                loading: { resultsForAllRaces: false },
                // TODO: set lastSampleUpdatesServerTimestamp the time when the list was created at the server
                lastSampleUpdatesServerTimestamp: now
            };

        case types.RECEIVE_RESULTS_FOR_ALL_RACES_FAILURE:
            return { 
                ...state,
                resultsForAllRaces: undefined,
                serverError: action.error,
                loading: { resultsForAllRaces: false }
            };

        case types.SET_RESULTS_FOR_ALL_RACES:
            return { 
                ...state,
                resultsForAllRaces: action.results,
                loading: { resultsForAllRaces: false }
            };
                
        case types.UPDATE_LANDING_PAGE_SELECTED_RACE_ID:
            return {
                ...state,
                landingPageSelectedRaceId: action.raceId
            };

        case types.FAVORITES_CHANGED:
            return { 
                ...state,
                favoriteEntryIds: action.favoriteEntryIds
            };

        case types.RECEIVE_LIVE_RESULTS_SUCCESS:
        {
            if (action.results) {
                const keepResultsSince = now - 60000;
                return { 
                    ...state,
                    receivedLiveResults: [...state.receivedLiveResults]
                        // only keep results that are less than one minute old
                        .filter(result => result.time > keepResultsSince)
                        .concat(action.results.map(result => ({ time: now, result }))),
                    lastLiveResultsReceivedTime: now
                };
            } else {
                return state;
            }
        }

        case types.RECEIVE_SAMPLE_UPDATES_FOR_LIST_SUCCESS:
        {
            if (!action.sampleUpdateResponse) {
                return {
                    ...state,
                    receivedSampleUpdates: undefined,
                    lastSampleUpdatesServerTimestamp: 0                        
                };
            }

            if (!action.sampleUpdateResponse.sampleUpdates.length) {
                // no new sample updates
                return {
                    ...state,
                    lastSampleUpdatesServerTimestamp: action.sampleUpdateResponse.timestamp,
                    sampleUpdatePollInterval: action.sampleUpdateResponse.pollInterval
                };
            }

            const keepSampleUpdatesSince = now - 60000;
            const receivedSampleUpdates = (state.receivedSampleUpdates || [])
                    // only keep sample updates that are less than one minute old
                    .filter(sampleUpdate => sampleUpdate.time > keepSampleUpdatesSince)
                    .concat(action.sampleUpdateResponse.sampleUpdates.map(sampleUpdate => ({ time: now, sampleUpdate })));
            return {
                ...state, 
                receivedSampleUpdates,
                lastSampleUpdatesReceivedTime: now,
                lastSampleUpdatesServerTimestamp: action.sampleUpdateResponse.timestamp,
                sampleUpdatePollInterval: action.sampleUpdateResponse.pollInterval
            };
        }

        case types.WRITE_LOG_ITEM:
            return {
                ...state,
                logItems: [...state.logItems, action.logItem]
            };
    
        default:
            return state;
    }
};

export default eventPageReducer;
