const emptyString = "";

const createPunchingCardNumberCell = ({ event, result }) => {
    const div = document.createElement("div");
    div.className = "punchingCardNumber";
    const punchingCardNumber = event.properties.hidePunchingCardNumbers
        ? emptyString
        : result.punchingCardNumber;
    div.innerText = punchingCardNumber || emptyString;
    return div;
};

export default createPunchingCardNumberCell;