export const sortedArraysAreEqual = (a1, a2) => {
    if(!a1 && !a2) {
        return true;
    }
    if (!a1 || !a2 || a1.length !== a2.length) {
        return false;
    }
    const s1 = a1.map(o=>o);
    const s2 = a2.map(o=>o);
    s1.sort();
    s2.sort();

    for (let i = 0; i < s1.length; i++) {
        if(s1[i] !== s2[i]) {
            return false;
        }
    }
    return true;
};

export const arraysAreEqual = (a1, a2) => {
    if(!a1 && !a2) {
        return true;
    }
    if (!a1 || !a2 || a1.length !== a2.length) {
        return false;
    }

    for (let i = 0; i < a1.length; i++) {
        if(a1[i] !== a2[i]) {
            return false;
        }
    }
    return true;
};
