import AccumulationType from "./models/AccumulationType";
import ControlType from "./models/ControlType";
import ResultStatus from "./models/ResultStatus";
import PersonResultBinaryReader from "./readers/PersonResultBinaryReader";
import PersonResultJsonReader from "./readers/PersonResultJsonReader";
import SampleUpdateResponseJsonReader from "./readers/SampleUpdateResponseJsonReader";
import SplitTimesResponseJsonReader from "./readers/SplitTimesResponseJsonReader";
import { mergeProperties, propertiesAreEqual } from "./ObjectUtil";
import ListType from "./models/ListType";

export const getElapsedTimePerKilometer = (result, sample) => {
    return sample && sample.accumulationType === AccumulationType.race && sample.controlType === ControlType.finish && sample.elapsedTime && isElapsedTimeSample(sample) && result.cl.courseLength 
        ? sample.elapsedTime / (result.cl.courseLength / 1000) 
        : undefined;
};

export const formatCourseLength = (courseLength, thousandSeparator = " ") => {
    if(!courseLength) {
        return "";
    }
    return courseLength.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator) + " m";
};

export const createResultsFromJson = (json, event) => {
    let results = undefined;
    if(json) {
        const reader = new PersonResultJsonReader();
        results = reader.read(json);
        populateClassProperties(results, event);
    }
    return results;
};

export const createResultsFromBuffer = (buffer, event) => {
    let results = undefined;
    if(buffer) {
        results = new PersonResultBinaryReader().readAll(buffer);
        populateClassProperties(results, event);
    }
    return results;
};

export const createSampleUpdateResponseFromJson = json => {
    const reader = new SampleUpdateResponseJsonReader();
    return reader.read(json);
};

export const createSplitTimesResponseFromJson = json => {
    const reader = new SplitTimesResponseJsonReader();
    return reader.read(json);
};

export const getSample = (result, accumulationType, controlType, controlName, isClassList) => {
    
    let sample = cloneSample(
        result.samples.find(s => 
            s.accumulationType === accumulationType &&
            s.controlType === controlType && 
            s.controlName === controlName
        ),
        isClassList);

    if(controlType !== ControlType.finish && !sample) {
        const finishSample = result.samples.find(s => s.accumulationType === accumulationType && s.controlType === ControlType.finish);
        if(isFinishedStatus(finishSample?.status)) {
            // an intermediate status is still unknown, but the participant has finished
            // use the finish status for the intermediate time as well
            sample = createUnknownSample(result, accumulationType, controlType, controlName, isClassList);
            sample.status = finishSample?.status ?? sample.status;
        }
    }

    return sample;
};

export const cloneResult = result => {
    if(!result) {
        return undefined;
    }
    const clone = { ...result };
    if(result.samples) {
        clone.samples = result.samples.map(s => cloneSample(s));
    }
    if(result.tags) {
        clone.tags = [...result.tags];
    }
    if(result.punches) {
        clone.punches = result.punches.map(p => ({ ...p }));
    }
    return clone;
};


const cloneSample = (sample, adjustNegativeTimeBehinds) => {
    if(!sample) {
        return undefined;
    }
    const clone = {...sample};
    if(adjustNegativeTimeBehinds && clone.timeBehind < 0) {
        clone.timeBehind = 0;
    }
    return clone;
};

const populateClassProperties = (results, event) => {
    if (!event) {
        return;
    }
    const unknownRace = {};
    results.forEach(r => {
        const cl = event.classes[r.cl.classId] ?? {};
        r.cl.name = cl.name;
        r.cl.sequence = cl.sequence;
        r.cl.courseLength = (cl.raceClasses[r.raceId] || unknownRace).courseLength;
        r.cl.timePresentation = cl.timePresentation;
        r.cl.hasOverallResults = cl.hasOverallResults;
    });
};

export const createUnknownSample = (result, accumulationType, controlType, controlName) => {
    return {
        // we need to set a unique sample id
        // base it on the result id, accumulation type, control type and control name
        sampleId: `${result.resultId}-${accumulationType}-1-`,
        status: ResultStatus.unknown, 
        accumulationType,
        controlType: controlType,
        controlName: controlName,
        isNullObject: true
    };
};

export const createResultsFromReceivedLiveResults = (receivedLiveResults, event, lastLiveResultsReceivedTime, filter) => {
    filter = filter || (result => true);

    const results = receivedLiveResults
        .filter(r => r.time > lastLiveResultsReceivedTime && filter(r))
        .map(r => ({ ...r.result }));
    
    populateClassProperties(results, event);
    return results;
};

export const mergeResults = (targetResult, sourceResult) => {
    mergeProperties(targetResult, sourceResult, ["samples"]);
    const updated = {
        result: undefined, // TODO: tailor-made comparison; return result object if different
        samples: mergeSampleCollections(targetResult, sourceResult)
    };
    return updated;
};

export const combineResultSets = (first, second) => {
    const dictionary = {};
    [first, second].forEach(array => {
        if(array) {
            array.forEach(o => {
                if(!dictionary[o.resultId] || dictionary[o.resultId].databaseModifiedTime < o.databaseModifiedTime) {
                    dictionary[o.resultId] = o;
                }
            });
        }
    });
    return Object.values(dictionary);
};

export const liveStatusesForIntermediate = [ 
    ResultStatus.started, 
    ResultStatus.notActivated, 
    ResultStatus.unknown, 
    ResultStatus.notYetFinished 
];

export const liveStatusesForFinish = [ 
    ResultStatus.notActivated, 
    ResultStatus.unknown, 
    ResultStatus.started, 
    ResultStatus.notYetFinished 
];

export const isLiveResult = (result, now) => {
    const finishSample = result.samples.find(s => s.accumulationType === AccumulationType.race && s.controlType === ControlType.finish);
    return isLiveTime(result, finishSample, now);
};

export const isLiveTime = (result, sample, now) => {
    return sample && 
        (
            (
                liveStatusesForFinish.indexOf(sample.status) !== -1 &&
                sample.controlType === ControlType.finish
            )
            ||
            (
                !sample.elapsedTime &&
                liveStatusesForIntermediate.indexOf(sample.status) !== -1 &&
                sample.controlType !== ControlType.finish
            )
        ) &&
        result.startTime &&
        now &&
        now >= result.startTime &&
        !(sample.accumulationType === AccumulationType.overall && result.overallElapsedTimeAtStart === undefined) &&
        !(sample.accumulationType === AccumulationType.overall && !result.cl.hasOverallResults);
};

export const getLiveZeroTime = (result, sample) => {
    if(!result.cl.timePresentation || !result.timePresentation) {
        return undefined;
    }

    return result.startTime - 
        1000 * (sample.accumulationType === AccumulationType.overall ? result.overallElapsedTimeAtStart : 0); // if overall mode, subtract overall elapsed time at start
};

export const getLiveTime = (result, sample, now) => {
    if(isLiveTime(result, sample, now) && result.cl.timePresentation && result.timePresentation) {
        const liveTime = (now - getLiveZeroTime(result, sample)) / 1000;
        return liveTime < 0
            ? undefined
            : liveTime;
    }
    return undefined;
};

export const getDatabaseModifiedTimeForSamplesInList = (samples, list) => {
    const samplesToConsider = samples.filter(s =>
        list.listType !== ListType.startList &&
        s.controlName === list.controlName);
    const modifiedTimes = samplesToConsider.map(sample => sample.databaseModifiedTime);
    return modifiedTimes.length === 0
        ? 0
        : Math.max(...modifiedTimes);
};

export const createResultSelectionDescription = (resultSelection, regions, classCategories, translate, activeLanguage) => {
    const atoms = [];
    const regionDictionary = {};
    const classCategoryDictionary = {};
    regions.map(r => regionDictionary[r.regionId] = r);
    classCategories.map(cc => classCategoryDictionary[cc.classCategoryId] = cc);

    const clubNames =
        resultSelection.filter.organisationFilter.organisationKeys.map(organisationKey => organisationKey.split("|")[0])
        .concat(resultSelection.filter.organisationFilter.regionIds.map(regionId => translate("resultSelectionDescription.organisationsInX", { x: (regionDictionary[regionId] ?? {}).name })))
        .concat(resultSelection.filter.organisationFilter.countryCodes.map(countryCode => translate("resultSelectionDescription.organisationsInX", { x: countryCode })));

    if (clubNames.length) {
        if(resultSelection.filter.filteredPlaceLimit === undefined) {
            atoms.push(
                translate("resultSelectionDescription.allFor") + 
                " " +
                clubNames.join(", ")
            );
        } else {
            atoms.push(
                translate("resultSelectionDescription.topXFor", { x: resultSelection.filter.filteredPlaceLimit }) + 
                " " +
                clubNames.join(", ")
            );
        }
    }

    if (resultSelection.filter.unfilteredPlaceLimit) {
        if(clubNames.length) {
            atoms.push(translate("resultSelectionDescription.topXForOtherOrganisations", { x: resultSelection.filter.unfilteredPlaceLimit }));
        } else {
            atoms.push(translate("resultSelectionDescription.topX", { x: resultSelection.filter.unfilteredPlaceLimit }));
        }
    }

    if (resultSelection.filter.classFilter.classCategoryIds.length) {
        atoms.push(
            translate("resultSelectionDescription.classCategoryX", { x: resultSelection.filter.classFilter.classCategoryIds.map(classCategoryId => (classCategoryDictionary[classCategoryId] || { names: {}}).names[activeLanguage.code]).join(", ") }) 
        );
    }

    if (resultSelection.filter.classFilter.onlyClassesContainingFilteredCompetitors) {
        atoms.push(translate("resultSelectionDescription.onlyClassesContainingFilteredCompetitors"));
    }

    return atoms;
};

export const isElapsedTimeStatus = status =>
    status === ResultStatus.ok ||
    status === ResultStatus.finished; // finished, but punches not yet validated

export const isElapsedTimeSampleStatus = status =>
    isElapsedTimeStatus(status) ||
    status === ResultStatus.started || // check/start punch registered
    status === ResultStatus.notActivated; // no check/start punch, but still consider as started

export const isElapsedTimeSample = sample => sample && isElapsedTimeSampleStatus(sample.status);

const finishedStatuses = [
    ResultStatus.ok,
    ResultStatus.didNotFinish,
    ResultStatus.didNotStart,
    ResultStatus.disqualified,
    ResultStatus.mispunched
];

export const isFinishedStatus = status => finishedStatuses.indexOf(status) !== -1;

const invalidStatuses = [
    ResultStatus.didNotStart,
    ResultStatus.didNotFinish,
    ResultStatus.disqualified,
    ResultStatus.mispunched,
    ResultStatus.deleted
];

export const isInvalidStatus = status => invalidStatuses.indexOf(status) !== -1;

const mergeSampleCollections = (targetResult, sourceResult) => {
    const mergedSamples = {};
    const updatedSamples = [];
    // the target samples are left as-is
    targetResult.samples.forEach(targetSample => mergedSamples[targetSample.sampleId] = targetSample);
    
    // for source samples:
    // if a sample exists in both target and source, the target object is kept but its properties are updated
    // otherwise, just add the sample to the target
    sourceResult.samples.forEach(sourceSample => {
        if(!mergedSamples[sourceSample.sampleId]) {
            mergedSamples[sourceSample.sampleId] = {};
        }
        
        const wasUpdated = mergeIfSamplesAreDifferent(mergedSamples[sourceSample.sampleId], sourceSample);
        if(wasUpdated) {
            updatedSamples.push(mergedSamples[sourceSample.sampleId]);
        }
    });

    targetResult.samples = Object.values(mergedSamples);

    return updatedSamples;
};

export const structureResultsForAllRaces = results => {
    const resultsByEntryIds = {};
    results.forEach(result => {
        resultsByEntryIds[result.entryId] = resultsByEntryIds[result.entryId] ?? {};
        resultsByEntryIds[result.entryId][result.raceId] = result;
    });
    return Object.values(resultsByEntryIds);
};

const propertiesToExcludeForSamplesAreEqual = [ "databaseModifiedTime", "status" ];

export const statusesToConsiderAsOkForIntermediate = [ 
    ResultStatus.notActivated, 
    ResultStatus.started, 
    ResultStatus.unknown,
    ResultStatus.notYetFinished,
    ResultStatus.finished 
];

const transformToDisplayableStatus = status => {
    if(statusesToConsiderAsOkForIntermediate.indexOf(status) !== -1) {
        return ResultStatus.ok;
    }
    return status;
};

const samplesAreEqual = (first, second) => {
    const equal = propertiesAreEqual(first, second, propertiesToExcludeForSamplesAreEqual);
    if(equal) {
        // check statuses
        return transformToDisplayableStatus(first.status) === transformToDisplayableStatus(second.status);
    }
    return equal;
};

const excludeDatabaseModifiedTime = ["databaseModifiedTime"];
export const mergeIfSamplesAreDifferent = (first, second) => {
    // samples are considered being equal if both of these statements are true
    // - all their properties except for status and databaseModifiedTime are equal
    // - their status properties evaluate to the same "displayable status" value (i.e. changing notActivated, started, notYetFinished, finished to ok)
    if(samplesAreEqual(first, second)) {
        // we want to use the latest status value internally
        // but not databaseModifiedTime, to avoid flagging the sample as updated in the list
        const statusesAreDifferent = first.status !== second.status;
        mergeProperties(first, second, excludeDatabaseModifiedTime);
        return statusesAreDifferent;
    } else {
        mergeProperties(first, second);
        return true;
    }
};

export const formatOverallPlaceDifferenceSinceStart = overallPlaceDifferenceSinceStart => {
    if(overallPlaceDifferenceSinceStart === undefined) {
        return "";
    }
    if(overallPlaceDifferenceSinceStart === 0) {
        return "0";
    }
    const sign = Math.sign(overallPlaceDifferenceSinceStart) < 0
        ? "-"
        : "+";
    return `${sign}${Math.abs(overallPlaceDifferenceSinceStart)}`;
};