import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import RaceBox from "../../../common/presentational/RaceBox";
import ListTypeBox from "../presentational/ListTypeBox";
import ClassBox from "../presentational/ClassBox";
import OrganisationBox from "./OrganisationBox";
import SearchTextBox from "./SearchTextBox";
import ListType from "../../../logic/models/ListType";
import { Translate, withLocalize } from "react-localize-redux";
import { isMultiRace, hasOverallResults, getSortedRaces, getSortedClasses, getSortedOrganisations, getCurrentRaceId } from "../../../logic/EventUtil";
import { getOrganisationKey } from "../../../logic/OrganisationUtil";
import { parseListFromLocation, getDefaultOrderByForListType, getUrlForCompetitors, getUrlForTags, getUrlForList, getCurrentListType } from "../../../logic/ListUtil";
import { sanitizeTagText } from "../../../logic/TagUtil";
import { Formik, Form } from "formik";

import ShowFavoritesButton from "../presentational/ShowFavoritesButton";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import "../../../styles/list-selector.scss";

const ListSelector = ({ event, translate, onClose }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const favoriteEntryIds = useSelector(state => state.eventPage.favoriteEntryIds);

    const list = parseListFromLocation(location, event) ?? {};
    list.raceId = list.raceId ?? getCurrentRaceId(event);

    const initialFormValues = {
        raceId: list.raceId + "",
        listType: list.listType ?? getCurrentListType(event),
        classId: list.classIds?.length === 1 ? list.classIds[0] : undefined,
        organisationKey: list.organisationKey,
        competitorSearchText: "",
        tagSearchText: ""
    };

    const navigateOrRedirect = urlForListResult => {
        if(urlForListResult.isExternal) {
            window.open(urlForListResult.url, "_blank");
        } else {
            navigate(urlForListResult.url);
        }
    };

    const closeListSelector = form => {
        onClose();
        form.setFieldValue("competitorSearchText", "");
        form.setFieldValue("tagSearchText", "");
    };

    const handleRaceChange = (newRace, form) => {
        if(list.classIds || list.organisationKey || list.entryIds) {
            closeListSelector(form);
            navigateOrRedirect(getUrlForList(event,
                {
                    ...list,
                    raceId: newRace.raceId
                }
            ));
        }
    };

    const handleListTypeChange = (newListType, form) => {
        if(list.classIds || list.organisationKey || list.entryIds) {
            closeListSelector(form);
            navigateOrRedirect(getUrlForList(event,
                {
                    ...list,
                    listType: newListType,
                    orderBy: getDefaultOrderByForListType(event, list.raceId, newListType),
                    direction: 1
                }
            ));
        }
    };

    const handleClassChange = (newClass, form) => {
        if(newClass){
            closeListSelector(form);
            navigateOrRedirect(getUrlForList(event,
                {
                    ...list,
                    classIds: [newClass.classId],
                    organisationKey: undefined,
                    entryIds: undefined,
                    tag: undefined
                }
            ));
        }
    };

    const classLabelRenderer = cl => cl.name;
    
    const handleOrganisationChange = (newOrganisation, form) => {
        if(newOrganisation){
            closeListSelector(form);
            navigateOrRedirect(getUrlForList(event,
                {
                    ...list,
                    classIds: undefined,
                    organisationKey: getOrganisationKey(newOrganisation),
                    entryIds: undefined,
                    tag: undefined
                }
            ));
        }
    };

    const handleCompetitorSearchTextSelected = (value, form) => {
        closeListSelector(form);
        if(value) {
            navigate(getUrlForCompetitors(event.slug, value));
        }
    };

    const handleTagSearchTextSelected = (value, form) => {
        closeListSelector(form);
        const sanitizedValue = sanitizeTagText(value);
        if(sanitizedValue) {
            navigate(getUrlForTags(event.slug, sanitizedValue));
        }
    };

    const handleFavoritesButtonClick = form => {
        closeListSelector(form);
        navigateOrRedirect(getUrlForList(event,
            {
                ...list,
                raceId: form.values.raceId,
                classIds: undefined,
                organisationKey: undefined,
                entryIds: favoriteEntryIds,
                tag: undefined
            }
        ));
    };

    const getListTypes = formValues => {
        if(formValues.raceId === "all") {
            return [
                ListType.startList,
                ListType.resultList
            ];
        }
        const classHasOverallResults = formValues.classId
            ? event.classes[formValues.classId].hasOverallResults 
            : true;
        const eventHasOverallResults = hasOverallResults(event, list.raceId);
        const listTypes = [
            ListType.startList,
            ListType.raceResultList
        ];
        if(classHasOverallResults && eventHasOverallResults) {
            listTypes.push(ListType.overallResultList);
        }
        return listTypes;
    };

    return (
        <Formik
            initialValues={initialFormValues}
            enableReinitialize
        >
            { form => 
                (
                    <Form>
                        <div className="list-selector">
                            { 
                                isMultiRace(event) &&
                                (
                                    <div className="list-selector-input">
                                        <RaceBox
                                            name="raceId"
                                            races={getSortedRaces(event, true /* includeAllRacesItem */)}
                                            onChange={newRace => handleRaceChange(newRace, form)}
                                        />
                                    </div>
                                )
                            }
                            <div className="list-selector-input">
                                <ListTypeBox
                                    name="listType"
                                    listTypes={getListTypes(form.values)}
                                    isMultiRace={isMultiRace(event)}
                                    onChange={newListType => handleListTypeChange(newListType, form)}
                                />
                            </div>
                            <div className="list-selector-input">
                                <ClassBox
                                    name="classId"
                                    classes={getSortedClasses(event)}
                                    labelRenderer={classLabelRenderer}
                                    onChange={newClass => handleClassChange(newClass, form)}
                                />
                            </div>
                            <div className="list-selector-input">
                                <OrganisationBox
                                    name="organisationKey"
                                    organisations={getSortedOrganisations(event)}
                                    onChange={newOrganisationKey => handleOrganisationChange(newOrganisationKey, form)}
                                />
                            </div>
                            <div className="list-selector-input">
                                <SearchTextBox
                                    name="competitorSearchText"
                                    showButton
                                    placeholder={translate("eventPage.personBox.placeholder")}
                                    onSelected={text => handleCompetitorSearchTextSelected(text, form)}
                                />
                            </div>

                            {
                                event.useTags &&
                                (
                                    <div className="list-selector-input">
                                        <SearchTextBox 
                                            name="tagSearchText"
                                            showButton
                                            placeholder={translate("eventPage.tagBox.placeholder")}
                                            onSelected={text => handleTagSearchTextSelected(text, form)}
                                        />
                                    </div>
                                )
                            }

                            {
                                favoriteEntryIds && favoriteEntryIds.length > 0 &&
                                (
                                    <ShowFavoritesButton
                                        favoriteCount={favoriteEntryIds.length}
                                        onClick={() => handleFavoritesButtonClick(form)}
                                    />
                                )
                            }
                            
                            <Link id="previous-years-link" to="/search">
                                <Translate id="previousYears"/>
                            </Link>
                        </div>
                    </Form>
                )
            }
        </Formik>
    );
};

ListSelector.propTypes = {
    event: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    translate: PropTypes.func.isRequired
};
  
export default withLocalize(ListSelector);
