import React from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import RaceCell from "../presentational/RaceCell";
import PlaceCell from "../presentational/PlaceCell";
import ElapsedTimeCell from "../presentational/ElapsedTimeCell";
import TimeBehindCell from "../presentational/TimeBehindCell";
import ElapsedTimePerKilometerCell from "../presentational/ElapsedTimePerKilometerCell";
import CourseLengthCell from "../presentational/CourseLengthCell";
import StartTimeCell from "../presentational/StartTimeCell";
import PunchingCardNumberCell from "../presentational/PunchingCardNumberCell";
import { setHighlightedResultId } from "../../../logic/ListUtil";
import ControlType from "../../../logic/models/ControlType";
import { getSample, createUnknownSample } from "../../../logic/ResultUtil";
import AccumulationType from "../../../logic/models/AccumulationType";
import SampleImportance from "../../../logic/models/SampleImportance";


const SingleCompetitorResultRow = ({ result, event, now }) => {
    
    const primarySample = getSample(result, AccumulationType.race, ControlType.finish) || createUnknownSample(result, AccumulationType.race, ControlType.finish);
    const secondarySample = getSample(result, AccumulationType.overall, ControlType.finish) || createUnknownSample(result, AccumulationType.overall, ControlType.finish);

    const attributes = {};
    if(result.clientUpdatedTime) {
        attributes["data-updatedtime"] = result.clientUpdatedTime;
    }
    return (
        <li {...attributes}>
            <RaceCell
                event={event}
                result={result}
                onClick={() => setHighlightedResultId(result.resultId)}
            />
            <StartTimeCell
                event={event}
                result={result}
                timeZone={event.timeZone}
            />
            <CourseLengthCell
                result={result}
            />
            <PlaceCell
                sample={primarySample}
                classNamePrefix={SampleImportance.primary}
            />
            <ElapsedTimeCell
                event={event}
                result={result}
                sample={primarySample}
                classNamePrefix={SampleImportance.primary}
                now={now}
            />
            <TimeBehindCell
                sample={primarySample}
                sampleImportance={SampleImportance.primary}
            />
            <ElapsedTimePerKilometerCell
                result={result}
                sample={primarySample}
            />
            <PlaceCell
                sample={secondarySample}
                classNamePrefix={SampleImportance.secondary}
            />
            <ElapsedTimeCell
                event={event}
                result={result}
                sample={secondarySample}
                classNamePrefix={SampleImportance.secondary}
                now={now}
            />
            <TimeBehindCell
                sample={secondarySample}
                sampleImportance={SampleImportance.secondary}
            />
            <PunchingCardNumberCell
                result={result}
            />
        </li>
    );
};

SingleCompetitorResultRow.propTypes = {
    result: PropTypes.object.isRequired,
    event: PropTypes.object.isRequired,
    now: PropTypes.number,
    translate: PropTypes.func.isRequired
};

export default withLocalize(SingleCompetitorResultRow);