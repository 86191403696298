import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const MessageModal = ({ title, message, className, onToggle, isOpen }) => {
    return (
        <>
            <Modal isOpen={isOpen} toggle={onToggle} className={className}>
                <ModalHeader toggle={onToggle}>{title}</ModalHeader>
                <ModalBody>
                    {message}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={onToggle}>OK</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

MessageModal.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    className: PropTypes.string,
    onToggle: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default MessageModal;