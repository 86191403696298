import TimeType from "../model/TimeType";
import { timeIsSet, timeIsSetAndPositive } from "./Util";

class SplitTimeHydrator {
    // populates an array of SplitTime objects (with code, isValid[TimeType.sinceStart], and times[TimeType.sinceStart]) with all time types: leg, sinceStart, passing
    hydrate(splitTimes, startTime) {
        if(splitTimes && splitTimes.length > 0) {
            splitTimes[0].isValid = {
                [TimeType.leg]: false,
                [TimeType.sinceStart]: timeIsSetAndPositive(splitTimes[0].times[TimeType.sinceStart])
            };
            splitTimes[0].times[TimeType.leg] = undefined;
            if (splitTimes[0].isValid[TimeType.sinceStart]) {
                splitTimes[0].times[TimeType.passing] = startTime;
            }
            splitTimes[0].isValid[TimeType.passing] = timeIsSetAndPositive(splitTimes[0].times[TimeType.passing]);

            for(let i = 1; i < splitTimes.length; i++) {
                const thisTimes = splitTimes[i].times;
                const lastTimes = splitTimes[i - 1].times;
                const thisTimeIsSet = timeIsSet(thisTimes[TimeType.sinceStart]);
                const bothTimesAreSet = timeIsSet(lastTimes[TimeType.sinceStart]) && thisTimeIsSet;
                thisTimes[TimeType.leg] = bothTimesAreSet
                    ? thisTimes[TimeType.sinceStart] - lastTimes[TimeType.sinceStart]
                    : undefined;
                thisTimes[TimeType.passing] = thisTimeIsSet && startTime
                    ? startTime + thisTimes[TimeType.sinceStart]
                    : undefined;
                splitTimes[i].isValid = {
                    [TimeType.leg]: timeIsSetAndPositive(thisTimes[TimeType.leg]),
                    [TimeType.sinceStart]: thisTimeIsSet && splitTimes[i - 1].isValid[TimeType.sinceStart],
                    [TimeType.passing]: timeIsSetAndPositive(thisTimes[TimeType.passing]) && splitTimes[i - 1].isValid[TimeType.passing]
                };
            }
        }
    }
}

export default SplitTimeHydrator;
