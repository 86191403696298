const getBaseUrl = () => {
    if (window.location.hostname === "localhost" && window.location.port === "") {
        // probably running in Arena mode
        return "http://localhost";
    } 
    return window.location.origin;
};

const getSignalRBaseUrl = () => {
    if (window.location.hostname === "localhost" && window.location.port !== "") {
        // running in local mode
        return "https://localhost:49640";
    } 
    return window.location.origin;
};

const getApplicationInsightsConnectionString = () => {
    switch (window.location.hostname) {
        case "oringenresultat.azurewebsites.net":
        case "resultat.oringen.se":
            return "InstrumentationKey=818166ea-1d1f-431b-b39d-4120d53094cc;IngestionEndpoint=https://northeurope-3.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/";
        case "oringenresultattest.azurewebsites.net":
            return "InstrumentationKey=b7b080b5-a168-459a-a2bd-9c4709849eba;IngestionEndpoint=https://northeurope-0.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/";
        default: 
            return undefined;
    }
};

const getWebPushApplicationServerKey = () => {
    // public application server keys obtained from https://web-push-codelab.glitch.me/
    // these keys are stored server-side as well, together with their corresponding private keys
    switch (window.location.hostname) {
        case "oringenresultat.azurewebsites.net":
        case "resultat.oringen.se":
            return "BDfGU7vBYXJ9xTO-DPYJ0Wl3YPiFtzvi3ug2tPGo0jobZ3mdYzqHVrkDFb2Y7vNBPcLSoZt-o0cScdpGE1Un2uI";
        case "oringenresultattest.azurewebsites.net":
            return "BGX3KPRcCMLcAUFczExFFThBhOPej5rmKmbNYmPCsqb3zUDeIppoHsh0io2hm5oMQ4avdRjAFwc36CxB4x6vKKQ";
        default: 
            return "BKoUgjUIjFy7d_DM3gGLSSqlDScuL8HphQgda0oGUEZ4cQI8C_hkOx8j3puH2OvdfcQ4VYnh2XT7mSCuVUNO72A";
    }
};


const settings = {
    apiBaseUrl: `${getBaseUrl()}/api`,
    signalRUrl: `${getSignalRBaseUrl()}/hubs/results`,
    useBinaryProtocol: false, //!!window.ArrayBuffer;
    applicationInsightsConnectionString: getApplicationInsightsConnectionString(),
    webPushApplicationServerKey: getWebPushApplicationServerKey()
};

export default settings;