import React from "react";
import PropTypes from "prop-types";
import { withLocalize, Translate } from "react-localize-redux";

const ShowFavoritesButton = ({ onClick, favoriteCount }) => {
    return (
        <button className="favorites-button btn btn-primary" type="button" onClick={onClick}>
            <Translate id="favorites"/> {`(${favoriteCount})`}
        </button>  
    );
};

ShowFavoritesButton.propTypes = {
    favoriteCount: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired
};

export default withLocalize(ShowFavoritesButton);