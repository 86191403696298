export default class TooltipTemplate {
    constructor(options) {
        options = options ?? {};
        this.creator = options.creator; // function that transforms a model object to a Tooltip object
    }

    createTooltip(data) {
        return this.creator(data);
    }
}
