import SplitTime from "../model/SplitTime";
import SplitTimeHydrator from "./SplitTimeHydrator";

class CourseSplitTimeCalculator {
    // Creates an array of split times based on the given split times for the course given by the controls.
    // The split time array follows the course, control for control.
    // Any split times that are not part of the course are filtered away.
    // splitTimes: array with SplitTime objects
    // startTime: JS Date object
    // controls: array with Control objects
    calculateCourseSplitTimes(splitTimes, startTime, controls) {
        const inputSplitTimes = splitTimes; // for clarity
        const outputSplitTimes = [];
        let beginInputSplitTimeIndex = 0;
        let inputSplitTimeIndex = 0;
        for (let i=0; i<controls.length; i++) {
            const code = controls[i].code;
            let splitTimeFound = false;
            inputSplitTimeIndex = beginInputSplitTimeIndex;
            while (inputSplitTimeIndex < inputSplitTimes.length) {
                const inputSplitTime = inputSplitTimes[inputSplitTimeIndex++];
                if (code === inputSplitTime.code) {
                    outputSplitTimes.push(inputSplitTime.clone());
                    splitTimeFound = true;
                    break;
                }
            }
            if(splitTimeFound) {
                beginInputSplitTimeIndex = inputSplitTimeIndex;
            } else {
                outputSplitTimes.push(new SplitTime({ code }));
            }
        }
        const hydrator = new SplitTimeHydrator();
        hydrator.hydrate(outputSplitTimes, startTime);
        return outputSplitTimes;
    }
}

export default CourseSplitTimeCalculator;
