import React, { useState } from "react";
import PropTypes from "prop-types";
import { withLocalize, Translate } from "react-localize-redux";
import LanguageSelector from "../../../common/presentational/LanguageSelector";
import ResultListTypeButton from "../presentational/ResultListTypeButton";
import ListType from "../../../logic/models/ListType";
import { Formik, Form, Field } from "formik";

const Header = ({ event, race, resetButtonVisible, resultListType, resultListTypeButtonsVisible, onSetResultListType, onResetClick, onRaceChange, translate }) => {
    const handleRaceChange = (fieldProps, domEvent) => {
        fieldProps.form.setFieldValue("raceId", domEvent.target.value); // always a string value
        const parsedValue = parseInt(domEvent.target.value);
        const newRace = Object.values(event.races).find(o => o.raceId === parsedValue);
        onRaceChange(newRace);
    };
    
    const renderResetButton = () => {
        if (resetButtonVisible) {
            return (
                <button className="btn" onClick={onResetClick}>
                    <Translate id="kiosk.header.resetButtonText" />
                </button>
            );
        }
        return undefined;
    };

    const renderResultListTypeButtons = () => {
        if (resultListTypeButtonsVisible) {
            const selectedType = resultListType;
            return (
                <ul id="result-list-sort-mode-buttons" className="button-list">
                    {
                        [ListType.raceResultList, ListType.overallResultList].map(type =>
                            (
                                <li key={type}>
                                    <ResultListTypeButton
                                        resultListType={type}
                                        selected={type === selectedType}
                                        onSetResultListType={onSetResultListType}
                                    />
                                </li>
                            )
                        ) 
                    }
                </ul>
            );
        }
        return undefined;
    };

    const renderRaceDropdown = () => {
        return (
            <Formik
                initialValues={{
                    raceId: race.raceId
                }}
                enableReinitialize
            >
                { 
                    form => 
                    (
                        <Form>
                            <Field name="raceId">
                                {
                                    fieldProps => (
                                        <select name="raceId" className="form-control" value={fieldProps.field.value} onChange={domEvent => handleRaceChange(fieldProps, domEvent)}>
                                            {
                                                Object.values(event.races).map(race => (
                                                    <option key={race.raceId} value={race.raceId}>
                                                        {translate("kiosk.header.raceX", { raceNumber: race.raceNumber })}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    )
                                }
                            </Field>
                        </Form>
                    )
                }
            </Formik>
        );
    };

    return (
        <nav>
            <div id="logotype" />
            <div id="race-number">
                {
                    race
                        ? renderRaceDropdown()
                        : ""
                }
            </div>
            <ul className="nav">
                <li className="nav-item">
                    {renderResetButton()}
                </li>
                <li className="nav-item">
                    {renderResultListTypeButtons()}
                </li>
                <li className="nav-item">
                    <LanguageSelector />
                </li>
            </ul>

        </nav>
    );
};

Header.propTypes = {
    event: PropTypes.object,
    race: PropTypes.object,
    resetButtonVisible: PropTypes.bool,
    resultListType: PropTypes.number, 
    resultListTypeButtonsVisible: PropTypes.bool,
    onSetResultListType: PropTypes.func,
    onResetClick: PropTypes.func,
    onRaceChange: PropTypes.func,
    translate: PropTypes.func.isRequired
};

export default withLocalize(Header);