import ClassResultListJsonReader from "./ClassResultListJsonReader";

export default class ResultSelectionExtractJsonReader {
    read(json) {
        const classResultListJsonReader = new ClassResultListJsonReader();
        return {
            classResultLists: json.classResultLists.map(classResultList => classResultListJsonReader.read(classResultList))
        };
    }
}
