import React from "react";
import { Navigate } from "react-router-dom";

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

const DefaultRedirectPage = () => {
    const year = currentMonth < 7
        ? currentYear - 1
        : currentYear;
    const url = `/${year}`;

    return <Navigate to={url} />;
};
  
export default DefaultRedirectPage;