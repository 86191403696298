import React, { useState } from "react";
import PropTypes from "prop-types";
import { isLiveTime, getLiveTime, isElapsedTimeSample } from "../../../logic/ResultUtil";
import { formatElapsedTime, formatTimeBehind } from "../../../logic/TimeUtil";
import { withLocalize } from "react-localize-redux";
import { getExternalUrlForClasses } from "../../../logic/EventUtil";
import ListType from "../../../logic/models/ListType";
import AccumulationType from "../../../logic/models/AccumulationType";
import ExternalLinkIcon from "./ExternalLinkIcon";
import { Tooltip } from "reactstrap";

const ElapsedTimeCell = ({ event, result, sample, showTimeBehindTooltip, statusesToHide = [], classNamePrefix, now, translate }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const listType = sample.accumulationType === AccumulationType.race
        ? ListType.raceResultList
        : ListType.overallResultList;
    const externalUrl = getExternalUrlForClasses(event, result.cl?.classId, result.raceId, listType);

    const isLive = isLiveTime(result, sample, now);
    const className = `${classNamePrefix ?? ''}ElapsedTime${isLive ? " is-live" : ""}`;

    if(externalUrl) {
        return (
            <div className={className} data-sampleid={sample.sampleId}>
                <a href={externalUrl} target="_blank">
                    <ExternalLinkIcon/>
                </a>
            </div>
        );
    }
    
    const liveElapsedTime = isLive 
        ? getLiveTime(result, sample, now)
        : undefined;
    
    const isElapsedTime = sample && isElapsedTimeSample(sample) && result.timePresentation && result.cl.timePresentation;

    const handleTooltipToggle = () => setTooltipOpen(!tooltipOpen);

    const wrapInTimeBehindTooltip = text => {
        const isValidTooltip = sample && isElapsedTimeSample(sample) && sample.timeBehind != null;
        if(showTimeBehindTooltip && isValidTooltip) {
            const elementId = `sample-${sample.sampleId}`;
            return (
                <>
                    <span id={elementId} href="#">
                        {text}
                    </span>
                    <Tooltip placement="bottom" text="left" isOpen={tooltipOpen} toggle={handleTooltipToggle} target={elementId}>
                        {formatTimeBehind(sample.timeBehind)}
                    </Tooltip>
                </>
            );
        }
        return text;
    };

    return (
        <div className={className} data-sampleid={sample.sampleId}>
            {
                isLive && result.timePresentation &&
                (
                    wrapInTimeBehindTooltip(formatElapsedTime(liveElapsedTime))
                )
            }

            {
                !isLive && sample && isElapsedTime &&
                (
                    wrapInTimeBehindTooltip(formatElapsedTime(sample.elapsedTime))
                )
            }

            {
                !isLive && sample && !isElapsedTime && statusesToHide.indexOf(sample.status) === -1 &&
                (
                    translate(`resultStatuses.${sample.status}`)
                )
            }
        </div>
    );
};
    
ElapsedTimeCell.propTypes = {
    event: PropTypes.object.isRequired,
    result: PropTypes.object.isRequired,
    sample: PropTypes.object.isRequired,
    showTimeBehindTooltip: PropTypes.bool,
    statusesToHide: PropTypes.array,
    classNamePrefix: PropTypes.string,
    now: PropTypes.number,
    translate: PropTypes.func.isRequired
};

export default withLocalize(ElapsedTimeCell);