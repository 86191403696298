import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import Header from "./Header";
import * as eventActions from "../../../actions/eventActions";
import { withLocalize } from "react-localize-redux";
import SingleCompetitorResultRow from "../presentational/SingleCompetitorResultRow";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import { createResultsFromReceivedLiveResults, mergeResults, isLiveResult } from "../../../logic/ResultUtil";
import { getCurrentRaceId, getCurrentTimeForEvent } from "../../../logic/EventUtil";
import { getOrganisationKey } from "../../../logic/OrganisationUtil";
import { getUrlForList, setHighlightedResultId } from "../../../logic/ListUtil";
import { getCurrentTime, fireTimerWhenWindowIsVisibleOnly } from "../../../logic/BrowserUtil";
import SingleCompetitorHeader from "../presentational/SingleCompetitorHeader";
import Organisation from "../presentational/Organisation";
import ManageTagsModal from "./ManageTagsModal";
import FavoriteIcon from "../presentational/FavoriteIcon";
import ControlType from "../../../logic/models/ControlType";
import ListLink from "../presentational/ListLink";

import "../../../styles/single-competitor.scss";

const SingleCompetitorPage = ({ translate, activeLanguage }) => {
    const params = useParams();
    const dispatch = useDispatch();

    const event = useSelector(state => state.eventPage.event);
    const results = useSelector(state => state.eventPage.resultsForEntry);
    const loading = useSelector(state => state.eventPage.loading.resultsForEntry);
    const receivedLiveResults = useSelector(state => state.eventPage.receivedLiveResults);
    const lastLiveResultsReceivedTime = useSelector(state => state.eventPage.lastLiveResultsReceivedTime);
    const favoriteEntryIds = useSelector(state => state.eventPage.favoriteEntryIds);

    const entryId = parseInt(params.entryId);

    const createLiveTimer = () => {
        let timer;
        const timerSetter = value => timer = value;
        // fireTimerWhenWindowIsVisibleOnly fires directly if the window is visible
        fireTimerWhenWindowIsVisibleOnly(timerSetter, handleLiveTimerElapsed, 1000);
        
        const destroy = () => {
            if(timer) {
                window.clearInterval(timer);
            }
        };
    
        const trigger = () => {
            handleLiveTimerElapsed();
        };
    
        return {
            destroy,
            trigger
        };
    };

    const createMergedResultCollection = (existingResults, newResults) => {
        const now = getCurrentTime();
        const resultDicitonary = [];
        existingResults.forEach(result => resultDicitonary[result.resultId] = { ...result });
        newResults.forEach(result => {
            resultDicitonary[result.resultId] = resultDicitonary[result.resultId] ?? {};
            const mergeResultsResponse = mergeResults(resultDicitonary[result.resultId], result);

            // set the clientUpdatedTime property, which gives the effect of marking the result as updated in the list thanks to CSS animations
            // only mark as updated if the result object itself has updated, or the sample that is shown for the user (in this case ControlType.finish) is updated 
            const wasUpdated = mergeResultsResponse.result || 
                mergeResultsResponse.samples.filter(s => s.controlType === ControlType.finish).length > 0; 
            if(wasUpdated) {
                resultDicitonary[result.resultId].clientUpdatedTime = now;
            }
        });
        return Object.values(resultDicitonary);
    };

    const getSortedResults = () => {
        if(!results) {
            return undefined;
        }
        const sortedResults = results.map(o => o);
        const races = event.races;
        sortedResults.sort((a, b) => races[a.raceId].raceNumber - races[b.raceId].raceNumber);
        return sortedResults;
    };

    const getOrganisationUrl = result => {
        return result && result.organisation
            ? getUrlForList(
                event, 
                { raceId: "all", organisationKey: getOrganisationKey(result.organisation), orderBy: `overall-${getCurrentRaceId(event)}` }
            ).url
            : undefined;
    };

    const getClassUrlResult = result => {
        return result && result.organisation
            ? getUrlForList(
                event, 
                { raceId: "all", classIds: [result.cl.classId], orderBy: `overall-${getCurrentRaceId(event)}` }
            )
            : undefined;
    };

    const getTagUrl = (result, tag) => {
        return result && tag
            ? getUrlForList(
                event, 
                { raceId: "all", tag, orderBy: `overall-${getCurrentRaceId(event)}` }
            ).url
            : undefined;
    };

    const handleToggleFavoriteLinkClick = () => {
        if(isFavorite(results[0])) {
            dispatch(eventActions.removeFavoriteEntryId(event, entryId, activeLanguage));
        } else {
            dispatch(eventActions.addFavoriteEntryId(event, entryId, activeLanguage));
        }
    };

    const handleManageTagsLinkClick = () => {
        setManageTagsModal({
            isOpen: true,
            result: results[0]
        });
    };

    const handleManageTagsModalSave = tags => {
        const result = manageTagsModal.result;
        result.tags = tags;
        setManageTagsModal({
            isOpen: false
        });
    };

    const handleManageTagsModalCancel = () => {
        setManageTagsModal({
            isOpen: false
        });
    };

    const isFavorite = result => {
        return event && (favoriteEntryIds || []).indexOf(result.entryId) !== -1;
    };

    const sortedResults = getSortedResults() || [];
    const result = sortedResults.find(result => result.raceId === getCurrentRaceId(event)) || sortedResults[0];
    const tags = (result ?? {}).tags || [];
    const classUrlResult = getClassUrlResult(result);

    const [ previousLastLiveResultsReceivedTime, setPreviousLastLiveResultsReceivedTime ] = useState(lastLiveResultsReceivedTime);
    const [ manageTagsModal, setManageTagsModal ] = useState({ isOpen: false, result: undefined });
    const [ now, setNow ] = useState(getCurrentTimeForEvent(event));

    const handleLiveTimerElapsed = () => {
        setNow(getCurrentTimeForEvent(event));
    };

    const [ liveTimer ] = useState(() => createLiveTimer());

    useEffect(() => {
        // now when the event is loaded, we can load the competitor's result
        dispatch(eventActions.fetchResultsForEntry(entryId));
    }, [event.eventId, entryId]);

    useEffect(() => {
        if(lastLiveResultsReceivedTime) {
            // we have got new results via SignalR
            const newResults = createResultsFromReceivedLiveResults(
                receivedLiveResults,
                event,
                previousLastLiveResultsReceivedTime,
                result => result.entryId === entryId);

            // eslint-disable-next-line react/no-did-update-set-state
            setPreviousLastLiveResultsReceivedTime(lastLiveResultsReceivedTime);

            if(newResults.length) {
                // eslint-disable-next-line no-console
                console.log(newResults);

                // TODO: handle multiple updates on same element
                const mergedResults = createMergedResultCollection(results, newResults);
                dispatch(eventActions.setResultsForEntry(mergedResults, entryId));
            }
        }
    }, [lastLiveResultsReceivedTime]);    

    return (
        <div id="single-competitor-page" className="page">
            <Header
                event={event}
                showMenuButton
            />
            <div className="content">
                <LoadingOverlay
                    active={loading}
                    spinner={<BounceLoader />}
                    text={translate("loading")} />
                {
                    result && 
                    (
                        <div>
                            <div id="caption-bar">
                                <div id="caption">
                                    <h1>{result.person.firstName} {result.person.lastName} {isFavorite(result) && <FavoriteIcon className="favorite-icon" />}</h1>
                                    {
                                        result.organisation && result.organisation.name &&
                                        (
                                            <h2>
                                                <Organisation organisation={result.organisation} url={getOrganisationUrl(result)} onClick={() => setHighlightedResultId(result.resultId)} />
                                            </h2>
                                        )
                                    }
                                    <h2>
                                        <ListLink
                                            url={classUrlResult.url}
                                            isExternal={classUrlResult.isExternal}
                                            onClick={() => setHighlightedResultId(result.resultId)}
                                        >
                                            {result.cl.name}
                                        </ListLink>
                                    </h2>
                                    {
                                        tags.length > 0 &&
                                        (
                                            <div id="tag-list">
                                                { result.tags.map(tag => <Link key={tag} to={getTagUrl(result, tag)} onClick={() => setHighlightedResultId(result.resultId)}>#{tag}</Link>) }
                                            </div>
                                        )
                                    }
                                </div>
                                <div id="caption-action-panel">
                                    <button type="button" className="btn btn-link" onClick={handleToggleFavoriteLinkClick}>
                                        {isFavorite(result) ? translate("removeFavorite") : translate("addFavorite")}
                                    </button>
                                    
                                    {
                                        event.useTags &&
                                        (
                                            <button type="button" className="btn btn-link" onClick={handleManageTagsLinkClick}>
                                                {tags.length ? translate("addOrRemoveTag") : translate("addTag")}
                                            </button>
                                        )
                                    }
                                </div>
                            </div>

                            <div id="race-list" className={`${result.cl.hasOverallResults ? "" : "no-overall-results"} ${result.timePresentation && result.cl.timePresentation ? "" : "no-time-presentation"} ${event.properties.hidePunchingCardNumbers ? "no-punching-card-numbers" : ""}`}>
                            
                                <SingleCompetitorHeader hasOverallResults={result.cl.hasOverallResults} />
                                
                                <ul id="race-list-body" className="grid-list-body">
                                    {sortedResults.map(result => (
                                        <SingleCompetitorResultRow
                                            key={result.resultId} 
                                            event={event}
                                            result={result}
                                            now={now}
                                        />
                                    ))}
                                </ul>
                            </div>

                        </div>
                    )
                }
                <ManageTagsModal isOpen={manageTagsModal.isOpen} result={manageTagsModal.result} onSave={handleManageTagsModalSave} onCancel={handleManageTagsModalCancel}/>
            </div>
        </div>
    );
};

SingleCompetitorPage.propTypes = {
    translate: PropTypes.func.isRequired,
    activeLanguage: PropTypes.object
};

export default withLocalize(SingleCompetitorPage);
