import AccumulationType from "../../logic/models/AccumulationType";

const createPlaceCell = ({ result }, sample, timeCellVisibilities, sampleImportance) => {
    let className = `${sampleImportance}Place`;
    if (timeCellVisibilities[sampleImportance].showLiveTime) {
        className += " is-live";
    }

    const div = document.createElement("div");
    div.setAttribute("data-sampleid", sample.sampleId);
    div.className = className;

    const visible = sample && (sample.accumulationType === AccumulationType.race || result.cl.hasOverallResults);

    if(visible && sample.place) {
        div.innerText = sample.place;
    }
    return div;
};

export default createPlaceCell;
