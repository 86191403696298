import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { parseListFromLocation, getUrlForList, sanitizeList } from "../../../logic/ListUtil";
import * as eventActions from "../../../actions/eventActions";

import ListPage from "./ListPage";
import AllRacesPage from "./AllRacesPage";

const ListRouter = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const event = useSelector(state => state.eventPage.event); 
    const listFromLocation = parseListFromLocation(location, event) ?? {}; // extract the parameters of the list from the querystring

    const sanitizedList = sanitizeList(listFromLocation, event);

    const url = getUrlForList(event, listFromLocation).url;
    const sanitizedUrl = getUrlForList(event, sanitizedList).url;

    useEffect(() => {
        dispatch(eventActions.setList(sanitizedList));
    }, [sanitizedUrl]);

    if(sanitizedUrl !== url) {
        return <Navigate replace to={sanitizedUrl}/>;
    }

    return listFromLocation.raceId === "all"
        ? <AllRacesPage listFromLocation={sanitizedList} />
        : <ListPage listFromLocation={sanitizedList} />;
};

export default ListRouter;
