import React from "react";
import LanguageSelector from "../../../common/presentational/LanguageSelector";

const Header= () => {
    return (
        <nav id="header" className="navbar navbar-expand">
            <div className="container header-inner">
                <div className="navbar-brand align-middle">
                    <div id="logotype" className="d-inline-block align-top"/>
                </div>
                <div className="navbar-nav ml-auto">
                    <LanguageSelector />
                </div>
            </div>
        </nav>
    );
};

export default Header;
