import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withLocalize } from "react-localize-redux";
import { getUrlForList } from "../../../logic/ListUtil";
import { getSortedClasses } from "../../../logic/EventUtil";

const ClassNavigationButtons = ({ event, list, translate }) => {
    const getAdjacentClassUrl = offset => {
        const adjacentClassId = getAdjacentClass(offset)?.classId;
        if(!adjacentClassId) {
            return undefined;
        }
        return getUrlForList(
            event, 
            { ...list, classIds: [adjacentClassId], organisationKey: undefined, entryIds: undefined, tag: undefined }
        ).url;
    };

    const getAdjacentClassTitle = offset => {
        const adjacentClass = getAdjacentClass(offset);
        if(!adjacentClass) {
            return undefined;
        }
        const key = offset < 0 ? "previous" : "next";
        return translate(`eventPage.${key}Class`) + ": " + adjacentClass.name;
    };

    const getAdjacentClass = offset => {
        const classId = (list.classIds || [])[0];
        const cl = event.classes[classId];
        const sortedClasses = getSortedClasses(event);
        const indexForThisClass = sortedClasses.indexOf(cl);
        return sortedClasses[indexForThisClass + offset];
    };

    const previousClassUrl = getAdjacentClassUrl(-1);
    const nextClassUrl = getAdjacentClassUrl(1);
    const previousClassTitle = getAdjacentClassTitle(-1);
    const nextClassTitle = getAdjacentClassTitle(1);

    return (
        <>
            {
                previousClassUrl &&
                (
                    <Link to={previousClassUrl} className="btn btn-primary" title={previousClassTitle}>
                        &lt;
                    </Link>
                )
            }
            {
                nextClassUrl &&
                (
                    <Link to={nextClassUrl} className="btn btn-primary" title={nextClassTitle}>
                        &gt;
                    </Link>
                )
            }
        </>
    );
};

ClassNavigationButtons.propTypes = {
    event: PropTypes.object,
    list: PropTypes.object,
    translate: PropTypes.func
};

export default withLocalize(ClassNavigationButtons);