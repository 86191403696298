import { parseDateString } from "./DateHelpers";

export default class SampleUpdateResponseJsonReader {
    read(json) {
        const sampleUpdateResponse = {};
        sampleUpdateResponse.timestamp = parseDateString(json.t);
        sampleUpdateResponse.sampleUpdates = json.s.map(createSampleUpdate);
        sampleUpdateResponse.pollInterval = json.p;
        return sampleUpdateResponse;
    }
}

const createSampleUpdate = jsonSampleUpdate => ({
    sampleId: jsonSampleUpdate.s,
    place: jsonSampleUpdate.p,
    timeBehind: jsonSampleUpdate.t
});
