import Control from "./Control";
import TimeType from "./TimeType";
import AccumulationType from "./AccumulationType";
import Competitor from "./Competitor";
import CourseSplitTimeCalculator from "../logic/CourseSplitTimeCalculator";
import PlaceAndTimeBehindCalculator from "../logic/PlaceAndTimeBehindCalculator";
import TopTimeCalculator from "../logic/TopTimeCalculator";
import NormalizedLengthCalculator from "../logic/NormalizedLengthCalculator";
import TimeLossCalculator from "../logic/TimeLossCalculator";

class Course {
    constructor(options) {
        // the controls of the course, including the start and the finish
        this.controls = options.controls
            .map((c, i) => new Control({
                number: i,
                ...c
            }));
        if (this.controls.length < 2) {
            throw "A course must contain at least two controls."; 
        }

        this.timeLossCalculationOptions = options.timeLossCalculationOptions;

        this.topPercentile = options.topPercentile || 0.25;

        if (options.competitors) {
            this.initializeFromCompetitors(options.competitors);
        }
    }

    createSplitTimes(splitTimes, startTime) {
        const calculator = new CourseSplitTimeCalculator();
        return calculator.calculateCourseSplitTimes(splitTimes, startTime, this.controls);
    }

    initializeFromCompetitors(competitors) {
        this.competitors = competitors.map(c => new Competitor(c));

        // set controls if needed

        calculateCourseSplitTimes(this.controls, this.competitors);

        calculatePlacesAndTimesBehind(this.controls, this.competitors);

        calculateLegTopTimes(this.controls, this.competitors, this.topPercentile);

        calculateNormalizedLegLengths(this.controls);

        calculatePerformanceIndices(this.controls, this.competitors);

        calculateTimeLoss(this.controls, this.competitors, this.timeLossCalculationOptions);
    }
}

const calculateCourseSplitTimes = (controls, competitors) => {
    const calculator = new CourseSplitTimeCalculator();
    competitors.forEach(competitor => 
        competitor.courseSplitTimes = calculator.calculateCourseSplitTimes(competitor.splitTimes, competitor.startTime, controls)
    );
};

const calculatePlacesAndTimesBehind = (controls, competitors) => {
    const calculator = new PlaceAndTimeBehindCalculator();
    calculator.calculatePlacesAndTimesBehind(controls, competitors);
};

const calculateLegTopTimes = (controls, competitors, topPercentile) => {
    const calculator = new TopTimeCalculator();
    controls.forEach((control, i) => {
        const times = competitors.map(c=>c.courseSplitTimes[i].times[TimeType.leg]);
        control.topTimes = {
            [AccumulationType.leg]: calculator.calculateTopTime(times, topPercentile)
        };
    });
};

const calculateNormalizedLegLengths = (controls) => {
    const calculator = new NormalizedLengthCalculator();
    const topTimes = getTopTimes(controls);
    const normalizedLengths = calculator.calculateNormalizedLengths(topTimes);
    controls.forEach((control, i) => control.normalizedLengths = {
        [AccumulationType.leg]: normalizedLengths[i]
    });
};

const calculatePerformanceIndices = (controls, competitors) => {
    const legTopTimes = getTopTimes(controls);
    competitors.forEach(competitor => competitor.calculatePerformanceIndices(legTopTimes));
};

const calculateTimeLoss = (controls, competitors, options) => {
    const calculator = new TimeLossCalculator();
    calculator.calculateTimeLoss(competitors, controls, options);
};

const getTopTimes = (controls) => controls.map((control, i) => i === 0 ? undefined : control.topTimes[AccumulationType.leg]);

export default Course;
