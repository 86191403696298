import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ResultRow from "../presentational/ResultRow";
import AccumulationType from "../../../logic/models/AccumulationType";
import ControlType from "../../../logic/models/ControlType";
import ListType from "../../../logic/models/ListType";
import { withLocalize, Translate } from "react-localize-redux";

const ResultList = ({ resultListType, selectedClass, results, showOverallResults }) => {
    useEffect(() => {
        document.getElementById("result-list-body-container").style.left = getScrollbarWidth() + "px";
    }, []);

    let key = 0;
    const resultSorter = createResultSorter(resultListType, selectedClass.timePresentation);
    const sortedResults = results.sort(resultSorter);

    const resultList = (
        <div>
            <div id="result-list-header-container">
                <table id="result-list-header">
                    <thead>
                        <tr>
                            <th className="race-place"><span><Translate id="kiosk.resultList.headers.racePlace"/></span></th>
                            <th className="person-name"><span><Translate id="kiosk.resultList.headers.personName"/></span></th>
                            <th className="organisation-name"><span><Translate id="kiosk.resultList.headers.organisationName"/></span></th>
                            <th className="race-elapsed-time"><span><Translate id="kiosk.resultList.headers.raceElapsedTime"/></span></th>
                            <th className="race-time-behind"><span><Translate id="kiosk.resultList.headers.raceTimeBehind"/></span></th>
                            <th className="overall-place"><span><Translate id="kiosk.resultList.headers.overallPlace"/></span></th>
                            <th className="overall-elapsed-time"><span><Translate id="kiosk.resultList.headers.overallElapsedTime"/></span></th>
                            <th className="overall-time-behind"><span><Translate id="kiosk.resultList.headers.overallTimeBehind"/></span></th>
                        </tr>
                    </thead>
                </table>
            </div>

            <div id="result-list-body-container">
                <table id="result-list-body">
                    <tbody>
                        {
                            sortedResults.map(result => 
                                (
                                    <ResultRow
                                        key={key++}
                                        result={result}
                                        raceSample={getRaceSample(result)}
                                        overallSample={showOverallResults ? getOverallSample(result) : undefined}
                                        timePresentation={selectedClass.timePresentation}
                                    />
                                )
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );

    const noResultsExist = (
        <div id="result-list-header-container">
        <div id="no-results-exist">
                <Translate id="kiosk.resultList.noResultsExist"/>
            </div>
        </div>
    );

    return (
        <div id="result-list">
            <div id="result-list-class-header-container">
                <div id="result-list-class-header">
                    <h2>{selectedClass.name}</h2>
                </div>
            </div>

            {sortedResults.length ? resultList : noResultsExist}
        </div>
    );
};

ResultList.propTypes = {
    resultListType: PropTypes.number,
    selectedClass: PropTypes.object,
    results: PropTypes.array,
    showOverallResults: PropTypes.bool
};

export default withLocalize(ResultList);

const getRaceSample = result =>
    result.samples.filter(s => s.accumulationType === AccumulationType.race && s.controlType === ControlType.finish)[0];

const getOverallSample = result => 
    result.samples.filter(s => s.accumulationType === AccumulationType.overall && s.controlType === ControlType.finish)[0];

const maxPlace = 99999;

const createResultSorter = (resultListType, timePresentation) => {
    const sampleGetter = resultListType === ListType.raceResultList
        ? getRaceSample
        : getOverallSample;

    return (a, b) => {
        const sampleA = sampleGetter(a) ?? {};
        const sampleB = sampleGetter(b) ?? {};
        let result;

        if (timePresentation) {
            result = (sampleA.place || maxPlace) - (sampleB.place || maxPlace);
            if (result !== 0) {
                return result;
            }
        }

        result = sampleA.status - sampleB.status;
        if (result !== 0) {
            return result;
        }

        return (a.person.lastName + ", " + a.person.firstName <= b.person.lastName + ", " + b.person.firstName)
            ? -1
            : 1;
    };
};

const getScrollbarWidth = () => {
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

    document.body.appendChild(outer);

    const widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";

    // add innerdiv
    const inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);

    const widthWithScroll = inner.offsetWidth;

    // remove divs
    outer.parentNode.removeChild(outer);

    return widthNoScroll - widthWithScroll;
};
