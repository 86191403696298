import React from "react";
import PropTypes from "prop-types";

const MenuIcon = ({ result, favoriteEntryIds }) => {
    const isFavorite = favoriteEntryIds?.indexOf(result.entryId) !== -1;
    const hasTags = result.tags && result.tags.length;
    let markup;
    if (isFavorite && hasTags) {
        markup = iconMarkups.hashtagFavorite;
    } else if (isFavorite) {
        markup = iconMarkups.favorite;
    } else if (hasTags) {
        markup = iconMarkups.hashtag;
    } else {
        markup = iconMarkups.dots;
    }
    // eslint-disable-next-line react/no-danger
    return <span dangerouslySetInnerHTML={{__html: markup}} />;
};

MenuIcon.propTypes = {
    result: PropTypes.object.isRequired,
    favoriteEntryIds: PropTypes.array
};

export default MenuIcon;

export const iconMarkups = {
    favorite: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.4300 4.9700"><path d="M4.70,0.71 L5.16,1.65 C5.19,1.71 5.25,1.76 5.32,1.77 L6.36,1.93 C6.53,1.94 6.61,2.16 6.47,2.29 L5.72,3.01 C5.68,3.06 5.65,3.14 5.67,3.21 L5.84,4.23 C5.87,4.41 5.69,4.54 5.53,4.46 L4.60,3.97 C4.54,3.94 4.47,3.94 4.40,3.97 L3.48,4.46 C3.32,4.54 3.13,4.41 3.17,4.23 L3.34,3.21 C3.37,3.14 3.33,3.06 3.28,3.01 L2.54,2.29 C2.41,2.16 2.47,1.94 2.65,1.93 L3.69,1.77 C3.76,1.76 3.82,1.71 3.84,1.65 L4.30,0.71 C4.38,0.54 4.62,0.54 4.70,0.71 L5.16,1.65 " /></svg>',
    hashtagFavorite: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.4300 4.9700"><path d="M7.21,0.70 L7.67,1.64 C7.70,1.70 7.76,1.75 7.83,1.76 L8.87,1.92 C9.04,1.93 9.12,2.15 8.98,2.28 L8.23,3.00 C8.19,3.05 8.16,3.13 8.18,3.20 L8.35,4.22 C8.38,4.40 8.20,4.53 8.04,4.45 L7.11,3.96 C7.05,3.93 6.98,3.93 6.91,3.96 L5.99,4.45 C5.83,4.53 5.64,4.40 5.68,4.22 L5.85,3.20 C5.88,3.13 5.84,3.05 5.79,3.00 L5.05,2.28 C4.92,2.15 4.98,1.93 5.16,1.92 L6.20,1.76 C6.27,1.75 6.33,1.70 6.35,1.64 L6.81,0.70 C6.89,0.53 7.13,0.53 7.21,0.70 L7.67,1.64 " /><path d="M3.38,3.09 3.08,3.08 3.19,2.04 3.69,2.04 C3.78,2.04 3.91,1.93 3.90,1.80 C3.92,1.67 3.87,1.51 3.65,1.52 L3.27,1.52 3.37,0.79 C3.39,0.66 3.29,0.51 3.15,0.49 C3.02,0.47 2.89,0.58 2.85,0.73 L2.75,1.52 1.73,1.52 1.81,0.77 1.80,0.77 1.81,0.77 C1.84,0.64 1.73,0.52 1.60,0.49 C1.46,0.48 1.33,0.58 1.29,0.74 L1.22,1.52 0.69,1.52 C0.58,1.52 0.48,1.65 0.47,1.76 C0.47,1.90 0.56,2.04 0.69,2.04 L0.85,2.04 1.16,2.04 1.02,3.08 0.54,3.08 C0.43,3.08 0.33,3.19 0.32,3.31 C0.31,3.44 0.42,3.58 0.56,3.58 L0.95,3.58 0.86,4.33 C0.86,4.46 1.00,4.59 1.11,4.59 C1.23,4.61 1.35,4.51 1.35,4.39 L1.47,3.58 2.50,3.58 2.40,4.26 C2.39,4.46 2.51,4.58 2.65,4.59 C2.74,4.61 2.89,4.54 2.90,4.42 L3.02,3.58 3.46,3.58 C3.59,3.58 3.72,3.48 3.73,3.35 C3.74,3.21 3.65,3.08 3.46,3.08 L3.38,3.09 L3.38,3.09 M2.69,2.03 2.56,3.07 1.53,3.08 1.67,2.03 " /></svg>',
    hashtag: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.4300 4.9700"><path d="M5.75,3.08 5.45,3.06 5.57,2.02 6.05,2.02 C6.15,2.02 6.28,1.92 6.28,1.79 C6.29,1.66 6.24,1.50 6.03,1.51 L5.64,1.51 5.74,0.78 C5.76,0.65 5.66,0.49 5.51,0.48 C5.39,0.47 5.26,0.57 5.22,0.72 L5.12,1.51 4.09,1.51 4.18,0.76 4.17,0.76 4.18,0.76 C4.21,0.63 4.10,0.51 3.98,0.48 C3.83,0.47 3.70,0.57 3.67,0.73 L3.58,1.51 3.06,1.51 C2.95,1.51 2.85,1.64 2.85,1.75 C2.84,1.89 2.93,2.02 3.06,2.02 L3.23,2.03 3.52,2.02 3.40,3.06 2.90,3.06 C2.80,3.06 2.70,3.17 2.69,3.29 C2.68,3.42 2.79,3.58 2.92,3.58 L3.33,3.58 3.23,4.33 C3.23,4.45 3.37,4.58 3.48,4.58 C3.60,4.59 3.72,4.50 3.73,4.38 L3.83,3.58 4.88,3.58 4.76,4.25 C4.76,4.45 4.88,4.58 5.01,4.58 C5.11,4.59 5.26,4.53 5.28,4.41 L5.38,3.58 5.83,3.58 C5.96,3.58 6.09,3.46 6.09,3.33 C6.11,3.19 6.02,3.06 5.83,3.06 L5.75,3.08 L5.75,3.08 M5.07,2.02 4.93,3.06 3.90,3.06 4.04,2.02 " /></svg>',
    dots: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.4300 4.9700"><path d="M3.00,2.53 C3.00,2.74 3.17,2.90 3.37,2.90 C3.57,2.90 3.74,2.74 3.74,2.53 C3.74,2.33 3.57,2.17 3.37,2.17 C3.17,2.17 3.00,2.33 3.00,2.53" /><path d="M4.13,2.53 C4.13,2.74 4.29,2.90 4.50,2.90 C4.70,2.90 4.86,2.74 4.86,2.54 C4.86,2.34 4.70,2.17 4.50,2.17 C4.29,2.17 4.13,2.34 4.13,2.54" /><path d="M5.25,2.53 C5.25,2.74 5.42,2.90 5.62,2.90 C5.82,2.90 5.99,2.74 5.99,2.53 C5.99,2.33 5.82,2.17 5.62,2.17 C5.42,2.17 5.25,2.33 5.25,2.53" /></svg>',
};