import React from "react";
import PropTypes from "prop-types";

const LanguageButton = ({ language, selected, onClick }) => {
    const handleClick = () => {
        onClick(language.code);
    };

    return (
        <button 
            id={`language_${language.code}`}
            className={`nav-item nav-link language-button ${selected ? "selected" : "not-selected"}`} 
            type="button"
            onClick={handleClick} 
        />
    );
};

LanguageButton.propTypes = {
    language: PropTypes.object.isRequired,
    selected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
};

export default LanguageButton;