import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const PersonCell = ({ result, url }) => {
    const name = `${result.person.firstName} ${result.person.lastName}`;

    return (    
        <div className="person">
            { 
                url
                    ? <Link to={url}>{name}</Link>
                    : name
            }
        </div>
    );
};

PersonCell.propTypes = {
    result: PropTypes.object.isRequired,
    url: PropTypes.string
};     

export default PersonCell;