import AccumulationType from "../../logic/models/AccumulationType";
import { isFirstRace } from "../../logic/ClassUtil";

const upArrow = '<svg xmlns="http://www.w3.org/2000/svg" class="arrow up-arrow" viewBox="0 0 666.6 500"><path d="M360.8,14.4c-10.4-15.1-31.1-19-46.3-8.5c-3.3,2.3-6.2,5.2-8.5,8.5L6,447.7c-10.5,15.1-6.8,35.9,8.3,46.3c5.6,3.9,12.2,6,19.1,6h600c18.4-0.1,33.3-15.1,33.3-33.5c0-6.7-2.1-13.2-5.9-18.8L360.8,14.4L360.8,14.4z"/></svg>';
const downArrow = '<svg xmlns="http://www.w3.org/2000/svg" class="arrow down-arrow" viewBox="0 0 666.6 500"><path d="M305.9,485.6c10.4,15.1,31.1,19,46.3,8.5c3.3-2.3,6.2-5.2,8.5-8.5l300-433.3c10.5-15.1,6.8-35.9-8.3-46.3 c-5.6-3.9-12.2-6-19.1-6h-600C14.8,0.1-0.1,15.1,0,33.5c0,6.7,2.1,13.2,5.9,18.8L305.9,485.6L305.9,485.6z"/></svg>';


const createOverallPlaceDifferenceSinceStartCell = ({ result, event }, sample, sampleImportance) => {
    const cl = event.classes[result.cl.classId];
    const race = event.races[result.raceId];
    
    const visible = sample && (sample.accumulationType === AccumulationType.overall && result.cl.hasOverallResults && !isFirstRace(cl, race, event));

    let type = "";
    if(visible) {
        if(sample.overallPlaceDifferenceSinceStart < 0) {
            type = "up";
        } else if (sample.overallPlaceDifferenceSinceStart > 0) {
            type = "down";
        } else {
            type = "zero";
        }
    }

    let className = `${sampleImportance}OverallPlaceDifferenceSinceStart`;

    const outerDiv = document.createElement("div");
    outerDiv.setAttribute("data-sampleid", sample.sampleId);
    outerDiv.className = className;

    const innerDiv = document.createElement("div");
    innerDiv.className = `inner ${type}`;
    outerDiv.appendChild(innerDiv);

    if(visible && sample.overallPlaceDifferenceSinceStart !== undefined) {
        const textDiv = '<div class="value">' + Math.abs(sample.overallPlaceDifferenceSinceStart) + '</div>';
        if(type === "up") {
            innerDiv.innerHTML = upArrow + textDiv;
        } else if(type === "down") {
            innerDiv.innerHTML = textDiv + downArrow;
        } else {
            innerDiv.innerHTML = textDiv;
        }
    }
    return outerDiv;
};

export default createOverallPlaceDifferenceSinceStartCell;
