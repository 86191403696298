import React from "react";
import PropTypes from "prop-types";

const PlaceCell = ({ sample, classNamePrefix }) => {
    const className = `${classNamePrefix ?? ''}Place`;

    return (
        <div className={className} data-sampleid={sample.sampleId}>
            {sample?.place}
        </div>
    );
};

PlaceCell.propTypes = {
    sample: PropTypes.object,
    classNamePrefix: PropTypes.string
};     

export default PlaceCell;