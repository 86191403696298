import React from "react";
import PropTypes from "prop-types";
import { formatCourseLength } from "../../../logic/ResultUtil";
import { withLocalize } from "react-localize-redux";

const CourseLengthCell = ({ result, translate }) => {
    return (
        <div className="courseLength">
            {
                result && result.cl && formatCourseLength(result.cl.courseLength, translate("thousandSeparator"))
            }
        </div>
    );
};
    
CourseLengthCell.propTypes = {
    result: PropTypes.object,
    translate: PropTypes.func.isRequired
};

export default withLocalize(CourseLengthCell);