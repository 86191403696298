import React from "react";
import PropTypes from "prop-types";

const SponsorBanner = ({ event }) => {
    return event.properties.sponsorBannerOptions
        ? (
            <>
                <style>
                    #sponsor-banner-desktop &#123;
                        background-image: url('{event.properties.sponsorBannerOptions.desktopUrl}');
                        width: {event.properties.sponsorBannerOptions.desktopWidth};
                        height: {event.properties.sponsorBannerOptions.desktopHeight};
                    &#125;
                    #sponsor-banner-mobile &#123;
                        background-image: url('{event.properties.sponsorBannerOptions.mobileUrl}');
                        width: {event.properties.sponsorBannerOptions.mobileWidth};
                        height: {event.properties.sponsorBannerOptions.mobileHeight};
                    &#125;
                </style>
                <a id="sponsor-banner-desktop" className="sponsor-banner" href={event.properties.sponsorBannerOptions.link} target="_blank" />
                <a id="sponsor-banner-mobile" className="sponsor-banner" href={event.properties.sponsorBannerOptions.link} target="_blank" />
            </>
        )
        : null;
};

SponsorBanner.propTypes = {
    event: PropTypes.object.isRequired
};

export default SponsorBanner;