import React from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import { Helmet } from "react-helmet";

const HelmetWrapper = ({ event, translate, activeLanguage }) => 
{
    const htmlAttributes = {
        lang: activeLanguage
            ? activeLanguage.code
            : undefined
    };

    return (
        <Helmet htmlAttributes={htmlAttributes}>
            <title>{event ? event.name : translate("loading")}</title>
            {
                event && <meta name="Description" content={translate("metaDescriptionForEvent", { eventName: event.name })}/>
            }
            {
                event && event.organisationIconSpriteBaseUrl && 
                (
                    <link rel="stylesheet" type="text/css" href={`${event.organisationIconSpriteBaseUrl}organisation-icon-sprite.css`} />
                )
            }
        </Helmet>
    );
};

HelmetWrapper.propTypes = {
    event: PropTypes.object,
    translate: PropTypes.func.isRequired,
    activeLanguage: PropTypes.object
};     

export default withLocalize(HelmetWrapper);