import React from "react";
import PropTypes from "prop-types";
import ListType from "../../../logic/models/ListType";
import AccumulationType from "../../../logic/models/AccumulationType";
import { parseOrderBy } from "../../../logic/AllRacesUtil";

const mobileAndTablet = "only screen and (max-width: 850px)";
const tablet = "only screen and (min-width: 681px) and (max-width: 850px)";
const mobile = "only screen and (min-width: 541px) and (max-width: 680px)";
const smallMobile = "only screen and (max-width: 540px)";

const AllRacesStyles = ({ races, list }) => {
    const orderBy = parseOrderBy(list.orderBy, races);

    const renderStyles = () => {
        switch(list.listType) {
            case ListType.startList: return renderStartListStyles();
            default: return renderResultListStyles();
        }
    };

    const renderStartListStyles = () => {
        const mobilePersonEnd = Math.floor(races.length / 2) + 1;
        const mobileOrganisationStart = mobilePersonEnd;
        const mobileOrganisationEnd = races.length + 1;
        const mobileFavoriteStart = mobileOrganisationEnd;
        return (
            <>
                #all-races-page #list.start-list .row &#123;
                    grid-template-columns: 3fr 3fr repeat({races.length}, 1fr) 0.33fr;
                &#125;

                #all-races-page #list.start-list .person &#123;
                    grid-row: 1;
                    grid-column: 1;
                &#125;
                #all-races-page #list.start-list .organisation &#123;
                    grid-row: 1;
                    grid-column: 2;
                &#125;
                #all-races-page #list.start-list .class &#123;
                    grid-row: 1;
                    grid-column: 2;
                &#125;
                #all-races-page #list.start-list .menu &#123;
                    grid-row: 1;
                    grid-column: {races.length+3};
                &#125;
                {
                    races.map((race, i) => (
                        <React.Fragment key={i}>
                            #all-races-page #list.start-list .race-{i+1}-startTime &#123;
                                grid-row: 1;
                                grid-column: {i+3};
                                {boldIfSelected(orderBy, race)}
                            &#125;
                        </React.Fragment>
                    ))
                }

                @media {mobileAndTablet} &#123; 
                    #all-races-page #list.start-list .row &#123;
                        grid-template-columns: repeat({races.length-1}, 1fr) 0.67fr 0.33fr;
                    &#125;
                    #all-races-page #list.start-list .person &#123;
                        grid-row: 1;
                        grid-column: 1/{mobilePersonEnd};
                    &#125;
                    #all-races-page #list.start-list .organisation &#123;
                        grid-row: 1;
                        grid-column: {mobileOrganisationStart}/{mobileOrganisationEnd};
                    &#125;
                    #all-races-page #list.start-list .class &#123;
                        grid-row: 1;
                        grid-column: {mobileOrganisationStart}/{mobileOrganisationEnd};
                    &#125;
                    #all-races-page #list.start-list .menu &#123;
                        grid-row: 1;
                        grid-column: {mobileFavoriteStart};
                    &#125;
                    {
                        races.map((race, i) => (
                            <React.Fragment key={i}>
                                #all-races-page #list.start-list .race-{i+1}-startTime &#123;
                                    grid-row: 2;
                                    grid-column: {i+1}/{i+2+(i === races.length - 1 ? 1 : 0)};
                                    font-size: 85%;
                                &#125;
                            </React.Fragment>
                        ))
                    }
                &#125;
            </>
        );
    };

    const renderResultListStyles = () => {
        const mobilePersonEnd = races.length;
        const mobileOrganisationStart = mobilePersonEnd;
        const mobileOrganisationEnd = 2 * races.length;
        const mobileFavoriteStart = mobileOrganisationEnd;
        return (
            <>
                #all-races-page #list.result-list .row &#123;
                    grid-template-columns: 3fr 3fr {races.map((o, i) => <React.Fragment key={i}>1fr 0.5fr </React.Fragment>)}0.33fr;
                &#125;

                #all-races-page #list.result-list .person &#123;
                    grid-row: 1;
                    grid-column: 1;
                &#125;
                #all-races-page #list.result-list .organisation &#123;
                    grid-row: 1;
                    grid-column: 2;
                &#125;
                #all-races-page #list.result-list .class &#123;
                    grid-row: 1;
                    grid-column: 2;
                &#125;
                #all-races-page #list.result-list .menu &#123;
                    grid-row: 1;
                    grid-column: {2*races.length+3};
                &#125;

                {
                    races.map((race, i) => (
                        <React.Fragment key={i}>
                            #all-races-page #list.result-list .race-{i+1}-ElapsedTime &#123;
                                grid-row: 1;
                                grid-column: {2*i+3};
                                {boldIfSelected(orderBy, race, AccumulationType.race)}
                            &#125;
                            #all-races-page #list.result-list .race-{i+1}-Place &#123;
                                grid-row: 1;
                                grid-column: {2*i+4};
                                {boldIfSelected(orderBy, race, AccumulationType.race)}
                            &#125;
                            #all-races-page #list.result-list .overall-{i+1}-ElapsedTime &#123;
                                grid-row: 2;
                                grid-column: {2*i+3};
                                font-size: 85%;
                                color: #777;
                                {boldIfSelected(orderBy, race, AccumulationType.overall)}
                            &#125;
                            #all-races-page #list.result-list .overall-{i+1}-Place &#123;
                                grid-row: 2;
                                grid-column: {2*i+4};
                                font-size: 85%;
                                color: #777;
                                {boldIfSelected(orderBy, race, AccumulationType.overall)}
                            &#125;

                            #all-races-page #list.result-list .race-{i+1}-full-width-ElapsedTime &#123;
                                grid-row: 1;
                                grid-column: {2*i+3}/{2*i+5};
                                {boldIfSelected(orderBy, race, AccumulationType.race)}
                            &#125;
                            #all-races-page #list.result-list .overall-{i+1}-full-width-ElapsedTime &#123;
                                grid-row: 2;
                                grid-column: {2*i+3}/{2*i+5};
                                font-size: 85%;
                                color: #777;
                                {boldIfSelected(orderBy, race, AccumulationType.overall)}
                            &#125;

                        </React.Fragment>
                    ))
                }
                @media {smallMobile} &#123;
                    #all-races-page #list.result-list .right &#123;
                        font-size: 60%;
                    &#125;                    
                &#125;                    

                @media {mobile} &#123;
                    #all-races-page #list.result-list .right &#123;
                        font-size: 80%;
                    &#125;                    
                &#125;                    

                @media {tablet} &#123;
                    #all-races-page #list.result-list .right &#123;
                        font-size: 90%;
                    &#125;                    
                &#125;                    

                @media {mobileAndTablet} &#123; 
                    #all-races-page #list.result-list .row &#123;
                        grid-template-columns: {races.map((o, i) => <React.Fragment key={i}>1fr 0.67fr </React.Fragment>)};
                    &#125;
                    #all-races-page #list.result-list .person &#123;
                        grid-row: 1;
                        grid-column: 1/{mobilePersonEnd};
                    &#125;
                    #all-races-page #list.result-list .organisation &#123;
                        grid-row: 1;
                        grid-column: {mobileOrganisationStart}/{mobileOrganisationEnd};
                        zoom: 85%;
                    &#125;
                    #all-races-page #list.result-list .class &#123;
                        grid-row: 1;
                        grid-column: {mobileOrganisationStart}/{mobileOrganisationEnd};
                    &#125;
                    #all-races-page #list.result-list .menu &#123;
                        grid-row: 1;
                        grid-column: {mobileFavoriteStart};
                    &#125;
                    {
                        races.map((race, i) => (
                            <React.Fragment key={i}>
                                #all-races-page #list.result-list .race-{i+1}-ElapsedTime &#123;
                                    grid-row: 2;
                                    grid-column: {2*i+1};
                                &#125;
                                #all-races-page #list.result-list .race-{i+1}-Place &#123;
                                    grid-row: 2;
                                    grid-column: {2*i+2};
                                    {borderRightIfNotLastCell(i)}
                                &#125;
                                #all-races-page #list.result-list .overall-{i+1}-ElapsedTime &#123;
                                    grid-row: 3;
                                    grid-column: {2*i+1};
                                &#125;
                                #all-races-page #list.result-list .overall-{i+1}-Place &#123;
                                    grid-row: 3;
                                    grid-column: {2*i+2};
                                    {borderRightIfNotLastCell(i)}
                                &#125;

                                #all-races-page #list.result-list .race-{i+1}-full-width-ElapsedTime &#123;
                                    grid-row: 2;
                                    grid-column: {2*i+1}/{2*i+3};
                                    {borderRightIfNotLastCell(i)}
                                &#125;
                                #all-races-page #list.result-list .overall-{i+1}-full-width-ElapsedTime &#123;
                                    grid-row: 3;
                                    grid-column: {2*i+1}/{2*i+3};
                                    {borderRightIfNotLastCell(i)}
                                &#125;
                            </React.Fragment>
                        ))
                    }
                &#125;
            </>
        );
    };

    const boldIfSelected = (orderBy, race, accumulationType) => {
        if(race.raceId === orderBy.raceId && (accumulationType === undefined || accumulationType === orderBy.accumulationType)) {
            return "font-weight: bold;";
        }
        return "";
    };

    const borderRightIfNotLastCell = i => {
        if(i < races.length - 1) {
            return "border-right: 1px solid rgba(0, 0, 0, 0.2);";
        }
        return "";
    };
    
    return (
        <style>
            {renderStyles()}
        </style>
    );
};

AllRacesStyles.propTypes = {
    races: PropTypes.array.isRequired,
    list: PropTypes.object.isRequired
}; 

export default AllRacesStyles;
