import React from "react";
import PropTypes from "prop-types";
import Organisation from "./Organisation";

const OrganisationCell = ({ result, url }) => 
(
    <div className="organisation">
        <Organisation organisation={result.organisation} url={url} />
    </div>
);

OrganisationCell.propTypes = {
    result: PropTypes.object.isRequired,
    url: PropTypes.string
};     

export default OrganisationCell;