import React from "react";
import PropTypes from "prop-types";
import { withLocalize, Translate } from "react-localize-redux";
import RaceBox from "../../../common/presentational/RaceBox";
import ListTypeBox from "./ListTypeBox";
import { getSortedRaces, isMultiRace } from "../../../logic/EventUtil";
import { Button } from "reactstrap";
import { Formik, Form, Field } from "formik";

const ExtractForm = ({ event, initialValues, onSubmit, onCancel, isLoading, translate }) => {
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
        >
            { form => 
                (
                    <Form>
                        <div className="card card-body bg-light">
                            {
                                isMultiRace(event) &&
                                (
                                    <div className="form-group">
                                        <label htmlFor="raceId">
                                            <Translate id="race" />
                                        </label>
                                        <RaceBox
                                            id="raceId"
                                            name="raceId"
                                            races={getSortedRaces(event)}
                                        />
                                    </div>
                                )
                            }

                            <div className="form-group">
                                <label htmlFor="listType">
                                    <Translate id="media.resultType" />
                                </label>
                                <ListTypeBox
                                    id="listType"
                                    name="listType"
                                    event={event}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="outputType">
                                    <Translate id="media.outputType" />
                                </label>
                                <Field as="select" id="outputType" name="outputType" className="form-control">
                                    <option value="html">
                                        {translate("media.outputTypeHtml")}
                                    </option>
                                    <option value="text">
                                        {translate("media.outputTypeText")}
                                    </option>
                                    <option value="excel">
                                        {translate("media.outputTypeExcel")}
                                    </option>
                                </Field>
                            </div>

                            <div className="button-container">
                                <Button type="submit" color="primary" disabled={isLoading}>
                                    <Translate id="media.loadResults"/>
                                </Button>
                                {
                                    onCancel &&
                                    (
                                        <Button color="secondary" onClick={onCancel} disabled={isLoading}>
                                            <Translate id="back"/>
                                        </Button>
                                    )
                                }
                            </div>
                        </div>
                    </Form>
                )
            }
        </Formik>
    );
};

ExtractForm.propTypes = {
    event: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    translate: PropTypes.func
};

export default withLocalize(ExtractForm);