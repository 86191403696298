import React from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import Header from "../presentational/Header";
import { Helmet } from "react-helmet";
import Style from "../../../common/presentational/Style";
import SearchResults from "./SearchResults";
import SelectEvent from "./SelectEvent";

import "../../../styles/search-page.scss";

const SearchPage = ({ translate, activeLanguage }) => {
    const htmlAttributes = {
        lang: activeLanguage.code
    };

    return (
        <div id="search-page">
            <Helmet htmlAttributes={htmlAttributes}>
                <title>{translate("search.fullTitle")}</title>
            </Helmet>
            
            <Style />
                
            <Header />

            <div className="container">
                <div className="section">
                    <SelectEvent/>
                </div>
                <div className="section">
                    <SearchResults/>
                </div>
            </div>
        </div>
    );
};

SearchPage.propTypes = {
    translate: PropTypes.func.isRequired,
    activeLanguage: PropTypes.object,
};

export default withLocalize(SearchPage);
