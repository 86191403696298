import "react-app-polyfill/ie9";
import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import initialState from "./reducers/initialState";
import { configureStore } from "./Store";
import RedBox from "redbox-react";
import "./styles/site.scss";
import "./styles/bootstrap-adjustments.scss";
import "./styles/list.scss";
import "bootstrap";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import settings from "./settings";
import "classlist-polyfill";
import "./polyfills/ArrayFind";
import "./polyfills/ObjectValues";
import { isDeprecatedBrowser } from "./logic/BrowserUtil";
import { createMobileAppToWebAppChannel } from "./logic/MobileAppUtil";

if(settings.applicationInsightsConnectionString) {
    // set up Application Insights
    window.appInsights = new ApplicationInsights({
        config: { connectionString: settings.applicationInsightsConnectionString }
    });
    window.appInsights.loadAppInsights();
}

// stupid react-loading-overlay uses PropTypes.function instead on PropTypes.func in some places
PropTypes.function = PropTypes.func;

const rootElement = document.getElementById('app');

if (isDeprecatedBrowser()) {
    rootElement.classList.add("deprecated-browser");
}

// make it possible for the mobile app to communicate with the browser through Javascript
window.MobileAppToWebAppChannel = createMobileAppToWebAppChannel();

const store = configureStore(initialState);

let render = () => {
    const App = require("./App").default;
    ReactDOM.render(
        <App store={store} />,
        rootElement
    );
};

if(module.hot) {
    // Support hot reloading of components
    // and display an overlay for runtime errors
    const renderApp = render;
    const renderError = (error) => {
        ReactDOM.render(
            <RedBox error={error} />,
            rootElement
        );
    };

    render = () => {
        try {
            renderApp();
        }
        catch(error) {
            renderError(error);
        }
    };

    module.hot.accept("./App", () => {
        render();
    });
}

render();
