import ListType from "./models/ListType";
import AccumulationType from "./models/AccumulationType";
import { getSortFunction, combineSorters, getSortablePersonName } from "./SortUtil";

export const sortResults = (results, list, event) => {
    const orderBy = parseOrderBy(list.orderBy, event.races);
    let sorters = [];

    const personSorter = getSortFunction(results => getSortablePersonName(getFirstResult(results, orderBy.raceId)));
    const organisationSorter = getSortFunction(results => (getFirstResult(results, orderBy.raceId)?.organisation ?? {}).name);
    const classSorter = getSortFunction(results => getFirstResult(results, orderBy.raceId)?.cl.sequence);
    const startTimeSorter = getSortFunction(results => getResultForRace(results, orderBy.raceId)?.startTime);
    const statusSorter = getSortFunction(results => getSampleForRace(results, orderBy.raceId, orderBy.accumulationType)?.status);
    const placeSorter = getSortFunction(results => getSampleForRace(results, orderBy.raceId, orderBy.accumulationType)?.place);
    const entryIdSorter = getSortFunction(results => getFirstResult(results, orderBy.raceId)?.entryId);

    switch(list.orderBy) {
        case "person":
            sorters = [personSorter, organisationSorter, entryIdSorter];
            break;
        case "organisation":
            sorters = [organisationSorter, personSorter, entryIdSorter];
            break;
        case "class":
            sorters = [classSorter, personSorter, entryIdSorter];
            break;
        default:
            switch(list.listType) {
                case ListType.startList:
                    sorters = [startTimeSorter, personSorter, entryIdSorter];
                    break;
                default:
                    sorters = [statusSorter, placeSorter, personSorter, entryIdSorter];
                    break;
            }
            break;
    }

    results.sort(combineSorters(sorters, list.direction));
};

export const parseOrderBy = (orderBy, races) => {
    const atoms = orderBy.split("-");
    return {
        accumulationType: atoms[0] === "overall"
            ? AccumulationType.overall
            : AccumulationType.race,
        raceId: parseInt(atoms[1])
    };
};

export const getFirstResult = competitorResults => Object.values(competitorResults)[0];

export const getResultForRace = (competitorResults, raceId) => competitorResults[raceId];

export const getSampleForRace = (competitorResults, raceId, accumulationType) => {
    const result = getResultForRace(competitorResults, raceId);
    return result?.samples.find(sample => sample.accumulationType === accumulationType);
};

export const formatAccumulationType = numericAccumulationType => Object.keys(AccumulationType).find(key => AccumulationType[key] === numericAccumulationType);

export const getListClassName = numericListType => {
    switch (numericListType) {
        case ListType.startList: return "start-list";
        default: return "result-list";
    }
};