import { formatElapsedTime } from "../../logic/TimeUtil";
import { getElapsedTimePerKilometer, isElapsedTimeSample } from "../../logic/ResultUtil";

const createElapsedTimePerKilometerCell = ({ result, raceSample }) => {
    const div = document.createElement("div");
    div.className = "elapsedTimePerKilometer";
    if(raceSample && isElapsedTimeSample(raceSample)) {
        div.innerText = formatElapsedTime(getElapsedTimePerKilometer(result, raceSample));
    }
    return div;
};

export default createElapsedTimePerKilometerCell;
