import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import PlaceCell from "../presentational/PlaceCell";
import PersonCell from "../presentational/PersonCell";
import OrganisationCell from "../presentational/OrganisationCell";
import ClassCell from "../presentational/ClassCell";
import ElapsedTimeCell from "../presentational/ElapsedTimeCell";
import StartTimeCell from "../presentational/StartTimeCell";
import MenuButtonCell from "../presentational/MenuButtonCell";
import { getFirstResult, getResultForRace, getSampleForRace, formatAccumulationType } from "../../../logic/AllRacesUtil";
import { isElapsedTimeSample } from "../../../logic/ResultUtil";
import { getUrlForEntry, getUrlForList } from "../../../logic/ListUtil";
import { getOrganisationKey } from "../../../logic/OrganisationUtil";
import ListType from "../../../logic/models/ListType";
import AccumulationType from "../../../logic/models/AccumulationType";
import ResultStatus from "../../../logic/models/ResultStatus";

const statusesToHide = [ResultStatus.started, ResultStatus.notActivated];

const AllRacesListRow = ({ event, races, results, list, onFavoriteButtonClick }) => {
    const result = getFirstResult(results);
    const favoriteEntryIds = useSelector(state => state.eventPage.favoriteEntryIds);

    const getEntryUrl = () => {
        return getUrlForEntry(event.slug, result.entryId);
    };

    const getOrganisationUrl = () => {
        return list.organisationKey
            ? undefined
            : getUrlForList(
                event, 
                { ...list, classIds: undefined, organisationKey: getOrganisationKey(result.organisation), entryIds: undefined, tag: undefined }
            ).url;
    };

    const renderListTypeSpecificCells = () => {
        switch(list.listType) {
            case ListType.startList: return renderStartListCells();
            default: return (
                <>
                    {renderResultListCells(AccumulationType.race)}
                    {renderResultListCells(AccumulationType.overall)}
                </>
            );
        }
    };

    const renderStartListCells = () => {
        return races.map((race, i) => {
            const classNamePrefix = `right ${formatAccumulationType(AccumulationType.race)}-${i+1}-`;
            const result = getResultForRace(results, race.raceId);
            if(!result) {
                return null;
            }
            return (
                <StartTimeCell
                    key={race.raceId}
                    event={event}
                    result={getResultForRace(results, race.raceId)}
                    timeZone={event.timeZone}
                    classNamePrefix={classNamePrefix}
                />
            );
        });
    };

    const renderResultListCells = accumulationType => {
        return races.map((race, i) => {
            const result = getResultForRace(results, race.raceId);
            const sample = getSampleForRace(results, race.raceId, accumulationType);
            if(!result || !sample) {
                return null;
            }
            const fullWidth = isElapsedTimeSample(sample) && result.cl.timePresentation && result.timePresentation ? "" : "full-width-";
            const classNamePrefix = `right ${formatAccumulationType(accumulationType)}-${i+1}-${fullWidth}`;
            return (
                <React.Fragment key={race.raceId}>
                    <ElapsedTimeCell
                        event={event}
                        result={result}
                        sample={sample}
                        timeZone={event.timeZone}
                        classNamePrefix={classNamePrefix}
                        statusesToHide={statusesToHide}
                        showTimeBehindTooltip
                    />
                    <PlaceCell
                        sample={sample}
                        classNamePrefix={classNamePrefix}
                    />
                </React.Fragment>
            );
        });
    };

    return (
        <li className="row">
            <PersonCell
                result={result}
                url={getEntryUrl()}
            />
            {
                list.organisationKey === undefined && list.entryIds === undefined &&
                (
                    <OrganisationCell
                        result={result}
                        url={getOrganisationUrl()}
                    />
                )
            }
            {
                list.classIds === undefined &&
                (
                    <ClassCell
                        event={event}
                        result={result}
                        list={list}
                    />
                )
            }
            <MenuButtonCell
                result={result}
                favoriteEntryIds={favoriteEntryIds}
                onFavoriteButtonClick={onFavoriteButtonClick}
            />
            {renderListTypeSpecificCells()}
        </li>
    );
};

AllRacesListRow.propTypes = {
    event: PropTypes.object.isRequired,
    races: PropTypes.array.isRequired,
    results: PropTypes.object.isRequired,
    list: PropTypes.object.isRequired,
    onFavoriteButtonClick: PropTypes.func.isRequired
}; 

export default AllRacesListRow;
