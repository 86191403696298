import React from "react";

const QuestionMark = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"	viewBox="0 0 340 340" width="16" height="16" style={{ "enableBackground": "new 0 0 340 340" }}>
            <path fill="#777" d="M170,0C76.109,0,0,76.112,0,170s76.109,170,170,170c93.89,0,170-76.112,170-170S263.89,0,170,0z M169.633,281.611
	c-12.574,0-22.803-10.23-22.803-22.804c0-12.573,10.229-22.803,22.803-22.803c12.575,0,22.806,10.229,22.806,22.803
	C192.439,271.381,182.208,281.611,169.633,281.611z M205.357,169.659c-9.856,9.221-16.367,15.312-16.367,30.062v10.911
	c0,2.761-2.238,5-5,5h-27.122c-2.762,0-5-2.239-5-5v-10.911c0-30.293,15.744-45.076,27.239-55.87
	c9.785-9.179,16.248-15.242,16.248-29.778c0-4.591,0-18.562-24.925-18.562c-23.37,0-24.306,12.883-24.705,18.384l-0.813,11.186
	c-0.099,1.346-0.736,2.596-1.771,3.464c-1.031,0.87-2.372,1.282-3.715,1.148l-27.051-2.707c-2.7-0.27-4.691-2.645-4.488-5.351
	l0.799-10.613c2.41-31.974,26.749-52.634,62.006-52.634c18.438,0,33.952,5.176,44.865,14.968
	c11.066,9.931,16.918,24.102,16.918,40.98C232.477,144.265,216.802,158.942,205.357,169.659z"/>
        </svg>
    );
};

export default QuestionMark;