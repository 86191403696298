// see https://redux.js.org/advanced/async-actions
import * as types from "./actionTypes";
import Api from "../logic/Api";

const requestResults = parameters => ({
    type: types.SEARCH_REQUEST_RESULTS,
    parameters
});
  
const receiveResultsSuccess = personResults => ({
    type: types.SEARCH_RECEIVE_RESULTS_SUCCESS,
    personResults
});

const receiveResultsFailure = error => ({
    type: types.SEARCH_RECEIVE_RESULTS_FAILURE,
    error
});

export const fetchResults = parameters => {
    return dispatch => {
        dispatch(requestResults(parameters));

        return Api.searchResults(
            parameters.fromEventTime,
            parameters.toEventTime,
            parameters.personName,
            parameters.organisationName,
            personResults => dispatch(receiveResultsSuccess(personResults)),
            error => dispatch(receiveResultsFailure(error))
        );
    };
};

export const resetResults = () => ({
    type: types.SEARCH_RESET_RESULTS
});
