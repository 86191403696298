import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Translate, withLocalize } from "react-localize-redux";
import queryString from "query-string";
import { Button } from "reactstrap";
import ResultListTableItem from "../presentational/ResultListTableItem";
import * as searchActions from "../../../actions/searchActions";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import { Formik, Form, Field } from "formik";

const extractParameters = p => {
    const personName = p.personName
        ? p.personName
        : undefined;
    const organisationName = p.organisationName
        ? p.organisationName
        : undefined;
    const fromYear = p.fromYear
        ? p.fromYear
        : undefined;
    const toYear = p.toYear
        ? p.toYear
        : undefined;
    const fromEventTime = fromYear
        ? `${fromYear}-01-01 00:00:00`
        : undefined;
    const toEventTime = toYear
        ? `${toYear}-12-31 23:59:59`
        : undefined;

    return {
        api: { personName, organisationName, fromEventTime, toEventTime },
        browser: { personName, organisationName, fromYear, toYear }
    };
};

const SearchResults = ({ translate, activeLanguage }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const parametersFromQueryString = queryString.parse(location.search);
    
    const personResults = useSelector(state => state.searchPage.personResults);
    const isLoading = useSelector(state => state.searchPage.isLoading);
    const parametersFromRedux = useSelector(state => state.searchPage.parameters);
    
    const [initialFormValues, setInitialFormValues] = useState({
        personName: parametersFromQueryString.personName,
        organisationName: parametersFromQueryString.organisationName,
        fromYear: parametersFromQueryString.fromYear,
        toYear: parametersFromQueryString.toYear
    });

    const parametersForApi = extractParameters(parametersFromQueryString).api;
    const parametersForBrowser = extractParameters(parametersFromQueryString).browser;

    useEffect(() => {
        if(parametersAreEqual(parametersForApi, parametersFromRedux)) {
            return;
        }

        if (parametersForApi.personName) {
            dispatch(searchActions.fetchResults(parametersForApi));
        } else {
            dispatch(searchActions.resetResults());
        }
        setInitialFormValues({...parametersForBrowser});
    }, [JSON.stringify(parametersForApi)]);

    const parametersAreEqual = (first, second) => {
        const f = first ?? {};
        const s = second ?? {};
        return f.personName === s.personName &&
            f.organisationName === s.organisationName &&
            f.fromEventTime === s.fromEventTime &&
            f.toEventTime === s.toEventTime;
    };

    const handleSubmit = formValues => {
        const p = extractParameters(formValues);
        const url = "/search?" + queryString.stringify(p.browser);
        navigate(url);
    };

    const formIsValid = formValues => !!formValues.personName;

    const renderForm = () => {
        return (
            <div id="search-form" className="card card-body bg-light">
                <Formik
                    initialValues={initialFormValues}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {({ values }) => (
                        <Form>
                            <div className="row">
                                <div className="form-group col-md-4 col-sm-6">
                                    <label htmlFor="personName">
                                        <Translate id="name" />*
                                    </label>
                                    <Field 
                                        type="text"
                                        className="form-control"
                                        id="personName"
                                        name="personName"
                                    />
                                </div>

                                <div className="form-group col-md-4 col-sm-6">
                                    <label htmlFor="organisationName">
                                        <Translate id="organisation" />
                                    </label>
                                    <Field 
                                        type="text"
                                        className="form-control"
                                        id="organisationName"
                                        name="organisationName"
                                    />
                                </div>

                                <div className="form-group col-md-2 col-6">
                                    <label htmlFor="fromYear">
                                        <Translate id="search.fromYear" />
                                    </label>
                                    <Field 
                                        type="number"
                                        className="form-control"
                                        id="fromYear"
                                        name="fromYear"
                                    />
                                </div>

                                <div className="form-group col-md-2 col-6">
                                    <label htmlFor="toYear">
                                        <Translate id="search.toYear" />
                                    </label>
                                    <Field 
                                        type="number"
                                        className="form-control"
                                        id="toYear"
                                        name="toYear"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <Button type="submit" color="primary" disabled={!formIsValid(values) || isLoading}>
                                        <Translate id="search.search"/>
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );        
    };

    const renderSearchResults = () => {
        if(!personResults || personResults.length === 0) {
            return undefined;
        }

        const numberOfResults = personResults.length;
        const maxNumberOfResults = 100;

        return (
            <>
                <p>
                    {numberOfResults === 1 && <Translate id="search.xResultWasFound" data={{ numberOfResults: personResults.length }}/>}
                    {numberOfResults > 1 && numberOfResults < maxNumberOfResults && <Translate id="search.xResultsWereFound" data={{ numberOfResults: personResults.length }}/>}
                    {numberOfResults >= maxNumberOfResults && <Translate id="search.maxNumberOfResultsWereFound" data={{ numberOfResults: numberOfResults }}/>}
                </p>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th className="year"><Translate id="search.year"/></th>
                            <th className="person-name"><Translate id="name"/></th>
                            <th className="organisation-name"><Translate id="organisation"/></th>
                            <th className="class-name"><Translate id="class"/></th>
                        </tr>
                    </thead>
                    <tbody>
                        {personResults.map(personResultGroup => <ResultListTableItem key={personResultGroup[0].resultId} personResultGroup={personResultGroup} />)}
                    </tbody>
                </table>
            </>            
        );
    };

    const htmlAttributes = {
        lang: activeLanguage.code
    };

    return (
        <>
            <h1>
                <Translate id="search.title" />
            </h1>

            <p>
                <Translate id="search.information" />
            </p>

            {renderForm()}

            <LoadingOverlay
                active={isLoading}
                spinner={<BounceLoader />}
                text={translate("loading")}
            />

            {
                personResults && personResults.length === 0 &&
                (
                    <p>
                        <Translate id="search.noResultsWereFound"/>
                    </p>
                )
            }

            {renderSearchResults()}
        </>
    );
};

SearchResults.propTypes = {
    translate: PropTypes.func.isRequired,
    activeLanguage: PropTypes.object,
};

export default withLocalize(SearchResults);
