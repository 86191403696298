const ResultStatus = {
    ok: 0,
    started: 1,
    didNotFinish: 2,
    disqualified: 3,
    mispunched: 4,
    finished: 5,
    notActivated: 6,
    didNotStart: 7,
    unknown: 8,
    deleted: 9,
    notYetFinished: 10 // pseudo-status, not present in backend
};

export default ResultStatus;