import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Outlet } from "react-router-dom";
import * as eventActions from "../../../actions/eventActions";
import { Translate, withLocalize } from "react-localize-redux";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import HelmetWrapper from "../presentational/HelmetWrapper";
import { Alert } from "reactstrap";
import Style from "../../../common/presentational/Style";

import "../../../styles/landing-page.scss";

const EventLoader = ({ translate }) => {
    const params = useParams();
    const dispatch = useDispatch();
    const eventSlug = params.eventSlug;
    const event = useSelector(state => state.eventPage.event);
    const loading = useSelector(state => state.eventPage.loading);
    const eventNotFound = useSelector(state => state.eventPage.eventNotFound);
    const serverError = useSelector(state => state.eventPage.serverError);

    useEffect(() => {
        if(!event || event.slug !== eventSlug) {
            dispatch(eventActions.fetchEvent(eventSlug));
        }
    }, [eventSlug]);

    if (eventNotFound) {
        return (
            <div id="error-message">
                <Alert color="danger">
                    <Translate id="eventNotFound" />
                </Alert>
            </div>
        );
    }

    if (serverError && serverError.status !== 404) {
        return (
            <div id="error-message">
                <Alert color="danger">
                    <Translate id="serverError" />
                    <br/>
                    <br/>
                    <pre>
                        {serverError.status} {serverError.statusText} when requesting {serverError.url}
                    </pre>
                    <a href={window.location.href}><Translate id="reloadPage" /></a>
                </Alert>
            </div>
        );
    }

    return (
        <>
            <HelmetWrapper event={event} />
            {
                // event-specific styling is fetched from an event property named cssVariables, stored in the database
            }
            <Style cssVariables={event?.properties.cssVariables} />

            <div id={event ? "" : "event-still-loading"}>
                <LoadingOverlay
                    active={loading.event}
                    spinner={<BounceLoader />}
                    text={translate("loading")} />
                {
                    event && <Outlet />
                }                
            </div>

        </>
    );
};

EventLoader.propTypes = {
    translate: PropTypes.func.isRequired
};

export default withLocalize(EventLoader);
