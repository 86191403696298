import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import Header from "./Header";
import { Translate, withLocalize } from "react-localize-redux";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import queryString from "query-string";
import SplitTimeManager from "../../../logic/splitTimes/SplitTimeManager";
import SplitTimeTable from "../../../lib/winsplits/rendering/SplitTimeTable";
import { isMultiRace, getCurrentTimeForEvent } from "../../../logic/EventUtil";
import { formatDate } from "../../../logic/TimeUtil";
import { getUrlForList, parseListFromLocation } from "../../../logic/ListUtil";
import Api from "../../../logic/Api";

import "../../../styles/split-times-page.scss";

const SplitTimesPage = ({ translate }) => {
    const event = useSelector(state => state.eventPage.event);
    const [splitTimeData, setSplitTimeData] = useState(undefined);
    const [splitTimeTable, setSplitTimeTable] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);

    const location = useLocation();

    const queryStringParams = queryString.parse(location.search);
    const parameters = {
        classIds: queryStringParams.classIds.split("_")
            .map(o => parseInt(o))
            .filter(o => o),
        raceId: parseInt(queryStringParams.raceId),
        listType: queryStringParams.type, 
        orderBy: queryStringParams.orderBy
    };

    useEffect(() => {
        setLoading(true);
        Api.getSplitTimes(
            parameters.raceId,
            parameters.classIds,
            successResponse => {
                setSplitTimeData(successResponse);
                setLoading(false);
            },
            errorResponse => {
                setError(errorResponse);
                setLoading(false);
            }
        );
    }, [event.slug, parameters.raceId, parameters.classIds.join(",")]);

    useEffect(() => {
        if (splitTimeData) {
            const splitTimeManager = new SplitTimeManager();
            const course = splitTimeManager.createCourse(splitTimeData);
            if (splitTimeTable) {
                splitTimeTable.destroy();
            }
            if (showSplitTimes()) {
                const stt = new SplitTimeTable({ course });
                setSplitTimeTable(stt);
                stt.render(document.getElementById("split-time-table-container"));
            }
        }
    }, [event, splitTimeData]);

    const splitTimesHiddenUntil = () => {
        return splitTimeData
            ? splitTimeData.splitTimesHiddenUntil
            : undefined;        
    };

    const showSplitTimes = () => {
        const hiddenUntil = splitTimesHiddenUntil();
        const now = getCurrentTimeForEvent(event);
        return event && (!hiddenUntil || now > hiddenUntil);
    };

    const getSplitTimesHiddenUntilText = () => {
        if (event && !showSplitTimes()) {
            const hiddenUntil = splitTimesHiddenUntil();
            return translate(
                "splitTimesPage.splitTimesForXWillBeVisibleAtY", 
                { 
                    className: getClasses().map(cl => cl.name).join(" + "),
                    time: formatDate(hiddenUntil, event.timeZone, "YYYY-MM-DD HH:mm"),
                });
        }
        return undefined;
    };

    const getClasses = () => {
        if (!event) {
            return [];
        }
        return parameters.classIds
            .map(classId => event.classes[classId])
            .filter(cl => cl);
    };

    const getResultListUrl = () => {
        const { classIds, raceId, listType, orderBy, direction } = parseListFromLocation(location, event);
        return getUrlForList(event, { classIds, raceId, listType, orderBy, direction })?.url;
    };

    const getCaption = () => {
        if (!event) {
            return undefined;
        }

        const { raceId } = parseListFromLocation(location, event);
        const className = getClasses().map(cl => cl.name).join(" + ");
        const raceNumber = event.races[raceId].raceNumber;
        const atoms = [];
        if(isMultiRace(event)) {
            atoms.push(translate("raceX", { raceNumber }) + ":");
        }
        atoms.push(translate("splitTimesPage.splitTimesForX", { className }));
        return atoms.join(" ");
    };

    const splitTimesHiddenUntilText = getSplitTimesHiddenUntilText();

    const resultListUrl = getResultListUrl();

    const hasSplitTimes = !splitTimesHiddenUntilText;

    return (
        <div id="split-times-page" className="page">
            <Header 
                event={event}
                showMenuButton
            />

            <div className="full-width-content">
                <div>
                    <LoadingOverlay
                        active={loading}
                        spinner={<BounceLoader />}
                        text={translate("loading")} />
                </div>

                <div id="caption-bar" className="content-inside-full-width-content">
                    <h1 id="caption">
                        {getCaption()}
                    </h1>
                    <div id="caption-action-panel">
                        { 
                            resultListUrl &&
                            (
                                <Link to={resultListUrl}>
                                    <Translate id="splitTimesPage.back" />
                                </Link>
                            )
                        }
                    </div>
                </div>

                {
                    splitTimesHiddenUntilText && 
                    (
                        <div id="split-times-hidden-until" className="text-content">{splitTimesHiddenUntilText}</div>
                    )
                }
                <div id="split-time-table-container" />
            </div>
        </div>
    );
};

SplitTimesPage.propTypes = {
    translate: PropTypes.func.isRequired
};
  
export default withLocalize(SplitTimesPage);
