export default class ClassCategoryJsonReader {
    read(json) {
        const classCategory = {
            names: []
        };
        classCategory.classCategoryId = json.i;
        for (let i = 0; i < json.n.length; i++) {
            classCategory.names[json.n[i].l] = json.n[i].v;
        }
        return classCategory;
    }
}
