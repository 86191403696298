import React from "react";
import PropTypes from "prop-types";
import { withLocalize, Translate } from "react-localize-redux";

const DeprecatedBrowserMessage = ({ translate }) => {
    // using inline style to prevent search engines from showing the text in search results
    return (
        <div id="deprecated-browser-message" style={{ display: "none" }}>
            <Translate id="deprecatedBrowserMessage" />
        </div>
    );
};

DeprecatedBrowserMessage.propTypes = {
    translate: PropTypes.func.isRequired
};

export default withLocalize(DeprecatedBrowserMessage);