/*
structure:
- classMappings: array
  - raceNumber: number or undefined
  - classNameRegex: string or undefined
  - liveloxEventId: number

example: 
{
    classMappings: [
        { raceNumber: 1, classNameRegex: "^MtbO", liveloxEventId: 1234 },
        { raceNumber: 1, liveloxEventId: 5678 }
    ]
}

Livelox endpoint:
https://www.livelox.com/Viewer?eventId={liveloxEventId}&className={className}
class name is matched in a heuristic way, see Livelox source (ClassNameMatchLevel.HeuristicStripped, allowCaseDifference)
*/

export default class LiveloxManager {
    isEnabledForEvent(event) {
        return event && event.properties && event.properties.liveloxOptions;
    }

    getRedirectUrl(event, race, cl) {
        if (!this.isEnabledForEvent(event) ||
            !race ||
            !cl) {
            return undefined;
        }

        const classMappings = (event.properties.liveloxOptions.classMappings || []);

        for (let i=0; i<classMappings.length; i++) {
            const classMapping = classMappings[i];
            const classNameMatches = 
                !classMapping.classNameRegex || 
                new RegExp(classMapping.classNameRegex, "i").test(cl.name);
            const raceNumberMatches = classMapping.raceNumber === race.raceNumber;

            if (classNameMatches && raceNumberMatches) {
                return classMapping.liveloxEventId
                    ? `https://www.livelox.com/Viewer?eventId=${classMapping.liveloxEventId}&className=${encodeURIComponent(cl.name)}`
                    : undefined;
            }
        }

        return undefined;
    }
}
