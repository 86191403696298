import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";
import Api from "../../../logic/Api";

const ManageTagsModal = ({ translate, isOpen, result, onSave, onCancel }) => {
    const [loading, setLoading] = useState(false);    
    const [showEntryReferenceTextbox, setShowEntryReferenceTextbox] = useState(true);    
    const [entryReferenceErrorMessage, setEntryReferenceErrorMessage] = useState(undefined);    
    const [showTagsTextarea, setShowTagsTextarea] = useState(false);    

    const entryReferenceTextbox = useRef();
    const tagsTextarea = useRef();

    const textareaValueToTags = textareaValue => {
        const tags = textareaValue
            .replace(/(?:\r\n|\r|\n)/g, " ")
            .replace(/#/g, "")
            .split(" ")
            .map(t => t.trim())
            .filter(t => t !== "");
        return tags;
    };

    const tagsToTextareaValue = tags => {
        return (tags || []).map(t => "#" + t).join("\r\n");
    };

    const handleSaveButtonClick = () => {
        const tags = textareaValueToTags(tagsTextarea.current.value);
        const entryReference = entryReferenceTextbox.current.value;

        Api.updateResultTags(result.resultId, tags, entryReference, 
            tags => {
                setLoading(false);
                onSave(tags);
            },
            () => {
                setEntryReferenceErrorMessage(translate("invalidEntryReference"));
                setShowEntryReferenceTextbox(true);
                setShowTagsTextarea(false);
                setLoading(false);
            }
        );
    };

    const handleCancelButtonClick = () => {
        entryReferenceTextbox.current.value = "";
        tagsTextarea.current.value = "";
        onCancel();
    };

    const handleValidateEntryReferenceButtonClick = () => {
        setLoading(true);

        const entryReference = entryReferenceTextbox.current.value;

        Api.validateEntryReference(result.resultId, entryReference, 
            () => {
                setEntryReferenceErrorMessage(false);
                setShowEntryReferenceTextbox(false);
                setShowTagsTextarea(true);
                setLoading(false);
                if(tagsTextarea.current) {
                    tagsTextarea.current.focus();
                }
            },
            () => {
                setEntryReferenceErrorMessage(translate("invalidEntryReference"));
                setLoading(false);
                if(entryReferenceTextbox.current) {
                    entryReferenceTextbox.current.focus();
                }
            }
        );
    };

    const handleOpened = () => {
        tagsTextarea.current.value = tagsToTextareaValue((result ?? {}).tags);
        if(entryReferenceTextbox.current) {
            entryReferenceTextbox.current.focus();
        }
        setEntryReferenceErrorMessage("");
    };

    const handleClosed = () => {
        entryReferenceTextbox.current.value = "";
        tagsTextarea.current.value = "";
        setShowEntryReferenceTextbox(true);
        setShowTagsTextarea(false);
        setLoading(false);
    };

    const handleEntryReferenceTextboxKeyPress = domEvent => {
        const text = domEvent.target.value;
        if(domEvent.key === "Enter") {
            handleValidateEntryReferenceButtonClick();
        }
    };

    const personName = result
        ? `${result.person.firstName ?? ""} ${result.person.lastName ?? ""}`
        : "";

    return (
        <div>
            <Modal isOpen={isOpen} onOpened={handleOpened} onClosed={handleClosed}>
                <ModalHeader>{translate("manageTagsTitle", { personName })}</ModalHeader>
                <ModalBody>
                    <div className={showEntryReferenceTextbox ? "" : "hidden"}>
                        <p>{translate("entryReferenceInformation")}</p>
                        <div className="form-group">
                            <label htmlFor="manage-tags-modal-entry-reference">{translate("entryReferenceLabel")}</label>
                            <input id="manage-tags-modal-entry-reference" type="text" className="form-control" ref={entryReferenceTextbox} onKeyPress={handleEntryReferenceTextboxKeyPress} />
                        </div>                                    
                    </div>
                    {
                        entryReferenceErrorMessage && 
                        (
                            <Alert color="danger">
                                {entryReferenceErrorMessage}
                            </Alert>
                        )
                    }
                    <div className={showTagsTextarea ? "" : "hidden"}>
                        <p>{translate("tagsInformation")}</p>
                        <div className="form-group">
                            <label htmlFor="manage-tags-modal-tags">{translate("tagsLabel")}</label>
                            <textarea id="manage-tags-modal-tags" className="form-control" rows="3" ref={tagsTextarea} />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {showEntryReferenceTextbox && <Button color="primary" onClick={handleValidateEntryReferenceButtonClick} disabled={loading}>{translate("next")}</Button>}
                    {showTagsTextarea && <Button color="primary" onClick={handleSaveButtonClick} disabled={loading}>{translate("save")}</Button>}
                    <Button color="secondary" onClick={handleCancelButtonClick}>{translate("cancel")}</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

ManageTagsModal.propTypes = {
    translate: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    result: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default withLocalize(ManageTagsModal);
