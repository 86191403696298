import TimeType from "./TimeType";
import AccumulationType from "./AccumulationType";

class SplitTime {
    // code: the code of the control that this split time belongs to.
    // times: [TimeType] (seconds, since epoch for TimeType.passing)
    // places: [TimeType]
    // timesBehind: [TimeType] (seconds)
    // isValid: [TimeType] whether this split time should be considered when calculating places. If null, defaults to whether the times[TimeType.sinceStart] is set.
    // performanceIndices: [AccumulationType]
    // timeLosses: [AccumulationType]

    constructor(options) {
        this.code = options.code;
        if (options.times) {
            this.times = {
                [TimeType.leg]: options.times[TimeType.leg],
                [TimeType.sinceStart]: options.times[TimeType.sinceStart],
                [TimeType.passing]: options.times[TimeType.passing]
            };
        } else {
            this.times = {
                [TimeType.sinceStart]: options.timeSinceStart
            };
        }
        if (options.isValid) {
            this.isValid = {
                [TimeType.leg]: options.isValid[TimeType.leg],
                [TimeType.sinceStart]: options.isValid[TimeType.sinceStart],
                [TimeType.passing]: options.isValid[TimeType.passing]
            };
        }
        if (options.timesBehind) {
            this.timesBehind = {
                [TimeType.leg]: options.timesBehind[TimeType.leg],
                [TimeType.sinceStart]: options.timesBehind[TimeType.sinceStart],
                [TimeType.passing]: options.timesBehind[TimeType.passing]
            };
        }
        if (options.places) {
            this.places = {
                [TimeType.leg]: options.places[TimeType.leg],
                [TimeType.sinceStart]: options.places[TimeType.sinceStart],
                [TimeType.passing]: options.places[TimeType.passing]
            };
        }
        if (options.performanceIndices) {
            this.performanceIndices = {
                [AccumulationType.leg]: options.performanceIndices[AccumulationType.leg],
                [AccumulationType.sinceStart]: options.performanceIndices[AccumulationType.sinceStart]
            };
        }
        if (options.timeLosses) {
            this.timeLosses = {
                [AccumulationType.leg]: options.timeLosses[AccumulationType.leg],
                [AccumulationType.sinceStart]: options.timeLosses[AccumulationType.sinceStart]
            };
        }
    }

    clone() {
        return new SplitTime({
            code: this.code,
            times: this.times,
            timesBehind: this.timesBehind,
            places: this.places,
            performanceIndices: this.performanceIndices,
            timeLosses: this.timeLosses,
            isValid: this.isValid
        });
    }
}

export default SplitTime;
