import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "./Header";
import { Translate, withLocalize } from "react-localize-redux";
import Api from "../../../logic/Api";
import queryString from "query-string";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import TagRow from "../presentational/TagRow";
import SearchTextBox from "./SearchTextBox";
import { logPageView, Page } from "../../../logic/LoggingUtil";
import { getUrlForTags } from "../../../logic/ListUtil";
import { sanitizeTagText } from "../../../logic/TagUtil";
import { Formik, Form } from "formik";

import "../../../styles/tags.scss";

const TagsPage = ({ translate }) => {
    const [ searchTagsInitialText, setSearchTagsInitialText ] = useState("");
    const [ searchTagsResults, setSearchTagsResults ] = useState(undefined);
    const [ searchTextboxVisible, setSearchTextboxVisible ] = useState(false);
    const [ loading, setLoading ] = useState(true);
   
    const navigate = useNavigate();
    const location = useLocation();
    const event = useSelector(state => state.eventPage.event);
    const queryStringParams = queryString.parse(location.search);
    const tagTextFromQueryString = queryStringParams.q;

    const initialFormValues = {
        tagSearchText: ""
    };

    useEffect(() => {
        loadTags();
        logPageView(Page.tags);
    }, [event, tagTextFromQueryString]);

    const loadTags = () => {
        setSearchTagsInitialText(tagTextFromQueryString);
        setSearchTagsResults(undefined);
        setSearchTextboxVisible(false);
        setLoading(true);
        Api.getTags(
            event.eventId, tagTextFromQueryString, 
            results => {
                setSearchTagsResults(results);
                setLoading(false);
            },
            error => {
                setLoading(false);
            }
        );
    };

    const handleChangeSearchLinkClick = () => {
        setSearchTextboxVisible(!searchTextboxVisible);
    };
    
    const handleTagSearchTextSelected = value => {
        const sanitizedValue = sanitizeTagText(value);
        if(sanitizedValue) {
            navigate(getUrlForTags(event.slug, sanitizedValue));
        }
    };

    return (
        <div id="tags-page" className="page">
            <Header 
                event={event}
                showMenuButton
            />
            <div>
                <LoadingOverlay
                    active={loading}
                    spinner={<BounceLoader />}
                    text={translate("loading")} />

                { 
                    searchTagsResults && 
                    (
                        <div>
                            <div id="caption-bar">
                                <h1 id="caption">
                                    <Translate id="tagsPage.searchResultsForX" data={{ text: searchTagsInitialText }} />
                                </h1>
                                {
                                    !searchTextboxVisible && 
                                    (
                                        <button type="button" id="caption-action-panel" className="btn btn-link" onClick={handleChangeSearchLinkClick}>
                                            <Translate id="changeSearch" />
                                        </button>                                            
                                    )
                                }
                            </div>

                            {
                                searchTextboxVisible && 
                                (
                                    <Formik
                                        initialValues={initialFormValues}
                                        enableReinitialize
                                    >
                                        {() => 
                                            (
                                                <Form>
                                                    <div id="search-container">
                                                        <SearchTextBox
                                                            name="tagSearchText"
                                                            placeholder={translate("eventPage.tagBox.placeholder")}
                                                            showButton
                                                            onSelected={handleTagSearchTextSelected}
                                                        />
                                                    </div>
                                                </Form>
                                            )
                                        }
                                    </Formik>
                                )
                            }

                            { 
                                searchTagsResults.length === 0 && 
                                <p><Translate id="tagsPage.noSearchResults"/></p>
                            }

                            {  
                                searchTagsResults.length > 0 && 
                                (
                                    <div className="grid-list">
                                        <div id="tag-list-header" className="grid-list-header">
                                            <div className="text"><Translate id="tag" /></div>
                                            <div className="competitors"><Translate id="competitors" /></div>
                                        </div>
                                        <ul id="tag-list-body" className="grid-list-body">
                                            {searchTagsResults.map(tag => <TagRow key={tag.text} tag={tag} event={event} />)}
                                        </ul>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
        </div>        
    );
};

TagsPage.propTypes = {
    translate: PropTypes.func.isRequired
};

export default withLocalize(TagsPage);
