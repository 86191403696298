import React from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import ClassStartList from "./ClassStartList";
import ClassResultList from "./ClassResultList";
import ListType from "../../../logic/models/ListType";

const ResultSelectionExtract = ({ event, listType, classResultLists }) => {
    return (
        <>
            {
                listType === ListType.startList && classResultLists.map(classResultList => <ClassStartList key={classResultList.cl.classId} event={event} {...classResultList} />)
            }
            {
                listType !== ListType.startList && classResultLists.map(classResultList => <ClassResultList key={classResultList.cl.classId} {...classResultList} />)
            }
        </>
    );
};

ResultSelectionExtract.propTypes = {
    event: PropTypes.object.isRequired,
    listType: PropTypes.number.isRequired,
    classResultLists: PropTypes.array.isRequired,
    translate: PropTypes.func.isRequired
};

export default withLocalize(ResultSelectionExtract);