import React from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import { getUrlForList } from "../../../logic/ListUtil";
import { getCurrentRaceId } from "../../../logic/EventUtil";

const getNames = (tag, translate) => {
    const limit = 5;
    const firstNames = tag.competitorNames.slice(0, limit);
    let text = firstNames.join (", ");
    if(tag.competitorNames.length > limit) {
        text += " " + translate("tagsPage.andXMore", { count: tag.competitorNames.length - limit });
    }
    return text;
};

const TagRow = ({ tag, event, translate }) => {
    return (
        <li key={tag.text}>
            <div className="text">
                <a href={getUrlForList(event, { tag: tag.text, raceId: getCurrentRaceId(event) }).url}>
                    {tag.text}
                </a>
            </div>
            <div className="competitors">
                {getNames(tag, translate)}
            </div>
        </li>
    );
};

TagRow.propTypes = {
    tag: PropTypes.object,
    event: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired
};

export default withLocalize(TagRow);