import * as types from "../actions/actionTypes";
import initialState from "./initialState";

const searchPageReducer = (state = initialState.searchPage, action) => {
    switch (action.type) {
        case types.SEARCH_REQUEST_RESULTS:
            return {
                ...state,
                parameters: action.parameters,
                isLoading: true,
                personResults: undefined,
                error: undefined,
            };
        case types.SEARCH_RECEIVE_RESULTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                personResults: action.personResults
            };
        case types.SEARCH_RECEIVE_RESULTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case types.SEARCH_RESET_RESULTS:
            return {
                ...state,
                isLoading: false,
                personResults: undefined,
                error: undefined,
                parameters: undefined
            };
        default:
            return state;
    }
};

export default searchPageReducer;