import { formatCourseLength } from "../../logic/ResultUtil";

const createCourseLengthCell = ({ result, translate }) => {
    const div = document.createElement("div");
    div.className = "courseLength";
    div.innerText = formatCourseLength(result.cl.courseLength, translate("thousandSeparator"));
    return div;
};

export default createCourseLengthCell;
