import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ListLink = ({ url, isExternal, onClick, children }) => {
    if(isExternal && url) {
        return (    
            <a href={url} target="_blank">
                {children}
            </a>
        );
    }

    return url 
        ? ( 
            <Link to={url} onClick={onClick}>
                {children}
            </Link>
        )
        : <>{children}</>;
};

ListLink.propTypes = {
    url: PropTypes.string,
    isExternal: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node
};     

export default ListLink;
