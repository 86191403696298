/*
structure:
- preoresultatEventId: number,
- classMappings: array
  - raceNumber: number
  - className: string
  - preoresultatClassId: number

example: 
{
    preoresultatEventId: 100242,
    classMappings: [
        { "className": "Pre-Elit", "raceNumber": 1, "preoresultatClassId": 103033, "preoresultatClassName": "Pre-Elit" },
        { "className": "Etappstart Pre-A", "raceNumber": 1, "preoresultatClassId": 103034, "preoresultatClassName": "E-st Pre-A" }
    ]
}

Preoresultat endpoints:
https://preoresultat.se/tavling/skarm/startlista.php?visaklass=103033&klubbm%C3%A4rken=1
https://preoresultat.se/tavling/skarm/resultat_skarm.php?visaklass=103033&klubbm%C3%A4rken=1
https://preoresultat.se/special/flerdags.php?visaklass=Pre-Elit&t%C3%A4vling=100242
*/

import ListType from "../models/ListType";

export default class PreoresultatManager {
    isEnabledForEvent(event) {
        return event && event.properties && event.properties.preoresultatOptions;
    }

    isEnabledForClass(event, race, cl) {
        return this.isEnabledForEvent(event) &&
               cl &&
               race &&
               event.properties.preoresultatOptions.classMappings.find(cm => cm.className === cl.name && cm.raceNumber === race.raceNumber);
    }

    getRedirectUrl(event, race, cl, listType) {
        if (!this.isEnabledForClass(event, race, cl)) {
            return undefined;
        }

        const classMappings = (event.properties.preoresultatOptions.classMappings || []);

        for (let i=0; i<classMappings.length; i++) {
            const classMapping = classMappings[i];
            const classNameMatches = classMapping.className === cl.name;
            const raceNumberMatches = classMapping.raceNumber === race.raceNumber;

            if (classNameMatches && raceNumberMatches) {
                switch(listType) {
                    case ListType.startList:
                        return `https://preoresultat.se/tavling/skarm/startlista.php?visaklass=${classMapping.preoresultatClassId}&klubbm%C3%A4rken=1`;
                    case ListType.raceResultList:
                    case ListType.resultList:
                        return `https://preoresultat.se/tavling/skarm/resultat_skarm.php?visaklass=${classMapping.preoresultatClassId}&klubbm%C3%A4rken=1`;
                    case ListType.overallResultList:
                        return cl.hasOverallResults
                            ? `https://preoresultat.se/special/flerdags.php?visaklass=${encodeURIComponent(classMapping.preoresultatClassName ?? classMapping.className)}&t%C3%A4vling=${event.properties.preoresultatOptions.preoresultatEventId}`
                            : undefined;
                }
            }
        }

        return undefined;
    }
}