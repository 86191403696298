import React from "react";
import { withLocalize, Translate } from "react-localize-redux";

const  EventLoader = () => 
(
    <div id="event-loader">
        <Translate id="kiosk.loadingEvent" />
    </div>
);


export default withLocalize(EventLoader);