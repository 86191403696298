export const getOrganisationKey = organisation =>
    organisation 
        ? `${organisation.name}|${organisation.countryCode ?? ""}`
        : undefined;

export const formatOrganisationName = (organisation, fallback) =>
    organisation
        ? organisation.name + (organisation.countryCode ? ` (${organisation.countryCode})` : "")
        : fallback;

