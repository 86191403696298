const emptyString = "";

const createBibNumberCell = ({ result }) => {
    const div = document.createElement("div");
    div.className = "bibNumber";
    div.innerText = result.bibNumber || emptyString;
    return div;
};

export default createBibNumberCell;
