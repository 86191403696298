import React, { useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { withLocalize, Translate } from "react-localize-redux";
import { supportsWebPush } from "../../../logic/BrowserUtil";
import { Input, Tooltip } from "reactstrap";
import { getHasFavoriteSubscription } from "../../../logic/ListUtil";
import QuestionMark from "../presentational/QuestionMark";
import queryString from "query-string";
import { isEmbeddedInMobileApp } from "../../../logic/MobileAppUtil";

const FavoriteListActionLinks = ({ event, onCreateFavoriteSubscription, onDeleteFavoriteSubscription }) => {
    const location = useLocation();
    const [subscribe, setSubscribe] = useState(event ? getHasFavoriteSubscription(event.eventId) : false);
    const [subscribeTooltipDesktopOpen, setSubscribeTooltipDesktopOpen] = useState(false);
    const [subscribeTooltipMobileOpen, setSubscribeTooltipMobileOpen] = useState(false);

    const handleSubscribeCheckboxChange = () => {
        const newValue = !subscribe;
        setSubscribe(newValue);
        if(newValue) {
            onCreateFavoriteSubscription();
        } else {
            onDeleteFavoriteSubscription();
        }
    };

    const toggleSubscribeTooltip = type => {
        if(type === "mobile") {
            setSubscribeTooltipMobileOpen(!subscribeTooltipMobileOpen);
        } else {
            setSubscribeTooltipDesktopOpen(!subscribeTooltipDesktopOpen);
        }
    };

    // first fetch web push enabled from event settings...
    let webPushEnabled = event.properties.webPushEnabled !== false;
    // ...but it can be overridden in querystring
    const params = queryString.parse(location.search);
    if(params.webPushEnabled && JSON.parse(params.webPushEnabled)) {
        webPushEnabled = true;
    }
    
    if(!webPushEnabled) {
        return null;
    }

    const createSubscribeCheckbox = (type) => {
        const toggleAction = () => toggleSubscribeTooltip(type);
        const isOpen = type === "mobile"
            ? subscribeTooltipMobileOpen
            : subscribeTooltipDesktopOpen;
        const supportsPush = supportsWebPush() || isEmbeddedInMobileApp();
        const elementId = `subscribe-${type}-tooltip`;

        return (
            <div className="push-label-container">
                <label disabled={!supportsPush} className="push-label">
                    <Input type="checkbox" checked={subscribe} onChange={handleSubscribeCheckboxChange} disabled={!supportsPush} />
                    <span>
                        <Translate id="subscribeToFavoritePushNotifications" />
                    </span>
                </label>
                <span id={elementId} href="#">
                    <QuestionMark/>
                </span>
                <Tooltip placement="bottom" text="Left" isOpen={isOpen} target={elementId} toggle={toggleAction}>
                    { supportsPush && <Translate id="subscribeToFavoritePushNotificationsTooltip"/> }
                    { !supportsPush && <Translate id="pushNotificationsNotSupportedTooltip"/> }
                </Tooltip>
            </div>
        );
    };

    return (
        <>
            <div key="desktop" id="caption-action-panel" className="desktop-visible">
                {createSubscribeCheckbox("desktop")}
            </div>
            <div key="mobile tablet" id="subscribe-checkbox-container" className="mobile-visible tablet-visible">
                {createSubscribeCheckbox("mobile")}
            </div>
        </>
    );
};

FavoriteListActionLinks.propTypes = {
    event: PropTypes.object,
    onCreateFavoriteSubscription: PropTypes.func,
    onDeleteFavoriteSubscription: PropTypes.func
};

export default withLocalize(FavoriteListActionLinks);
