import React from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import { Field } from "formik";

const RaceBox = ({id, name, races, onChange, translate, autoFocus = false }) => {
    const handleChange = (fieldProps, domEvent) => {
        fieldProps.form.setFieldValue(name, domEvent.target.value); // always a string value
        if(onChange) {
            const parsedValue = domEvent.target.value === "all"
                ? domEvent.target.value
                : parseInt(domEvent.target.value);
            const newRace = races.find(o => o.raceId === parsedValue);
            onChange(newRace);
        }
    };

    return (
        <Field name={name}>
            {
                fieldProps => (
                    <select id={id} name={name} className="form-control" value={fieldProps.field.value} onChange={domEvent => handleChange(fieldProps, domEvent)} autoFocus={autoFocus}>
                        {
                            races.map(r =>
                                (
                                    <option key={r.raceId} value={r.raceId}>
                                        {r.raceId === "all" && translate("allRaces")}
                                        {r.raceId !== "all" && translate("raceX", { raceNumber: r.raceNumber })}
                                    </option>
                                )   
                            )
                        }
                    </select>
                )
            }
        </Field>        
    );
};

RaceBox.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    races: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    translate: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool
};

export default withLocalize(RaceBox);