import ClassJsonReader from "./ClassJsonReader";
import PersonResultJsonReader from "./PersonResultJsonReader";

const classJsonReader = new ClassJsonReader();
const personResultJsonReader = new PersonResultJsonReader();

export default class ClassResultListJsonReader {
    read(json) {
        return {
            cl: classJsonReader.read(json["class"]),
            results: json.results.map(r => personResultJsonReader.read(r)),
            numberOfCompetitors: json.numberOfCompetitors
        };
    }
}
