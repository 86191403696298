import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as eventActions from "../../../actions/eventActions";
import Header from "./Header";
import { Translate, withLocalize } from "react-localize-redux";
import AllRacesStyles from "./AllRacesStyles";
import AllRacesListRow from "./AllRacesListRow";
import AllRacesListHeader from "./AllRacesListHeader";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import ListType from "../../../logic/models/ListType";
import { getSortedRaces, getSortedClasses } from "../../../logic/EventUtil";
import { getFirstResult, getListClassName, sortResults } from "../../../logic/AllRacesUtil";
import { formatOrganisationName } from "../../../logic/OrganisationUtil";
import { getPageFromList, getUrlForList } from "../../../logic/ListUtil";
import { logPageView } from "../../../logic/LoggingUtil";
import InteractiveListCaption from "./InteractiveListCaption";
import ClassNavigationButtons from "../presentational/ClassNavigationButtons";

import "../../../styles/all-races.scss";

const AllRacesPage = ({ listFromLocation, activeLanguage, translate }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const resultsForCompetitors = useSelector(state => state.eventPage.resultsForAllRaces);
    const loading = useSelector(state => state.eventPage.loading.resultsForAllRaces); 

    const event = useSelector(state => state.eventPage.event);
    const races = getSortedRaces(event);
    
    const numberOfCompetitors = resultsForCompetitors
        ? resultsForCompetitors.length
        : undefined;
    const isValid = loading || numberOfCompetitors !== undefined;

    const reloadKey = JSON.stringify({
        eventId: event.eventId,
        classIds: listFromLocation.classIds, 
        organisationKey: listFromLocation.organisationKey, 
        entryIds: listFromLocation.entryIds
    });

    useEffect(() => {
        // now when the event is loaded, we can load the results
        dispatch(eventActions.fetchResultsForAllRaces(listFromLocation));
    }, [reloadKey]);

    useEffect(() => {
        const page = getPageFromList(listFromLocation);
        logPageView(page);
    }, [reloadKey]);

    useEffect(() => {
        if(resultsForCompetitors) {
            const clonedResultsForCompetitors = [...resultsForCompetitors];
            sortResults(clonedResultsForCompetitors, listFromLocation, event);
            dispatch(eventActions.setResultsForAllRaces(clonedResultsForCompetitors));
        }
    }, [listFromLocation.orderBy, listFromLocation.direction]);

    const handleColumnHeaderClick = columnKey => {
        navigate(getUrlForList(event,
            {
                ...listFromLocation,
                orderBy: columnKey,
                direction: columnKey === listFromLocation.orderBy
                    ? -listFromLocation.direction
                    : 1
            }
        ).url);
    };

    const handleFavoriteButtonClick = (entryId, value) => {
        if(value) {
            dispatch(eventActions.addFavoriteEntryId(event, entryId, activeLanguage));
        } else {
            dispatch(eventActions.removeFavoriteEntryId(event, entryId, activeLanguage));
        }
    };

    const getNoItemsText = () => {
        const itemTypeText = listFromLocation?.listType === ListType.startList
            ? "StartTimes"
            : "Results";

        return `eventPage.no${itemTypeText}`;
    };    

    return (
        <div id="all-races-page" className="page">
            <Header 
                event={event}
                showMenuButton
            />

            <div className="content">
                <LoadingOverlay
                    active={loading}
                    spinner={<BounceLoader />}
                    text={translate("loading")} />

                <AllRacesStyles 
                    races={races}
                    list={listFromLocation}
                />

                {
                    isValid && 
                    (
                        <div id="caption-bar">
                            <h1 id="caption">
                                <InteractiveListCaption
                                    event={event}
                                    list={listFromLocation}
                                />
                            </h1>
                            {
                                listFromLocation.classIds &&
                                (
                                    <div key="desktop" id="caption-action-panel">
                                        <ClassNavigationButtons
                                            event={event}
                                            list={listFromLocation}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    )
                }

                {
                    resultsForCompetitors &&
                    (
                        <div id="list" className={`grid-list ${getListClassName(listFromLocation.listType)}`}>
                            <div className="list-scroll-container" style={{display: isValid ? "block" : "none" }}>
                                { 
                                    numberOfCompetitors === 0 && !loading &&
                                    (
                                        <div id="no-results">
                                            <Translate id={getNoItemsText()} />
                                        </div>
                                    )
                                }
                                {
                                    numberOfCompetitors > 0 &&
                                    (
                                        <AllRacesListHeader
                                            event={event}
                                            races={races}
                                            resultsForCompetitors={resultsForCompetitors}
                                            list={listFromLocation}
                                            onColumnClick={handleColumnHeaderClick}
                                        />
                                    )
                                }
                                <ul className="grid-list-body" style={{ display: loading ? 'none' : 'block'}}>
                                    {
                                        resultsForCompetitors.map(resultsForCompetitor => (
                                            <AllRacesListRow
                                                key={getFirstResult(resultsForCompetitor).entryId}
                                                event={event}
                                                races={races}
                                                results={resultsForCompetitor}
                                                list={listFromLocation}
                                                onFavoriteButtonClick={handleFavoriteButtonClick}
                                            />
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

AllRacesPage.propTypes = {
    listFromLocation: PropTypes.object.isRequired,
    activeLanguage: PropTypes.object,
    translate: PropTypes.func.isRequired
};

export default withLocalize(AllRacesPage);
