import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import Header from "./Header";
import { Translate, withLocalize } from "react-localize-redux";
import CompetitorsResultRow from "../presentational/CompetitorsResultRow";
import Api from "../../../logic/Api";
import queryString from "query-string";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import SearchTextBox from "./SearchTextBox";
import { getUrlForEntry, getUrlForCompetitors } from "../../../logic/ListUtil";
import { logPageView, Page } from "../../../logic/LoggingUtil";
import { Formik, Form } from "formik";
import * as eventActions from "../../../actions/eventActions";

import "../../../styles/competitors.scss";

const CompetitorsPage = ({ translate, activeLanguage }) => {
    const [ searchCompetitorsInitialText, setSearchCompetitorsInitialText ] = useState("");
    const [ searchCompetitorsResults, setSearchCompetitorsResults ] = useState("");
    const [ loading, setLoading ] = useState(true);
    const [ initialFormValues, setInitialFormValues ] = useState({
        competitorSearchText: ""
    });
    const [ searchTextboxVisible, setSearchTextboxVisible ] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const event = useSelector(state => state.eventPage.event);
    const queryStringParams = queryString.parse(location.search);
    const competitorsTextFromQueryString = queryStringParams.q;
    const competitorSearchTextRef = useRef();

    useEffect(() => {
        loadCompetitors();            
        logPageView(Page.competitors);
    }, [event, competitorsTextFromQueryString]);    

    const loadCompetitors = () => {
        setSearchCompetitorsInitialText(competitorsTextFromQueryString);
        setSearchCompetitorsResults(undefined);
        setSearchTextboxVisible(false);
        setLoading(true);
        Api.getCompetitorsByPersonName(
            event, competitorsTextFromQueryString, 
            results => {
                setSearchCompetitorsResults(results);
                setLoading(false);
            },
            error => {
                setLoading(false);
            }
        );
    };

    useEffect(() => {
        if(searchTextboxVisible) {
            competitorSearchTextRef.current.focus();
            competitorSearchTextRef.current.select();
        }
    }, [searchTextboxVisible]);
    
    const groupResultsByEntryId = results => {
        const resultsByEntryId = [];
        results.forEach(r => {
            resultsByEntryId[r.entryId] = resultsByEntryId[r.entryId] || [];
            resultsByEntryId[r.entryId].push(r);
        });
        return resultsByEntryId;
    };

    const handleChangeSearchLinkClick = () => {
        setInitialFormValues({
            competitorSearchText: competitorsTextFromQueryString
        });
        setSearchTextboxVisible(!searchTextboxVisible);
    };
    
    const handleCompetitorSearchTextSelected = value => {
        navigate(getUrlForCompetitors(event.slug, value));
    };

    const handleFavoriteButtonClick = (entryId, value) => {
        if(value) {
            dispatch(eventActions.addFavoriteEntryId(event, entryId, activeLanguage));
        } else {
            dispatch(eventActions.removeFavoriteEntryId(event, entryId, activeLanguage));
        }
    };    

    if (searchCompetitorsResults && searchCompetitorsResults.length === 1) {
        // a single result, redirect to single competitor page
        return <Navigate to={getUrlForEntry(event.slug, searchCompetitorsResults[0][0].entryId)} replace/>;
    }

    return (
        <div id="competitors-page" className="page">
            <Header 
                event={event}
                showMenuButton
            />

            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader />}
                text={translate("loading")} />

            { 
                searchCompetitorsResults && 
                (
                    <div className="content">
                        <div id="caption-bar">
                            <h1 id="caption">
                                <Translate id="competitorsPage.searchResultsForX" data={{ text: searchCompetitorsInitialText }} />
                            </h1>
                            {
                                !searchTextboxVisible && 
                                (
                                    <button type="button" id="caption-action-panel" className="btn btn-link" onClick={handleChangeSearchLinkClick}>
                                        <Translate id="changeSearch" />
                                    </button>                                            
                                )
                            }
                        </div>

                        {
                            searchTextboxVisible && 
                            (
                                <Formik
                                    initialValues={initialFormValues}
                                    enableReinitialize
                                >
                                    {() => 
                                        (
                                            <Form>
                                                <div id="search-container">
                                                    <SearchTextBox
                                                        name="competitorSearchText"
                                                        placeholder={translate("eventPage.personBox.placeholder")}
                                                        showButton
                                                        onSelected={handleCompetitorSearchTextSelected}
                                                        ref={competitorSearchTextRef}
                                                    />
                                                </div>
                                            </Form>
                                        )
                                    }
                                </Formik>
                            )
                        }

                        { 
                            searchCompetitorsResults.length === 0 && 
                            <p><Translate id="competitorsPage.noSearchResults"/></p>
                        }

                        { 
                            searchCompetitorsResults.length > 0 && 
                            (
                                <div className="grid-list">
                                    <div id="competitor-list-header" className="grid-list-header">
                                        <div className="person"><Translate id="name" /></div>
                                        <div className="organisation"><Translate id="organisation" /></div>
                                        <div className="class"><Translate id="class" /></div>
                                        <div className="races"><Translate id="races" /></div>
                                    </div>
                                    <ul id="competitor-list-body"  className="grid-list-body">
                                        {searchCompetitorsResults.map(results => <CompetitorsResultRow key={results[0].entryId} results={results} event={event} onFavoriteButtonClick={handleFavoriteButtonClick} />)}
                                    </ul>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    );
};

CompetitorsPage.propTypes = {
    translate: PropTypes.func.isRequired,
    activeLanguage: PropTypes.object
};

export default withLocalize(CompetitorsPage);
