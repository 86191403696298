const createOrganisationCountryCodeCell = ({ result }) => {
    const div = document.createElement("div");
    div.className = "organisationCountryCode";

    if(result.organisation && result.organisation.countryCode) {
        div.innerText = result.organisation.countryCode;
    }
    return div;
};

export default createOrganisationCountryCodeCell;
