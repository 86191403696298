import React from "react";
import PropTypes from "prop-types";
import { formatClockTime } from "../../../logic/TimeUtil";

const StartListItem = ({ event, person, organisation, startTime, matchesResultSelectionFilter }) => {
    const organisationName = organisation ? organisation.name : undefined;
    const organisationCountryCode = organisation ? organisation.countryCode : undefined;
    const className = matchesResultSelectionFilter ? "matches-result-selection-filter" : undefined;

    return (
        <tr className={className}>
            <td className="person-name">{person.firstName} {person.lastName}</td>
            <td className="organisation-name">{organisationName}</td>
            <td className="organisation-country-code">{organisationCountryCode}</td>
            <td className="start-time">{formatClockTime(startTime, event.timeZone)}</td>
        </tr>
    );
};

StartListItem.propTypes = {
    event: PropTypes.object.isRequired,
    person: PropTypes.object.isRequired,
    organisation: PropTypes.object,
    startTime: PropTypes.number,
    matchesResultSelectionFilter: PropTypes.bool
};

export default StartListItem;