import React from "react";
import { Provider } from "react-redux";
import LocalizationRoot from "./LocalizationRoot";
import PropTypes from "prop-types";
import { LocalizeProvider } from "react-localize-redux";

const App = ({ store }) => {
    return (
        <Provider store={store}>
            <LocalizeProvider>
                <LocalizationRoot />
            </LocalizeProvider>
        </Provider>
    );
};

App.propTypes = {
    store: PropTypes.object.isRequired
};

export default App;