import CellTemplate from "./CellTemplate";
import SubCellTemplate from "./SubCellTemplate";
import TooltipTemplate from "./TooltipTemplate";
import Cell from "./Cell";
import SubCell from "./SubCell";
import Tooltip from "./Tooltip";
import { formatElapsedTime, formatPlace, formatControlName, formatControlCode, formatTimeBehind } from "../logic/FormatUtil";
import TimeType from "../model/TimeType";
import strings from "../strings/sv";

export default class DefaultSplitTimeTableConfiguration {
    constructor() {
        
        const subCellTemplates = {
            times: {
                [TimeType.leg]: new SubCellTemplate({
                    creator: splitTime => new SubCell({
                        className: getClassName("winsplits-time-leg", splitTime.places[TimeType.leg], splitTime.timeLosses[TimeType.leg]),
                        text: formatElapsedTime(splitTime.times[TimeType.leg])
                    })
                }),
                [TimeType.sinceStart]: new SubCellTemplate({
                    creator: splitTime => new SubCell({
                        className: getClassName("winsplits-time-since-start", splitTime.places[TimeType.sinceStart]),
                        text: formatElapsedTime(splitTime.times[TimeType.sinceStart])
                    })
                })
            },

            places: {
                [TimeType.leg]: new SubCellTemplate({
                    creator: splitTime => new SubCell({
                        className: getClassName("winsplits-place-leg", splitTime.places[TimeType.leg], splitTime.timeLosses[TimeType.leg]),
                        text: formatPlace(splitTime.places[TimeType.leg])
                    })
                }),
                
                [TimeType.sinceStart]: new SubCellTemplate({
                    creator: splitTime => new SubCell({
                        className: getClassName("winsplits-place-since-start", splitTime.places[TimeType.sinceStart]),
                        text: formatPlace(splitTime.places[TimeType.sinceStart])
                    })
                })
            }
        };

        const getLegTooltip = splitTime => {
            let content = formatTimeBehind(splitTime.timesBehind[TimeType.leg]);
            if (splitTime.timeLosses[TimeType.leg]) {
                content += "<br/>" + strings.timeLoss + ": " + formatElapsedTime(splitTime.timeLosses[TimeType.leg]);
            }
            return new Tooltip({ content });
        };

        const getFinishTimeTooltip = competitor => {
            const lastSplitTime = getLastSplitTime(competitor);
            const content = formatTimeBehind(lastSplitTime.timesBehind[TimeType.sinceStart]) +
                            "<br/>" +
                            strings.timeLoss + ": " + formatElapsedTime(lastSplitTime.timeLosses[TimeType.sinceStart]);
            return new Tooltip({ content });
        };

        const getCompetitorTooltip = competitor => {
            const lastSplitTime = getLastSplitTime(competitor);
            const placeString = formatPlace(getLastSplitTime(competitor).places[TimeType.sinceStart], false);
            const content = 
                (placeString ? placeString + ". " : "") +
                (competitor.person
                    ? `${competitor.person.firstName ?? ""} ${competitor.person.lastName ?? ""}`
                    : "") +
                "<br/>" +
                (competitor.organisation
                    ? competitor.organisation.name + "<br/>"
                    : "") +
                formatTimeBehind(lastSplitTime.timesBehind[TimeType.sinceStart]) +
                "<br/>" +
                strings.timeLoss + ": " + formatElapsedTime(lastSplitTime.timeLosses[TimeType.sinceStart]);
            return new Tooltip({ content });
        };

        const tooltipTemplates = {
            cells: {
                [TimeType.leg]: new TooltipTemplate({
                    creator: getLegTooltip
                }),
                [TimeType.sinceStart]: new TooltipTemplate({
                    creator: splitTime => new Tooltip({
                        content: formatTimeBehind(splitTime.timesBehind[TimeType.sinceStart])
                    })
                })
            },
            competitorHeaderCells: {
                finishTime: new TooltipTemplate({
                    creator: getFinishTimeTooltip
                }),
                competitor: new TooltipTemplate({
                    creator: getCompetitorTooltip
                })
            }
        };

        this.headerCells = [
            [
                new Cell({
                    mode: "wide",
                    subCells: [
                        createSubCell(0, 0, {
                            className: "winsplits-header-place",
                            text: strings.placeAbbreviated
                        })
                    ]
                }),
                new Cell({
                    mode: "wide",
                    subCells: [
                        createSubCell(0, 0, {
                            className: "winsplits-header-person-name",
                            text: strings.name
                        }),
                        createSubCell(1, 0, {
                            className: "winsplits-header-organisation-name",
                            text: strings.organisation
                        })                        
                    ]
                }),
                new Cell({
                    mode: "wide",
                    subCells: [
                        createSubCell(0, 0, {
                            className: "winsplits-header-finish-time",
                            text: strings.time
                        })
                    ]
                }),
                new Cell({
                    mode: "narrow",
                    subCells: [
                        createSubCell(0, 0, {
                            className: "winsplits-header-person-initials",
                            text: strings.name
                        }),
                        createSubCell(1, 0, {
                            className: "winsplits-header-organisation-initials",
                            text: strings.organisation
                        })                        
                    ]
                }),
            ]
        ];

        this.controlHeaderCellTemplates = [
            new CellTemplate({
                creator: control => new Cell({
                }),
                subCellTemplates: [
                    // control name
                    { 
                        subRow: 0,
                        subColumn: 0,
                        subCellTemplate: new SubCellTemplate({
                            creator: control => new SubCell({
                                className: "winsplits-control-name",
                                text: formatControlName(control, strings)
                            })
                        })
                    },
                    // control code
                    { 
                        subRow: 1,
                        subColumn: 0,
                        subCellTemplate: new SubCellTemplate({
                            creator: control => new SubCell({
                                className: "winsplits-control-code",
                                text: formatControlCode(control, strings)
                            })
                        })
                    }                    
                ]
            })
        ];

        this.competitorHeaderCellTemplates = [
            new CellTemplate({
                creator: competitor => new Cell({
                    mode: "wide",
                }),
                subCellTemplates: [
                    // place
                    { 
                        subRow: 0,
                        subColumn: 0,
                        subCellTemplate: new SubCellTemplate({
                            creator: competitor => new SubCell({
                                className: "winsplits-competitor-place",
                                text: formatPlace(getLastSplitTime(competitor).places[TimeType.sinceStart], false)
                            })
                        })
                    }
                ]
            }),

            new CellTemplate({
                creator: competitor => new Cell({
                    mode: "wide",
                }),
                subCellTemplates: [
                    // person name
                    { 
                        subRow: 0,
                        subColumn: 0,
                        subCellTemplate: new SubCellTemplate({
                            creator: competitor => new SubCell({
                                className: "winsplits-competitor-person-name",
                                text: competitor.person
                                    ? `${competitor.person.firstName ?? ""} ${competitor.person.lastName ?? ""}`
                                    : ""
                            })
                        })
                    },
                    // organisation name
                    { 
                        subRow: 1,
                        subColumn: 0,
                        subCellTemplate: new SubCellTemplate({
                            creator: competitor => new SubCell({
                                className: "winsplits-competitor-organisation-name",
                                text: competitor.organisation?.name ?? ""
                            })
                        })
                    }                    
                ]
            }),

            new CellTemplate({
                creator: competitor => new Cell({
                    mode: "wide",
                }),
                subCellTemplates: [
                    // finish time
                    { 
                        subRow: 0,
                        subColumn: 0,
                        subCellTemplate: new SubCellTemplate({
                            creator: competitor => new SubCell({
                                className: "winsplits-competitor-finish-time",
                                text: formatElapsedTime(getLastSplitTime(competitor).times[TimeType.sinceStart])
                            })
                        })
                    }               
                ],
                tooltipTemplates: [
                    { subRow: 0, subColumn: 0, tooltipTemplate: tooltipTemplates.competitorHeaderCells.finishTime }
                ]
            }),

            new CellTemplate({
                creator: competitor => new Cell({
                    mode: "narrow",
                }),
                subCellTemplates: [
                    // person initials
                    { 
                        subRow: 0,
                        subColumn: 0,
                        subCellTemplate: new SubCellTemplate({
                            creator: competitor => new SubCell({
                                className: "winsplits-competitor-person-initials",
                                text: competitor.person
                                    ? getNameInitials(`${competitor.person.firstName ?? ""} ${competitor.person.lastName ?? ""}`, 3)
                                    : ""
                            })
                        })
                    },
                    // organisation initials
                    { 
                        subRow: 1,
                        subColumn: 0,
                        subCellTemplate: new SubCellTemplate({
                            creator: competitor => new SubCell({
                                className: "winsplits-competitor-organisation-initials",
                                text: competitor.organisation
                                    ? getNameInitials(competitor.organisation?.name ?? "")
                                    : ""
                            })
                        })
                    }                    
                ],
                tooltipTemplates: [
                    { subRow: 0, subColumn: 0, tooltipTemplate: tooltipTemplates.competitorHeaderCells.competitor },
                    { subRow: 1, subColumn: 0, tooltipTemplate: tooltipTemplates.competitorHeaderCells.competitor }
                ]
            })            
        ];

        this.splitTimeCellTemplate = new CellTemplate({
            creator: splitTime => new Cell({

            }),
            subCellTemplates: [
                { subRow: 0, subColumn: 0, subCellTemplate: subCellTemplates.times[TimeType.leg] },
                { subRow: 0, subColumn: 1, subCellTemplate: subCellTemplates.places[TimeType.leg] },
                { subRow: 1, subColumn: 0, subCellTemplate: subCellTemplates.times[TimeType.sinceStart] },
                { subRow: 1, subColumn: 1, subCellTemplate: subCellTemplates.places[TimeType.sinceStart] }
            ],
            tooltipTemplates: [
                { subRow: 0, tooltipTemplate: tooltipTemplates.cells[TimeType.leg] },
                { subRow: 1, tooltipTemplate: tooltipTemplates.cells[TimeType.sinceStart] }
            ]
        });


    }
    
    getSortedCompetitors(competitors) {
        return competitors.sort((a, b) => {
            const aPlace = getLastSplitTime(a).places[TimeType.sinceStart];
            const bPlace = getLastSplitTime(b).places[TimeType.sinceStart];
            const aPerson = a.person ?? {};
            const bPerson = b.person ?? {};
            const aPersonName = `${aPerson.lastName ?? ""}, ${aPerson.firstName ?? ""}`;
            const bPersonName = `${bPerson.lastName ?? ""}, ${bPerson.firstName ?? ""}`;
            if (aPlace && bPlace) {
                const diff = aPlace - bPlace;
                return diff === 0
                    ? compareStrings(aPersonName, bPersonName)
                    : diff;
            }
            if (aPlace) {
                return -1;
            }
            if (bPlace) {
                return 1;
            }
            return compareStrings(aPersonName, bPersonName);
        });
    }
}

const compareStrings = (a, b) => {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
};

const getLastSplitTime = competitor => competitor.courseSplitTimes[competitor.courseSplitTimes.length - 1];

const getClassName = (className, place, timeLoss) => {
    const atoms = [className];

    if (place === 1) {
        atoms.push("winsplits-best");
    } else if (place <= 3) {
        atoms.push("winsplits-top");
    }

    if (timeLoss) {
        atoms.push("winsplits-time-loss");
    }

    return atoms.join(" ");
};

const createSubCell = (subRow, subColumn, subCellOptions) => ({
    subRow,
    subColumn,
    subCell: new SubCell(subCellOptions)
});

const getNameInitials = (name, lastAtomMinLength) => {
    const atoms = (name ?? "").replace("-", " ").split(" ");

    let result = "";
    let count = 0;
    atoms.forEach(atom => {
        count++;
        if (atom) {
            if (count === atoms.length && lastAtomMinLength) {
                result += atom.substr(0, lastAtomMinLength);
            } else {
                // allow for one lowercase letter (e.g. GoIF)
                const upperLetters = getUpperLetterCount(atom) >= atom.length - 1
                    ? atom
                    : getUpperLetters(atom);
                result += upperLetters;
            }
        }
    });
    return result;
};

const getUpperLetterCount = str => {
    let count = 0;
    for (let i=0; i<str.length; i++) {
        count+= str[i] === str[i].toUpperCase() ? 1 : 0;
    }
    return count;
};

const getUpperLetters = str => {
    let upperLetters = "";
    for (let i=0; i<str.length; i++) {
        upperLetters += str[i] === str[i].toUpperCase() ? str[i] : "";
    }
    return upperLetters;
};