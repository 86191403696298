export default class CellTemplate {
    constructor(options) {
        options = options ?? {};
        this.creator = options.creator; // function that transforms a model object to a Cell object
        this.subCellTemplates = options.subCellTemplates; // array of { subRow, subColumn, subCellTemplate }
        this.tooltipTemplates = options.tooltipTemplates || []; // array of { subRow, subColumn, tooltipTemplate }
        this.mode = options.mode; // undefined, narrow or wide
    }

    createCell(data) {
        const cell = this.creator(data);
        if(this.mode) {
            cell.className = (cell.className ?? "") + " " + `winsplits-mode-${this.mode}`;
        }
        
        cell.subCells = this.subCellTemplates.map(subCellTemplate => ({
            subRow: subCellTemplate.subRow,
            subColumn: subCellTemplate.subColumn,
            subCell: subCellTemplate.subCellTemplate.createSubCell(data)
        }));
        cell.tooltips = this.tooltipTemplates.map(tooltipTemplate => ({
            subRow: tooltipTemplate.subRow,
            subColumn: tooltipTemplate.subColumn,
            tooltip: tooltipTemplate.tooltipTemplate.createTooltip(data)
        }));
        return cell;
    }
}
