import IntermediateControlJsonReader from "./IntermediateControlJsonReader";
import ControlJsonReader from "./ControlJsonReader";
import { parseDateString } from "./DateHelpers";

const intermediateControlJsonReader = new IntermediateControlJsonReader();
const controlJsonReader = new ControlJsonReader();

export default class RaceClassJsonReader {
    read(json) {
        const rc = {};
        rc.raceClassId = json.i;
        rc.raceId = json.r;
        rc.externalRaceId = json.er;
        rc.minCourseLength = json.mi;
        rc.maxCourseLength = json.ma;
        if (rc.minCourseLength && rc.maxCourseLength) {
            rc.courseLength = 10 * Math.round((rc.minCourseLength + rc.maxCourseLength) / 2 / 10);
        }
        rc.intermediateControls = (json.ic || [])
            .map(ic => intermediateControlJsonReader.read(ic));
        rc.controls = (json.c || [])
            .map(c => controlJsonReader.read(c));
        rc.splitTimesHiddenUntil = parseDateString(json.sh);
        rc.raceClassIdsWithSameCourse = json.rwc;
        return rc;
    }
}
