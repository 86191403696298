import React from "react";
import PropTypes from "prop-types";

const PunchingCardNumberCell = ({ result }) => {
    return (
        <div className="punchingCardNumber">
            {result.punchingCardNumber}
        </div>
    );
};
    
PunchingCardNumberCell.propTypes = {
    result: PropTypes.object.isRequired
};

export default PunchingCardNumberCell;