import { formatTimeBehind } from "../../logic/TimeUtil";
import { isElapsedTimeSample } from "../../logic/ResultUtil";
import AccumulationType from "../../logic/models/AccumulationType";

const createTimeBehindCell = ({ result }, sample, timeCellVisibilities, sampleImportance) => {
    let className = `${sampleImportance}TimeBehind`;
    const showLiveTime = timeCellVisibilities[sampleImportance].showLiveTime;
    if (showLiveTime) {
        className += " is-live";
    }

    const div = document.createElement("div");
    div.className = className;
    div.setAttribute("data-sampleid", sample.sampleId);

    const visible = sample && (sample.accumulationType === AccumulationType.race || result.cl.hasOverallResults);

    if(visible && !showLiveTime && isElapsedTimeSample(sample) && sample.timeBehind != undefined) {
        div.innerText = formatTimeBehind(sample.timeBehind);
    }
    return div;
};

export default createTimeBehindCell;
