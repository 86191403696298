import TimeAccuracy from "../model/TimeAccuracy";

export const timeIsSet = (time) => time || time === 0;

export const timeIsSetAndPositive = (time) => time >= 0;

export const sum = (array, valueSelector) => {
    valueSelector = valueSelector || (v => v);
    let sum = 0;
    array.forEach(value => sum += valueSelector(value));
    return sum;
};
 
export const roundTime = (time, accuracy) => {
    if (!timeIsSet(time)) {
        return undefined;
    }
    switch (accuracy) {
        case TimeAccuracy.hundredthsSeconds: return Math.round(100 * time) / 100;
        case TimeAccuracy.tenthsSeconds: return Math.round(10 * time) / 10;
        case TimeAccuracy.minutes: return 60 * Math.round(time / 60);
        case TimeAccuracy.hours: return 3600 * Math.round(time / 3600);
        default: return Math.round(time);
    }
};