import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { withLocalize } from "react-localize-redux";
import { getUrlForList, getUrlForEntry, setHighlightedResultId } from "../../../logic/ListUtil";
import { getSortedRaces, isFutureRace } from "../../../logic/EventUtil";
import ListType from "../../../logic/models/ListType";
import PersonCell from "../presentational/PersonCell";
import MenuButtonCell from "../presentational/MenuButtonCell";
import ListLink from "../presentational/ListLink";
import Organisation from "./Organisation";

const CompetitorsResultRow = ({ results, event, onFavoriteButtonClick, translate }) => {
    if(!results || !results.length){
        return null;
    }
    
    const result = results[0];
    const organisation = result.organisation ?? {};
    const favoriteEntryIds = useSelector(state => state.eventPage.favoriteEntryIds);

    const getRaceResults = () =>
        getSortedRaces(event)
            .map(r => ({
                    race: r,
                    result: results.filter(res => res.raceId === r.raceId)[0]
                })
            );

    const getEntryUrl = () => getUrlForEntry(event.slug, result.entryId);

    return (
        <li>
            <PersonCell
                result={result}
                url={getEntryUrl()}
            />
            <div className="organisation">
                <Organisation organisation={organisation} />
            </div>
            <div className="class">
                {result.cl.name}
            </div>
            <div className="races">
                {
                    getRaceResults().map(rr => {
                        const listType = isFutureRace(event, rr.race)
                            ? ListType.startList
                            : ListType.raceResultList;
                        const list = rr.result
                            ? {
                                classIds: [rr.result.cl.classId],
                                raceId: rr.race.raceId,
                                listType: listType,
                                highlightedResultId: rr.result.resultId
                            }
                            : undefined;
                        const urlResult = getUrlForList(event, list);
                        return (
                            <span key={rr.race.raceId} className="race">
                                {urlResult?.url && (
                                    <ListLink
                                        url={urlResult.url}
                                        isExternal={urlResult.isExternal}
                                        onClick={() => setHighlightedResultId(rr.result?.resultId)}
                                    >
                                        {translate("raceXAbbreviated", { raceNumber: rr.race.raceNumber })}
                                    </ListLink>
                                )}
                            </span>
                        );
                    })
                }
            </div>
            <MenuButtonCell
                result={result}
                favoriteEntryIds={favoriteEntryIds}
                onFavoriteButtonClick={onFavoriteButtonClick}
            />
        </li>
    );
};

CompetitorsResultRow.propTypes = {
    results: PropTypes.array,
    event: PropTypes.object.isRequired,
    onFavoriteButtonClick: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired
};

export default withLocalize(CompetitorsResultRow);