import React from "react";
import PropTypes from "prop-types";

const ClassButton = ({ cl, onClick }) => {
    const handleClick = () => {
        onClick(cl);
    };

    let className;
    if (cl.name.length > 18) {
        className = "class-name-extra-small";
    } else if (cl.name.length > 13) {
        className = "class-name-small";
    } else {
        className = "class-name";
    }

    return (
        <button type="button" className="btn" onClick={handleClick}>
            <span className={className}>{cl.name}</span>
        </button>
    );
};

ClassButton.propTypes = {
    cl: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
};
    
export default ClassButton;