import { startOfDay } from "./TimeUtil";
import { getCurrentTime } from "./BrowserUtil";
import PreoresultatManager from "./preoresultat/PreoresultatManager";

const preoresultatManager = new PreoresultatManager();

export const getCurrentRaceId = event => {
    if(!event) {
        return undefined;
    }
    // selects the race that is before the current time, or active during the current time
    const now = getCurrentTimeForEvent(event);
    const races = Object.values(event.races);
    const earlierAndCurrentRaces = races.filter(r => startOfDay(r.startTime, event.timeZone) < now);

    earlierAndCurrentRaces.sort((r1, r2) => -(r1.startTime - r2.startTime));
    
    return (earlierAndCurrentRaces[0] || getSortedRaces(event)[0]).raceId;
};

export const hasOverallResults = (event, raceId) => {
    return event && isMultiRace(event) && event.races[raceId] && event.races[raceId].raceNumber > 1;
};

export const isMultiRace = event => {
    return event && Object.values(event.races).length > 1;
};

export const isLive = event => {
    const now = getCurrentTimeForEvent(event);
    return event && event.startTime <= now && event.endTime >= now;
};

export const getSortedRaces = (event, includeAllRacesItem) => {
    const sortedRaces = Object.values(event.races).map(o => o);
    sortedRaces.sort((a, b) => a.raceNumber - b.raceNumber);
    if(includeAllRacesItem) {
        sortedRaces.push({ raceId: "all" });
    }
    return sortedRaces;
};

export const getSortedClasses = (event, classIds) => {
    const sortedClasses = Object.values(event.classes).map(cl => cl).filter(cl => !classIds || classIds.indexOf(cl.classId) !== -1);
    sortedClasses.sort((a, b) => a.sequence - b.sequence);
    return sortedClasses;
};

export const getSortedOrganisations = event => {
    const sortedOrganisations = Object.values(event.organisations).map(o => o);
    sortedOrganisations.sort((a, b) => a.name < b.name ? -1 : (a.name === b.name ? 0 : 1));
    return sortedOrganisations;
};

export const getCurrentTimeForEvent = event => {
    const now = getCurrentTime();
    if(event && event.testingRule) {
        return event.testingRule.zeroTime + ((now - event.testingRule.zeroTime) % (1000 * event.testingRule.timeWindow));
    }
    return now;
};

export const getExternalUrlForClasses = (event, classIds, raceId, listType) => {
    if(!event || !classIds) {
        return undefined;
    }
    
    const classId = Array.isArray(classIds)
        ? classIds[0]
        : classIds;
    const cl = event.classes[classId];
    const race = event.races[raceId];
    
    if(!cl || !race) {
        return undefined;
    }

    const preoresultatRedirectUrl = preoresultatManager.getRedirectUrl(event, race, cl, listType);
    if(preoresultatRedirectUrl) {
        return preoresultatRedirectUrl;
    }

    return cl.externalUrl;
};

export const isFutureRace = (event, race) => {
    const currentTime = getCurrentTimeForEvent(event);
    return currentTime < race.startTime;
};
