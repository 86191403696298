import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { withLocalize, Translate } from "react-localize-redux";
import Dropdown from "../../../common/presentational/Dropdown";
import LiveloxManager from "../../../logic/livelox/LiveloxManager";
import WinSplitsManager from "../../../logic/winSplits/WinSplitsManager";
import EventorManager from "../../../logic/eventor/EventorManager";
import MessageModal from "./MessageModal";
import { getUrlForSplitTimes, getUrlForList } from "../../../logic/ListUtil";
import { logEvent, Event } from "../../../logic/LoggingUtil";
import { getIntermediateControlLabel } from "../../../logic/ClassUtil";
import ListType from "../../../logic/models/ListType";
import ClassNavigationButtons from "./ClassNavigationButtons";

const liveloxManager = new LiveloxManager();
const winSplitsManager = new WinSplitsManager();
const eventorManager = new EventorManager();

const ClassListActionLinks = ({ event, list, intermediateControls, onIntermediateControlLinkClick, activeLanguage, translate }) => {
    const navigate = useNavigate();

    const [liveloxMessageModalOpen, setLiveloxMessageModalOpen] = useState(false);
    const [winSplitsMessageModalOpen, setWinSplitsMessageModalOpen] = useState(false);
    const [eventorMessageModalOpen, setEventorMessageModalOpen] = useState(false);
    
    const handleResultsForAllClassesWithThisCourseLinkClick = () => {

        const raceId = list.raceId;
        const raceClass = event.classes[list.classIds[0]].raceClasses[raceId];

        // create raceClassId => classId dictionary
        const raceClassDictionary = {};
        Object.values(event.classes).forEach(cl => {
            if (cl.raceClasses[raceId]) {
                raceClassDictionary[cl.raceClasses[raceId].raceClassId] = cl.classId;
            }
        });

        const classIds = raceClass?.raceClassIdsWithSameCourse.map(raceClassId => raceClassDictionary[raceClassId]);

        const url = getUrlForList(
            event, 
            { ...list, classIds, organisationKey: undefined, entryIds: undefined, tag: undefined }
        ).url;

        navigate(url);
    };

    const handleSplitTimesLinkClick = () => {
        navigate(getUrlForSplitTimes(event.slug, list.raceId, list.classIds, list.listType, list.orderBy, list.direction));
    };

    const handleLiveloxLinkClick = () => {
        const race = event.races[list.raceId];
        const classId = (list.classIds || [])[0];
        const cl = event.classes[classId];
        
        const url = liveloxManager.getRedirectUrl(event, race, cl);

        if (url) {
            logEvent(Event.redirectToLivelox);
            window.open(url, "_blank");
        } else {
            setLiveloxMessageModalOpen(!liveloxMessageModalOpen);
        }
    };

    const handleWinSplitsLinkClick = () => {
        const race = event.races[list.raceId];
        const classId = (list.classIds || [])[0];
        const cl = event.classes[classId];
        
        const url = winSplitsManager.getRedirectUrl(event, race, cl, activeLanguage);

        if (url) {
            logEvent(Event.redirectToWinSplits);
            window.open(url, "_blank");
        } else {
            setWinSplitsMessageModalOpen(!winSplitsMessageModalOpen);
        }
    };

    const handleEventorLinkClick = () => {
        const race = event.races[list.raceId];
        const classId = (list.classIds || [])[0];
        const cl = event.classes[classId];
        
        const url = eventorManager.getRedirectUrl(event, race, cl);

        if (url) {
            logEvent(Event.redirectToEventor);
            window.open(url, "_blank");
        } else {
            setEventorMessageModalOpen(!eventorMessageModalOpen);
        }
    };

    const handleLiveloxMessageModalToggle  = () => setLiveloxMessageModalOpen(!liveloxMessageModalOpen);

    const handleWinSplitsMessageModalToggle = () => setWinSplitsMessageModalOpen(!winSplitsMessageModalOpen);

    const handleEventorMessageModalToggle = () => setEventorMessageModalOpen(!eventorMessageModalOpen);

    if(!list.classIds || list.classIds.length !== 1) {
        return [];
    }

    const classId = list.classIds[0];
    const raceId = list.raceId;
    const cl = event.classes[classId];
    const raceClass = cl.raceClasses[raceId];
    const otherClassesHaveSameCourse = raceClass?.raceClassIdsWithSameCourse.length > 1;
    const splitTimesEnabled = cl.timePresentation;
    const liveloxEnabled = liveloxManager.isEnabledForEvent(event);
    const winSplitsEnabled = winSplitsManager.isEnabledForEvent(event);
    const eventorEnabled = eventorManager.isEnabledForEvent(event);

    const showResultsForAllClassesWithThisCourseLink = otherClassesHaveSameCourse && list.listType === ListType.raceResultList;
    const dropdownClassNames = `mobile-visible-flex tablet-visible-flex ${showResultsForAllClassesWithThisCourseLink ? "desktop-visible-flex" : ""}`;
    const listClassNames = showResultsForAllClassesWithThisCourseLink ? "hidden" : "desktop-visible-flex";

    return (
        <>
            <div key="mobile tablet" id="caption-action-panel" className={dropdownClassNames}>
                <div>
                    <Dropdown text={<Translate id="eventPage.links" />} id="linksDropdown" align="right">
                        {
                            intermediateControls &&
                            (
                                <>
                                    {
                                        intermediateControls.map(intermediateControl => (
                                            <button key={intermediateControl.sequence} type="button" className="dropdown-item btn btn-link" onClick={() => onIntermediateControlLinkClick(intermediateControl)}>
                                                {getIntermediateControlLabel(intermediateControl, intermediateControls, translate)}
                                            </button>
                                        ))
                                    }
                                    <button type="button" className="dropdown-item btn btn-link" onClick={() => onIntermediateControlLinkClick(undefined)}>
                                        <Translate id="eventPage.finish" />
                                    </button>
                                    <div className="dropdown-divider"/ >
                                </>
                            )
                        }
                        {
                            otherClassesHaveSameCourse && list.listType === ListType.raceResultList &&
                            (
                                <button type="button" className="dropdown-item btn btn-link" onClick={handleResultsForAllClassesWithThisCourseLinkClick}>
                                    <Translate id="eventPage.resultsForAllClassesWithThisCourse" />
                                </button>
                            )
                        }
                        {
                            splitTimesEnabled &&
                            (
                                <button type="button" className="dropdown-item btn btn-link" onClick={handleSplitTimesLinkClick}>
                                    <Translate id="eventPage.splitTimes" />
                                </button>
                            )
                        }
                        {
                            liveloxEnabled &&
                            (
                                <button type="button" className="dropdown-item btn btn-link" onClick={handleLiveloxLinkClick}>
                                    <Translate id="eventPage.livelox" />
                                </button>
                            )
                        }
                        {
                            winSplitsEnabled &&
                            (
                                <button type="button" className="dropdown-item btn btn-link" onClick={handleWinSplitsLinkClick}>
                                    <Translate id="eventPage.winSplits" />
                                </button>
                            )
                        }
                        {
                            eventorEnabled &&
                            (
                                <button type="button" className="dropdown-item btn btn-link" onClick={handleEventorLinkClick}>
                                    <Translate id="eventPage.eventor" />
                                </button>
                            )
                        }
                    </Dropdown>
                </div>
            </div>
            <div key="desktop" id="caption-action-panel" className={listClassNames}>
                {
                    otherClassesHaveSameCourse && list.listType === ListType.raceResultList &&
                    (
                        <button type="button" className="btn btn-link" onClick={handleResultsForAllClassesWithThisCourseLinkClick}>
                            <Translate id="eventPage.resultsForAllClassesWithThisCourse" />
                        </button>
                    )
                }
                <button type="button" className="btn btn-link" onClick={handleSplitTimesLinkClick}>
                    <Translate id="eventPage.splitTimes" />
                </button>
                {
                    liveloxEnabled &&
                    (
                        <button type="button" className="btn btn-link" onClick={handleLiveloxLinkClick}>
                            <Translate id="eventPage.livelox" />
                        </button>
                    )
                }
                {
                    winSplitsEnabled &&
                    (
                        <button type="button" className="btn btn-link" onClick={handleWinSplitsLinkClick}>
                            <Translate id="eventPage.winSplits" />
                        </button>
                    )
                }
                {
                    eventorEnabled &&
                    (
                        <button type="button" className="btn btn-link" onClick={handleEventorLinkClick}>
                            <Translate id="eventPage.eventor" />
                        </button>
                    )
                }
                <ClassNavigationButtons
                    event={event}
                    list={list}
                />
            </div>

            <MessageModal 
                isOpen={liveloxMessageModalOpen}
                onToggle={handleLiveloxMessageModalToggle}
                title={translate("eventPage.livelox")}
                message={translate("eventPage.liveloxNotAvailableForClass")}
            />
            
            <MessageModal 
                isOpen={winSplitsMessageModalOpen}
                onToggle={handleWinSplitsMessageModalToggle}
                title={translate("eventPage.winSplits")}
                message={translate("eventPage.winSplitsNotAvailableForClass")}
            />
            
            <MessageModal
                isOpen={eventorMessageModalOpen}
                onToggle={handleEventorMessageModalToggle}
                title={translate("eventPage.eventor")}
                message={translate("eventPage.eventorNotAvailableForClass")}
            />
        </>
    );
};

ClassListActionLinks.propTypes = {
    list: PropTypes.object,
    event: PropTypes.object,
    intermediateControls: PropTypes.array,
    onIntermediateControlLinkClick: PropTypes.func,
    activeLanguage: PropTypes.object,
    translate: PropTypes.func
};

export default withLocalize(ClassListActionLinks);