import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Translate, withLocalize } from "react-localize-redux";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import Api from "../../../logic/Api";
import { getLocalStorageItem, setLocalStorageItem } from "../../../logic/BrowserUtil";
import { getCurrentRaceId, isMultiRace } from "../../../logic/EventUtil";
import { getOrganisationKey } from "../../../logic/OrganisationUtil";
import { createResultSelectionDescription } from "../../../logic/ResultUtil";
import { parseListType, getListTypeLabelKey } from "../../../logic/ListUtil";
import Header from "../presentational/Header";
import EditForm from "../presentational/EditForm";
import ExtractForm from "../presentational/ExtractForm";
import ResultSelectionExtract from "../presentational/ResultSelectionExtract";
import ResultSelectionCreatedModal from "../presentational/ResultSelectionCreatedModal";
import ResultSelectionExtractJsonReader from "../../../logic/readers/ResultSelectionExtractJsonReader";
import ListType from "../../../logic/models/ListType";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import { Button, Alert } from "reactstrap";
import queryString from "query-string";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import "../../../styles/media-page.scss";
import "../../../styles/header.scss";

const Subpage = {
    list: "list",
    edit: "edit",
    extract: "extract",
    results: "results"
};

const MediaPage = ({ translate, activeLanguage }) => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const querystringParams = queryString.parse(location.search);
    let { subpage, raceId, listType /* string */ } = params;
    const { eventSlug, guid } = params;
    if (raceId) {
        raceId = parseInt(raceId);
    }
    if (listType) {
        listType = parseListType(listType);
    }

    const outputType = querystringParams.outputType || "html";

    const [event, setEvent] = useState(undefined);
    const [countries, setCountries] = useState(undefined);
    const [regions, setRegions] = useState(undefined);
    const [allOrganisationFilterItems, setAllOrganisationFilterItems] = useState(undefined);
    // guids for the result selections that the user has created (stored in local storage)
    const [myResultSelectionGuids, setMyResultSelectionGuids] = useState([]);
    // result selection objects corresponding to myResultSelectionGuids + the currently shown result selection even if it is not in myResultSelectionGuids 
    const [myResultSelections, setMyResultSelections] = useState({});
    const [resultSelection, setResultSelection] = useState(undefined);
    const [resultSelectionExtract, setResultSelectionExtract] = useState(undefined);
    const [error, setError] = useState(undefined);
    const [resultSelectionCreatedModalIsOpen, setResultSelectionCreatedModalIsOpen] = useState(false);
    const [loadingEvent, setLoadingEvent] = useState(false);
    const [loadingRegions, setLoadingRegions] = useState(false);
    const [loadingCountries, setLoadingCountries] = useState(false);
    const [resultSelectionLoadingCount, setResultSelectionLoadingCount] = useState(0);
    const [loadingResultSelectionExtract, setLoadingResultSelectionExtract] = useState(false);
    const [savingResultSelection, setSavingResultSelection] = useState(false);
    const [editFormData, setEditFormData] = useState(undefined);
    const [extractFormData, setExtractFormData] = useState(undefined);
    const [editResultSelectionBackSubpage, setEditResultSelectionBackSubpage] = useState(undefined);

    const loading = loadingEvent || loadingRegions || loadingCountries || loadingResultSelectionExtract || savingResultSelection || resultSelectionLoadingCount > 0;

    const getAllResultSelectionGuids = myResultSelectionGuids => {
        // guids for the result selections that the user has created (stored in local storage), and the guid that the user has requested (if not in myResultSelectionGuids)
        const guids = [...myResultSelectionGuids];
        if(guid && guids.indexOf(guid) === -1) {
            guids.push(guid);
        }
        return guids;
    };

    const isEventDataLoaded =
        event &&
        countries &&
        regions &&
        getAllResultSelectionGuids(myResultSelectionGuids).length === Object.keys(myResultSelections).length;

    useEffect(() => {
        if(!subpage) {
            setUrl({ subpage: Subpage.list });
        }
    }, [subpage]);

    useEffect(() => {
        load();
    }, []);
    
    useEffect(() => {
        if (isEventDataLoaded && guid) {
            setResultSelectionLoadingCount(setResultSelectionLoadingCount(previous => previous + 1));
            loadResultSelection(
                guid, 
                resultSelection => handleResultSelectionLoaded(resultSelection),
                event.eventId
            );
        }
    }, [isEventDataLoaded, guid]);

    useEffect(() => {
        if (isEventDataLoaded) {
            handleEventDataLoaded();
        }
    }, [isEventDataLoaded]);

    useEffect(() => {
        if(isEventDataLoaded && subpage === Subpage.results && raceId !== undefined && listType !== undefined && resultSelection) {
            if(outputType === "text" || outputType === "excel") {
                getResultSelectionExtract(resultSelection.guid, outputType);
            } else {
                getResultSelectionExtractAsJson(resultSelection.guid);
            }
            setExtractFormData({
                raceId,
                listType,
                outputType
            });
        }
    }, [isEventDataLoaded, resultSelection?.guid, subpage, raceId, listType, outputType]);

    const load = () => {
        setLoadingEvent(true);
        Api.getEvent(eventSlug,
            event => {
                // load selections for this event from local storage
                let myResultSelectionGuidsArray;
                try {
                    myResultSelectionGuidsArray = JSON.parse(getLocalStorageItem(`resultSelections_${event.eventId}`));
                }
                catch(e) {
                    myResultSelectionGuidsArray = [];
                }
                if (!Array.isArray(myResultSelectionGuidsArray)) {
                    myResultSelectionGuidsArray = [];
                }

                const guids = getAllResultSelectionGuids(myResultSelectionGuidsArray);
                setEvent(event);
                setMyResultSelectionGuids(myResultSelectionGuidsArray);
                setLoadingEvent(false);
                setResultSelectionLoadingCount(previous => previous + guids.length);
                guids.forEach(g => loadResultSelection(g, undefined, event.eventId));
            },
            error => {
                setError(extractError(error));
                setLoadingEvent(false);
            }
        );

        setLoadingRegions(true);
        Api.getRegions(eventSlug,
            regions => {
                setRegions(regions);
                setLoadingRegions(false);
            },
            error => {
                setError(extractError(error));
                setLoadingRegions(false);
            }
        );

        setLoadingCountries(true);
        Api.getCountries(eventSlug,
            countries => {
                setCountries(countries);
                setLoadingCountries(false);
            },
            error => {
                setError(extractError(error));
                setLoadingCountries(false);
            }
        );
    };

    const loadResultSelection = (guidToLoad, callback, eventId) => {
        Api.getResultSelection(guidToLoad,
            resultSelection => {
                setMyResultSelections(previous => ({
                    ...previous,
                    [guidToLoad]: resultSelection
                }));
                if (callback) {
                    callback(resultSelection);
                }
                setResultSelectionLoadingCount(previous => previous - 1);
            },
            error => {
                let errorMessage;

                if (error.status === 404) {
                    setMyResultSelectionGuids(previous => {
                        const updated = [ ...previous.filter(o => o !== guidToLoad) ];
                        saveToLocalStorage(updated, eventId);
                        return updated;
                    });
                } else {
                    errorMessage = extractError(error);
                }
                setError(errorMessage);
                setResultSelectionLoadingCount(previous => previous - 1);
            }
        );
    };

    const handleEventDataLoaded = () => {
        setAllOrganisationFilterItems(getOrganisationFilterItems());
        setExtractFormData({
            ...extractFormData,
            raceId: getCurrentRaceId(event)
        });
        if(guid) {
            handleResultSelectionLoaded(myResultSelections[guid]);
        }
    };

    const handleResultSelectionLoaded = resultSelection => {
        if (raceId && listType) {
            const extractFormData = {
                raceId,
                listType: parseListType(listType),
                outputType
            };

            setResultSelection(resultSelection);
                
            if(extractFormData.outputType === "text" || extractFormData.outputType === "excel") {
                getResultSelectionExtract(extractFormData.outputType);
            } else {
                getResultSelectionExtractAsJson();
            }
        } else {
            setResultSelection(resultSelection);
            setEditFormData(editFormData);
        }
    };

    const getOrganisationFilterItems = () => {
        const organisations = Object.values(event.organisations);
        return regions.map(o => ({ id: `region_${o.regionId}`, label: `${o.name} (${translate("regionLC")})`, text: o.name }))
            .concat(countries.map(o => ({ id: `country_${o}`, label: `${o} (${translate("countryLC")})`, text: o })))
            .concat(organisations.map(o => ({ id: `organisation_${getOrganisationKey(o)}`, label: `${o.name} (${translate("organisationLC")})`, text: o.name })));
    };

    const handleEditResultSelectionButtonClick = resultSelection => {
        setEditResultSelectionBackSubpage(subpage);
        setUrl({ subpage: Subpage.edit, guid: resultSelection.guid });
    };

    const handleDeleteResultSelectionButtonClick = resultSelection => {
        const guidToDelete = resultSelection.guid;
        Api.deleteResultSelection(
            guidToDelete,
            () => {
                setMyResultSelections(previous => {
                    const updated = { ...previous };
                    delete updated[guidToDelete];
                    return updated;
                });
                setMyResultSelectionGuids(previous => {
                    const updated = previous.filter(o => o !== guidToDelete);
                    saveToLocalStorage(updated, event.eventId);
                    delete updated[guidToDelete];
                    return updated;
                });
                setResultSelection(undefined);
                setUrl({ subpage: Subpage.list });
            },
            error => {
                setError(extractError(error));
            }
        );
    };

    const handleCreateNewResultSelectionButtonClick = () => {
        setUrl({ subpage: Subpage.edit });
        setEditResultSelectionBackSubpage(Subpage.list);
        setResultSelectionExtract(undefined);
    };

    const handleShowExtractFormButtonClick = () => {
        setResultSelectionExtract(undefined);
        setUrl({ subpage: Subpage.extract, guid });
    };

    const handleEditFormSubmit = formValues => {
        const resultSelectionObject = createResultSelectionObject(formValues);
        setSavingResultSelection(true);

        if (resultSelectionObject.guid) {
            Api.updateResultSelection(
                resultSelectionObject,
                resultSelection => {
                    setMyResultSelections(previous => ({
                        ...previous,
                        [resultSelection.guid]: resultSelection
                    }));
                    setResultSelection(resultSelection);
                    setSavingResultSelection(false);
                    handleResultSelectionLoaded(resultSelection);
                    handleEditFormCancel();
                },
                error => {
                    setError(extractError(error));
                    setSavingResultSelection(false);
                }
            );
        } else {
            Api.createResultSelection(
                resultSelectionObject,
                resultSelection => {
                    setMyResultSelections(previous => ({
                        ...previous,
                        [resultSelection.guid]: resultSelection
                    }));
                    setMyResultSelectionGuids(previous => {
                        const updated = [...previous, resultSelection.guid].filter(onlyUnique);
                        saveToLocalStorage(updated, event.eventId);
                        return updated;
                    });
                    setResultSelection(resultSelection);
                    setResultSelectionCreatedModalIsOpen(true);
                    setSavingResultSelection(false);
                    handleResultSelectionLoaded(resultSelection);
                },
                error => {
                    setError(extractError(error));
                    setSavingResultSelection(false);
                }
            );
        }
    };

    const handleEditFormCancel = () => {
        if(editResultSelectionBackSubpage === Subpage.list) {
            setUrl({ subpage: Subpage.list });
        } else {
            setUrl({ subpage: Subpage.extract, guid });
        }
    };

    const handleExtractFormSubmit = formValues => {
        let newRaceId = formValues.raceId;
        let newListType = parseInt(formValues.listType) ?? ListType.raceResultList;
        newRaceId = parseInt(newRaceId) || event.races[0].raceId;
        setUrl({ subpage: Subpage.results, guid, raceId: newRaceId, listType: newListType, outputType: formValues.outputType });
    };

    const handleExtractFormCancel = () => {
        setResultSelection(undefined);
        setUrl({ subpage: Subpage.list });
    };

    const handleResultSelectionCreatedModalClosed = () => {
        setResultSelectionCreatedModalIsOpen(false);
        setUrl({ subpage: Subpage.extract, guid: resultSelection.guid });
    };

    const saveToLocalStorage = (resultSelectionGuids, eventId) => {
        setLocalStorageItem(`resultSelections_${eventId}`, JSON.stringify(resultSelectionGuids));
    };

    const createResultSelectionObject = formValues => ({
        guid: formValues.guid, 
        eventId: event.eventId,
        filter: {
            classFilter: {
                classCategoryIds: formValues.classCategories.filter(o => o.value).map(o => o.classCategoryId),
                // if no organisation filter, there is no need to set onlyClassesContainingFilteredCompetitors
                onlyClassesContainingFilteredCompetitors: formValues.organisationFilterItems.length > 0
                    ? formValues.onlyClassesContainingFilteredCompetitors 
                    : false
            },
            organisationFilter: {
                regionIds: getRegionIds(formValues.organisationFilterItems),
                countryCodes: getCountryCodes(formValues.organisationFilterItems),
                organisationKeys: getOrganisationKeys(formValues.organisationFilterItems)
            },
            unfilteredPlaceLimit: formValues.unfilteredPlaceLimit,
            filteredPlaceLimit: formValues.filteredPlaceLimit
        }
    });

    const getRegionIds = organisationFilterItems => {
        return organisationFilterItems
            .filter(o => o.id.substring(0, 7) === "region_")
            .map(o => parseInt(o.id.substring(7)));
    };

    const getCountryCodes = organisationFilterItems => {
        return organisationFilterItems
            .filter(o => o.id.substring(0, 8) === "country_")
            .map(o => o.id.substring(8));
    };

    const getOrganisationKeys = organisationFilterItems => {
        return organisationFilterItems
            .filter(o => o.id.substring(0, 13) === "organisation_")
            .map(o => o.id.substring(13));
    };

    const getResultSelectionExtractAsJson = (guidToLoad) => {
        setLoadingResultSelectionExtract(true);
        Api.getResultSelectionExtractAsJson(
            guidToLoad, 
            raceId, 
            listType,
            json => {
                const reader = new ResultSelectionExtractJsonReader();
                setResultSelectionExtract(reader.read(json));
                setLoadingResultSelectionExtract(false);
            },
            error => {
                setError(extractError(error));
                setLoadingResultSelectionExtract(false);
            }
        );
    };

    const getResultSelectionExtract = (guidToLoad, outputType) => {
        const resources = {
            sheetLabel: translate("media.excelSheetLabel"),
            columnHeaders: {
                place: translate("place"),
                name: translate("name"),
                organisation: translate("organisation"),
                country: translate("country"),
                elapsedTime: translate("elapsedTime"),
                timeBehind: translate("timeBehind"),
                startTime: translate("startTime")
            },
            resultStatuses: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(status => translate(`resultStatuses.${status}`)),
            numberOfCompetitorsSingularis: translate("media.competitor"),
            numberOfCompetitorsPluralis: translate("media.competitors")
        };
        const fileExtension = outputType === "excel"
            ? "xlsx"
            : "txt";

        setLoadingResultSelectionExtract(true);
        Api.getResultSelectionExtract(
            guidToLoad, 
            raceId, 
            listType,
            outputType,
            resources,
            blob => {
                const raceId = extractFormData.raceId ?? event.races[0].raceId;
                const raceName = translate("raceX", { raceNumber: event.races[raceId].raceNumber });
                const listTypeString = translate(getListTypeLabelKey(extractFormData.listType, isMultiRace(event)));
    
                // create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${raceName}, ${listTypeString}.${fileExtension}`);
                // append to DOM
                document.body.appendChild(link);
                // force download
                link.click();
                // clean up and remove the link
                link.parentNode.removeChild(link);

                setLoadingResultSelectionExtract(false);
            },
            error => {
                setError(extractError(error));
                setLoadingResultSelectionExtract(false);
            }
        );
    };

    const getListTypeString = listType => {
        switch(listType) {
            case ListType.raceResultList:
                return "raceResultList";
            case ListType.overallResultList:
                return "overallResultList";
            case ListType.startList:
                return "startList";
        }
    };

    const renderResultSelectionDescriptionBox = (resultSelection, extraListItems, buttonVisibilities) => {
        buttonVisibilities = buttonVisibilities ?? {};

        if(!event) {
            return undefined;
        }

        return (
            <div className="result-selection-description card card-body bg-light">
                <div className="result-selection-description-inner">
                    <ul>
                        {
                            createResultSelectionDescription(resultSelection, regions, event.classCategories, translate, activeLanguage)
                                .map((atom, i) => <li key={i}>{atom}</li>)
                        }
                        {
                            (extraListItems || []).map((atom, i) => <li key={`extra_${i}`}>{atom}</li>)
                        }
                    </ul>
                    <div className="buttons">
                        {
                            buttonVisibilities.show && 
                            (
                                <Link to={getUrl({ subpage: Subpage.extract, guid: resultSelection.guid })} className="btn btn-primary btn-sm">
                                    <Translate id="media.showResultSelectionButtonText" />
                                </Link>
                            )
                        }
                        {
                            buttonVisibilities.edit &&
                            (
                                <Button color="secondary" className="btn-sm" onClick={() => handleEditResultSelectionButtonClick(resultSelection)} disabled={loading}>
                                    <Translate id="media.showEditFormButtonText" />
                                </Button>
                            )
                        }
                        {
                            buttonVisibilities.delete &&
                            (
                                <Button color="secondary" className="btn-sm" onClick={() => handleDeleteResultSelectionButtonClick(resultSelection)} disabled={loading}>
                                    <Translate id="media.deleteResultSelectionButtonText" />
                                </Button>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    };

    const getExtractFormStrings = () => {
        const raceId = extractFormData.raceId ?? event.races[0].raceId;
        return [
            translate("raceX", { raceNumber: event.races[raceId].raceNumber }),
            extractFormData.listType === ListType.startList ? undefined : translate(getListTypeLabelKey(extractFormData.listType, isMultiRace(event)))
        ].filter(o => o);
    };

    const getResultSelectionsSortedByModifiedTime = resultSelections => {
        const clonedResultSelections = [...resultSelections];
        clonedResultSelections.sort((a, b) => a.modifiedTime < b.modifiedTime ? -1 : (a.modifiedTime > b.modifiedTime ? 1 : 0));
        return clonedResultSelections;
    };

    const extractError = error => {
        return {
            status: error.status,
            statusText: error.statusText
        };
    };

    const createEditFormData = resultSelection => {
        if(!resultSelection) {
            return {
                guid: undefined,
                classCategories: Object.keys(event.classCategories).map(o => ({ classCategoryId: o, value: false })),
                organisationFilterItems: [],
                unfilteredPlaceLimit: "",
                filteredPlaceLimit: "",
                onlyClassesContainingFilteredCompetitors: true
            };
        }
        const organisationFilterItemIds =
            resultSelection.filter.organisationFilter.regionIds.map(regionId => `region_${regionId}`)
            .concat(resultSelection.filter.organisationFilter.countryCodes.map(countryCode => `country_${countryCode}`))
            .concat(resultSelection.filter.organisationFilter.organisationKeys.map(regionId => `organisation_${regionId}`));

        const allItems = getOrganisationFilterItems();

        const isNil = o => o === null || o === undefined;

        return {
            guid: resultSelection.guid,
            classCategories: Object.values(event.classCategories).map(o => ({ classCategoryId: o.classCategoryId, value: resultSelection.filter.classFilter.classCategoryIds?.indexOf(o.classCategoryId) > -1 })),
            organisationFilterItems: organisationFilterItemIds
                .map(id => allItems.find(item => item.id === id))
                .filter(item => !!item),
            unfilteredPlaceLimit: isNil(resultSelection.filter.unfilteredPlaceLimit) 
                ? "" 
                : resultSelection.filter.unfilteredPlaceLimit,
            filteredPlaceLimit: isNil(resultSelection.filter.filteredPlaceLimit)
                ? ""
                : resultSelection.filter.filteredPlaceLimit,
            onlyClassesContainingFilteredCompetitors: resultSelection.filter.classFilter.onlyClassesContainingFilteredCompetitors || false
        };
    };

    const hasEmptyOrganisationFilter = resultSelection => {
        const organisationFilter = resultSelection.filter.organisationFilter;
        return !organisationFilter ||
            (
                organisationFilter.regionIds.length === 0 &&
                organisationFilter.organisationKeys.length === 0 &&
                organisationFilter.countryCodes.length === 0
            );
    };

    const setUrl = parameters => {
        navigate(getUrl(parameters));
    };

    const getUrl = parameters => {
        const guidString = parameters.guid 
            ? `/${parameters.guid}`
            : "";

        const raceIdString = parameters.raceId
            ? `/${parameters.raceId}`
            : "";
        
        const listTypeString = parameters.listType !== undefined
            ? `/${getListTypeString(parameters.listType)}`
            : "";

        const outputTypeString = parameters.outputType && parameters.outputType !== "html"
            ? `?outputType=${parameters.outputType}`
            : "";

        return `/${encodeURIComponent(eventSlug)}/media/${parameters.subpage}${guidString}${raceIdString}${listTypeString}${outputTypeString}`;
    };

    const onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
    };

    const numberOfStoredResultSelections = myResultSelectionGuids.length;
    const resultSelectionIsOwnedByUser = resultSelection && myResultSelectionGuids.indexOf(resultSelection.guid) !== -1;

    // subpages:
    // - list all result selections
    // - create/edit a result selection
    // - show create results form for result selection
    // - show result list

    return (
        <div id="media-page">

            <Header
                event={event}
            />

            <ResultSelectionCreatedModal
                resultSelection={resultSelection}
                eventSlug={eventSlug}
                isOpen={resultSelectionCreatedModalIsOpen}
                onClosed={handleResultSelectionCreatedModalClosed}
            />

            <div id="media-page-inner">
                {
                    error &&
                    (
                        <Alert color="danger">
                            <Translate id="anErrorOccurred" />
                            <br/>
                            <br/>
                            {`${error.status} ${error.statusText}`}
                        </Alert>
                    )                        
                }
                {
                    loading &&
                    (
                        <LoadingOverlay
                            active
                            spinner={<BounceLoader />}
                            text={translate("loading")} 
                        />
                    )
                }
                {
                    subpage === Subpage.list &&
                    (
                        <>
                            <h1>
                                <Translate id="media.resultSelectionListTitle" />
                            </h1>
                            <p>
                            { numberOfStoredResultSelections === 0 && <Translate id="media.resultSelectionListNoDataDescription" />} 
                            { numberOfStoredResultSelections > 0 && <Translate id="media.resultSelectionListDescription" />} 
                            </p>
                            <div id="result-selection-list">
                                {
                                    getResultSelectionsSortedByModifiedTime(Object.values(myResultSelections))
                                        .filter(resultSelection => myResultSelectionGuids.indexOf(resultSelection.guid) !== -1)
                                        .map(resultSelection => (
                                            <div key={resultSelection.guid}>
                                                { renderResultSelectionDescriptionBox(resultSelection, [], { show: true, edit: true, delete: true }) }
                                            </div>
                                        ))
                                }
                            </div>
                            <p>
                                <Button color="secondary" onClick={handleCreateNewResultSelectionButtonClick} disabled={loading}>
                                    <Translate id="media.createNewResultSelectionButtonText" />
                                </Button>
                            </p>
                        </>
                    )
                }
                {
                    subpage === Subpage.edit && event && !(guid && !resultSelection) &&
                    (
                        <>
                            <h1><Translate id="media.editFormTitle" /></h1>
                            <p><Translate id="media.editFormDescription" /></p>
                            <EditForm
                                event={event}
                                initialValues={createEditFormData(resultSelection)}
                                allOrganisationFilterItems={allOrganisationFilterItems}
                                isLoading={loading}
                                onSubmit={handleEditFormSubmit}
                                onCancel={handleEditFormCancel}
                            />
                        </>
                    )
                }
                {
                    ((subpage === Subpage.extract && resultSelection) || (subpage === Subpage.results && resultSelection && !resultSelectionExtract)) &&
                    (
                        <>
                            <h1>
                                <Translate id="media.getResultsForResultSelection" />
                            </h1>
                            
                            { renderResultSelectionDescriptionBox(resultSelection, [], { edit: resultSelectionIsOwnedByUser }) }

                            <ExtractForm
                                event={event}
                                initialValues={extractFormData}
                                isLoading={loading}
                                onSubmit={handleExtractFormSubmit}
                                onCancel={handleExtractFormCancel}
                            />
                        </>
                    )
                }
                {
                    subpage === Subpage.results && resultSelectionExtract &&
                    (
                        <>
                            {
                                listType === ListType.startList &&
                                (
                                    <>
                                        <h1>
                                            <Translate id="media.showingStartListSelection" />
                                        </h1>
                                        <p>
                                            <Translate id="media.startListsForXClassesWereFound" data={{ count: resultSelectionExtract.classResultLists.length }} />
                                        </p>
                                    </>
                                )
                            }
                            {
                                listType !== ListType.startList &&
                                (
                                    <>
                                        <h1>
                                            <Translate id="media.showingResultSelection" />
                                        </h1>
                                        <p>
                                            <Translate id="media.resultsForXClassesWereFound" data={{ count: resultSelectionExtract.classResultLists.length }} />
                                        </p>
                                    </>
                                )
                            }

                            { renderResultSelectionDescriptionBox(resultSelection, getExtractFormStrings()) }

                            <p>
                                <Button color="secondary" onClick={handleShowExtractFormButtonClick} disabled={loading}>
                                    <Translate id="back" />
                                </Button>
                            </p>

                            <div className={resultSelection && resultSelection.filter.unfilteredPlaceLimit && !hasEmptyOrganisationFilter(resultSelection) ? "result-selection-filter-in-use" : undefined}>
                                <ResultSelectionExtract
                                    event={event}
                                    listType={listType}
                                    {...resultSelectionExtract}
                                />
                            </div>

                            { 
                                resultSelectionExtract.classResultLists.length > 0 &&
                                (
                                    <p>
                                        <Button color="secondary" onClick={handleShowExtractFormButtonClick} disabled={loading}>
                                            <Translate id="back" />
                                        </Button>
                                    </p>
                                )
                            }
                        </>
                    )
                }

            </div>
        </div>
    );
};

MediaPage.propTypes = {
    translate: PropTypes.func.isRequired,
    activeLanguage: PropTypes.object,
};

export default withLocalize(MediaPage);
