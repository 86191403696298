export const getSortFunction = valueGetter => {
    return (a, b) => {
        const aValue = valueGetter(a);
        const bValue = valueGetter(b);

        if(aValue != null && bValue == null) {
            return -1;
        }
        if(aValue == null && bValue != null) {
            return 1;
        }

        return aValue < bValue
            ? -1
            : (aValue > bValue ? 1 : 0);
    };
};

export const combineSorters = (sorters, direction) => {
    return (a, b) => {
        if(sorters) {
            for (let i = 0; i < sorters.length; i++) {
                let result = direction * sorters[i](a, b);
                if(result !== 0) {
                    return result;
                }
            }
        }
        return 0;
    };
};
    
export const getSortablePersonName = result => {
    const person = result?.person ?? {};
    return `${person.lastName ?? ""}, ${person.firstName ?? ""}`;
};