import { formatElapsedTime, formatClockTime } from "../../logic/TimeUtil";
import ResultStatus from "../../logic/models/ResultStatus";
import ListType from "../../logic/models/ListType";
import AccumulationType from "../../logic/models/AccumulationType";
import { getExternalUrlForClasses } from "../../logic/EventUtil";
import { createExternalLinkIconElement } from "../../common/presentational/IconUtil";

const createElapsedTimeCell = ({ event, result, now, translate }, sample, timeCellVisibilities, sampleImportance) => {
    const div = document.createElement("div");
    let className = `${sampleImportance}ElapsedTime`;
    const listType = sample.accumulationType === AccumulationType.race
        ? ListType.raceResultList
        : ListType.overallResultList;
    const externalUrl = getExternalUrlForClasses(event, result.cl?.classId, result.raceId, listType);

    if(externalUrl) {
        const a = document.createElement("a");
        a.setAttribute("href", externalUrl);
        a.setAttribute("target", "_blank");
        a.appendChild(createExternalLinkIconElement());
        div.appendChild(a);
    } else {
        const tcv = timeCellVisibilities[sampleImportance];
        if(tcv.showStartTime) {
            className += " is-start-time";
        }
        if(tcv.showLiveTime) {
            className += " is-live";
        }
        if(tcv.showTextStatus) {
            className += " is-text-status";
        }
    
        div.setAttribute("data-sampleid", sample.sampleId);
    
        const visible = sample && (sample.accumulationType === AccumulationType.race || result.cl.hasOverallResults);
    
        if(visible) {
            if(tcv.showElapsedTime) {
                div.innerText = formatElapsedTime(sample.elapsedTime);
            }
            if(tcv.showStartTime) {
                div.innerText = formatClockTime(result.startTime, event.timeZone);
            }
            if(tcv.showTextStatus) {
                // if the status is started, but the start time has not passed, show "before start" (ResultStatus.notActivated) instead
                const status = sample.status === ResultStatus.started && result.startTime && now < result.startTime
                    ? ResultStatus.notActivated
                    : sample.status;
                div.innerText = translate(`resultStatuses.${status}`);
            }
        }
    }

    div.className = className;
    return div;
};

export default createElapsedTimeCell;
