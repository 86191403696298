import React from "react";
import PropTypes from "prop-types";
import OrganisationIcon from "./OrganisationIcon";
import { Link } from "react-router-dom";
import { formatOrganisationName } from "../../../logic/OrganisationUtil";

const Organisation = ({ organisation, url, onClick }) => {
    if (!organisation || !organisation.name) {
        return "";
    }

    const name = formatOrganisationName(organisation);

    if(url) {
        return (
            <Link to={url} onClick={onClick}>
                <OrganisationIcon organisation={organisation} />
                {name}
            </Link>
        );
    }

    return (
        <span onClick={onClick}>
            <OrganisationIcon organisation={organisation} />
            {name}
        </span>
    );
};

Organisation.propTypes = {
    organisation: PropTypes.object,
    url: PropTypes.string,
    onClick: PropTypes.func
};     

export default Organisation;