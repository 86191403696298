import React from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import { formatAccumulationType } from "../../../logic/AllRacesUtil";
import ListType from "../../../logic/models/ListType";
import AccumulationType from "../../../logic/models/AccumulationType";

const AllRacesListHeader = ({ event, races, resultsForCompetitors, list, onColumnClick, translate }) => {
    const columns = [];
    columns.push({
        className: "person",
        key: "person",
        label: translate("eventPage.columns.person", { numberOfResults: resultsForCompetitors.length })
    });

    if(list.organisationKey === undefined && list.entryIds === undefined) {
        columns.push({
            className: "organisation",
            key: "organisation",
            label: translate("eventPage.columns.organisation")
        });
    }

    if(list.classIds === undefined) {
        columns.push({
            className: "class",
            key: "class",
            label: translate("eventPage.columns.class")
        });
    }

    if(list.listType === ListType.startList) {
        races.forEach((race, i) => {
            columns.push({
                className: `right race-${i+1}-startTime`,
                key: `race-${race.raceId}`,
                label: translate("raceX", { raceNumber: i+1 })
            });
        });
    } else {
        const accumulationTypes = [AccumulationType.race];
        if(!(list.classIds && !event.classes[list.classIds[0]].hasOverallResults)) {
            accumulationTypes.push(AccumulationType.overall);
        }

        accumulationTypes.forEach(accumulationType => {
            races.forEach((race, i) => {
                columns.push({
                    className: `center ${formatAccumulationType(accumulationType)}-${i+1}-full-width-ElapsedTime`,
                    key: `${formatAccumulationType(accumulationType)}-${race.raceId}`,
                    label: translate(`${formatAccumulationType(accumulationType)}X`, { raceNumber: i+1 })
                });
            });
        });
    }

    const isSelected = columnKey => columnKey === list.orderBy;

    const handleClick = e => { 
        if (e.target && e.target.closest) {
            onColumnClick(e.target.closest("[data-key]").attributes["data-key"].value);
        }
    };

    return (
        <div id="list-header" className="grid-list-header row">
            {
                columns.map(column => (
                    <div
                        key={column.key}    
                        className={column.className}
                        data-key={column.key}
                        onClick={handleClick}
                    >
                        <div className="grid-list-header-button">
                            <div className="title" title={column.label}>
                                {column.label}
                            </div>
                            {
                                isSelected(column.key) &&
                                <div className="direction"/>
                            }
                        </div>                            
                    </div>
                ))
            }
        </div>
    );
};

AllRacesListHeader.propTypes = {
    event: PropTypes.object.isRequired,
    races: PropTypes.array.isRequired,
    resultsForCompetitors: PropTypes.array.isRequired,
    list: PropTypes.object.isRequired,
    onColumnClick: PropTypes.func.isRequired,
    translate: PropTypes.func
}; 

export default withLocalize(AllRacesListHeader);
