let w;
try {
    w = window;
} catch(e) {
    w = self;
}

export default class PersonResultBinaryReader {
    readNext(buffer, offset) {
        return read(buffer, offset);
    }

    readAll(buffer) {
        const results = [];
        let offset = 0;
        while (offset < buffer.byteLength) {
            const readResult = read(buffer, offset);
            results.push(readResult.value);
            offset += readResult.byteLength;
        }
        return results;
    }

    static isSupported() {
        return w.ArrayBuffer && w.TextDecoder;
    }
}

const read = (buffer, offset) => {
    let view = new DataView(buffer, offset, 2);
    const length = view.getUint16(0, true);
    offset += 2;
    view = new DataView(buffer, offset, length);
    let position = 0,
        getStringResult,
        readPunchesResult,
        readSampleResult;
    const personResult = {
        samples: []
    };

    while (position < length) {
        const field = view.getUint8(position);
        position += 1;
        switch (field) {
            case fields.resultId:
                personResult.resultId = getUint64(view, position);
                position += 8;
                break;

            case fields.eventId:
                ensureEventExists(personResult);
                personResult.event.eventId = getUint64(view, position);
                position += 8;
                break;

            case fields.raceId:
                personResult.raceId = getUint64(view, position);
                position += 8;
                break;

            case fields.externalRaceId:
                personResult.externalRaceId = getUint64(view, position);
                position += 8;
                break;

            case fields.entryId:
                personResult.entryId = getUint64(view, position);
                position += 8;
                break;

            case fields.entryReference:
                getStringResult = getString(view, offset, position);
                personResult.entryReference = getStringResult.value;
                position += getStringResult.byteLength + 1;
                break;

            case fields.classId:
                ensureClassExists(personResult);
                personResult.cl.classId = getUint64(view, position);
                position += 8;
                break;

            case fields.externalClassId:
                ensureClassExists(personResult);
                personResult.cl.externalClassId = getUint64(view, position);
                position += 8;
                break;

            case fields.className:
                ensureClassExists(personResult);
                getStringResult = getString(view, offset, position);
                personResult.cl.className = getStringResult.value;
                position += getStringResult.byteLength + 1;
                break;

            case fields.classSequence:
                ensureClassExists(personResult);
                personResult.cl.sequence = view.getUint16(position, true);
                position += 2;
                break;

            case fields.classTimePresentation:
                ensureClassExists(personResult);
                personResult.cl.timePresentation = view.getUint8(position) === 1;
                position += 1;
                break;

            case fields.classHasOverallResult:
                ensureClassExists(personResult);
                personResult.cl.hasOverallResult = view.getUint8(position) === 1;
                position += 1;
                break;

            case fields.personId:
                ensurePersonExists(personResult);
                personResult.person.personId = getUint64(view, position);
                position += 8;
                break;

            case fields.personFirstName:
                ensurePersonExists(personResult);
                getStringResult = getString(view, offset, position);
                personResult.person.firstName = getStringResult.value;
                position += getStringResult.byteLength + 1;
                break;

            case fields.personLastName:
                ensurePersonExists(personResult);
                getStringResult = getString(view, offset, position);
                personResult.person.lastName = getStringResult.value;
                position += getStringResult.byteLength + 1;
                break;

            case fields.personBirthYear:
                ensurePersonExists(personResult);
                personResult.person.birthYear = view.getUint16(position);
                position += 2;
                break;

            case fields.personSex:
                ensurePersonExists(personResult);
                getStringResult = getString(view, offset, position);
                personResult.person.sex = getStringResult.value;
                position += getStringResult.byteLength + 1;
                break;

            case fields.personNationalityCode:
                ensurePersonExists(personResult);
                getStringResult = getString(view, offset, position);
                personResult.person.nationalityCode = getStringResult.value;
                position += getStringResult.byteLength + 1;
                break;

            case fields.organisationId:
                ensureOrganisationExists(personResult);
                personResult.organisation.organisationId = getUint64(view, position);
                position += 8;
                break;

            case fields.organisationExternalId:
                ensureOrganisationExists(personResult);
                getStringResult = getString(view, offset, position);
                personResult.organisation.organisationExternalId = getStringResult.value;
                position += getStringResult.byteLength + 1;
                break;

            case fields.organisationName:
                ensureOrganisationExists(personResult);
                getStringResult = getString(view, offset, position);
                personResult.organisation.name = getStringResult.value;
                position += getStringResult.byteLength + 1;
                break;

            case fields.organisationCountryCode:
                ensureOrganisationExists(personResult);
                getStringResult = getString(view, offset, position);
                personResult.organisation.countryCode = getStringResult.value;
                position += getStringResult.byteLength + 1;
                break;

            case fields.bibNumber:
                getStringResult = getString(view, offset, position);
                personResult.bibNumber = getStringResult.value;
                position += getStringResult.byteLength + 1;
                break;

            case fields.punchingCardNumber:
                getStringResult = getString(view, offset, position);
                personResult.punchingCardNumber = getStringResult.value;
                position += getStringResult.byteLength + 1;
                break;

            case fields.startTime:
                personResult.startTime = getDateTime(view, position);
                position += 6;
                break;

            case fields.overallElapsedTimeAtStart:
                personResult.overallElapsedTimeAtStart = getTime(view, position);
                position += 4;
                break;

            case fields.timePresentation:
                personResult.timePresentation = view.getUint8(position) === 1;
                position += 1;
                break;

            case fields.modifiedTime:
                personResult.modifiedTime = getDateTime(view, position);
                position += 6;
                break;

            case fields.databaseModifiedTime:
                personResult.databaseModifiedTime = getDateTime(view, position);
                position += 6;
                break;

            case fields.punches:
                readPunchesResult = readPunches(view, position);
                personResult.punches = readPunchesResult.punches;
                position += readPunchesResult.byteLength;
                break;

            case fields.startOfSample:
                readSampleResult = readSample(view, offset, position);
                readSampleResult.value.resultId = personResult.resultId;
                personResult.samples.push(readSampleResult.value);
                position += readSampleResult.byteLength;
                break;

            case fields.tag:
                personResult.tags = personResult.tags || [];
                getStringResult = getString(view, offset, position);
                personResult.tags.push( getStringResult.value);
                position += getStringResult.byteLength + 1;
                break;

            case fields.isDeleted:
                personResult.isDeleted = view.getUint8(position) === 1;
                position += 1;
                break;

            case fields.classAllowsFiltering:
                ensureClassExists(personResult);
                personResult.cl.allowFiltering = view.getUint8(position) === 1;
                position += 1;
                break;

            case fields.classExternalUrl:
                ensureClassExists(personResult);
                getStringResult = getString(view, offset, position);
                personResult.cl.externalUrl = getStringResult.value;
                position += getStringResult.byteLength + 1;
                break;
        }
    }

    return {
        value: personResult,
        byteLength: 2 + length
    };
};

const readPunches = (view, position) => {
    const numberOfPunches = view.getUint8(position),
        punches = [];

    position++;
    for (let i = 0; i < numberOfPunches; i++) {
        const punch = {};
        punch.code = view.getUint16(position);
        position += 2;
        punch.elapsedTime = getNullableTime(view, position);
        position += 4;
        punches.push(punch);
    }
    return {
        punches: punches,
        byteLength: 1 + numberOfPunches * 6
    };
};

const readSample = (view, offset, position) => {
    const sample = {},
        startPosition = position;
    let getStringResult;
    while (position < view.byteLength) {
        const field = view.getUint8(position);
        position += 1;
        switch (field) {
            case fields.sampleId:
                sample.sampleId = getUint64(view, position);
                position += 8;
                break;

            case fields.resultId:
                sample.resultId = getUint64(view, position);
                position += 8;
                break;

            case fields.sampleAccumulationType:
                sample.accumulationType = view.getUint8(position);
                position += 1;
                break;

            case fields.sampleControlType:
                sample.controlType = view.getUint8(position);
                position += 1;
                break;

            case fields.sampleControlName:
                getStringResult = getString(view, offset, position);
                sample.controlName = getStringResult.value;
                position += getStringResult.byteLength + 1;
                break;

            case fields.sampleElapsedTime:
                sample.elapsedTime = getTime(view, position);
                position += 4;
                break;

            case fields.sampleTimeBehind:
                sample.timeBehind = getTime(view, position);
                position += 4;
                break;

            case fields.samplePassedTime:
                sample.passedTime = getDateTime(view, position);
                position += 6;
                break;

            case fields.samplePlace:
                sample.place = view.getUint16(position, true);
                position += 2;
                break;

            case fields.sampleOverallPlaceDifferenceSinceStart:
                sample.overallPlaceDifferenceSinceStart = view.getUint16(position, true);
                position += 2;
                break;

            case fields.sampleStatus:
                sample.status = view.getUint8(position);
                position += 1;
                break;

            case fields.modifiedTime:
                sample.modifiedTime = getDateTime(view, position);
                position += 6;
                break;

            case fields.databaseModifiedTime:
                sample.databaseModifiedTime = getDateTime(view, position);
                position += 6;
                break;

            case fields.endOfSample:
                return {
                    value: sample,
                    byteLength: position - startPosition
                };
        }
    }
    throw "Reading past length of view. Probably a serialization error.";
};

const fields = {
        resultId: 0,
        raceId: 1,
        entryId: 2,
        classId: 3,
        className: 4,
        classSequence: 5,
        classTimePresentation: 6,
        personId: 7,
        personFirstName: 8,
        personLastName: 9,
        personBirthYear: 10,
        personSex: 11,        
        personNationalityCode: 12,
        organisationId: 13,
        organisationName: 14,
        organisationCountryCode: 15,
        bibNumber: 16,
        punchingCardNumber: 17,
        startTime: 18,
        timePresentation: 19,
        modifiedTime: 20,
        databaseModifiedTime: 21,
        startOfSample: 22,
        sampleId: 23,
        sampleAccumulationType: 24,
        sampleControlType: 25,
        sampleControlName: 26,
        sampleElapsedTime: 27,
        sampleTimeBehind: 28,
        samplePassedTime: 29,
        samplePlace: 30,
        sampleOverallPlaceDifferenceSinceStart: 31,        
        sampleStatus: 32,
        endOfSample: 33,
        classHasOverallResult: 34,
        punches: 35,
        externalRaceId: 36,
        externalClassId: 37,
        overallElapsedTimeAtStart: 38,
        organisationExternalId: 39,
        entryReference: 40,
        tag: 41,
        eventId: 42,
        isDeleted: 43,
        classAllowsFiltering: 44,
        classExternalUrl: 45
    },
    maxUint32 = 256 * 256 * 256 * 256,
    textDecoder = w.TextDecoder ? new TextDecoder("utf-8") : undefined;

const getUint64 = (view, position) =>
    view.getUint32(position, true) + maxUint32 * view.getUint32(position + 4, true);

const getString = (view, bufferOffset, position) => {
    const result = {
        byteLength: view.getUint8(position)
    };

    result.value = textDecoder.decode(new Uint8Array(view.buffer, bufferOffset + position + 1, result.byteLength));

    return result;
};

const zeroTime = 2208988800000; // diff in milliseconds between 1900 and 1970
const getDateTime = (view, position) => {
    const millisecondsSince1900 = view.getUint32(position, true) + maxUint32 * view.getUint16(position + 4, true);
    return millisecondsSince1900 - zeroTime;
};

const getTime = (view, position) => view.getInt32(position, true) / 1000;

const getNullableTime = (view, position) => {
    const value = view.getInt32(position, true);
    return value === 2147483647
        ? undefined
        : value / 1000;
};

const ensureEventExists = personResult => {
    if (!personResult.event) {
        personResult.event = {};
    }
};

const ensureClassExists = personResult => {
    if (!personResult.cl) {
        personResult.cl = {};
    }
};

const ensurePersonExists = personResult => {
    if (!personResult.person) {
        personResult.person = {};
    }
};

const ensureOrganisationExists = personResult => {
    if (!personResult.organisation) {
        personResult.organisation = {};
    }
};
