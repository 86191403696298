export default class SubCellTemplate {
    constructor(options) {
        options = options ?? {};
        this.creator = options.creator; // function that transforms a model object to a SubCell object
    }

    createSubCell(data) {
        return this.creator(data);
    }
}
