export default class SubCell {
    constructor(options) {
        options = options ?? {};
        this.className = options.className;
        this.text = options.text;
    }

    createElement() {
        const domElement = document.createElement("div");
        if (this.className) {
            domElement.className = this.className;
        }
        domElement.innerText = this.text;

        return domElement;
    }
}