export default {
    eventPage: {
        event: undefined,
        eventNotFound: false,
        serverError: undefined,
        landingPageSelectedRaceId: undefined,
        results: undefined,
        resultsForEntry: undefined,
        resultsForAllRaces: undefined,
        list: undefined,
        loading: {
            event: false,
            results: false,
            resultsForEntry: false,
            resultsForAllRaces: false
        },
        favoriteEntryIds: undefined,
        lastResultsReceivedTime: 0,
        receivedLiveResults: [], // used for both list page and single competitor page
        lastLiveResultsReceivedTime: 0, // used for both list page and single competitor page
        receivedSampleUpdates: [], // used for both list page and single competitor page
        lastSampleUpdatesReceivedTime: 0, // used for both list page and single competitor page
        lastSampleUpdatesServerTimestamp: 0, // used for both list page and single competitor page
        sampleUpdatePollInterval: undefined,
        logItems: []
    },
    searchPage: {
        parameters: undefined
    }
};