import React from "react";
import PropTypes from "prop-types";
import { withLocalize, Translate } from "react-localize-redux";
import { formatElapsedTime, formatTimeBehind } from "../../../logic/TimeUtil";

const Result = ({ person, organisation, samples, timePresentation, matchesResultSelectionFilter }) => {
    const sample = samples[0];
    const place = sample && timePresentation ? sample.place : undefined;
    const organisationName = organisation ? organisation.name : undefined;
    const organisationCountryCode = organisation ? organisation.countryCode : undefined;
    const elapsedTime = sample && timePresentation && sample.elapsedTime ? formatElapsedTime(sample.elapsedTime) : undefined;
    const timeBehind = sample && timePresentation && sample.timeBehind ? formatTimeBehind(sample.timeBehind) : undefined;
    const sampleStatus = sample ? sample.status : undefined;
    const className = matchesResultSelectionFilter ? "matches-result-selection-filter" : undefined;

    return (
        <tr className={className}>
            <td className="place">{place}</td>
            <td className="person-name">{person.firstName} {person.lastName}</td>
            <td className="organisation-name">{organisationName}</td>
            <td className="organisation-country-code">{organisationCountryCode}</td>
            <td className="elapsed-time">{timePresentation && sampleStatus === 0 ? elapsedTime : <Translate id={`resultStatuses.${sampleStatus}`} />}</td>
            <td className="time-behind">{timeBehind}</td>
        </tr>
    );
};

Result.propTypes = {
    place: PropTypes.number,
    person: PropTypes.object.isRequired,
    organisation: PropTypes.object,
    samples: PropTypes.array.isRequired,
    timePresentation: PropTypes.bool.isRequired,
    matchesResultSelectionFilter: PropTypes.bool
};

export default withLocalize(Result);