import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typeahead, Highlighter } from "react-bootstrap-typeahead";
import { Translate, withLocalize } from "react-localize-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Formik, Form, Field } from "formik";

const EditForm = ({ event, initialValues, onSubmit, onCancel, allOrganisationFilterItems, isLoading, translate }) => {
    const classCategories = Object.values(event.classCategories);

    const [organisationSelectionModalIsOpen, setOrganisationSelectionModalIsOpen] = useState(false);
    const [organisationFilterSelectorValue, setOrganisationFilterSelectorValue] = useState(undefined);

    const handleOrganisationFilterSelectorChange = selectedItems => {
        setOrganisationFilterSelectorValue(selectedItems[0]);
    };

    const handleAddOrganisationFilterItem = () => {
        setOrganisationSelectionModalIsOpen(true);
    };

    const handleRemoveOrganisationFilterItem = (form, item) => {
        form.setFieldValue("organisationFilterItems", form.values.organisationFilterItems.filter(i => i !== item));
    };

    const handleCancel = e => {
        onCancel();
    };

    const handleOrganisationSelectionModalOk = form => {
        if (organisationFilterSelectorValue) {
            const organisationFilterItems = form.values.organisationFilterItems.filter(item => item !== organisationFilterSelectorValue);
            organisationFilterItems.push(organisationFilterSelectorValue);
            form.setFieldValue("organisationFilterItems", organisationFilterItems);
            setOrganisationFilterSelectorValue(undefined);
        }

        setOrganisationSelectionModalIsOpen(false);
    };

    const handleOrganisationSelectionModalCancel = () => {
        setOrganisationFilterSelectorValue(undefined);
        setOrganisationSelectionModalIsOpen(false);
    };

    const getOrganisationFilterItemType = item => {
        const atoms = item.id.split("_");
        switch(atoms[0]) {
            case "organisation": return translate("organisationLC");
            case "region": return translate("regionLC");
            case "country": return translate("countryLC");
            default: return "";
        }
    };

    const formIsValid = formValues => {
        const unfilteredPlaceLimit = parseInt(formValues.unfilteredPlaceLimit);
        return (classCategories.length === 0 || formValues.classCategories.filter(o => o.value).length > 0) &&
               unfilteredPlaceLimit >= 0;
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
        >
            { form => 
                (
                    <Form>
                        <div className="card card-body bg-light">
                            { 
                                classCategories && 
                                (
                                    <div className="form-group">
                                        <label><Translate id="classCategories" /></label>
                                        {
                                            classCategories.map((classCategory, i) => {
                                                const name = `classCategories[${i}].value`;
                                                return (
                                                    <div className="form-check" key={classCategory.classCategoryId}>
                                                        <Field
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id={name}
                                                            name={name}
                                                        />
                                                        <label className="form-check-label" htmlFor={name}>
                                                            <Translate>
                                                                {({ _, activeLanguage }) => classCategory.names[activeLanguage.code] }
                                                            </Translate>
                                                        </label>
                                                    </div>                    
                                                );
                                            })
                                        }
                                    </div>
                                )
                            }

                            <div className="form-group">
                                <label htmlFor="unfilteredPlaceLimit">
                                    <Translate id="media.unfilteredPlaceLimit" />
                                </label>
                                <Field
                                    type="number"
                                    className="form-control"
                                    id="unfilteredPlaceLimit"
                                    name="unfilteredPlaceLimit"
                                />
                                <small className="form-text text-muted">
                                    <Translate id="media.unfilteredPlaceLimitHelpText" />
                                </small>
                            </div>

                            <Modal id="organisation-selection-modal" isOpen={organisationSelectionModalIsOpen} onClosed={handleOrganisationSelectionModalCancel}>
                                <ModalHeader>
                                    <Translate id="media.addOrganisationSelectionFilterItem" />
                                </ModalHeader>
                                <ModalBody>
                                    <Typeahead
                                        id="organisation-selector"
                                        placeholder={translate("media.organisationSelectionPlaceholder")}
                                        highlightOnlyResult
                                        paginate={false}
                                        maxResults={1000}
                                        options={allOrganisationFilterItems}
                                        selected={organisationFilterSelectorValue ? [organisationFilterSelectorValue] : []}
                                        onChange={handleOrganisationFilterSelectorChange}
                                        clearButton
                                    />
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" onClick={() => handleOrganisationSelectionModalOk(form)}>
                                        <Translate id="ok" />
                                    </Button>
                                    <Button color="secondary" onClick={handleOrganisationSelectionModalCancel}>
                                        <Translate id="cancel" />
                                    </Button>
                                </ModalFooter>
                            </Modal>                

                            <div className="form-group">
                                <label><Translate id="media.organisationSelection"/></label>
                                {
                                    form.values.organisationFilterItems.length === 0 &&
                                    (
                                        <p>
                                            <Translate id="media.noOrganisationSelectionFilterItems" />
                                        </p>
                                    )
                                }
                                { 
                                    form.values.organisationFilterItems.length > 0 &&
                                    (
                                        <ul id="organisation-selection" className="list-unstyled">
                                            { 
                                                form.values.organisationFilterItems.map(item => 
                                                    (
                                                        <li key={item.id}>
                                                            <span className="name">
                                                                {item.text}
                                                            </span>
                                                            <span className="type">
                                                                {getOrganisationFilterItemType(item)}
                                                            </span>
                                                            <span className="button">
                                                                <Button color="secondary" onClick={() => handleRemoveOrganisationFilterItem(form, item)}>
                                                                    <Translate id="remove" />
                                                                </Button>
                                                            </span>
                                                        </li>
                                                    )
                                                )
                                            }
                                        </ul>
                                    )
                                }
                                <div>
                                    <Button id="add-organisation-selection-filter-item-button" color="secondary" onClick={handleAddOrganisationFilterItem}>
                                        <Translate id="media.addOrganisationSelectionFilterItem" />
                                    </Button>
                                </div>
                            </div>

                            {
                                form.values.organisationFilterItems.length > 0 &&
                                (
                                    <div>
                                        <div className="form-group">
                                            <label htmlFor="filteredPlaceLimit"><Translate id="media.filteredPlaceLimit" /></label>
                                            <Field
                                                type="number"
                                                className="form-control"
                                                id="filteredPlaceLimit"
                                                name="filteredPlaceLimit"
                                            />
                                            <small className="form-text text-muted">
                                                <Translate id="media.filteredPlaceLimitHelpText" />
                                            </small>
                                        </div>
                            
                                        <div className="form-group">
                                            <div className="form-check">
                                                <Field
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="onlyClassesContainingFilteredCompetitors"
                                                    name="onlyClassesContainingFilteredCompetitors"
                                                />
                                                <label className="form-check-label" htmlFor="onlyClassesContainingFilteredCompetitors">
                                                    <Translate id="media.onlyClassesContainingFilteredCompetitors" />
                                                </label>
                                            </div>                    
                                        </div>
                                    </div>
                                )
                            }
                                
                            <div className="button-container">
                                <Button type="submit" color="primary" disabled={!formIsValid(form.values) || isLoading}>
                                    <Translate id="save"/>
                                </Button>
                                {
                                    onCancel &&
                                    (
                                        <Button color="secondary" onClick={handleCancel} disabled={isLoading}>
                                            <Translate id="cancel"/>
                                        </Button>
                                    )
                                }
                            </div>
                        </div>
                    </Form>
                )
            }
        </Formik>
    );
};

EditForm.propTypes = {
    event: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
    allOrganisationFilterItems: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    translate: PropTypes.func.isRequired
};

export default withLocalize(EditForm);