import React from "react";
import PropTypes from "prop-types";
import { Translate, withLocalize } from "react-localize-redux";
import ClassButton from "../presentational/ClassButton";

const getClassesInClassCategory = (classes, classCategoryId) => classes.filter(cl => cl.classCategoryId === classCategoryId);

const ClassGrid = ({ classCategories, classes, onClassClick }) => {

    return (
        <div id="class-grid-container">
            {
                classCategories.map(cc => 
                    (
                        <div key={cc.classCategoryId} className="class-category-grid-container">
                            <h2>
                                <Translate>
                                    {({ _, activeLanguage }) => cc.names[activeLanguage.code] }
                                </Translate>
                            </h2>
                            <div className="class-category-grid">
                                {
                                    getClassesInClassCategory(classes, cc.classCategoryId)
                                        .map(cl => <ClassButton key={cl.classId} cl={cl} onClick={onClassClick} />)
                                }
                            </div>
                        </div>
                    )
                )
            }
        </div>
    );
};

ClassGrid.propTypes = {
    classCategories: PropTypes.array,
    classes: PropTypes.array,
    onClassClick: PropTypes.func.isRequired
};

export default withLocalize(ClassGrid);
