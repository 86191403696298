const strings = {
    startAbbreviated: "S",
    finishAbbreviated: "M",
    placeAbbreviated: "Plac",
    name: "Namn",
    organisation: "Klubb",
    time: "Tid",
    timeLoss: "Bommad tid"
};

export default strings;