export default class OrganisationJsonReader {
    read(json) {
        const organisation = {};
        organisation.organisationId = json.i;
        organisation.organisationExternalId = json.ei;
        organisation.name = json.n;
        organisation.countryCode = json.c;
        return organisation;
    }
}
