export const getIntermediateControls = (cl, raceId) => {
    const raceClass = cl.raceClasses[raceId];
    return raceClass?.intermediateControls ?? [];
};

export const getIntermediateControlByName = (cl, raceId, controlName) => {
    if(!cl || !raceId || !controlName) {
        return undefined;
    }
    return getIntermediateControls(cl, raceId).find(o => o.controlName === controlName);
};

export const getIntermediateControlLabel = (intermediateControl, intermediateControls, translate, lowercase) => {
    if(intermediateControl?.displayName) {
        return intermediateControl.displayName;
    }
    const lcControlName = intermediateControl?.controlName.toLowerCase();
    if(!lcControlName) {
        return undefined;
    }

    let label;
    if(isRadio(lcControlName)) {
        let radioControlNumber = 0;
        for(let i = 0; i < intermediateControls.length; i++) {
            if(isRadio(getActualIntermediateControlName(intermediateControls[i]).toLowerCase())) {
                radioControlNumber++;
            }
            if(intermediateControls[i].sequence === intermediateControl.sequence) {
                label = translate("eventPage.radioX", { number: radioControlNumber });
                break;
            }
        }
    } else if(isTv(lcControlName)) {
        let tvControlNumber = 0;
        for(let i = 0; i < intermediateControls.length; i++) {
            if(isTv(getActualIntermediateControlName(intermediateControls[i]).toLowerCase())) {
                tvControlNumber++;
            }
            if(intermediateControls[i].sequence === intermediateControl.sequence) {
                label = translate("eventPage.tvX", { number: tvControlNumber });
                break;
            }
        }
    } else if(isPrewarning(lcControlName)) {
        label = translate("eventPage.prewarning");
    } else if(isLastControl(lcControlName)) {
        label = translate("eventPage.lastControl");
    } else if(isArenaPassage(lcControlName)) {
        label = translate("eventPage.arenaPassingControl");
    }

    label = lowercase
        ? label?.toLowerCase()
        : label;
    return label;
};

export const isFirstRace = (cl, race, event) => {
    if(!cl || !race) {
        return false;
    }
    let isFirst = true;
    Object.values(cl.raceClasses).forEach(raceClass => {
        let raceNumber = event.races[raceClass.raceId].raceNumber;
        if(raceNumber < race.raceNumber) {
            isFirst = false;
        }
    });
    return isFirst;
};

export const getCourseLength = (cl, race) => {
    const raceClass = Object.values(cl.raceClasses).filter(rc => rc.raceId === race?.raceId)[0];
    return  raceClass?.minCourseLength;
};

export const getActualIntermediateControlName = intermediateControl => intermediateControl?.displayName ?? intermediateControl?.controlName;

// kind of fragile to depend on naming
const isTv = lcControlName => lcControlName.indexOf("tv") !== -1;
const isRadio = lcControlName => lcControlName.indexOf("radio") !== -1 || lcControlName.indexOf("intermediate") !== -1;
const isPrewarning = lcControlName => lcControlName.indexOf("förvarning") !== -1 || lcControlName.indexOf("prewarning") !== -1;
const isLastControl = lcControlName => lcControlName.indexOf("sista") !== -1 || lcControlName.indexOf("last") !== -1;
const isArenaPassage = lcControlName => lcControlName.indexOf("varvning") !== -1;
