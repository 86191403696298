// event page

export const REQUEST_EVENT = "REQUEST_EVENT";
export const RECEIVE_EVENT_SUCCESS = "RECEIVE_EVENT_SUCCESS";
export const RECEIVE_EVENT_FAILURE = "RECEIVE_EVENT_FAILURE";

export const REQUEST_RESULTS = "REQUEST_RESULTS";
export const RECEIVE_RESULTS_SUCCESS = "RECEIVE_RESULTS_SUCCESS";
export const RECEIVE_RESULTS_FAILURE = "RECEIVE_RESULTS_FAILURE";

export const SET_LIST = "SET_LIST";

export const REQUEST_RESULTS_FOR_ENTRY = "REQUEST_RESULTS_FOR_ENTRY";
export const RECEIVE_RESULTS_FOR_ENTRY_SUCCESS = "RECEIVE_RESULTS_FOR_ENTRY_SUCCESS";
export const RECEIVE_RESULTS_FOR_ENTRY_FAILURE = "RECEIVE_RESULTS_FOR_ENTRY_FAILURE";

export const REQUEST_RESULTS_FOR_ALL_RACES = "REQUEST_RESULTS_FOR_ALL_RACES";
export const RECEIVE_RESULTS_FOR_ALL_RACES_SUCCESS = "RECEIVE_RESULTS_FOR_ALL_RACES_SUCCESS";
export const RECEIVE_RESULTS_FOR_ALL_RACES_FAILURE = "RECEIVE_RESULTS_FOR_ALL_RACES_FAILURE";

export const REQUEST_SAMPLE_UPDATES_FOR_LIST = "REQUEST_SAMPLE_UPDATES_FOR_LIST";
export const RECEIVE_SAMPLE_UPDATES_FOR_LIST_SUCCESS = "RECEIVE_SAMPLE_UPDATES_FOR_LIST_SUCCESS";
export const RECEIVE_SAMPLE_UPDATES_FOR_LIST_FAILURE = "RECEIVE_SAMPLE_UPDATES_FOR_LIST_FAILURE";

export const REQUEST_SAMPLE_UPDATES_FOR_ENTRY = "REQUEST_SAMPLE_UPDATES_FOR_ENTRY";
export const RECEIVE_SAMPLE_UPDATES_FOR_ENTRY_SUCCESS = "RECEIVE_SAMPLE_UPDATES_FOR_ENTRY_SUCCESS";
export const RECEIVE_SAMPLE_UPDATES_FOR_ENTRY_FAILURE = "RECEIVE_SAMPLE_UPDATES_FOR_ENTRY_FAILURE";

export const SET_RESULTS_FOR_ENTRY = "SET_RESULTS_FOR_ENTRY";

export const SET_RESULTS_FOR_ALL_RACES = "SET_RESULTS_FOR_ALL_RACES";

export const FAVORITES_CHANGED = "FAVORITES_CHANGED";

export const RECEIVE_LIVE_RESULTS_SUCCESS = "RECEIVE_LIVE_RESULTS_SUCCESS";

export const UPDATE_LANDING_PAGE_SELECTED_RACE_ID = "UPDATE_LANDING_PAGE_SELECTED_RACE_ID";

export const BROWSER_WINDOW_VISIBILITY_CHANGED = "BROWSER_WINDOW_VISIBILITY_CHANGED";

export const WRITE_LOG_ITEM = "WRITE_LOG_ITEM";

export const CLEAR_EVENT_CACHE = "CLEAR_EVENT_CACHE";
export const CLEAR_EVENT_CACHE_SUCCESS = "CLEAR_EVENT_CACHE_SUCCESS";
export const CLEAR_EVENT_CACHE_FAILURE = "CLEAR_EVENT_CACHE_FAILURE";

export const FAVORITE_SUBSCRIPTION_CREATED = "FAVORITE_SUBSCRIPTION_CREATED";
export const FAVORITE_SUBSCRIPTION_DELETED = "FAVORITE_SUBSCRIPTION_DELETED";

// search page

export const SEARCH_REQUEST_RESULTS = "SEARCH_REQUEST_RESULTS";
export const SEARCH_RECEIVE_RESULTS_SUCCESS = "SEARCH_RECEIVE_RESULTS_SUCCESS";
export const SEARCH_RECEIVE_RESULTS_FAILURE = "SEARCH_RECEIVE_RESULTS_FAILURE";
export const SEARCH_RESET_RESULTS = "SEARCH_RESET_RESULTS";