import React from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import ListLink from "./ListLink";
import { isFutureRace } from "../../../logic/EventUtil";
import { getUrlForList, getDefaultOrderByForListType } from "../../../logic/ListUtil";
import ListType from "../../../logic/models/ListType";

const RaceCell = ({ event, result, onClick, translate }) => {
    const race = event.races[result.raceId];
    const text = translate("raceX", { raceNumber: race.raceNumber });
    const listType = isFutureRace(event, race)
        ? ListType.startList
        : ListType.raceResultList;
    const list = {
        raceId: result.raceId,
        classIds: [result.cl.classId],
        listType: listType,
        orderBy: getDefaultOrderByForListType(event, result.raceId, listType),
        direction: 1 
    };
    const raceUrlResult = getUrlForList(event, list);

    return (    
        <div className="race">
            {
                <ListLink
                    url={raceUrlResult.url}
                    isExternal={raceUrlResult.isExternal}
                    onClick={onClick}
                >
                    {text}
                </ListLink>
            }
        </div>
    );
};

RaceCell.propTypes = {
    event: PropTypes.object.isRequired,
    result: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    translate: PropTypes.func.isRequired
};     

export default withLocalize(RaceCell);