import React, { useEffect, useState } from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { Button, Collapse, List } from "reactstrap";
import Api from "../../../logic/Api";


const SelectEvent = () => {
    const [events, setEvents] = useState(undefined);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        Api.getEvents(
            (eventsFromApi) => {
                setEvents(eventsFromApi);
            },
            () => {}
        );
    }, []);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <>
            <h1>
                <Translate id="selectEvent.title" />
            </h1>

            <Button color="primary" onClick={toggle}>
                <Translate id="selectEvent.buttonText" />
            </Button>

            <Collapse isOpen={isOpen}>
                {
                    events &&
                    (
                        <List className="event-list" type="unstyled">
                            {
                                events.map(event => (
                                    <li key={event.eventId}>
                                        <a href={`/${event.slug}`}>
                                            {event.name}
                                        </a>
                                    </li>
                                ))
                            }
                        </List>
                    )
                }
            </Collapse>
        </>
    );
};

export default withLocalize(SelectEvent);
