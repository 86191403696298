/*
structure:
- classMappings: array
  - raceNumber: number or undefined
  - classNameRegex: string or undefined
  - winSplitsEventName: string

example: 
{
    classMappings: [
        { raceNumber: 1, classNameRegex: "^MtbO", winSplitsEventName: "O-Ringen etapp 1, MtbO" },
        { raceNumber: 1, winSplitsEventName: "O-Ringen etapp 1" }
    ]
}

WinSplits Online endpoint:
http://obasen.orientering.se/winsplits/online/sv/default.asp?eventDate={winSplitsEventStartDate}&eventName={winSplitsEventName}&categoryName={className}
*/

import { formatDate } from "../TimeUtil";

export default class WinSplitsManager {
    isEnabledForEvent(event) {
        return event && event.properties && event.properties.winSplitsOptions;
    }

    getRedirectUrl(event, race, cl, language) {
        if (!this.isEnabledForEvent(event) ||
            !race ||
            !cl) {
            return undefined;
        }

        const classMappings = (event.properties.winSplitsOptions.classMappings || []);

        for (let i=0; i<classMappings.length; i++) {
            const classMapping = classMappings[i];
            const classNameMatches = 
                !classMapping.classNameRegex || 
                new RegExp(classMapping.classNameRegex, "i").test(cl.name);
            const raceNumberMatches = classMapping.raceNumber === race.raceNumber;

            if (classNameMatches && raceNumberMatches) {
                if(!classMapping.winSplitsEventName) {
                    return undefined;
                }
                const eventDate = formatDate(race.startTime, event.timeZone);
                const winSplitsLanguage = getWinSplitsLanguage(language.code);
                return `http://obasen.orientering.se/winsplits/online/${winSplitsLanguage}/default.asp?eventDate=${encodeURIComponent(eventDate)}&eventName=${encodeURIComponent(classMapping.winSplitsEventName)}&categoryName=${encodeURIComponent(cl.name)}`;
            }
        }

        return undefined;
    }
}

const getWinSplitsLanguage = language => {
    switch (language) {
        case "sv": return "sv";
        default: return "en";
    }
};