import PersonResultJsonReader from "./PersonResultJsonReader";
import RaceClassJsonReader from "./RaceClassJsonReader";
import { parseDateString } from "./DateHelpers";

const personResultJsonReader = new PersonResultJsonReader();
const raceClassJsonReader = new RaceClassJsonReader();

export default class SplitTimesResponseJsonReader {
    read(json) {
        const splitTimesResponse = {};
        splitTimesResponse.raceClasses = json.rc.map(rc => raceClassJsonReader.read(rc));
        splitTimesResponse.results = personResultJsonReader.read(json.r);
        splitTimesResponse.splitTimesHiddenUntil = parseDateString(json.sh);
        return splitTimesResponse;
    }
}