import React from "react";
import PropTypes from "prop-types";
import { withLocalize, Translate } from "react-localize-redux";
import StartListItem from "./StartListItem";

const ClassStartList = ({ cl, results, numberOfCompetitors, translate }) => {
    const competitorsString = numberOfCompetitors === 1
        ? translate("media.competitor")
        : translate("media.competitors");

    return (
        <div className="class-list class-start-list">
            <div className="class-list-header class-start-list-header">
                <h2>{cl.name}</h2>
                <span className="number-of-competitors">{numberOfCompetitors} {competitorsString}</span>
            </div>

            <table>
                <thead>
                    <tr>
                        <th className="person-name"><span><Translate id="name"/></span></th>
                        <th className="organisation-name"><span><Translate id="organisation"/></span></th>
                        <th className="organisation-country-code"><span><Translate id="country"/></span></th>
                        <th className="start-time"><span><Translate id="startTime"/></span></th>
                    </tr>
                </thead>
                <tbody>
                    {results.map(result => <StartListItem key={result.resultId} {...result} />)}
                </tbody>
            </table>
        </div>
    );
};

ClassStartList.propTypes = {
    cl: PropTypes.object.isRequired,
    results: PropTypes.array.isRequired,
    numberOfCompetitors: PropTypes.number.isRequired,
    translate: PropTypes.func
};

export default withLocalize(ClassStartList);