import React from "react";
import PropTypes from "prop-types";
import { withLocalize, Translate } from "react-localize-redux";

const ResultListTypeButton = ({ resultListType, selected, onSetResultListType }) => {
    const handleSetResultListType = () => {
        onSetResultListType(resultListType);
    };

    const icon = selected
        ?
        (
            <svg x="0px" y="0px" viewBox="0 0 512 512">
                <g>
                    <path d="M256,128c-70.4,0-128,57.6-128,128s57.6,128,128,128s128-57.6,128-128S326.4,128,256,128z M256,0
                        C114.3,0,0,114.3,0,256s114.3,256,256,256s256-114.3,256-256S397.7,0,256,0z M256,460.8c-113.4,0-204.8-91.4-204.8-204.8
                        S142.6,51.2,256,51.2S460.8,142.6,460.8,256S369.4,460.8,256,460.8z"/>
                </g>
            </svg>
        )
        :
        ( 
            <svg x="0px" y="0px" viewBox="0 0 512 512">
                <g>
                    <path d="M256,0C114.68,0,0,114.68,0,256c0,141.3,114.68,256,256,256c141.3,0,256-114.7,256-256
                        C512,114.68,397.3,0,256,0z M256,460.8c-113.15,0-204.8-91.65-204.8-204.8S142.85,51.2,256,51.2S460.8,142.85,460.8,256
                        S369.15,460.8,256,460.8z"/>
                </g>
            </svg>
        );

    return (
        <button onClick={handleSetResultListType} className="btn">
            <span className="result-list-type-button-container">
                <span className={`result-list-type-button-icon ${selected ? "selected" : "not-selected"}`}>
                    {icon}
                </span>
                <span className="wide">
                    <Translate id={`kiosk.header.setResultListType.${resultListType}`} />
                </span>
                <span className="narrow">
                    <Translate id={`kiosk.header.setResultListType_abbreviated.${resultListType}`} />
                </span>
            </span>
        </button>
    );
};

ResultListTypeButton.propTypes = {
    resultListType: PropTypes.number.isRequired,
    selected: PropTypes.bool.isRequired,
    onSetResultListType: PropTypes.func.isRequired
};
    
export default withLocalize(ResultListTypeButton);
