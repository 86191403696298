import {
    JsonHubProtocol,
    HttpTransportType,
    HubConnectionBuilder,
    LogLevel
} from "@microsoft/signalr";
  
import * as types from "../actions/actionTypes";
import * as eventActions from "../actions/eventActions";
import PersonResultJsonReader from "../logic/readers/PersonResultJsonReader";
import PersonResultBinaryReader from "../logic/readers/PersonResultBinaryReader";
import settings from "../settings";
import { isLive } from "../logic/EventUtil";
import { getCurrentTime } from "../logic/BrowserUtil";
import queryString from "query-string";

let _event;
let _sampleUpdatePollInterval; // in milliseconds
let _subscription;
let _dispatch;

// SignalR-specific variables
let _signalRConnection;
let _isConnectedToSignalR = false;
let _isConnectingToSignalR = false;
let _currentSignalRGroups = [];
let _groupsToSubscribeTo = [];
let _groupsToSubscribeToWhenConnectedToSignalR;
const _retryTimeInterval = 5000;

// poll-specific variables
let _getRecentSampleUpdatesTimer;


// Binary protocol seems to be more lengthy than JSON, so explicitly setting to false in this case
//const useBinary = PersonResultBinaryReader.isSupported();
const useBinary = false; 

const jsonReader = new PersonResultJsonReader();
const binaryReader = new PersonResultBinaryReader();

const liveUpdateMiddleware = ({ dispatch }) => next => (action) => {
    if (action.type === types.RECEIVE_EVENT_SUCCESS) {
        setEvent({ event: action.event, dispatch });
    }

    if (action.type === types.RECEIVE_RESULTS_SUCCESS && isLive(_event)) {
        setupListSubscription({ list: action.list, dispatch });
    }
    
    if (action.type === types.RECEIVE_RESULTS_FOR_ENTRY_SUCCESS && isLive(_event)) {
        setupEntrySubscription({ entryId: action.entryId, dispatch });
    }

    if (action.type === types.BROWSER_WINDOW_VISIBILITY_CHANGED) {
        handleBrowserWindowVisibilityChanged({ visible: action.visible, dispatch});
    }

    if (action.type === types.RECEIVE_SAMPLE_UPDATES_FOR_LIST_SUCCESS || 
        action.type === types.RECEIVE_SAMPLE_UPDATES_FOR_LIST_FAILURE ||
        action.type === types.RECEIVE_SAMPLE_UPDATES_FOR_ENTRY_SUCCESS ||
        action.type === types.RECEIVE_SAMPLE_UPDATES_FOR_ENTRY_FAILURE) {
        if (action.sampleUpdateResponse) {
            _sampleUpdatePollInterval = action.sampleUpdateResponse.pollInterval * 1000;
        }
        if(_subscription.list) {
            setupRecentSampleUpdatesTimerForList({ list: _subscription.list, dispatch });
        } else {
            setupRecentSampleUpdatesTimerForEntry({ entryId: _subscription.entryId, dispatch });
        }
    }

    return next(action);
};

const setEvent = ({ event, dispatch }) => {
    // TODO: review this code with regard to switching back and forth between events
    _event = event;

    let sampleUpdatePollInterval = event.properties.sampleUpdatePollInterval;
    sampleUpdatePollInterval = isNaN(sampleUpdatePollInterval)
        ? 15
        : parseFloat(sampleUpdatePollInterval);        
    _sampleUpdatePollInterval = sampleUpdatePollInterval * 1000;

    if(_event.properties.signalRPushEnabled) {
        if (eventUsesMemoryDataAccess(_event)) {
            setupSignalRConnection(dispatch);
        } else {
            stopSignalRConnection(_signalRConnection);
        }
    }
};

// called only once
const setupSignalRConnection = dispatch => {
    _signalRConnection = new HubConnectionBuilder()
        .withUrl(settings.signalRUrl)
        .configureLogging(LogLevel.Error)
        .withAutomaticReconnect()
        .build();

    // separate methods for binary and json
    _signalRConnection.on("BinaryResultsUpdated", data => handleBinaryResultsUpdated(data, dispatch));
    _signalRConnection.on("JsonResultsUpdated", json => handleJsonResultsUpdated(json, dispatch));
    _signalRConnection.onclose(handleSignalRConnectionClosed);

    _dispatch = dispatch;

    startSignalRConnection();
};

// called every time a connection should be activated (e.g. when page is loaded or tab becomes active)
const startSignalRConnection = () => {
    if(!_signalRConnection) {
        return;
    }

    _isConnectedToSignalR = false;
    _isConnectingToSignalR = true;
    log("SignalR connecting...");
    _signalRConnection.start()
        .then(() => {
            log("SignalR connected.");
            if(_groupsToSubscribeToWhenConnectedToSignalR) {
                subscribeToSignalRGroups(_groupsToSubscribeToWhenConnectedToSignalR);
            }
            _isConnectedToSignalR = true;
            _isConnectingToSignalR = false;
        })
        .catch(error => {
            // eslint-disable-next-line no-console
            console.error("SignalR connection failed.", error);
            window.setTimeout(startSignalRConnection, _retryTimeInterval);
        });
};

// called every time a connection should be inactivated (e.g. tab becomes inactive)
const stopSignalRConnection = () => {
    if (_isConnectedToSignalR || _isConnectingToSignalR) {
        log("SignalR disconnecting...");
        _currentSignalRGroups = [];
        _groupsToSubscribeTo = [];
        _groupsToSubscribeToWhenConnectedToSignalR = undefined;
        _signalRConnection.stop()
            .then(() => {
                log("SignalR disconnected.");
                _isConnectedToSignalR = false;
                _isConnectingToSignalR = false;
            }).
            catch(error => {
                // eslint-disable-next-line no-console
                console.error("SignalR disconnection failed.", error);
            });
    }
};

const handleBinaryResultsUpdated = (data, dispatch) => {
    // binary support not yet implemented
    try {
        // in binary case we need to decode base64 to ArrayBuffer
        //var decoded = base64_arraybuffer.decode(data);
        //$("#data").append($("<div/>").text(data));
        //console.log("BinaryResultsUpdated");
    } catch(e) {
        //console.log("BinaryResultsUpdated: FAILURE");
    }
};

const handleJsonResultsUpdated = (json, dispatch) => {
    try {
        const results = jsonReader.read(json, true /* includeDeleted  */);
        dispatch(eventActions.receiveLiveResultsSuccess(results));
        log("JsonResultsUpdated", results);
    } catch(error) {
        // eslint-disable-next-line no-console        
        console.error("JsonResultsUpdated: FAILURE", error);
    }
};

const handleSignalRConnectionClosed = error => {
    // disconnection can be caused by 
    // - deliberate application code (e.g. when making browser tab inactive) - do not start connection
    // - network connectivity problems - try to re-establish the connection
    log("SignalR closed.");
    if(error) {
        window.setTimeout(startSignalRConnection, _retryTimeInterval);
    }
};

const subscribeToSignalRGroups = groups => {
    // if multiple subscription requests are emitted in a short period of time, we need to keep track of what the last request is
    _groupsToSubscribeTo = groups.map(o => o);
    
    groups.forEach(group => {
        log("Calling SubscribeToGroup...", group);
        _signalRConnection
            .invoke("SubscribeToGroup", group)
            .then(o => {
                const groupWasFound = _groupsToSubscribeTo.indexOf(group) !== -1;
                if (groupWasFound) {
                    log("SubscribeToGroup completed.", group);
                    _currentSignalRGroups.push(group);
                } else {
                    log("SubscribeToGroup completed, but group was not found. Probably a response for an outdated request.", group);
                    unsubscribeFromSignalRGroups([group]);
                }
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.error("SubscribeToGroup failed.", {group, error});
            });
    });
};

const unsubscribeFromSignalRGroups = (groups, stopSignalRConnectionWhenCompleted) => {
    let count = 0;
    const disconnectWhenCompleted = currentCount => {
        if (currentCount === groups.length && stopSignalRConnectionWhenCompleted) {
            stopSignalRConnection();
        }
    };
    groups.forEach(group => {
        log("Calling UnsubscribeFromGroup...", group);
        _signalRConnection
            .invoke("UnsubscribeFromGroup", group)
            .then(o => {
                log("UnsubscribeFromGroup completed.", group);
                disconnectWhenCompleted(++count);
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.error("UnsubscribeFromGroup failed.", {group, error});
                disconnectWhenCompleted(++count);
            });
    });
    // to handle the case when there are no groups
    disconnectWhenCompleted(0);
};

const eventUsesMemoryDataAccess = event => {
    const now = new Date().getTime();
    return event.useMemoryDataAccessFrom && 
           event.useMemoryDataAccessTo &&
           event.useMemoryDataAccessFrom <= now &&
           event.useMemoryDataAccessTo >= now;
};

export const setupListSubscription = ({ list, triggerResultUpdate, dispatch }) => {
    unsubscribeFromSignalRGroups(_currentSignalRGroups);

    if (list) {
        if (triggerResultUpdate) {
            dispatch(eventActions.fetchResults(list, true /* treatAsLiveResults */));
        }

        const groups = getSignalRGroupNamesForList(_event.eventId, list, useBinary);

        if(_isConnectedToSignalR) {
            subscribeToSignalRGroups(groups);
        } else {
            _groupsToSubscribeToWhenConnectedToSignalR = groups;
        }

        setupRecentSampleUpdatesTimerForList({ list, dispatch });

        _subscription = { list };
    }
};

export const setupEntrySubscription = ({ entryId, triggerResultUpdate, dispatch }) => {
    unsubscribeFromSignalRGroups(_currentSignalRGroups);

    if (triggerResultUpdate) {
        dispatch(eventActions.fetchResultsForEntry(entryId, true /* treatAsLiveResults */));
    }

    const groups = [
        getSignalRGroupNameForEntry(_event.eventId, entryId, useBinary)
    ];

    if(_isConnectedToSignalR) {
        subscribeToSignalRGroups(groups);
        _currentSignalRGroups = groups;
    } else {
        _groupsToSubscribeToWhenConnectedToSignalR = groups;
    }

    setupRecentSampleUpdatesTimerForEntry({ entryId, dispatch });

    _subscription = { entryId };
};

const handleBrowserWindowVisibilityChanged = ({ visible, dispatch }) => {
    if (visible) {
        if (isLive(_event)) {
            if (!_isConnectedToSignalR && !_isConnectingToSignalR) {
                startSignalRConnection();
            }
            if (_subscription) {
                if (_subscription.list) {
                    setupListSubscription({ list: _subscription.list, triggerResultUpdate: true, dispatch });
                } else if(_subscription.entryId) {
                    setupEntrySubscription({ entryId: _subscription.entryId, triggerResultUpdate: true, dispatch });
                }
            }
        }
    
    } else {

        if (_isConnectedToSignalR) {
            unsubscribeFromSignalRGroups(_currentSignalRGroups, true /* stopSignalRConnectionWhenCompleted */);
        }
        destroyGetRecentSampleUpdatesTimer();
    }
};

const getSignalRGroupNameForEntry = (eventId, entryId, useBinary) => {
    const suffix = useBinary ? "Binary" : "Json";
    return `Entry_${eventId}_${entryId}_${suffix}`;
};

 const getSignalRGroupNamesForList = (eventId, list, useBinary) => {
    const suffix = useBinary ? "Binary" : "Json";
    
    if(list.classIds) {
        return list.classIds.map(classId => `Class_${eventId}_${classId}_${suffix}`);
    }
    if(list.organisationKey) {
        return [`OrganisationKey_${eventId}_${list.organisationKey}_${suffix}`];
    }
    if(list.entryIds) {
        return list.entryIds.map(entryId => `Entry_${eventId}_${entryId}_${suffix}`);
    }
    if(list.tag) {
        return [`Tag_${eventId}_${list.tag}_${suffix}`];
    }
    return [];
};

const setupRecentSampleUpdatesTimerForList = ({list, dispatch}) => {
    setupGetRecentSampleUpdatesTimer(() => dispatch(eventActions.fetchSampleUpdatesForList(list)));
};

const setupRecentSampleUpdatesTimerForEntry = ({entryId, dispatch}) => {
    setupGetRecentSampleUpdatesTimer(() => dispatch(eventActions.fetchSampleUpdatesForEntry(entryId)));
};

const setupGetRecentSampleUpdatesTimer = (callback) => {
    destroyGetRecentSampleUpdatesTimer();
    if(_sampleUpdatePollInterval > 0) {
        _getRecentSampleUpdatesTimer = window.setTimeout(callback, _sampleUpdatePollInterval);
    }
};

const destroyGetRecentSampleUpdatesTimer = () => {
    if(_getRecentSampleUpdatesTimer) {
        window.clearInterval(_getRecentSampleUpdatesTimer);
        _getRecentSampleUpdatesTimer = undefined;
    }
};

const log = (message, data) => {
    const urlParams = queryString.parse(window.location.search);
    const showLog = urlParams.showLog;
    if(showLog) {
        _dispatch(eventActions.writeLogItem({
            time: getCurrentTime(),
            text: message,
            data: data
        }));
    }
};

export default liveUpdateMiddleware;