import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const Dropdown = ({ text, type = "link", id, containerClassName, toggleClassName, align, children }) => {
    let menuClassName = "dropdown-menu";
    if(align === "right") {
        menuClassName += " dropdown-menu-right";
    }

    const isButton = type === "button";
    const toggleId = `${id}-text`;

    return (
        <div className={`dropdown ${containerClassName}`} id={id}>
            {
                isButton &&
                (
                    <button className={`btn btn-primary dropdown-toggle ${toggleClassName}`} type="button" id={toggleId} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="dropdown-text-container">
                            {text}
                        </span>
                    </button>
                )
            }
            {
                !isButton &&
                (
                    <a className={`dropdown-toggle ${toggleClassName}`} href="#" id={toggleId} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="dropdown-text-container">
                            {text}
                        </span>
                    </a>
                )
            }
            <div className={menuClassName} aria-labelledby={id}>
                {children}
            </div>
        </div>
    );
};

Dropdown.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]).isRequired,
    type: PropTypes.string,
    id: PropTypes.string.isRequired,
    containerClassName: PropTypes.string,
    toggleClassName: PropTypes.string,
    align: PropTypes.string,
    onMenuVisible: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
};

export default Dropdown;