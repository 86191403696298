import { createStore, applyMiddleware, compose } from "redux";
import liveUpdateMiddleware from "./middleware/liveUpdateMiddleware";
import createRootReducer from "./reducers/rootReducer";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunkMiddleware from "redux-thunk";

let store;

export const configureStore = initialState => {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    store = createStore(
        createRootReducer(),
        initialState, 
        composeEnhancers(applyMiddleware(
            thunkMiddleware,
            liveUpdateMiddleware,
            reduxImmutableStateInvariant()
        )));

    return store;
};

export const getStore = () => store; 