import { getUrlForList, setHighlightedResultId } from "../../logic/ListUtil";

const createClassCell = ({ result, event, list }) => {
    const urlResult = getUrlForList(
        event, 
        { ...list, classIds: [result.cl.classId], organisationKey: undefined, entryIds: undefined, tag: undefined }
    );

    const div = document.createElement("div");
    div.className = "class";
    const a = document.createElement("a");
    a.setAttribute("href", urlResult.url);
    if(urlResult.isExternal) {
        a.setAttribute("target", "_blank");
    } else {
        a.addEventListener("click", () => setHighlightedResultId(result.resultId));
    }
    a.innerText = result.cl.name;
    div.appendChild(a);
    return div;
};

export default createClassCell;