import moment from "moment";
import "../lib/moment-timezone/moment-timezone";

export const formatElapsedTime = seconds => {
    if (seconds === undefined) {
        return "";
    }

    const h = Math.floor(seconds / 3600),
        m = Math.floor(seconds / 60) % 60,
        s = Math.floor(seconds % 60);

    if (h > 0) {
        return h + ":" +
            (m < 10 ? "0" : "") + m + ":" + 
            (s < 10 ? "0" : "") + s;
    }

    return m + ":" +
        (s < 10 ? "0" : "") + s;
};

export const formatTimeBehind = seconds => {
    if (seconds === undefined) {
        return "";
    }
    const sign = seconds < 0 ? "-" : "+";

    return sign + formatElapsedTime(Math.abs(seconds));
};

const timeFormat = "HH:mm";
const timeFormatWithSeconds = "HH:mm:ss";

export const formatClockTime = (secondsSinceEpoch, timeZone, includeSeconds = true) => {
    if (!secondsSinceEpoch) {
        return "";
    }

    const m = timeZone
        ? moment(secondsSinceEpoch).tz(timeZone)
        : moment(secondsSinceEpoch);
    return m 
        ? m.format(includeSeconds ? timeFormatWithSeconds : timeFormat)
        : "";
};

export const startOfDay = (time, timeZone) =>
    1000 * moment(time).tz(timeZone).startOf("day").utc().unix();

export const formatDate = (secondsSinceEpoch, timeZone, formatString = "YYYY-MM-DD") => {
    if (!secondsSinceEpoch) {
        return "";
    }

    const m = timeZone
        ? moment(secondsSinceEpoch).tz(timeZone)
        : moment(secondsSinceEpoch);
    return m
        ? m.format(formatString)
        : "";
};
