import AccumulationType from "../model/AccumulationType";
import TimeType from "../model/TimeType";
import { roundTime } from "./Util";

class TimeLossCalculator {
    calculateTimeLoss(competitors, controls, options) {
        // options
        //   timeLossPercentile: number
        //   timeLossCondition
        //     time: number
        //     percent: number
        //     both: boolean
        //   timeAccuracy: TimeAccuracy

        if (!competitors.length) {
            return;
        }

        let { timeLossPercentile, timeLossCondition, timeAccuracy } = (options ?? {});

        // if option values are not specified, set defaults
        if (timeLossPercentile === undefined) {
            timeLossPercentile = 0.5;
        }
        timeLossCondition = timeLossCondition || {
            time: 15,
            percent: 0.10,
            both: true
        };
        timeLossCondition.both = !!timeLossCondition.both;
        timeAccuracy = timeAccuracy || 1;

        validatePreconditions(competitors, controls, timeLossPercentile, timeLossCondition, timeAccuracy);

        const normalizedLengths = controls.map(c => (c.normalizedLengths ?? {})[AccumulationType.leg]);

        competitors.forEach(competitor => {
            let j;

            // calculate median leg performance index
            let performanceIndices = [];
            competitor.courseSplitTimes.forEach((st, i) => {
                const performanceIndex = st.performanceIndices[AccumulationType.leg];
                if (performanceIndex) {
                    performanceIndices.push({
                        legIndex: i,
                        performanceIndex
                    });
                }
            });
            let medianPerformanceIndex = calculateMedianPerformanceIndex(performanceIndices, normalizedLengths, timeLossPercentile);

            // check whether time loss has occured
            const timeLosses = [];
            competitor.numberOfTimeLossLegs = 0;
            for (j=1; j < controls.length; j++) {
                timeLosses[j] = 0;
                if (medianPerformanceIndex > 0 && competitor.courseSplitTimes[j].isValid[TimeType.leg]) {
                    const estimatedTime = controls[j].topTimes[AccumulationType.leg] / medianPerformanceIndex;
                    const legTime = competitor.courseSplitTimes[j].times[TimeType.leg];
                    const bTime = (legTime - estimatedTime) > timeLossCondition.time;
                    const bPercent = ((legTime - estimatedTime) / estimatedTime) > timeLossCondition.percent;
                    if ((bTime && bPercent && timeLossCondition.both) || ((bTime || bPercent) && !timeLossCondition.both)) {
                        timeLosses[j] = -1; // indicates that time loss has occured on this leg
                        competitor.numberOfTimeLossLegs++;
                    }
                }
            }

            // calculate time loss
            performanceIndices = [];
            let validLegCount = 0;
            for (j=1; j < controls.length; j++) {
                if (timeLosses[j] === 0 && competitor.courseSplitTimes[j].isValid[TimeType.leg]) {
                    performanceIndices[validLegCount] = {
                        legIndex: j,
                        performanceIndex: competitor.courseSplitTimes[j].performanceIndices[AccumulationType.leg]
                    };
                    validLegCount++;
                }
            }
            medianPerformanceIndex = calculateMedianPerformanceIndex(performanceIndices, normalizedLengths, timeLossPercentile) || 1;

            competitor.courseSplitTimes[0].timeLosses = {
                [AccumulationType.leg]: undefined,
                [AccumulationType.sinceStart]: 0
            };
            let totalTimeLoss = 0;
            for (j=1; j < controls.length; j++) {
                if (timeLosses[j] !== 0) {
                    // calculate time loss
                    timeLosses[j] = roundTime(competitor.courseSplitTimes[j].times[TimeType.leg] - controls[j].topTimes[AccumulationType.leg] / medianPerformanceIndex, timeAccuracy);
                    if (timeLosses[j] < 0) {
                        timeLosses[j] = 0; // prevent negative time losses
                    }
                }
                totalTimeLoss += timeLosses[j];
                competitor.courseSplitTimes[j].timeLosses = {
                    [AccumulationType.leg]: timeLosses[j],
                    [AccumulationType.sinceStart]: totalTimeLoss
                };
            }

            // calculate percentual time loss
            let validTimeSum = 0;
            for (j=1; j < controls.length; j++) {
                if (competitor.courseSplitTimes[j].isValid[TimeType.leg]) {
                    validTimeSum += competitor.courseSplitTimes[j].times[TimeType.leg];
                }
            }
            if (validTimeSum - totalTimeLoss > 0) {
                competitor.coursePercentualTimeLoss = totalTimeLoss / (validTimeSum - totalTimeLoss);
            }
        });
    }
}

const validatePreconditions = (competitors, controls, timeLossPercentile, timeLossCondition, timeAccuracy) => {
    const errors = [];

    if (controls.filter((c, i) => i > 0 && (!c.normalizedLengths || c.normalizedLengths[AccumulationType.leg] === undefined)).length > 0) {
        errors.push("Control normalized lengths not set.");
    }

    if (isNaN(timeLossPercentile) || timeLossPercentile < 0 || timeLossPercentile > 1) {
        errors.push("Invalid time loss percentile; should be 0 <= timeLossPercentile <= 1.");
    }

    if (isNaN(timeLossCondition.time)) {
        errors.push("Invalid time loss condition time; should be numeric.");
    }

    if (isNaN(timeLossCondition.percent)) {
        errors.push("Invalid time loss condition percent; should be numeric.");
    }

    if (isNaN(timeAccuracy) || timeAccuracy <= 0) {
        errors.push("Invalid time accuracy; should be a positive number.");
    }

    if (errors.length) {
        throw errors.join("\n");
    }
};

const calculateMedianPerformanceIndex = (performanceIndices, normalizedLengths, percentile) => {
    if (performanceIndices.length === 0) {
        return 0;
    } else if (performanceIndices.length === 1) {
        return performanceIndices[0].performanceIndex;
    }

    const sortedPerformanceIndices = [...performanceIndices];
    sortPerformanceIndices(sortedPerformanceIndices);
    let normalizedLengthSum = 0;
    sortedPerformanceIndices.forEach(performanceIndex => normalizedLengthSum += normalizedLengths[performanceIndex.legIndex]);
    const percentileSum = percentile * normalizedLengthSum;
    normalizedLengthSum = 0;
    let i = 0;
    for (i; i < sortedPerformanceIndices.length; i++) {
        normalizedLengthSum += normalizedLengths[sortedPerformanceIndices[i].legIndex];
        if (normalizedLengthSum > percentileSum) {
            break;
        }
    }
    return sortedPerformanceIndices[i]
        ? sortedPerformanceIndices[i].performanceIndex
        : undefined;
};

const sortPerformanceIndices = performanceIndices => performanceIndices.sort((a, b) => a.performanceIndex - b.performanceIndex);

export default TimeLossCalculator;