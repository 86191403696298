import RaceClassJsonReader from "./RaceClassJsonReader";

const raceClassJsonReader = new RaceClassJsonReader();

export default class ClassJsonReader {
    read(json) {
        const cl = {};
        cl.classId = json.i;
        cl.name = json.n;
        cl.raceClasses = [];
        (json.r || []).map(r => {
            const raceClass = raceClassJsonReader.read(r);
            cl.raceClasses[raceClass.raceId] = raceClass;
        });
        cl.sequence = json.s;
        cl.classCategoryId = json.cc;
        cl.timePresentation = json.t;
        cl.hasOverallResults = json.or;
        cl.allowFiltering = json.af;
        cl.externalUrl = json.eu;
        return cl;
    }
}
