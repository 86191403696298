class TopTimeCalculator {
    calculateTopTime(times, topPercentile) {
        const sortedTimes = [];
        times.forEach(time => {
            if (time > 0) {
                sortedTimes.push(time);
            }
        });
        sortedTimes.sort((a, b) => a - b);
    
        const numberOfTimesToConsider = topPercentile * sortedTimes.length;
        let timeSum = 0;
        for (let i = 0; i < Math.ceil(numberOfTimesToConsider); i++) {
            const factor = i+1 > numberOfTimesToConsider
                ? numberOfTimesToConsider - i
                : 1;
            timeSum += factor * sortedTimes[i];
        }
        const topTime = numberOfTimesToConsider === 0
            ? undefined
            : timeSum / numberOfTimesToConsider;
        return topTime;
    }
}

export default TopTimeCalculator;