import { emptyDomElement } from "../../logic/BrowserUtil";
import { iconMarkups } from "../../pages/event/presentational/MenuIcon";

const setMenuIcon = (menuButton, result, favoriteEntryIds) => {
    const isFavorite = favoriteEntryIds.indexOf(result.entryId) !== -1; 
    const hasTags = result.tags && result.tags.length;
    let icon;
    if (isFavorite && hasTags) {
        icon = icons.hashtagFavorite();
    } else if (isFavorite) {
        icon = icons.favorite();
    } else if (hasTags){
        icon = icons.hashtag();
    } else {
        icon = icons.dots();
    }
    emptyDomElement(menuButton);
    menuButton.appendChild(icon);
};

export default setMenuIcon;

const htmlToElement = html => {
    const template = document.createElement("template");
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content
        ? template.content.firstChild
        : template.firstChild; // IE fix
};

const icons = {
    favorite: () => htmlToElement(iconMarkups.favorite),
    hashtagFavorite: () => htmlToElement(iconMarkups.hashtagFavorite),
    hashtag: () => htmlToElement(iconMarkups.hashtag),
    dots: () => htmlToElement(iconMarkups.dots)
};
