import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

export default class Tooltip {
    constructor(options) {
        options = options ?? {};
        this.content = options.content;
    }

    applyToElement(element) {
        if (this.content) {
            const options = {
                content: '<div class="winsplits-tooltip">' + this.content + '</div>',
                allowHTML: true,
                arrow: true,
                delay: 300
            };
    
            tippy(element, options);
        }
    }
}
