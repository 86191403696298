import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { withLocalize, Translate } from "react-localize-redux";
import { useNavigate } from "react-router-dom";
import ListHeader from "../presentational/ListHeader";
import { getUrlForList, getOrderByForList, getPageFromList } from "../../../logic/ListUtil";
import * as eventActions from "../../../actions/eventActions";
import { createListRenderer } from "../../../rendering/ListRenderer";
import ListType from "../../../logic/models/ListType";
import { hasOverallResults, isMultiRace, getExternalUrlForClasses } from "../../../logic/EventUtil";
import { isFirstRace, getIntermediateControls, getIntermediateControlLabel, getIntermediateControlByName, getCourseLength } from "../../../logic/ClassUtil";
import { formatCourseLength } from "../../../logic/ResultUtil";
import { arraysAreEqual } from "../../../logic/ArrayUtil";
import ManageTagsModal from "./ManageTagsModal";
import ClassListActionLinks from "../presentational/ClassListActionLinks";
import FavoriteListActionLinks from "../presentational/FavoriteListActionLinks";
import Header from "./Header";
import InteractiveListCaption from "./InteractiveListCaption";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import Dropdown from "../../../common/presentational/Dropdown";
import { logPageView } from "../../../logic/LoggingUtil";

const ListPage = ({ listFromLocation, translate, activeLanguage }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [manageTagsModal, setManageTagsModal] = useState({
        isOpen: false,
        result: undefined
    });
    const [listRenderer, setListRenderer] = useState(undefined);
    const [resultsHaveBeenRequested, setResultsHaveBeenRequested] = useState(false); // used to prevent double rendering

    const event = useSelector(state => state.eventPage.event); 
    const results = useSelector(state => state.eventPage.results); // might be initialized at first render, use together with resultsHaveBeenRequested
    const loading = useSelector(state => state.eventPage.loading.results); 
    const listFromRedux = useSelector(state => state.eventPage.list); 
    const listUrl = getUrlForList(event, listFromLocation, false /* includeControlTypeAndName */, false /* includeSorting */).url;
    const favoriteEntryIds = useSelector(state => state.eventPage.favoriteEntryIds); 
    const singleClass = listFromLocation.classIds && listFromLocation.classIds.length === 1
        ? event.classes[listFromLocation.classIds[0]]
        : undefined;
    const isMultipleClasses = listFromLocation.classIds && listFromLocation.classIds.length > 1;
    const intermediateControls = singleClass && listFromRedux
        ? getIntermediateControls(singleClass, listFromRedux.raceId)
        : undefined;
    const selectedIntermediateControl = getIntermediateControlByName(singleClass, listFromRedux?.raceId, listFromRedux?.controlName);
    const orderByForList = getOrderByForList(listFromLocation);
    const page = getPageFromList(listFromLocation);

    const renderingDomElementRef = useRef();

    useEffect(() => {
        if(listRenderer) {
            listRenderer.refresh();
        }
    }, [activeLanguage.code]);

    useEffect(() => {
        if(page) {
            logPageView(page);
        }
    }, [page]);

    useEffect(() => {
        if(listUrl) {
            // now when the event is loaded, we can load the list
            loadResults();
        }
    }, [listUrl, listFromLocation?.controlName]);

    // if the requested intermediate control is not present in this class, redirect to the finish
    useEffect(() => {
        if(!selectedIntermediateControl && listFromRedux?.controlName) {
            navigate(getUrlForList(event,
                {
                    ...listFromLocation,
                    controlName: undefined
                }
            ).url);
        }
    }, [selectedIntermediateControl]);

    useEffect(() => {
        if(resultsHaveBeenRequested && results) {
            // the list renderer has to be created in useEffect, since renderingDomElementRef.current won't be set otherwise
            const controlNameChangeOnly = listFromLocation.classIds && arraysAreEqual(listFromRedux?.classIds, listFromLocation.classIds) && listFromLocation.controlName !== listFromRedux.controlName;
            
            const lr = createListRenderer({ 
                renderingDomElement: renderingDomElementRef.current,
                event,
                list: listFromLocation,
                results,
                favoriteEntryIds, 
                onFavoriteButtonClick: handleFavoriteButtonClick,
                onManageTagsButtonClick: handleManageTagsButtonClick,
                getTranslate: () => translate,
                onLinkClick: handleListRendererLinkClick
            });
            lr.refresh({ calculatePlacesAndTimeBehinds: controlNameChangeOnly });
            setListRenderer(lr);
            return () => {
                lr.destroy();
            };
        }
    }, [resultsHaveBeenRequested, results, listFromLocation.controlName, orderByForList]);

    const handleResultHeaderColumnClick = columnKey => {
        navigate(getUrlForList(event,
            {
                ...listFromLocation,
                orderBy: columnKey,
                direction: columnKey === listFromLocation.orderBy
                    ? -listFromLocation.direction
                    : 1
            }
        ).url);
    };

    const loadResults = () => {
        // redirect to other system?
        const externalUrl = getExternalUrlForClasses(event, listFromLocation.classIds, listFromLocation.raceId, listFromLocation.listType);
        if (externalUrl) {
            window.open(externalUrl, "_blank");
            navigate(`/${encodeURIComponent(event.slug)}`);
            return;
        }

        dispatch(eventActions.fetchResults(listFromLocation));
        setResultsHaveBeenRequested(true);
    };

    const getListCssClass = () => {
        if(!listFromRedux) {
            return undefined;
        }
        // use listFromRedux here, to keep column visibility in sync with the actual list in the Redux state
        const cssClasses = ["grid-list"];

        switch(listFromRedux.listType) {
            case ListType.startList:
                cssClasses.push("start-list");
                break;
            case ListType.resultList:
            case ListType.raceResultList:
                cssClasses.push("race-result-list");
                break;
            case ListType.overallResultList:
                cssClasses.push("overall-result-list");
                break;
        }

        if(listFromRedux.organisationKey) {
            cssClasses.push("organisation-list");
        }

        const race = event.races[listFromRedux.raceId];

        if(singleClass) {
            cssClasses.push("class-list");
            if(!singleClass.hasOverallResults || isFirstRace(singleClass, race, event)) {
                cssClasses.push("no-overall-results");
            }
            if(!singleClass.timePresentation) {
                cssClasses.push("no-time-presentation");
            }
            if(listFromRedux.controlName) {
                cssClasses.push("intermediate-control");
            }
        }

        if(listFromRedux.entryIds || listFromRedux.tag || isMultipleClasses) {
            cssClasses.push("competitor-list");
        }

        if(!singleClass && !hasOverallResults(event, listFromRedux.raceId)) {
            cssClasses.push("no-overall-results");
        }

        return cssClasses.join(" ");
    };

    const handleListRendererLinkClick = (url, isExternalLink, jQueryEvent) => {
        if(isExternalLink || jQueryEvent.originalEvent.ctrlKey) {
            window.open(url, "_blank");
        } else {
            navigate(url);
        }
    };

    const handleFavoriteButtonClick = (entryId, value) => {
        if(value) {
            dispatch(eventActions.addFavoriteEntryId(event, entryId, activeLanguage));
        } else {
            dispatch(eventActions.removeFavoriteEntryId(event, entryId, activeLanguage));
        }
    };

    const handleManageTagsButtonClick = result => {
        setManageTagsModal({
            ...manageTagsModal,
            isOpen: true,
            result
        });
    };

    const handleManageTagsModalSave = tags => {
        const result = manageTagsModal.result;
        result.tags = tags;
        setManageTagsModal({
            ...manageTagsModal,
            isOpen: false
        });
        listRenderer.refreshMenuButton(result.entryId);
    };

    const handleManageTagsModalCancel = () => {
        setManageTagsModal({
            ...manageTagsModal,
            isOpen: false
        });
    };

    const handleCreateFavoriteSubscription = () => {
        dispatch(eventActions.createFavoriteSubscription(event, favoriteEntryIds, activeLanguage));
    };

    const handleDeleteFavoriteSubscription = () => {
        dispatch(eventActions.deleteFavoriteSubscription(event));
    };

    const handleIntermediateControlLinkClick = intermediateControl => {
        navigate(getUrlForList(event,
            {
                ...listFromLocation,
                controlName: intermediateControl?.controlName
            }
        ).url);
    };

    const getSelectedIntermediateControlLabel = () => {
        if(!singleClass) {
            return "";
        }
        return getIntermediateControlLabel(intermediateControls.find(o => o.controlName === listFromRedux.controlName), intermediateControls, translate)
            ?? translate("eventPage.finish");
    };

    const getActionLinks = () => {
        if(singleClass) {
            return (
                <ClassListActionLinks
                    event={event}
                    list={listFromRedux}
                    intermediateControls={showIntermediateControlsSelector ? intermediateControls : undefined}
                    onIntermediateControlLinkClick={handleIntermediateControlLinkClick}
                />
            );
        }
        if(listFromRedux?.entryIds) {
            return (
                <FavoriteListActionLinks
                    event={event}
                    onCreateFavoriteSubscription={handleCreateFavoriteSubscription}
                    onDeleteFavoriteSubscription={handleDeleteFavoriteSubscription}
                />
            );
        }

        return [];
    };

    const getNoItemsText = () => {
        const itemTypeText = listFromRedux?.listType === ListType.startList
            ? "StartTimes"
            : "Results";
        const stageText = isMultiRace(event)
            ? "ForThisStage"
            : "";

        return `eventPage.no${itemTypeText}${stageText}`;
    };

    const numberOfResults = results
        ? results.length
        : undefined;
    const isValid = loading || numberOfResults !== undefined;

    const pageClassName = listFromRedux?.entryIds
        ? "page favorite-list"
        : "page";

    const showIntermediateControlsSelector = listFromRedux?.listType !== ListType.startList &&
                                             intermediateControls &&
                                             intermediateControls.length > 0 &&
                                             numberOfResults > 0;
    const courseLength = singleClass && listFromRedux?.listType !== ListType.overallResultList
        ? getCourseLength(singleClass, event.races[listFromRedux?.raceId])
        : undefined;
    
    return (
        <div id="list-page" className={pageClassName}>
            <Header
                event={event}
                showMenuButton
            />

            <div className="content">
                <LoadingOverlay
                    active={loading}
                    spinner={<BounceLoader />}
                    text={translate("loading")}
                />

                {
                    isValid && 
                    (
                        <div id="caption-bar">
                            <h1 id="caption">
                                <InteractiveListCaption
                                    event={event}
                                    list={listFromLocation}
                                />
                                {
                                    courseLength !== undefined &&
                                    (
                                        <span className="caption-course-length">{formatCourseLength(courseLength, translate("thousandSeparator"))}</span>
                                    )
                                }
                            </h1>

                            {
                                showIntermediateControlsSelector &&
                                (
                                    <Dropdown type="button" toggleClassName="desktop-visible btn-sm intermediate-controls-dropdown-inner" text={getSelectedIntermediateControlLabel()} id="intermediate-controls-dropdown">
                                        {
                                            intermediateControls.map(intermediateControl => (
                                                <button key={intermediateControl.sequence} type="button" className="dropdown-item btn btn-link" onClick={() => handleIntermediateControlLinkClick(intermediateControl)}>
                                                    {getIntermediateControlLabel(intermediateControl, intermediateControls, translate)}
                                                </button>
                                            ))
                                        }
                                        <button type="button" className="dropdown-item btn btn-link" onClick={() => handleIntermediateControlLinkClick(undefined)}>
                                            <Translate id="eventPage.finish" />
                                        </button>
                                    </Dropdown>
                                )
                            }

                            {
                                numberOfResults > 0 && getActionLinks()
                            }
                        </div>                        
                    )
                }

                <div id="list" className={getListCssClass()}>
                    <div className="list-scroll-container" style={{display: isValid ? "block" : "none" }}>
                        { 
                            numberOfResults === 0 && !loading &&
                            (
                                <div id="no-results">
                                    <Translate id={getNoItemsText()} />
                                </div>
                            )
                        }
                        { 
                            listFromRedux && numberOfResults > 0 && 
                            (
                                <ListHeader 
                                    event={event}
                                    numberOfResults={numberOfResults}
                                    listType={listFromRedux.listType} 
                                    orderBy={listFromRedux.orderBy} 
                                    direction={listFromRedux.direction} 
                                    onColumnClick={handleResultHeaderColumnClick} 
                                />
                            )
                        }
                        <ul className="list-body grid-list-body" style={{ display: loading ? 'none' : 'block'}} ref={renderingDomElementRef} />
                    </div>
                </div>

                <ManageTagsModal isOpen={manageTagsModal.isOpen} result={manageTagsModal.result} onSave={handleManageTagsModalSave} onCancel={handleManageTagsModalCancel}/>
            </div>

        </div>
    );
};

ListPage.propTypes = {
    listFromLocation: PropTypes.object.isRequired,
    activeLanguage: PropTypes.object,
    translate: PropTypes.func.isRequired
};
  
export default withLocalize(ListPage);
