import React from "react";
import PropTypes from "prop-types";
import { formatClockTime } from "../../../logic/TimeUtil";
import { getExternalUrlForClasses } from "../../../logic/EventUtil";
import ListType from "../../../logic/models/ListType";
import ExternalLinkIcon from "./ExternalLinkIcon";

const StartTimeCell = ({ event, result, timeZone, classNamePrefix }) => {
    const externalUrl = getExternalUrlForClasses(event, result?.cl?.classId, result?.raceId, ListType.startList);
    const className = `${classNamePrefix ?? ''}startTime`;
    return (
        <div className={className}>
            {
                result &&
                (
                    <>
                        {externalUrl && <a href={externalUrl} target="_blank"><ExternalLinkIcon/></a>}
                        {!externalUrl && formatClockTime(result.startTime, timeZone)}
                    </>
                )
            }
        </div>
    );
};
    
StartTimeCell.propTypes = {
    event: PropTypes.object.isRequired,
    result: PropTypes.object,
    timeZone: PropTypes.string.isRequired,
    classNamePrefix: PropTypes.string
};

export default StartTimeCell;