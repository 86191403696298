import React, { useEffect, useState, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { withLocalize } from "react-localize-redux";
import queryString from "query-string";
import ClassGrid from "./ClassGrid";
import Header from "./Header";
import InactivityCountdown from "../presentational/InactivityCountdown";
import EventLoader from "../presentational/EventLoader";
import ResultList from "./ResultList";
import ResultLoader from "../presentational/ResultLoader";
import ErrorMessage from "../presentational/ErrorMessage";

import Api from "../../../logic/Api";
import ListType from "../../../logic/models/ListType";
import ResultStatus from "../../../logic/models/ResultStatus";
import ControlType from "../../../logic/models/ControlType";
import { isMultiRace } from "../../../logic/EventUtil";
import { isFirstRace } from "../../../logic/ClassUtil";
import "../../../styles/kiosk.scss";

// TODO: make configurable
const _inactivityThreshold = 30000;
const _numberOfInactivityCountdownSteps = 5;

const KioskPage = () => {
    const params = useParams();
    const location = useLocation();
    const [event, setEvent] = useState(undefined);
    const [selectedRace, setSelectedRace] = useState(undefined);
    const [loadingEvent, setLoadingEvent] = useState(true);
    const [selectedClass, setSelectedClass] = useState(undefined);
    const [results, setResults] = useState([]);
    const [loadingResults, setLoadingResults] = useState(false);
    const [countdownStepsLeftToReset, setCountdownStepsLeftToReset] = useState(undefined);
    const [error, setError] = useState(undefined);
    const [resultListType, setResultListType] = useState(undefined);
    const [waitTimer, setWaitTimer] = useState(undefined);
    const [countdownTimer, setCountdownTimer] = useState(undefined);
    const lastActivityTimeRef = useRef();
    const selectedClassRef = useRef();
    const countdownStepsLeftToResetRef = useRef();
    const eventRef = useRef();

    useEffect(() => {
        setupInactivityHandling();
        const eventSlug = params.eventSlug;
        loadEvent(eventSlug);
        return () => {
            if(waitTimer) {
                window.clearInterval(waitTimer);
            }
            if(countdownTimer) {
                window.clearInterval(countdownTimer);
            }
        };
    }, []);

    useEffect(() => {
        selectedClassRef.current = selectedClass;
    }, [selectedClass]);

    useEffect(() => {
        eventRef.current = event;
    }, [event]);

    useEffect(() => {
        countdownStepsLeftToResetRef.current = countdownStepsLeftToReset;
    }, [countdownStepsLeftToReset]);

    const handleClassSelected = cl => {
        setSelectedClass(cl);
        setResultListType(selectedRace.raceNumber === Math.max.apply(undefined, Object.values(event.races).map(r => r.raceNumber)) && cl.hasOverallResults
            ? ListType.overallResultList
            : ListType.raceResultList);
        setLoadingResults(true);
        loadResults(selectedRace.raceId, cl.classId);
    };

    const handleResetClick = () => {
        setSelectedClass(undefined);
        setResults([]);
        setCountdownStepsLeftToReset(undefined);
        setSelectedRace(getClosestRace(Object.values(eventRef.current.races), new Date().getTime()));
    };

    const handleSetResultListType = newResultListType => {
        setResultListType(newResultListType);
    };

    const handleRaceChange = newRace => {
        setSelectedRace(newRace);
        if(selectedClass) {
            loadResults(newRace.raceId, selectedClass.classId);
        }
    };

    const setupInactivityHandling = () => {
        // should go back to start page after a bit of inactivity
        // two parts:
        // 1. wait for a certain time of inactivity
        // 2. show countdown timer

        let _countdownTimer;
        document.addEventListener(
            'mousemove', 
            () => {
                lastActivityTimeRef.current = new Date().getTime();
                if (_countdownTimer) {
                    window.clearInterval(_countdownTimer);
                    setCountdownTimer(undefined);
                    setCountdownStepsLeftToReset(undefined);
                }
            });

        setWaitTimer(window.setInterval(
            () => {
                const now = new Date().getTime();
                if (selectedClassRef.current && lastActivityTimeRef.current && now - lastActivityTimeRef.current > _inactivityThreshold && !countdownStepsLeftToResetRef.current) {
                    // inactivity limit reached
                    setCountdownStepsLeftToReset(_numberOfInactivityCountdownSteps);
                    _countdownTimer = window.setInterval(
                        () => {
                            const updatedCountdownStepsLeftToReset = countdownStepsLeftToResetRef.current - 1;
                            if (updatedCountdownStepsLeftToReset === 0) {
                                window.clearInterval(_countdownTimer);
                                setCountdownTimer(undefined);
                                handleResetClick();
                            } else {
                                setCountdownStepsLeftToReset(updatedCountdownStepsLeftToReset);
                            }
                        },
                        1000);
                    setCountdownTimer(_countdownTimer);
                }
            },
            1000));
    };

    const loadEvent = eventSlug => 
        Api.getEvent(
            eventSlug,
            event => {
                const querystringRaceNumber = queryString.parse(location.search).raceNumber;
                
                const races = Object.values(event.races);
                let race = querystringRaceNumber
                    ? races.find(r => r.raceNumber === parseInt(querystringRaceNumber))
                    : undefined;
                race = race ?? getClosestRace(races, new Date().getTime());

                setEvent(event);
                setSelectedRace(race);
                setLoadingEvent(false);
            },
            error => {}
        );

    const loadResults = (raceId, classId) =>
        Api.getClassResults(
            event,
            raceId,
            [classId],
            results => loadResultsCompleted(results),
            error => setError(error)
        );

    const loadResultsCompleted = results => {
        const now = new Date();
        lastActivityTimeRef.current = now.getTime();
        // only care about finish results
        for (let i = 0; i < results.length; i++) {
            results[i].samples = results[i].samples.filter(s => s.controlType === ControlType.finish);
            // change to pseudo-status notYetFinished where applicable
            for (let j = 0; j < results[i].samples.length; j++) {
                const sample = results[i].samples[j];
                if (sample.status === ResultStatus.notActivated &&
                    (!results[i].startTime || results[i].startTime < now)) {
                    sample.status = ResultStatus.notYetFinished;
                }
            }
        }
        results = results.filter(r => r.samples.length);

        setResults(results);
        setLoadingResults(false);
    };

    return (
        <div id="kiosk">
            <Header
                event={event}
                resultListType={selectedClass ? resultListType : undefined}
                resetButtonVisible={!!selectedClass}
                resultListTypeButtonsVisible={selectedClass && selectedRace && selectedClass.hasOverallResults && !isFirstRace(selectedClass, selectedRace, event)}
                race={selectedRace}
                onRaceChange={handleRaceChange}
                onResetClick={handleResetClick}
                onSetResultListType={handleSetResultListType}
            />
            <InactivityCountdown stepsLeftToReset={countdownStepsLeftToReset} />
            {
                loadingEvent
                    ? <EventLoader />
                    : ""
            }

            {
                !loadingEvent && !selectedClass
                    ? <ClassGrid
                        classCategories={event.classCategories}
                        classes={getClassesForRace(event.classes, selectedRace)}
                        onClassClick={handleClassSelected}
                    />
                    : ""
            }

            {
                loadingResults
                    ? <ResultLoader selectedClass={selectedClass} />
                    : ""
            }

            {
                !loadingResults && selectedClass
                    ? <ResultList
                        selectedClass={selectedClass}
                        results={results}
                        resultListType={isMultiRace(event) ? resultListType : ListType.raceResultList}
                        showOverallResults={selectedClass.hasOverallResults && !isFirstRace(selectedClass, selectedRace, event)}
                    />
                    : ""
            }
            {
                error
                    ? <ErrorMessage error={error} />
                    : ""
            }
        </div>
    );
};

export default withLocalize(KioskPage);

const getClosestRace = (races, time) => {
    const orderedRaces = races.sort((r1, r2) => timeDiffToRace(r1, time) - timeDiffToRace(r2, time));
    return orderedRaces[0];
};

const timeDiffToRace = (race, time) =>
    race.startTime <= time && race.endTime >= time
        ? 0
        : Math.min(Math.abs(race.startTime - time, race.endTime - time));

const getClassesForRace = (classes, race) => {
    const sortedClasses = classes.map(o => o);
    sortedClasses.sort((a, b) => a.sequence - b.sequence);
    return sortedClasses.filter(cl => Object.values(cl.raceClasses).some(rc => rc.raceId === race.raceId));
};
