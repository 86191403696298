import React, { useEffect } from "react";
import routes from "./routes";
import PropTypes from "prop-types";
import languages from "./languages";
import globalTranslations from "./translations/global";
import { withLocalize } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import { getLocalStorageItem, setLocalStorageItem } from "./logic/BrowserUtil";
import { BrowserRouter } from "react-router-dom";
import BrowserVisibilityChangeDetector from "./common/components/BrowserVisibilityChangeDetector";
import DeprecatedBrowserMessage from "./common/presentational/DeprecatedBrowserMessage";
import queryString from "query-string";
import detectBrowserLanguage from "detect-browser-language";

const LocalizationRoot = ({ initialize, activeLanguage }) => {
    // 1. fetch language from querystring
    let defaultLanguageCode = queryString.parse(window.location.search)?.language;
    if (defaultLanguageCode) {
        setLocalStorageItem("languageCode", defaultLanguageCode);
    }

    // 2. fetch last selected language from local storage
    defaultLanguageCode = defaultLanguageCode || getLocalStorageItem("languageCode");

    // 3. if not found, use browser language setting
    if (!defaultLanguageCode) {
        defaultLanguageCode = detectBrowserLanguage()?.substr(0, 2) ?? "";
    }

    // 4. if not any support for browser's language, fallback to default language as set in settings.js, or as last fallback the first language
    if (!languages.find(l => l.code === defaultLanguageCode)) {
        defaultLanguageCode = (languages.find(l => l.isDefault) ?? languages[0]).code;
    }

    useEffect(() => {
        initialize({
            languages,
            translation: globalTranslations,
            options: {
                defaultLanguage: defaultLanguageCode,
                renderToStaticMarkup
            }
        });
    }, []);

    useEffect(() => {
        if(activeLanguage?.code) {
            setLocalStorageItem("languageCode", activeLanguage.code);
        }
    }, [activeLanguage?.code]);

    return (
        <BrowserRouter>
            <BrowserVisibilityChangeDetector />
            <DeprecatedBrowserMessage />
            {activeLanguage?.code && routes}
        </BrowserRouter>
    );
};

LocalizationRoot.propTypes = {
    activeLanguage: PropTypes.object,
    initialize: PropTypes.func.isRequired,
};

export default withLocalize(LocalizationRoot);
