import React from "react";
import { Translate, withLocalize } from "react-localize-redux";
import PropTypes from "prop-types";


const SingleCompetitorHeader = ({ hasOverallResults }) => {
    return (
        <div id="race-list-header" className="grid-list-header">
            <div className="race">
                <Translate id="eventPage.columns.race"/>
            </div>
            <div className="startTime">
                <Translate id="eventPage.columns.startTime"/>
            </div>
            <div className="courseLength">
                <Translate id="eventPage.columns.courseLength"/>
            </div>
            <div className="primaryPlace">
                <Translate id={`eventPage.columns.primaryPlace.${hasOverallResults ? "race" : "singleRace"}`}/>
            </div>
            <div className="primaryElapsedTime">
                <Translate id={`eventPage.columns.primaryElapsedTime.${hasOverallResults ? "race" : "singleRace"}`}/>
            </div>
            <div className="primaryTimeBehind">
                <Translate id={`eventPage.columns.primaryTimeBehind.${hasOverallResults ? "race" : "singleRace"}`}/>
            </div>
            <div className="elapsedTimePerKilometer">
                <Translate id="eventPage.columns.elapsedTimePerKilometer"/>
            </div>
            <div className="secondaryPlace">
                <Translate id="eventPage.columns.secondaryPlace.race"/>
            </div>
            <div className="secondaryElapsedTime">
                <Translate id="eventPage.columns.secondaryElapsedTime.race"/>
            </div>
            <div className="secondaryTimeBehind">
                <Translate id="eventPage.columns.secondaryTimeBehind.race"/>
            </div>
            <div className="punchingCardNumber">
                <Translate id="eventPage.columns.punchingCardNumber"/>
            </div>
        </div>
    );
};

SingleCompetitorHeader.propTypes = {
    hasOverallResults: PropTypes.bool
};

export default withLocalize(SingleCompetitorHeader);