import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { withLocalize } from "react-localize-redux";
import { getUrlForList, getListTypeLabelKey, getDefaultOrderByForListType } from "../../../logic/ListUtil";
import { formatOrganisationName, getOrganisationKey } from "../../../logic/OrganisationUtil";
import { isMultiRace, hasOverallResults, getSortedRaces, getSortedClasses, getSortedOrganisations } from "../../../logic/EventUtil";
import { getIntermediateControls, getIntermediateControlLabel, getIntermediateControlByName } from "../../../logic/ClassUtil";
import ListType from "../../../logic/models/ListType";
import Dropdown from "../../../common/presentational/Dropdown";
import ClassBox from "../presentational/ClassBox";
import OrganisationBox from "./OrganisationBox";
import { Formik, Form } from "formik";

const InteractiveListCaption = ({ event, list, translate }) => {
    const navigate = useNavigate();

    const eventIsMultiRace = isMultiRace(event);

    const singleClass = list.classIds && list.classIds.length === 1
        ? event.classes[list.classIds[0]]
        : undefined;

    const intermediateControls = singleClass
        ? getIntermediateControls(singleClass, list.raceId)
        : undefined;
    const selectedIntermediateControl = getIntermediateControlByName(singleClass, list.raceId, list.controlName);

    const createComponents = () => translate("listCaptionTemplate").split("|").map(atom => {
        let selectedText;
        if(atom === "${race}") {
            selectedText = list.raceId === "all"
                ? translate("allRaces")
                : translate("raceX", { raceNumber: event.races[list.raceId].raceNumber });
            return {
                render: form => (
                    <Dropdown id="interactive-list-caption-race-picker" containerClassName="clickable-component-container" toggleClassName="clickable-component" type="link" text={selectedText}>
                        {
                            getSortedRaces(event, true /* includeAllRacesItem */).map(race => (
                                <button key={race.raceId} type="button" className="dropdown-item btn btn-link" onClick={()=> handleRaceLinkClick(race)}>
                                        {race.raceId === "all" && translate("allRaces")}
                                        {race.raceId !== "all" && translate("raceX", { raceNumber: race.raceNumber })}
                                </button>
                            ))
                        }
                    </Dropdown>
                )
            };
        } else if(atom === "${listType}") {
            if(list.listType === ListType.raceResultList) {
                selectedText = selectedIntermediateControl
                    ? getIntermediateControlLabel(selectedIntermediateControl, intermediateControls, translate)
                    : translate("resultList");
            } else if(list.listType === ListType.overallResultList && selectedIntermediateControl) {
                selectedText = translate("eventPage.intermediateOverallResultsAtX", { intermediateControl: getIntermediateControlLabel(selectedIntermediateControl, intermediateControls, translate, true /* lowercase */) });
            } else {
                selectedText = translate(getListTypeLabelKey(list.listType, eventIsMultiRace));
            }

            return {
                render: form => (
                    <Dropdown id="interactive-list-caption-list-type-picker" containerClassName="clickable-component-container" toggleClassName="clickable-component" type="link" text={selectedText}>
                        {
                            getListTypes().map(listType => (
                                <button key={listType} type="button" className="dropdown-item btn btn-link" onClick={()=> handleListTypeLinkClick(listType)}>
                                    {translate(getListTypeLabelKey(listType, eventIsMultiRace))}
                                </button>
                            ))
                        }
                    </Dropdown>
                )
            };
        } else if(atom === "${subject}" && list.classIds) {
            selectedText = getSortedClasses(event, list.classIds).map(cl => cl.name).join(", ");

            return {
                render: form => (
                    <Dropdown id="interactive-list-caption-class-picker" containerClassName="clickable-component-container" toggleClassName="clickable-component" type="link" text={selectedText}>
                        <ClassBox
                            id="interactive-list-caption-class-picker-typeahead"
                            name="classId"
                            classes={getSortedClasses(event)}
                            labelRenderer={cl => cl.name}
                            onChange={cl => handleClassChange(cl, form)}
                            focusOnVisible
                        />
                    </Dropdown>
                )
            };
        } else if(atom === "${subject}" && list.organisationKey) {
            const organisation = event.organisations[list.organisationKey];
            selectedText = formatOrganisationName(organisation, list.organisationKey);
            return {
                render: form => (
                    <Dropdown id="interactive-list-caption-organisation-picker" containerClassName="clickable-component-container" toggleClassName="clickable-component" type="link" text={selectedText}>
                        <OrganisationBox
                            id="interactive-list-caption-organisation-picker-typeahead"
                            name="organisationKey"
                            organisations={getSortedOrganisations(event)}
                            onChange={newOrganisationKey => handleOrganisationChange(newOrganisationKey, form)}
                            focusOnVisible
                        />
                    </Dropdown>
                )
            };
        } else if(atom === "${subject}" && list.entryIds) {
            return {
                render: () => translate("eventPage.myFavorites")
            };
        } else if(atom === "${subject}" && list.tag) {
            return {
                render: () => "#" + list.tag
            };
        } else {
            return {
                render: () => atom
            };
        }
    });

    const getListTypes = () => {
        if(list.raceId === "all") {
            return [
                ListType.startList,
                ListType.resultList
            ];
        }
        const classHasOverallResults = list.classIds
            ? event.classes[list.classIds[0]].hasOverallResults 
            : true;
        const eventHasOverallResults = hasOverallResults(event, list.raceId);
        const listTypes = [
            ListType.startList,
            ListType.raceResultList
        ];
        if(classHasOverallResults && eventHasOverallResults) {
            listTypes.push(ListType.overallResultList);
        }
        return listTypes;
    };

    const handleRaceLinkClick = race => {
        navigateOrRedirect(getUrlForList(event,
            {
                ...list,
                raceId: race.raceId,
                controlName: undefined
            }
        ));
    };

    const handleListTypeLinkClick = listType => {
        navigateOrRedirect(getUrlForList(event,
            {
                ...list,
                listType: listType,
                orderBy: getDefaultOrderByForListType(event, list.raceId, listType),
                direction: 1
            }
        ));
    };

    const handleClassChange = (cl, form) => {
        form.setFieldValue("classId", undefined);
        document.querySelector("#interactive-list-caption-class-picker .dropdown-menu").classList.remove("show");
        navigateOrRedirect(getUrlForList(event,
            {
                ...list,
                classIds: [cl.classId],
                organisationKey: undefined,
                entryIds: undefined,
                tag: undefined,
                controlName: undefined
            }
        ));
    };

    const handleOrganisationChange = (organisation, form) => {
        form.setFieldValue("organisationKey", undefined);
        document.querySelector("#interactive-list-caption-organisation-picker .dropdown-menu").classList.remove("show");
        navigateOrRedirect(getUrlForList(event,
            {
                ...list,
                classIds: undefined,
                organisationKey: getOrganisationKey(organisation),
                entryIds: undefined,
                tag: undefined,
                controlName: undefined
            }
        ));
    };

    const navigateOrRedirect = urlForListResult => {
        if(urlForListResult.isExternal) {
            window.open(urlForListResult.url, "_blank");
        } else {
            navigate(urlForListResult.url);
        }
    };

    return (
        <Formik
            initialValues={{}}
        >
            {
                form => (
                    <Form id="interactive-list-caption">
                        {
                            createComponents().map((component, i) => (
                                <React.Fragment key={i}>
                                    {component.render(form)}
                                </React.Fragment>
                            ))
                        }
                    </Form>
                )
            }
        </Formik>
    );
};

InteractiveListCaption.propTypes = {
    event: PropTypes.object.isRequired,
    list: PropTypes.object.isRequired,
    translate: PropTypes.func
}; 

export default withLocalize(InteractiveListCaption);