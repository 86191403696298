import React from "react";
import PropTypes from "prop-types";
import { formatElapsedTime } from "../../../logic/TimeUtil";
import { getElapsedTimePerKilometer, isElapsedTimeSample } from "../../../logic/ResultUtil";

const ElapsedTimePerKilometerCell = ({ result, sample }) => {
    return (
        <div className="elapsedTimePerKilometer">
            {
                sample && isElapsedTimeSample(sample) && formatElapsedTime(getElapsedTimePerKilometer(result, sample))
            }
        </div>
    );
};
    
ElapsedTimePerKilometerCell.propTypes = {
    result: PropTypes.object,
    sample: PropTypes.object
};

export default ElapsedTimePerKilometerCell;