import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import Header from "./Header";
import RaceBox from "../../../common/presentational/RaceBox";
import ClassBox from "../presentational/ClassBox";
import OrganisationBox from "./OrganisationBox";
import SearchTextBox from "./SearchTextBox";
import ShowFavoritesButton from "../presentational/ShowFavoritesButton";
import * as eventActions from "../../../actions/eventActions";
import ListType from "../../../logic/models/ListType";
import { Translate, withLocalize } from "react-localize-redux";
import { hasOverallResults, isMultiRace, getSortedRaces, getCurrentRaceId, getSortedClasses, getSortedOrganisations } from "../../../logic/EventUtil";
import { getUrlForList, getUrlForCompetitors, getUrlForTags, getDefaultOrderByForListType } from "../../../logic/ListUtil";
import { sanitizeTagText } from "../../../logic/TagUtil";
import { Formik, Form } from "formik";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";

import "../../../styles/landing-page.scss";

const LandingPage = ({ translate }) => {
    const [ favoritesButtonClicked, setFavoritesButtonClicked ] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const event = useSelector(state => state.eventPage.event);
    const selectedRaceId = useSelector(state => state.eventPage.landingPageSelectedRaceId) ?? getCurrentRaceId(event);
    const favoriteEntryIds = useSelector(state => state.eventPage.favoriteEntryIds);

    const handleRaceChange = newRace => {
        dispatch(eventActions.updateLandingPageSelectedRaceId(newRace.raceId));
    };

    const navigateOrRedirect = urlForListResult => {
        if(urlForListResult.isExternal) {
            window.open(urlForListResult.url, "_blank");
        } else {
            navigate(urlForListResult.url);
        }
    };

    const handleClassStartListButtonClick = formValues => {
        navigateOrRedirect(getUrlForList(
            event,
            {
                raceId: formValues.raceId,
                classIds: formValues.classId ? [formValues.classId] : undefined,
                organisationKey: undefined,
                entryIds: undefined,
                tag: undefined,
                listType: ListType.startList,
                orderBy: getDefaultOrderByForListType(event, formValues.raceId, ListType.startList),
                direction: 1,
            }
        ));
    };

    const handleClassRaceResultListButtonClick = formValues => {
        const listType = formValues.raceId === "all" ? ListType.resultList : ListType.raceResultList;
        navigateOrRedirect(getUrlForList(
            event,
            {
                raceId: formValues.raceId,
                classIds: formValues.classId ? [formValues.classId] : undefined,
                organisationKey: undefined,
                entryIds: undefined,
                tag: undefined,
                listType,
                orderBy: getDefaultOrderByForListType(event, formValues.raceId, listType),
                direction: 1
            }
        ));
    };

    const handleClassOverallResultListButtonClick = formValues => {
        navigateOrRedirect(getUrlForList(
            event,
            {
                raceId: formValues.raceId,
                classIds: formValues.classId ? [formValues.classId] : undefined,
                organisationKey: undefined,
                entryIds: undefined,
                tag: undefined,
                listType: ListType.overallResultList,
                orderBy: "primaryPlace",
                direction: 1
            }
        ));
    };

    const classLabelRenderer = cl => {
        return cl.name;
    };

    const organisationLabelRenderer = organisation => {
        return organisation.name;
    };

    const handleOrganisationStartListButtonClick = formValues => {
        navigateOrRedirect(getUrlForList(
            event,
            {
                raceId: formValues.raceId,
                classIds: undefined,
                organisationKey: formValues.organisationKey,
                entryIds: undefined,
                tag: undefined,
                listType: ListType.startList,
                orderBy: getDefaultOrderByForListType(event, formValues.raceId, ListType.startList),
                direction: 1
            }
        ));
    };

    const handleOrganisationRaceResultListButtonClick = formValues => {
        const listType = formValues.raceId === "all" ? ListType.resultList : ListType.raceResultList;
        navigateOrRedirect(getUrlForList(
            event,
            {
                raceId: formValues.raceId,
                classIds: undefined,
                organisationKey: formValues.organisationKey,
                entryIds: undefined,
                tag: undefined,
                listType,
                orderBy: getDefaultOrderByForListType(event, formValues.raceId, listType),
                direction: 1
            }
        ));
    };

    const handleOrganisationOverallResultListButtonClick = formValues => {
        navigateOrRedirect(getUrlForList(
            event,
            {
                raceId: formValues.raceId,
                classIds: undefined,
                organisationKey: formValues.organisationKey,
                entryIds: undefined,
                tag: undefined,
                listType: ListType.overallResultList,
                orderBy: "primaryPlace",
                direction: 1
            }
        ));
    };

    const handleCompetitorSearchTextSelected = value => {
        if(value) {
            navigate(getUrlForCompetitors(event.slug, value));
        }
    };

    const handleTagSearchTextSelected = value => {
        const sanitizedValue = sanitizeTagText(value);
        if(sanitizedValue) {
            navigate(getUrlForTags(event.slug, sanitizedValue));
        }
    };

    const handleFavoritesButtonClick = () => {
        setFavoritesButtonClicked(!favoritesButtonClicked);
    };

    const handleFavoritesStartListButtonClick = () => {
        navigateOrRedirect(getUrlForList(
            event,
            {
                raceId: selectedRaceId,
                classIds: undefined,
                organisationKey: undefined,
                entryIds: favoriteEntryIds,
                tag: undefined,
                listType: ListType.startList,
                orderBy: getDefaultOrderByForListType(event, selectedRaceId, ListType.startList),
                direction: 1
            }
        ));
    };

    const handleFavoritesRaceResultListButtonClick = formValues => {
        navigateOrRedirect(getUrlForList(
            event,
            {
                raceId: selectedRaceId,
                classIds: undefined,
                organisationKey: undefined,
                entryIds: favoriteEntryIds,
                tag: undefined,
                listType: formValues.raceId === "all" ? ListType.resultList : ListType.raceResultList,
                orderBy: undefined,
                direction: 1
            }
        ));
    };

    const handleFavoritesOverallResultListButtonClick = () => {
        navigateOrRedirect(getUrlForList(
            event,
            {
                raceId: selectedRaceId,
                classIds: undefined,
                organisationKey: undefined,
                entryIds: favoriteEntryIds,
                tag: undefined,
                listType: ListType.overallResultList,
                orderBy: undefined,
                direction: 1
            }
        ));
    };

    const getButtons = (formValues, handleStartListButtonClick, handleRaceResultListButtonClick, handleOverallResultListButtonClick, expanded, hasOverallResults, isMultiRace) => {
        // TODO: buttons should cover full width: three buttons for multi-race, two buttons for single-race
        let classNames = "button-container";
        if(expanded) {
            classNames += " expanded";
        }

        if(formValues.raceId === "all") {
            return (
                <div className={classNames}>
                    <button type="button" className="btn btn-primary" onClick={() => handleStartListButtonClick(formValues)}>
                        <Translate id="startList" />
                    </button>
                    <button type="button" className="btn btn-primary" onClick={() => handleRaceResultListButtonClick(formValues)}>
                        <Translate id="resultList" />
                    </button>
                    <button type="button" className="btn btn-primary invisible">
                        <Translate id="overallResultList" />
                    </button>
                </div>
            );
        }

        return (
            <div className={classNames}>
                <button type="button" className="btn btn-primary" onClick={() => handleStartListButtonClick(formValues)}>
                    <Translate id="startList" />
                </button>
                <button type="button" className="btn btn-primary" onClick={() => handleRaceResultListButtonClick(formValues)}>
                    <Translate id={isMultiRace ? "raceResultList" : "resultList"} />
                </button>
                <button type="button" className={`btn btn-primary ${hasOverallResults ? "": "invisible"}`} onClick={() => handleOverallResultListButtonClick(formValues)}>
                    <Translate id="overallResultList" />
                </button>
            </div>
        );
    };

    const eventIsMultiRace = isMultiRace(event);

    const handleSubmit = () => {

    };

    const initialFormValues = {
        raceId: selectedRaceId,
        classId: undefined,
        organisationKey: undefined,
        competitorSearchText: "",
        tagSearchText: ""
    };

    return (
        <div id="landing-page" className="page">
            <Header
                event={event}
            />

            <div className="landing-page-input-container-wrapper">
                <div className="landing-page-input-container">
                    <Formik
                        initialValues={initialFormValues}
                        enableReinitialize
                        onSubmit={handleSubmit}
                    >
                        {({ values }) => 
                            {
                                const selectedClass = event.classes[values.classId];
                                const selectedOrganisation = event.organisations[values.organisationKey];
                                return (
                                    <Form>
                                        {
                                            isMultiRace(event) && 
                                            (
                                                <div className="landing-page-input">
                                                    <RaceBox
                                                        name="raceId"
                                                        races={getSortedRaces(event, true /* includeAllRacesItem */)}
                                                        onChange={handleRaceChange}
                                                        autoFocus
                                                    />
                                                </div>
                                            )
                                        }
        
                                        <div className="landing-page-input">
                                            <ClassBox
                                                name="classId"
                                                classes={getSortedClasses(event)}
                                                labelRenderer={classLabelRenderer}
                                                autoFocus={!isMultiRace(event)}
                                            />
        
                                            {getButtons(values, handleClassStartListButtonClick, handleClassRaceResultListButtonClick, handleClassOverallResultListButtonClick, !!selectedClass, selectedClass?.hasOverallResults, eventIsMultiRace) }
                                        </div>
        
                                        <div className="landing-page-input">
                                            <OrganisationBox 
                                                name="organisationKey"
                                                organisations={getSortedOrganisations(event)}
                                                labelRenderer={organisationLabelRenderer}
                                            />
        
                                            {getButtons(values, handleOrganisationStartListButtonClick, handleOrganisationRaceResultListButtonClick, handleOrganisationOverallResultListButtonClick, !!selectedOrganisation, hasOverallResults(event, selectedRaceId), eventIsMultiRace)}
                                        </div>
        
                                        <div className="landing-page-input">
                                            <SearchTextBox
                                                name="competitorSearchText"
                                                showButton
                                                placeholder={translate("eventPage.personBox.placeholder")}
                                                onSelected={handleCompetitorSearchTextSelected}
                                            />
                                        </div>
        
                                        {
                                            event.useTags &&
                                            (
                                                <div className="landing-page-input">
                                                    <SearchTextBox 
                                                        name="tagSearchText"
                                                        showButton
                                                        placeholder={translate("eventPage.tagBox.placeholder")}
                                                        onSelected={handleTagSearchTextSelected}
                                                    />
                                                </div>
                                            )
                                        }
        
                                        { 
                                            favoriteEntryIds && favoriteEntryIds.length > 0 &&
                                            (
                                                <div className="landing-page-input">
                                                    <ShowFavoritesButton
                                                        favoriteCount={favoriteEntryIds.length}
                                                        onClick={handleFavoritesButtonClick}
                                                    />
                                                    {getButtons(values, handleFavoritesStartListButtonClick, handleFavoritesRaceResultListButtonClick, handleFavoritesOverallResultListButtonClick, favoritesButtonClicked, hasOverallResults(event, selectedRaceId), eventIsMultiRace)}
                                                </div>                                    
                                            )
                                        }
                                    </Form>
                                );
                            }
                        }
                    </Formik>
                    <Link id="previous-years-link" to="/search">
                        <Translate id="previousYears"/>
                    </Link>
                </div>
            </div>
        </div>
    );
};

LandingPage.propTypes = {
    translate: PropTypes.func.isRequired
};

export default withLocalize(LandingPage);
