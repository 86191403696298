import React from "react";
import { Routes, Route } from "react-router-dom";
import SearchPage from "./pages/search/containers/SearchPage";
import EventLoader from "./pages/event/containers/EventLoader";
import LandingPage from "./pages/event/containers/LandingPage";
import CompetitorsPage from "./pages/event/containers/CompetitorsPage";
import TagsPage from "./pages/event/containers/TagsPage";
import SingleCompetitorPage from "./pages/event/containers/SingleCompetitorPage";
import ListRouter from "./pages/event/containers/ListRouter";
import SplitTimesPage from "./pages/event/containers/SplitTimesPage";
import ClearCachePage from "./pages/event/containers/ClearCachePage";
import KioskPage from "./pages/kiosk/containers/KioskPage";
import MediaPage from "./pages/media/containers/MediaPage";
import DefaultRedirectPage from "./pages/event/containers/DefaultRedirectPage";

export default (
    <Routes>
        <Route path="/search" element={<SearchPage />}/>
        <Route path="/:eventSlug" element={<EventLoader />}>
            <Route index element={<LandingPage />}/>
            <Route path="competitors/:entryId" element={<SingleCompetitorPage />}/>
            <Route path="competitors" element={<CompetitorsPage />}/>
            <Route path="tags" element={<TagsPage />}/>
            <Route path="list" element={<ListRouter />}/>
            <Route path="splits" element={<SplitTimesPage />}/>
            <Route path="kiosk" element={<KioskPage />}/>
            <Route path="media" element={<MediaPage />}/>
            <Route path="media/:subpage" element={<MediaPage />}/>
            <Route path="media/:subpage/:guid" element={<MediaPage />}/>
            <Route path="media/:subpage/:guid/:raceId/:listType" element={<MediaPage />}/>
            <Route path="press" element={<MediaPage />}/>
            <Route path="press/:subpage" element={<MediaPage />}/>
            <Route path="press/:subpage/:guid" element={<MediaPage />}/>
            <Route path="press/:subpage/:guid/:raceId/:listType" element={<MediaPage />}/>
            <Route path="clearCache" element={<ClearCachePage />}/>
        </Route>
        <Route path="/" element={<DefaultRedirectPage />}/>
    </Routes>
);