import { getLocalStorageItem, setLocalStorageItem } from "../logic/BrowserUtil";

const MOBILE_APP_PUSH_NOTIFICATION_HANDLE_LOCAL_STORAGE_ITEM_KEY = "MobileAppPushNotificationHandle";
const MOBILE_APP_PUSH_NOTIFICATION_PLATFORM_LOCAL_STORAGE_ITEM_KEY = "MobileAppPushNotificationPlatform";

// for an example of mobile app <==> web app (C# <==> JS) communication,
// see https://blog.klimczyk.pl/posts/2019/04/call-js-in-csharp-in-xamarin

// creates an object with web app functions that the mobile app can invoke
// functions can have zero or one parameters; if one parameter, it must be a string (serialize objects to json and send as a string if you need more flexibility) 
// return type can be void or any object; if null, a string "null" will be returned, if an object, it will be serialized to a json string
export const createMobileAppToWebAppChannel = () => ({
    setMobileAppPushNotificationHandle
});

// the mobile app injects a WebAppToMobileAppChannel object to the global window object
// if a such object is found, we know that we are executing in a mobile app context
export const isEmbeddedInMobileApp = () => window.WebAppToMobileAppChannel !== undefined;

// store config data from mobile app in local storage

const setMobileAppPushNotificationHandle = (data) => {
    const { handle, platform } = JSON.parse(data);
    setLocalStorageItem(MOBILE_APP_PUSH_NOTIFICATION_HANDLE_LOCAL_STORAGE_ITEM_KEY, handle);
    setLocalStorageItem(MOBILE_APP_PUSH_NOTIFICATION_PLATFORM_LOCAL_STORAGE_ITEM_KEY, platform);
    return { result: "OK", integer: 1 }; // for testing purposes only
};

export const getMobileAppPushNotificationHandle = () => ({
    handle: getLocalStorageItem(MOBILE_APP_PUSH_NOTIFICATION_HANDLE_LOCAL_STORAGE_ITEM_KEY),
    platform: getLocalStorageItem(MOBILE_APP_PUSH_NOTIFICATION_PLATFORM_LOCAL_STORAGE_ITEM_KEY)
});
