import React from "react";
import { withLocalize, Translate } from "react-localize-redux";
import PropTypes from "prop-types";
import { formatElapsedTime, formatTimeBehind } from "../../../logic/TimeUtil";
import { isElapsedTimeSample } from "../../../logic/ResultUtil";

const renderOverallSample = (overallSample, timePresentation) => {
    const overallPlace = overallSample && timePresentation ? overallSample.place : undefined;
    const overallElapsedTime = overallSample && timePresentation && overallSample.elapsedTime ? formatElapsedTime(overallSample.elapsedTime) : undefined;
    const overallTimeBehind = overallSample && timePresentation && overallSample.timeBehind ? formatTimeBehind(overallSample.timeBehind) : undefined;
    const overallStatus = overallSample && overallSample.status;

    if (!overallSample || (timePresentation && isElapsedTimeSample(overallSample))) {
        return [
            <td key="overallSamplePlace" className="overall-place">{overallPlace}</td>,
            <td key="overallElapsedTime" className="overall-elapsed-time">{overallElapsedTime}</td>,
            <td key="overallTimeBehind" className="overall-time-behind">{overallTimeBehind}</td>
        ];
    }
    return [
        <td key="overallElapsedTime" className="overall-place-and-elapsed-time" colSpan="2"><Translate id={`resultStatuses.${overallStatus}`} /></td>,
        <td key="overallTimeBehind" className="overall-time-behind"/>
    ];
};

const ResultRow = ({ result, raceSample, overallSample, timePresentation }) => {
    raceSample = raceSample ?? {};
    const racePlace = timePresentation ? raceSample.place : undefined;
    const organisationName = result.organisation ? result.organisation.name : undefined;
    const raceElapsedTime = timePresentation && raceSample.elapsedTime ? formatElapsedTime(raceSample.elapsedTime) : undefined;
    const raceTimeBehind = timePresentation && raceSample.timeBehind ? formatTimeBehind(raceSample.timeBehind) : undefined;

    return (
        <tr>
            <td className="race-place">{racePlace}</td>
            <td className="person-name">{result.person.firstName} {result.person.lastName}</td>
            <td className="organisation-name">{organisationName}</td>
            <td className="race-elapsed-time">{timePresentation && isElapsedTimeSample(raceSample) ? raceElapsedTime : <Translate id={`resultStatuses.${raceSample.status}`} />}</td>
            <td className="race-time-behind">{raceTimeBehind}</td>
            {renderOverallSample(overallSample, timePresentation)}
        </tr>
    );
};

ResultRow.propTypes = {
    result: PropTypes.object.isRequired,
    raceSample: PropTypes.object,
    overallSample: PropTypes.object,
    timePresentation: PropTypes.bool
};

export default withLocalize(ResultRow);
