import React from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import { isMultiRace } from "../../../logic/EventUtil";
import { getListTypeLabelKey } from "../../../logic/ListUtil";
import ListType from "../../../logic/models/ListType";
import { Field } from "formik";

const ListTypeBox = ({ id, name, event, translate }) => {
    const getListTypes = () => {
        const listTypes = [
            ListType.raceResultList
        ];
        if(isMultiRace(event)) {
            listTypes.push(ListType.overallResultList);
        }
        listTypes.push(ListType.startList);
        return listTypes;
    };

    const eventIsMultiRace = isMultiRace(event);

    return (
        <Field as="select" id={id} name={name} className="form-control">
            {
                getListTypes().map(listType => (
                    <option key={listType} value={listType}>
                        {translate(getListTypeLabelKey(listType, eventIsMultiRace))}
                    </option>
                ))
            }
        </Field>  
    );
};

ListTypeBox.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    event: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired
};

export default withLocalize(ListTypeBox);