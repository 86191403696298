import { getUrlForEntry } from "../../logic/ListUtil";

const emptyString = "";

const createPersonCell = ({ result, event }) => {
    const div = document.createElement("div");
    div.className = "person";
    const a = document.createElement("a");
    a.setAttribute("href", getUrlForEntry(event.slug, result.entryId));
    a.innerText = `${result.person.firstName || emptyString} ${result.person.lastName || emptyString}`;
    div.appendChild(a);
    return div;
};

export default createPersonCell;
