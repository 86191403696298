import { fireTimerWhenWindowIsVisibleOnly, emptyDomElement, getCurrentTime } from "../logic/BrowserUtil";
import { getSample, getElapsedTimePerKilometer, createUnknownSample, createResultsFromReceivedLiveResults, mergeResults, combineResultSets, isLiveTime, getLiveZeroTime, getLiveTime, liveStatusesForFinish, cloneResult, getDatabaseModifiedTimeForSamplesInList, isElapsedTimeStatus, isElapsedTimeSample, mergeIfSamplesAreDifferent, statusesToConsiderAsOkForIntermediate, isElapsedTimeSampleStatus, isInvalidStatus } from "../logic/ResultUtil";
import { getSortFunction, combineSorters, getSortablePersonName } from "../logic/SortUtil";
import { resultBelongsToList, listsAreEqual, getHighlightedResultId } from "../logic/ListUtil";
import { getCurrentTimeForEvent, isLive } from "../logic/EventUtil";
import { isFirstRace } from "../logic/ClassUtil";
import { formatElapsedTime, formatTimeBehind, formatClockTime } from "../logic/TimeUtil";
import { getStore } from "../Store";
import { arraysAreEqual } from "../logic/ArrayUtil";
import { mergeProperties } from "../logic/ObjectUtil";
import { logEvent, Event } from "../logic/LoggingUtil";
import * as jquery from "jquery";
import ListType from "../logic/models/ListType";
import AccumulationType from "../logic/models/AccumulationType";
import ResultStatus from "../logic/models/ResultStatus";
import SampleImportance from "../logic/models/SampleImportance";

import createBibNumberCell from "./cellCreators/createBibNumberCell";
import createClassCell from "./cellCreators/createClassCell";
import createCourseLengthCell from "./cellCreators/createCourseLengthCell";
import createElapsedTimeCell from "./cellCreators/createElapsedTimeCell";
import createElapsedTimePerKilometerCell from "./cellCreators/createElapsedTimePerKilometerCell";
import createMenuButtonCell from "./cellCreators/createMenuButtonCell";
import createOrganisationCell from "./cellCreators/createOrganisationCell";
import createOrganisationNameCell from "./cellCreators/createOrganisationNameCell";
import createOrganisationCountryCodeCell from "./cellCreators/createOrganisationCountryCodeCell";
import createPersonCell from "./cellCreators/createPersonCell";
import createPlaceCell from "./cellCreators/createPlaceCell";
import createOverallPlaceDifferenceSinceStartCell from "./cellCreators/createOverallPlaceDifferenceSinceStartCell";
import createPunchingCardNumberCell from "./cellCreators/createPunchingCardNumberCell";
import createStartTimeCell from "./cellCreators/createStartTimeCell";
import createTimeBehindCell from "./cellCreators/createTimeBehindCell";
import setMenuIcon from "./cellCreators/setMenuIcon";
import ControlType from "../logic/models/ControlType";

// TODO
// dynamically add tooltips for cells where overflow-ellipsis is in use
const showTooltip = () => {
    const element = this; 
    const titleAttributeName = "title"; 
    const title = element.getAttribute(titleAttributeName); 
    const isNarrower = element.offsetWidth < element.scrollWidth;
    if (!title) { 
        if (isNarrower) {
            element.setAttribute(titleAttributeName, element.innerText);
        }
    } else {
        if (!isNarrower) {
            element.removeAttribute(titleAttributeName);
        }
    }
};

const RECENTLY_UPDATED_THRESHOLD = 60000;

export const createListRenderer = o => {
    const options = {
        event: undefined,
        list: {},
        results: undefined,
        favoriteEntryIds: [],
        listItems: undefined,               // array
        listItemDictionary: undefined,      // dictionary, resultId => listItem
        liveSamples: undefined,             // array
        previousSortedResultIds: undefined,
        hasRendered: false,
        sampleGetter: undefined,
        store: getStore(),
        lastLiveResultsReceivedTime: 0,
        lastResultsReceivedTime: 0,
        lastSampleUpdatesReceivedTime: 0,
        onRender: undefined,
        unsubscribeFromStore: undefined,
        eventIsLive: undefined,
        liveTimer: undefined,
        ...o
    };

    const init = () => {
        // here, we connect the renderer to the Redux store
        // onStoreChange() will be called each time the store changes
        // call options.unsubscribeFromStore() to unsubscribe when destroying the list renderer
        options.unsubscribeFromStore = options.store.subscribe(handleStoreChange);

        // the live timer is responsible for updating elapsed times for competitors that have started but not yet finished
        // updates are on a per-second basis
        options.liveTimer = createLiveTimer();

        addEventListeners();
    };

    const createLiveTimer = () => {
        let timer;
        const timerSetter = value => timer = value;
        // fireTimerWhenWindowIsVisibleOnly fires directly if the window is visible
        fireTimerWhenWindowIsVisibleOnly(timerSetter, handleLiveTimerElapsed, 1000);
        
        const destroy = () => {
            if(timer) {
                window.clearInterval(timer);
            }
        };
    
        const trigger = () => {
            handleLiveTimerElapsed();
        };
    
        return {
            destroy,
            trigger
        };
    };

    const initialize = (event, results, list, favoriteEntryIds, highlightedResultId, calculatePlacesAndTimeBehinds, translate) => {
        if(results) {
            const clonedResults = results.map(r => cloneResult(r));

            const now = getCurrentTimeForEvent(event);
            options.eventIsLive = isLive(event); // must set this one early

            const sampleGetter = createSampleGetter(list); 
            const listItems = createListItems(event, clonedResults, list, sampleGetter, favoriteEntryIds, highlightedResultId, now, translate);
            if(calculatePlacesAndTimeBehinds) {
                calculatePlacesAndTimeBehindsForClassListAfterListItemUpdate(listItems, list);
            }

            const liveSampleDictionary = [];
            const listItemDictionary = [];
            listItems.forEach(listItem => {
                [listItem.primaryLive, listItem.secondaryLive].forEach(liveSample => {
                    liveSampleDictionary[liveSample.sample.sampleId] = liveSample;
                });
                listItemDictionary[listItem.result.resultId] = listItem;
            });
            const liveSamples = Object.values(liveSampleDictionary);
    
            options.hasRendered = false;
            emptyDomElement(options.renderingDomElement);
            const documentFragment = document.createDocumentFragment();
            listItems.forEach(listItem => documentFragment.appendChild(listItem.domElementData.li));
            options.renderingDomElement.appendChild(documentFragment);
            
            options.event = event;
            options.results = clonedResults;
            options.list = list;
            options.sampleGetter = sampleGetter;
            
            options.liveSamples = liveSamples;
            options.listItems = listItems;
            options.listItemDictionary = listItemDictionary;

            render();

            const element = document.querySelectorAll("[data-highlightonload]")[0];
            const scrollContainer = document.querySelector(".list-scroll-container");
            if(element && scrollContainer.scrollTo) {
                // scroll into view, centered
                const elementRect = element.getBoundingClientRect();
                const scrollContainerRect = scrollContainer.getBoundingClientRect();
                const elementMiddle = (elementRect.top - scrollContainerRect.top) + elementRect.height / 2; 
                const middle = elementMiddle - (scrollContainer.clientHeight / 2);
                scrollContainer.scrollTo({
                    top: middle,
                    left: 0,
                    behavior: "auto"
                });
            }
        }
    };

    const refresh = args => {
        initialize(
            options.event,
            options.results,
            options.list, 
            options.favoriteEntryIds,
            getHighlightedResultId(),
            args?.calculatePlacesAndTimeBehinds,
            options.getTranslate()
        );
    };

    const destroy = () => {
        if(options.liveTimer) {
            options.liveTimer.destroy();
        }
        if(options.unsubscribeFromStore) {
            options.unsubscribeFromStore();
        }
        removeEventListeners();
    };

    const refreshMenuButton = entryId => {
        const listItem = options.listItems.find(li => li.result.entryId === entryId);
        if (listItem) {
            const menuButton = listItem.domElementData.cells["menu"].querySelector(".menu-button");
            setMenuIcon(menuButton, listItem.result, options.favoriteEntryIds);
        }
    };

    const render = updatePlacesAndTimeBehindsForNonLiveListItems => {
        // called every second
        const now = getCurrentTimeForEvent(options.event);
        options.eventIsLive = isLive(options.event);

        if(options.listItems) {
            const isClassList = !!options.list.classIds;

            updateLiveSamples(options.liveSamples, isClassList, now);
            refreshDataInLiveSamplesDomElements(options.liveSamples, isClassList, isClassList && updatePlacesAndTimeBehindsForNonLiveListItems);
            sortListItems(options.listItems, options.list.orderBy, options.list.direction);
            
            const sortedResultIds = options.listItems.map(o => o.result.resultId);

            if(!options.hasRendered) {
                // first time after initialize
                options.hasRendered = true;
                toggleVisibility(options.renderingDomElement, true);
                repositionListItemDomElements(options.listItems, options.renderingDomElement, true /* forceCompleteRepositioning */);
            } else {
                // second time after initialize and onwards
                // reposition dom elements only if needed
                const performElementRepositioning = !arraysAreEqual(options.previousSortedResultIds, sortedResultIds);
                if(performElementRepositioning) {
                    repositionListItemDomElements(options.listItems, options.renderingDomElement);
                }
            }
    
            options.previousSortedResultIds = sortedResultIds;

            // handle competitors that have just started
            const justStartedLiveSamples = options.liveSamples
                .filter(o => o.willBeEnabledTime && o.willBeEnabledTime <= now && options.eventIsLive);
            handleJustStartedLiveSamples(justStartedLiveSamples, now);
        }

        if(options.onRender) {
            options.onRender({
                listItems: options.listItems,
                now
            });
        }
    };

    // **************************************************************************** EVENT HANDLERS ****************************************************************************

    const addEventListeners = () => {
        jquery(document).on("click", "#list .list-body a", handleForwardLinkClick);
        jquery(document).on("click", "#list .list-body .toggle-favorite-button", handleFavoriteButtonClick);
        jquery(document).on("click", "#list .list-body .manage-tags-button", handleManageTagsButtonClick);
        window.addEventListener("orientationchange", handleOrientationChange);    
        window.addEventListener("resize", handleResize);    
        
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeprint
        window.addEventListener("beforeprint", handleBeforePrint);
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onafterprint
        window.addEventListener("afterprint", handleAfterPrint);
    };

    const removeEventListeners = () => {
        jquery(document).off("click", "#list .list-body a", handleForwardLinkClick);
        jquery(document).off("click", "#list .list-body .toggle-favorite-button", handleFavoriteButtonClick);
        jquery(document).off("click", "#list .list-body .manage-tags-button", handleManageTagsButtonClick);
        window.removeEventListener("orientationchange", handleOrientationChange);
        window.removeEventListener("resize", handleResize);
        window.removeEventListener("beforeprint", handleBeforePrint);
        window.removeEventListener("afterprint", handleAfterPrint);
        clearListItemsEventListeners(options.listItems);
    };

    const handleLiveTimerElapsed = () => {
        render();
    };

    const handleStoreChange = () => {
        // this function is called when new data is available in the Redux store
        const translate = options.getTranslate();
        const state = options.store.getState();
        const {
            event,
            results, // results requested via the API, not including live updates
            receivedLiveResults, // all results that have been received over SignalR since the API request was made
            receivedSampleUpdates, // all samples that have been received over SignalR since the API request was made
            list,
            loading,
            favoriteEntryIds,
            lastResultsReceivedTime,
            lastLiveResultsReceivedTime,
            lastSampleUpdatesReceivedTime
        } = state.eventPage;

        if(loading.results) {
            toggleVisibility(options.renderingDomElement, false);
            clearListItemsEventListeners(options.listItems);
            options.listItems = undefined;
        }
        options.favoriteEntryIds = favoriteEntryIds;

        if(lastResultsReceivedTime !== options.lastResultsReceivedTime || options.list.listType !== list.listType || options.list.controlName !== list.controlName) {
            // combine the API fetched results with the SignalR-received results
            const receivedLiveResultsArray = createResultsFromReceivedLiveResults(receivedLiveResults, event, 0, result => resultBelongsToList(result, list));
            const combinedResults = combineResultSets(results, receivedLiveResultsArray);
            
            initialize(event, combinedResults, list, options.favoriteEntryIds, undefined, false, translate);
            options.lastResultsReceivedTime = lastResultsReceivedTime;
        } else if (lastLiveResultsReceivedTime !== options.lastLiveResultsReceivedTime) {
            // we have got new results via SignalR
            insertOrUpdateListItemsAfterLiveResultsReceived(receivedLiveResults, lastLiveResultsReceivedTime, event, translate);
        } else if (receivedSampleUpdates && lastSampleUpdatesReceivedTime !== options.lastSampleUpdatesReceivedTime) {
            // we have got updated samples when it comes to places or times behind
            updateListItemsAfterSampleUpdatesReceived(receivedSampleUpdates, lastSampleUpdatesReceivedTime, event, translate);
        } else if(!listsAreEqual(options.list, list)) {
            options.list = list;
            options.hasRendered = false;
            render();
        }
    };

    const handleForwardLinkClick = jQueryEvent => {
        // clicking an anchor element will break the React Router navigation
        // therefore, capture the click event and propagate it upwards where it can be handled by React Router
        let linkElement = jQueryEvent.target;
        while(linkElement && linkElement.localName !== "a") {
            linkElement = linkElement.parentNode;
        }
        if(linkElement) {
            jQueryEvent.preventDefault();
            const url = linkElement.getAttribute("href");
            const isExternalLink = linkElement.getAttribute("target") === "_blank";
            if(url) {
                options.onLinkClick(url, isExternalLink, jQueryEvent);
            }
        }
    };

    const handleFavoriteButtonClick = jQueryEvent => {
        const element = jQueryEvent.currentTarget;
        const toggled = !(element.dataset.toggled === "true");
        // need to store toggled value on menu button, a bit ugly
        const menuButton = element.parentNode.parentNode.firstChild;
        menuButton.dataset.isFavorite = toggled;
        const entryId = parseInt(element.dataset.entryId);
        if(options.onFavoriteButtonClick) {
            options.onFavoriteButtonClick(entryId, toggled);
        }
        refreshMenuButton(entryId);
    };

    const handleManageTagsButtonClick = jQueryEvent => {
        if(options.listItems) {
            const element = jQueryEvent.currentTarget;
            const entryId = parseInt(element.dataset.entryId);
            const listItem = options.listItems.find(li => li.result.entryId === entryId);
            if(options.onManageTagsButtonClick && listItem) {
                options.onManageTagsButtonClick(listItem.result);
            }
        }
    };

    const handleOrientationChange = () => {
        options.hasRendered = false;
        render();
    };

    const handleResize = () => {
        options.hasRendered = false;
        render();
    };

    const handleBeforePrint = () => {
        logEvent(Event.print);
        // Microsoft Edge and Firefox don't handle transform: translateY(...) well when printing
        // therefore, replace transform with top during the printing process
        options.listItems.forEach(listItem => {
            const li = listItem.domElementData.li;
            li.style.top = `${listItem.domElementData.top}px`;
            li.style.transform = "translateY(0)";
        });
    };

    const handleAfterPrint = () => {
        // Microsoft Edge and Firefox don't handle transform: translateY(...) well when printing
        // therefore, replace transform with top during the printing process
        options.listItems.forEach(listItem => {
            const li = listItem.domElementData.li;
            li.style.top = "";
            li.style.transform = `translateY(${listItem.domElementData.top}px)`;
        });
    };

    // **************************************************************************** LIST ITEMS AND LIVE SAMPLES ****************************************************************************

    /*
    listItem: {
        result,                    // the result object - never replaced by a new object, but properties can be updated
        primarySample,             // the primary sample, e.g. the race elapsed time for a race result list - never replaced by a new object, but properties can be updated
        secondarySample,           // the secondary sample, e.g. the overall elapsed time for a race result list - never replaced by a new object, but properties can be updated
        raceSample                 // the race sample; not affected by the list type (race or overall)
        previousPositionInList,    // the position in the list, zero-based, for the list item during the last render operation 
        domElementData: {          // keeps references to DOM element related to the list items, e.g. all cells in the row
            li,                            // the li element that acts as the container for the listItem
            top,                           // the y position of the list item DOM element, used for printing
            cells,                         // cellName => DOM element dictionary keeping track of the div elements for the cells
            recentlyUpdatedFadeoutCallback // function to call one minute after a result update was encountered
        },
        primaryLive: {             // object containing live-related data for the primary sample
            listItem,              // the parent list item
            sample,                // the parent primary sample
            enabled,               // whether this sample is live, e.g. the competitor has started but not yet punched the sample
            elapsedTime,           // the (primary) sample elapsed time, but only if the competitor has punched the sample with an OK status
            livePlace,             // the current place for competitor that has punched the sample, or the place for a live competitor if the sample would be punched right now
            liveElapsedTime,       // the current elapsed time for a competitor that has punched the sample, or the elapsed time for a live competitor if the sample would be punched right now
            liveTimeBehind,        // the current time behind for a competitor that has punched the sample, or the time behind for a live competitor if the sample would be punched right now
            liveZeroTime,          // if the sample is live and not disqualified, the time that the competitor started (the actual start time if a race sample, 
                                   // or a fictive start time reflecting the overall time if an overall sample)
            comparisonElapsedTime, // elapsed time value used when comparing non-live and live competitors. Fixed elapsed time for competitors that have punched the sample, live time for live competitors, null for not started or disqualified competitors.
            comparisonTimeBehind,  // time behind value used when comparing non-live and live competitors. Fixed time behind for competitors that have punched the sample, live time behind for live competitors, null for not started or disqualified competitors.
            comparisonPlace,       // place value used when comparing non-live and live competitors. Fixed place for competitors that have punched the sample, live place if the sample would be punched right now for live competitors, null for not started or disqualified competitors.
            comparisonStatus,      // status value used when comparing non-live and live competitors. Fixed status for not started competitors and competitors that have punched the sample, OK for live competitors. 
            willBeEnabledTime,     // the time when a competitor becomes live, e.g. the start time. Only set for not started competitors that we know the start time for.
            domElements: {         // object containing the DOM elements for cells that are subject for live update
                elapsedTime.       // the DOM element for the elapsed time cell
                timeBehind,        // the DOM element for the time behind cell
                place,             // the DOM element for the place cell
            }
        },
        secondaryLive: {          // object containing live-related data for the secondary sample
            [see above] 
        }
    }
    */

   const insertOrUpdateListItemsAfterLiveResultsReceived = (receivedLiveResults, lastLiveResultsReceivedTime, event, translate) => {
        const newResults = createResultsFromReceivedLiveResults(
            receivedLiveResults, 
            event, 
            options.lastLiveResultsReceivedTime,
            result => resultBelongsToList(result, options.list));

        if(newResults.length > 0) {
            log("New results", newResults);
        }
        insertOrUpdateListItems(event, newResults, translate);
        options.lastLiveResultsReceivedTime = lastLiveResultsReceivedTime;
        render(true /* updatePlacesAndTimeBehindsForNonLiveListItems */);
    };

    const updateListItemsAfterSampleUpdatesReceived = (receivedSampleUpdates, lastSampleUpdatesReceivedTime, event, translate) => {
        const newSampleUpdates = receivedSampleUpdates
            .filter(o => o.time > options.lastSampleUpdatesReceivedTime)
            .map(o => o.sampleUpdate);
        const now = getCurrentTimeForEvent(event);
        const isClassList = !!options.list.classIds;

        const items = [];
        if (options.listItems) {
            options.listItems.forEach(li => li.result.samples.forEach(sample => items[sample.sampleId] = {
                sample,
                result: li.result,
                li
            }));
        }

        const resultsToUpdate = [];
        newSampleUpdates.forEach(sampleUpdate => {
            const item = items[sampleUpdate.sampleId];
            if (item) {
                const { sample, result, li } = item;
                let resultToUpdate = resultsToUpdate[result.resultId];
                if (!resultToUpdate) {
                    resultToUpdate = {
                        result: cloneResult(result),
                        li
                    };
                    resultsToUpdate[result.resultId] = resultToUpdate;
                }

                const sampleToUpdate = resultToUpdate.result.samples.find(s => s.sampleId === sample.sampleId);
                sampleToUpdate.place = sampleUpdate.place;
                sampleToUpdate.overallPlaceDifferenceSinceStart = sampleUpdate.overallPlaceDifferenceSinceStart;
                sampleToUpdate.timeBehind = sampleUpdate.timeBehind;
            }
        });
        resultsToUpdate.forEach(result => updateListItem(result.li, result.result, isClassList, options.sampleGetter, now, translate));

        options.lastSampleUpdatesReceivedTime = lastSampleUpdatesReceivedTime;
        render();
    };

    const createListItems = (event, results, list, sampleGetter, favoriteEntryIds, highlightedResultId, now, translate) => {
        if(!results) {
            return undefined;
        }
        return results.map(result => createListItem(event, result, list, sampleGetter, favoriteEntryIds, highlightedResultId, now, translate));
    };

    const createListItemEventListeners = (listItem, list) => {
        clearListItemEventListeners(listItem);
        const timeSinceModification = getCurrentTime() - getDatabaseModifiedTimeForSamplesInList(listItem.result.samples, list);

        if(timeSinceModification < RECENTLY_UPDATED_THRESHOLD) {
            listItem.domElementData.li.classList.add("recently-updated");
            listItem.domElementData.recentlyUpdatedFadeoutCallback = window.setTimeout(() => {
                listItem.domElementData.li.classList.remove("recently-updated");
            }, RECENTLY_UPDATED_THRESHOLD - timeSinceModification);
        }
    };

    const clearListItemsEventListeners = listItems => {
        if(listItems) {
            listItems.forEach(clearListItemEventListeners);
        }
    };

    const clearListItemEventListeners = listItem => {
        if(listItem.domElementData && listItem.domElementData.recentlyUpdatedFadeoutCallback) {
            window.clearTimeout(listItem.domElementData.recentlyUpdatedFadeoutCallback);
            listItem.domElementData.recentlyUpdatedFadeoutCallback = undefined;
        }
    };

    const createListItem = (event, result, list, sampleGetter, favoriteEntryIds, highlightedResultId, now, translate) => {
        const isClassList = !!list.classIds;

        const clonedResult = cloneResult(result);
        const primarySample = sampleGetter(SampleImportance.primary, clonedResult, isClassList);
        const secondarySample = sampleGetter(SampleImportance.secondary, clonedResult, isClassList);
        const raceSample = primarySample.accumulationType === AccumulationType.race 
            ? primarySample
            : secondarySample;
        const cells = createCells({ event, result: clonedResult, primarySample, secondarySample, raceSample, list, now, translate, favoriteEntryIds });
        const listItem = { 
            result: clonedResult,
            primarySample,
            secondarySample,
            raceSample,
            previousPositionInList: undefined
        };
        listItem.domElementData = {
            li: createListItemDomElement(listItem, cells, listItem.result.resultId === highlightedResultId),
            cells,
            recentlyUpdatedFadeoutCallback: undefined
        };
        createListItemEventListeners(listItem, list);

        listItem.primaryLive = createLiveSample(listItem, primarySample, SampleImportance.primary, now);
        listItem.secondaryLive = createLiveSample(listItem, secondarySample, SampleImportance.secondary, now);
        return listItem;
    };

    // called only when results are updated by SignalR
    const updateListItem = (targetListItem, sourceResult, isClassList, sampleGetter, now, translate) => {
        const sourcePrimarySample = sampleGetter(SampleImportance.primary, sourceResult, isClassList);
        const sourceSecondarySample = sampleGetter(SampleImportance.secondary, sourceResult, isClassList);
        const updated = mergeResults(targetListItem.result, sourceResult);

        if(!sourcePrimarySample.isNullObject) {
            mergeIfSamplesAreDifferent(targetListItem.primaryLive.sample, sourcePrimarySample);
            mergeIfSamplesAreDifferent(targetListItem.primarySample, sourcePrimarySample);
        }
        if(!sourceSecondarySample.isNullObject) {
            mergeIfSamplesAreDifferent(targetListItem.secondaryLive.sample, sourceSecondarySample);
            mergeIfSamplesAreDifferent(targetListItem.secondarySample, sourceSecondarySample);
        }

        mergeProperties(targetListItem.primaryLive, createLiveSample(targetListItem, targetListItem.primarySample, SampleImportance.primary, now));
        mergeProperties(targetListItem.secondaryLive, createLiveSample(targetListItem, targetListItem.secondarySample, SampleImportance.secondary, now));

        updateListItemCells(targetListItem, now, translate);

        return updated;
    };

    const updateListItemCells = (listItem, now, translate) => {
        const newCells = createCells({ 
            event: options.event,
            result: listItem.result, 
            primarySample: listItem.primarySample, 
            secondarySample: listItem.secondarySample, 
            raceSample: listItem.raceSample, 
            list: options.list, 
            now, 
            translate: translate,
            favoriteEntryIds: options.favoriteEntryIds
        });

        updateListItemDomElement(listItem, newCells);
        createListItemEventListeners(listItem, options.list);
    };

    // called only when results are updated by SignalR
    const insertOrUpdateListItems = (event, results, translate) => {
        const now = getCurrentTimeForEvent(event);
        const isClassList = !!options.list.classIds;
        // loop through all results
        results.forEach(result => {
            // do we have an existing list item for this result?
            let existingListItem = options.listItemDictionary[result.resultId];
            const isDeleted = isResultDeleted(result, isClassList);

            if(existingListItem) {
                if(isDeleted) {
                    removeListItem(existingListItem);
                } else {
                    updateListItem(existingListItem, result, isClassList, options.sampleGetter, now, translate);
                }
            } else {
                // a new result; add to dictionary and array
                if(!isDeleted) {
                    const newListItem = createListItem(event, result, options.list, options.sampleGetter, options.favoriteEntryIds, undefined, now, translate);
                    options.listItemDictionary[newListItem.result.resultId] = newListItem;
                    options.listItems.push(newListItem);
                    options.results.push(result);
                    options.renderingDomElement.appendChild(newListItem.domElementData.li);
                    existingListItem = newListItem;
                }
            }
        });
        if(options.list.classIds && options.listItems) {
            calculatePlacesAndTimeBehindsForClassListAfterListItemUpdate(options.listItems, options.list);
            // a new result has the ability to change all other results' places and times behind
            // so we need to recreate the cells for all results
            options.listItems.forEach(listItem => updateListItemCells(listItem, now, translate));
        }
    };

    const removeListItem = (listItem) => {
        clearListItemEventListeners(listItem);
        const li = listItem.domElementData.li;
        li.parentNode.removeChild(li);
        options.results = options.results.filter(o => o.resultId !== listItem.resultId);
        options.listItemDictionary[listItem.result.resultId] = undefined;
        options.listItems = options.listItems.filter(o => o.result.resultId !== listItem.result.resultId);
        [listItem.primaryLive, listItem.secondaryLive].forEach(liveSample => {
            options.liveSamples = options.liveSamples.filter(o => o.sampleId !== liveSample.sampleId);
        });
    };

    const isResultDeleted = (result, isClassList) => {
        return result.isDeleted 
            || options.sampleGetter(SampleImportance.primary, result, isClassList).status === ResultStatus.deleted
            || options.sampleGetter(SampleImportance.secondary, result, isClassList).status === ResultStatus.deleted;        
    };

    const calculatePlacesAndTimeBehindsForClassListAfterListItemUpdate = (listItems, list) => {
        const skipOverallPlaceDifferenceSinceStartCalculation = isFirstRace(list.raceId);

        [SampleImportance.primary, SampleImportance.secondary].forEach(sampleImportance => {
            const sampleKey = `${sampleImportance}Sample`;
            const liveKey = `${sampleImportance}Live`;
            const elapsedTimes = listItems
                .filter(listItem => isElapsedTimeSample(listItem[sampleKey]))
                .map(listItem => listItem[sampleKey].elapsedTime);
            const sortedTimesData = getSortedTimesData(elapsedTimes);

            const validOverallElapsedTimesAtStart = skipOverallPlaceDifferenceSinceStartCalculation
                ? []
                : listItems
                    .filter(listItem => isValidSampleForPlaceDifferenceSinceStartCalculation(listItem[sampleKey]))
                    .map(listItem => listItem.result.overallElapsedTimeAtStart)
                    .filter(time => time !== undefined);
            const sortedOverallElapsedTimesAtStartData = getSortedTimesData(validOverallElapsedTimesAtStart);

            listItems.forEach(listItem => {
                const elapsedTime = listItem[sampleKey].elapsedTime;
                if(elapsedTime && isElapsedTimeSample(listItem[sampleKey])) {
                    const place = sortedTimesData.placeForTime[elapsedTime];
                    const overallPlaceAtStart = sortedOverallElapsedTimesAtStartData.placeForTime[listItem.result.overallElapsedTimeAtStart];
                    listItem[sampleKey].place = place;
                    listItem[sampleKey].timeBehind = elapsedTime - sortedTimesData.bestTime;
                    listItem[sampleKey].overallPlaceDifferenceSinceStart = place && overallPlaceAtStart
                        ? place - overallPlaceAtStart
                        : undefined;
                    const sampleInResultObject = listItem.result.samples.find(o => o.sampleId === listItem[sampleKey].sampleId);
                    if(sampleInResultObject) {
                        sampleInResultObject.place = listItem[sampleKey].place;
                        sampleInResultObject.timeBehind = listItem[sampleKey].timeBehind;
                        sampleInResultObject.overallPlaceDifferenceSinceStart = listItem[sampleKey].overallPlaceDifferenceSinceStart;
                    }
                    listItem[liveKey].comparisonPlace = listItem[sampleKey].place;
                    listItem[liveKey].comparisonTimeBehind = listItem[sampleKey].timeBehind;
                }
            });
        });
    };

    const getSortedTimesData = times => {
        const sortedTimes = [...times];
        sortedTimes.sort((a, b) => a - b);
        const placeForTime = [];
        let previousTime, placeExcludingTies = 0, place, bestTime = sortedTimes[0];

        sortedTimes.forEach(time => {
            placeExcludingTies++;
            if(time !== previousTime) {
                place = placeExcludingTies;
            }
            if(!placeForTime[time]) {
                placeForTime[time] = place;
            }
        });

        return {
            sortedTimes,
            bestTime,
            placeForTime
        };
    };

    const isValidSampleForPlaceDifferenceSinceStartCalculation = sample => {
        if(sample.accumulationType !== AccumulationType.overall) {
            return false;
        }
        return (sample.elapsedTime && isElapsedTimeSample(sample)) || isInvalidStatus(sample.status);
    };

    const createSampleGetter = list => {
        const controlType = list.controlName
            ? ControlType.control
            : ControlType.finish;
        // we're using two samples:
        // - a primary (e.g. race finish sample for a race result list), called "primarySample"
        // - a secondary (e.g. overall finish sample for a race result list), called "secondarySample"
        switch(list.listType) {
            case ListType.startList:
                return () => ({});
    
            case ListType.resultList:
            case ListType.raceResultList:
                return (sampleImportance, result, isClassList) => sampleImportance === SampleImportance.primary
                    ? getSample(result, AccumulationType.race, controlType, list.controlName, isClassList) || createUnknownSample(result, AccumulationType.race, controlType, list.controlName)
                    : getSample(result, AccumulationType.overall, controlType, list.controlName, isClassList) || createUnknownSample(result, AccumulationType.overall, controlType, list.controlName);
    
            case ListType.overallResultList:
                return (sampleImportance, result, isClassList) => sampleImportance === SampleImportance.primary
                    ? getSample(result, AccumulationType.overall, controlType, list.controlName, isClassList) || createUnknownSample(result, AccumulationType.overall, controlType, list.controlName)
                    : getSample(result, AccumulationType.race, controlType, list.controlName, isClassList) || createUnknownSample(result, AccumulationType.race, controlType, list.controlName);
        }
    };
    
    const createLiveSample = (listItem, sample, sampleImportance, now) => {
        const result = listItem.result;
        const elapsedTime = sample.elapsedTime !== undefined && isElapsedTimeSample(sample)
            ? sample.elapsedTime
            : undefined;
        const liveSample = {
            listItem,
            sample,
            comparisonTimeBehind: sample.timeBehind,
            comparisonPlace: sample.place,
            elapsedTime,
            willBeEnabledTime: result.startTime && result.startTime > now && liveStatusesForFinish.indexOf(sample.status) !== -1
                ? result.startTime
                : undefined,
            domElements: getLiveSampleDomElements(listItem, sampleImportance)
        };
        updateLiveSample(liveSample, now);
        return liveSample;
    };

    const updateLiveSamples = (liveSamples, isClassList, now) => {
        // calculate live elapsed times
        liveSamples.forEach(liveSample => {
            if(liveSample.enabled) {
                liveSample.liveElapsedTime = liveSample.liveZeroTime === undefined
                    ? undefined // probably a no time presentation class
                    : (now - liveSample.liveZeroTime) / 1000;
                liveSample.comparisonElapsedTime = liveSample.liveElapsedTime;
            }
        });
    
        // live times behind and live places are only relevant for class lists
        if(isClassList) {
            // divide in race and overall samples
            [AccumulationType.race, AccumulationType.overall].forEach(accumulationType => {
                const liveSamplesForThisAccumulation = liveSamples.filter(ls => ls.sample.accumulationType === accumulationType);
        
                // sort based on live elapsed times
                liveSamplesForThisAccumulation.sort(liveSampleSorter);
        
                // calculate live times behind and places
                let lastTime, 
                    bestElapsedTime, 
                    place = 0,
                    placeExcludingTies = 0;
                // calculate places by looping through the sorted array
                liveSamplesForThisAccumulation.forEach(liveSample => {
                    if(liveSample.elapsedTime !== undefined) {
                        placeExcludingTies++;
                        if(placeExcludingTies === 1) {
                            bestElapsedTime = liveSample.elapsedTime;
                        }
                        if(liveSample.elapsedTime !== lastTime) {
                            place = placeExcludingTies;
                        }
                        lastTime = liveSample.elapsedTime;
                    } else if(liveSample.enabled && liveSample.liveElapsedTime !== undefined) {
                        liveSample.livePlace = place + 1;
                        liveSample.comparisonPlace = liveSample.livePlace;
                    }
                });
                // calculate times behind
                if(bestElapsedTime !== undefined) {
                    liveSamplesForThisAccumulation.forEach(liveSample => {
                        if(liveSample.enabled && liveSample.liveElapsedTime !== undefined ) {
                            liveSample.liveTimeBehind = liveSample.liveElapsedTime - bestElapsedTime;
                            liveSample.comparisonTimeBehind = liveSample.liveTimeBehind;
                        }
                    });
                }        
            });
        }
    };
    
    const updateLiveSample = (liveSample, now) => {
        const result = liveSample.listItem.result;
        const sample = liveSample.sample;
        const enabled = isLiveTime(result, sample, now) && options.eventIsLive;
        const liveElapsedTime = enabled 
            ? getLiveTime(result, sample, now) 
            : undefined;

        liveSample.enabled = enabled;
        liveSample.liveZeroTime = enabled 
            ? getLiveZeroTime(result, sample)
            : undefined;
        liveSample.liveElapsedTime = liveElapsedTime;
        liveSample.comparisonElapsedTime = liveElapsedTime !== undefined
            ? liveElapsedTime 
            : liveSample.elapsedTime;
        liveSample.comparisonStatus = 
            liveElapsedTime || 
            (sample.controlType === ControlType.finish && sample.status === ResultStatus.finished) || 
            (sample.controlType === ControlType.control && statusesToConsiderAsOkForIntermediate.indexOf(sample.status) !== -1)
                ? ResultStatus.ok 
                : sample.status;
    };

    const handleJustStartedLiveSamples = (justStartedLiveSamples, now) => {
        justStartedLiveSamples.forEach(justStartedLiveSample => {
            updateLiveSample(justStartedLiveSample, now);
            Object.values(justStartedLiveSample.domElements).forEach(domElement => {
                domElement.classList.remove("is-start-time");
                domElement.classList.remove("is-text-status");
                domElement.classList.add("is-live");
            });
        });
    };

    // **************************************************************************** DOM-RELATED FUNCTIONS ****************************************************************************

    const createListItemDomElement = (listItem, cells, highlighted) => {
        const li = document.createElement("li");
        li.setAttribute("data-resultid", listItem.result.resultId);
        li.setAttribute("data-entryid", listItem.result.entryId);
        if(highlighted) {
            li.setAttribute("data-highlightonload", "true");
        }
        Object.values(cells).forEach(cell => li.appendChild(cell));
        return li;
    };

    const updateListItemDomElement = (listItem, cells) => {
        const li = listItem.domElementData.li;
        emptyDomElement(li);
        Object.values(cells).forEach(cell => li.appendChild(cell));
        listItem.domElementData.cells = cells;
        [SampleImportance.primary, SampleImportance.secondary]
            .forEach(sampleImportance => 
                listItem[`${sampleImportance}Live`].domElements = getLiveSampleDomElements(listItem, sampleImportance)
            );
    };

    const createCells = context => {
        const timeCellVisibilities = {
            [SampleImportance.primary]: getTimeCellVisibilities(context.result, context.primarySample, context.now),
            [SampleImportance.secondary]: getTimeCellVisibilities(context.result, context.secondarySample, context.now),
        };

        const cells = [];
        cells["primaryPlace"] = createPlaceCell(context, context.primarySample, timeCellVisibilities, SampleImportance.primary);
        cells["secondaryPlace"] = createPlaceCell(context, context.secondarySample, timeCellVisibilities, SampleImportance.secondary);
        cells["primaryOverallPlaceDifferenceSinceStart"] = createOverallPlaceDifferenceSinceStartCell(context, context.primarySample, SampleImportance.primary);
        cells["secondaryOverallPlaceDifferenceSinceStart"] = createOverallPlaceDifferenceSinceStartCell(context, context.secondarySample, SampleImportance.secondary);
        cells["primaryElapsedTime"] = createElapsedTimeCell(context, context.primarySample, timeCellVisibilities, SampleImportance.primary);
        cells["secondaryElapsedTime"] = createElapsedTimeCell(context, context.secondarySample, timeCellVisibilities, SampleImportance.secondary);
        cells["primaryTimeBehind"] = createTimeBehindCell(context, context.primarySample, timeCellVisibilities, SampleImportance.primary);
        cells["secondaryTimeBehind"] = createTimeBehindCell(context, context.secondarySample, timeCellVisibilities, SampleImportance.secondary);
        cells["person"] = createPersonCell(context);
        cells["organisation"] = createOrganisationCell(context);
        cells["organisationName"] = createOrganisationNameCell(context);
        cells["organisationCountryCode"] = createOrganisationCountryCodeCell(context);
        cells["class"] = createClassCell(context);
        cells["startTime"] = createStartTimeCell(context);
        cells["elapsedTimePerKilometer"] = createElapsedTimePerKilometerCell(context);
        cells["bibNumber"] = createBibNumberCell(context);
        cells["courseLength"] = createCourseLengthCell(context);
        cells["punchingCardNumber"] = createPunchingCardNumberCell(context);
        cells["menu"] = createMenuButtonCell(context);
        return cells;
    };

    const getLiveSampleDomElements = (listItem, sampleImportance) => ({
        elapsedTime: listItem.domElementData.cells[`${sampleImportance}ElapsedTime`],
        timeBehind: listItem.domElementData.cells[`${sampleImportance}TimeBehind`],
        place: listItem.domElementData.cells[`${sampleImportance}Place`]
    });

    const refreshDataInLiveSamplesDomElements = (liveSamples, updatePlacesAndTimeBehindsForLiveListItems, updatePlacesAndTimeBehindsForNonLiveListItems) => {
        if(liveSamples) {
            liveSamples.forEach(liveSample => {
                if(liveSample.enabled) {
                    if(liveSample.domElements.elapsedTime) {
                        liveSample.domElements.elapsedTime.innerText = liveSample.liveElapsedTime === undefined
                            ? formatClockTime(liveSample.listItem.result.startTime, options.event.timeZone) // probably a no time presentation class
                            : formatElapsedTime(liveSample.liveElapsedTime);
                    }
                    if(updatePlacesAndTimeBehindsForLiveListItems) {
                        if(liveSample.domElements.timeBehind) {
                            liveSample.domElements.timeBehind.innerText = formatTimeBehind(liveSample.liveTimeBehind);
                        }
                        if(liveSample.domElements.place && liveSample.livePlace !== undefined) {
                            liveSample.domElements.place.innerText = `(${liveSample.livePlace})`;
                        }
                    }
                }
                else if(updatePlacesAndTimeBehindsForNonLiveListItems) {
                    liveSample.domElements.timeBehind.innerText = formatTimeBehind(liveSample.comparisonTimeBehind);
                    liveSample.domElements.place.innerText = liveSample.comparisonPlace ?? "";
                }
            });
        }
    };

    const repositionListItemDomElements = (listItems, parentDomElement, forceCompleteRepositioning) => {
        if(!listItems.length) {
            parentDomElement.style.height = "0";
            return;
        }
        const height = Math.max(...listItems.map(li=>li.domElementData.li.offsetHeight)); // newly inserted items have a height of 0, avoid them
        parentDomElement.style.height = (listItems.length * height) + "px";
        let i = 0;
        if(forceCompleteRepositioning) {
            parentDomElement.classList.add("no-animation");
        }
        listItems.forEach(listItem => {
            if(forceCompleteRepositioning || i !== listItem.previousPositionInList) {
                const li = listItem.domElementData.li;
                listItem.domElementData.top = i * height;
                li.style.transform = `translateY(${i * height}px)`;
                li.style.zIndex = i;
                li.classList.add(i % 2 ? "even" : "odd");
                li.classList.remove(i % 2 ? "odd" : "even");
                listItem.previousPositionInList = i;
            }
            i++;
        });

        if(forceCompleteRepositioning) {
            // we must set a timeout so that the translateY transitions above is painted without animation before animation is activated again
            window.setTimeout(() => parentDomElement.classList.remove("no-animation"), 10);
        }
    };

    const resetListItemDomElementPositions = listItems => {
        if(listItems) {
            listItems.forEach(listItem => {
                listItem.domElementData.li.style.transform = "translateY(0px)";
                listItem.previousPositionInList = undefined;
            });
        }
    };

    const toggleVisibility = (domElement, visible) => {
        domElement.style.display = visible ? "block": "none";
    };

    // **************************************************************************** TIME LOGIC ****************************************************************************

    const startTimeStatuses = [
        ResultStatus.notActivated,
        ResultStatus.started, // when making a check punch right before start, the status is set to 'started'
        ResultStatus.unknown
    ];

    const getTimeCellVisibilities = (result, sample, now) => {
        const showElapsedTime = isValidElapsedTime(sample) && result.timePresentation;

        const showStartTime =
             !showElapsedTime &&
             sample &&
             sample.accumulationType === AccumulationType.race &&
             startTimeStatuses.indexOf(sample.status) !== -1 &&
             result.startTime &&
             now &&
             now < result.startTime;
    
        const showLiveTime = isLiveTime(result, sample, now) && result.timePresentation;
    
        const showTextStatus =
            sample && 
            !showElapsedTime && 
            !showLiveTime &&
            !showStartTime;
    
        return {
            showElapsedTime, 
            showStartTime,
            showLiveTime,
            showTextStatus
        };
    };
    
    const isValidElapsedTime = sample =>
        sample && 
        isElapsedTimeSample(sample) &&
        sample.elapsedTime !== undefined;

    // **************************************************************************** SORTING ****************************************************************************

    const sortListItems = (listItems, orderBy, direction = 1 /* 1 - ascending, -1 - descending */) => {
        if(!listItems) {
            return;
        }

        const sorters = columnSorters[orderBy];
        listItems.sort(combineSorters(sorters, direction));
    };

    const getSortableStatus = status =>
        isElapsedTimeStatus(status)
            ? ResultStatus.ok
            : status;

    const getSortableSampleStatus = sample =>
        isElapsedTimeSample(sample)
            ? ResultStatus.ok
            : sample.status;
    
    // some helper variables for sorting
    const primaryStatusSorter = getSortFunction(o => getSortableSampleStatus(o.primarySample));
    const primaryLiveStatusSorter = getSortFunction(o => getSortableStatus(o.primaryLive.comparisonStatus));
    const secondaryStatusSorter = getSortFunction(o => getSortableSampleStatus(o.secondarySample));
    const secondaryLiveStatusSorter = getSortFunction(o => getSortableStatus(o.secondaryLive.comparisonStatus));
    const primaryPlaceSorter = getSortFunction(o => o.primarySample.place);
    const primaryLivePlaceSorter = getSortFunction(o => o.primaryLive.comparisonPlace);
    const primaryOverallPlaceDifferenceSinceStartSorter = getSortFunction(o => o.primarySample.overallPlaceDifferenceSinceStart);
    const secondaryPlaceSorter = getSortFunction(o => o.secondarySample.place);
    const secondaryLivePlaceSorter = getSortFunction(o => o.secondaryLive.comparisonPlace);
    const secondaryOverallPlaceDifferenceSinceStartSorter = getSortFunction(o => o.secondarySample.overallPlaceDifferenceSinceStart);
    const personSorter = getSortFunction(o => getSortablePersonName(o.result));
    const organisationNameSorter = getSortFunction(o => (o.result.organisation ?? {}).name);
    const entryIdSorter = getSortFunction(o => o.result.entryId);
    const startTimeSorter = getSortFunction(o => o.result.startTime);
    const startTimeForValidPrimaryResultsOnlySorter = getSortFunction(o => isElapsedTimeSampleStatus(o.primarySample.status) ? o.result.startTime : undefined);
    const startTimeForValidSecondaryResultsOnlySorter = getSortFunction(o => isElapsedTimeSampleStatus(o.secondarySample.status) ? o.result.startTime : undefined);
    const bibNumberSorter = getSortFunction(o => parseInt(o.result.bibNumber) || o.result.bibNumber);
    const primaryLiveElapsedTimeSorter = getSortFunction(o => o.primaryLive.comparisonElapsedTime);
    const secondaryLiveElapsedTimeSorter = getSortFunction(o => o.secondaryLive.comparisonElapsedTime);
    const primaryLiveTimeBehindSorter = getSortFunction(o => o.primaryLive.comparisonTimeBehind);
    const secondaryLiveTimeBehindSorter = getSortFunction(o => o.secondaryLive.comparisonTimeBehind);

    // sorting functions for each column
    // there are multiple sorters; if the first sorter returns a tie, the next one is applied, and so on
    const columnSorters = {
        "primaryPlace": [primaryStatusSorter, primaryPlaceSorter, primaryLivePlaceSorter, primaryLiveElapsedTimeSorter, startTimeForValidPrimaryResultsOnlySorter, personSorter, entryIdSorter],
        "primaryOverallPlaceDifferenceSinceStart": [primaryStatusSorter, primaryOverallPlaceDifferenceSinceStartSorter, primaryPlaceSorter, primaryLivePlaceSorter, primaryLiveElapsedTimeSorter, startTimeForValidPrimaryResultsOnlySorter, personSorter, entryIdSorter],
        "primaryElapsedTime": [primaryLiveStatusSorter, primaryLiveElapsedTimeSorter, primaryLivePlaceSorter, startTimeForValidPrimaryResultsOnlySorter, personSorter, entryIdSorter],
        "primaryTimeBehind": [primaryLiveStatusSorter, primaryLiveTimeBehindSorter, primaryLivePlaceSorter, primaryLiveElapsedTimeSorter, startTimeForValidPrimaryResultsOnlySorter, personSorter, entryIdSorter],
        "secondaryPlace": [secondaryStatusSorter, secondaryPlaceSorter, secondaryLivePlaceSorter, secondaryLiveElapsedTimeSorter, startTimeForValidSecondaryResultsOnlySorter, personSorter, entryIdSorter],
        "secondaryOverallPlaceDifferenceSinceStart": [secondaryStatusSorter, secondaryOverallPlaceDifferenceSinceStartSorter, secondaryPlaceSorter, secondaryLivePlaceSorter, secondaryLiveElapsedTimeSorter, startTimeForValidSecondaryResultsOnlySorter, personSorter, entryIdSorter],
        "secondaryElapsedTime": [secondaryLiveStatusSorter, secondaryLiveElapsedTimeSorter, secondaryLivePlaceSorter, startTimeForValidSecondaryResultsOnlySorter, personSorter, entryIdSorter],
        "secondaryTimeBehind": [secondaryLiveStatusSorter, secondaryLiveTimeBehindSorter, secondaryLivePlaceSorter, secondaryLiveElapsedTimeSorter, startTimeForValidSecondaryResultsOnlySorter, personSorter, entryIdSorter],
        "startTime": [startTimeSorter, /* bibNumberSorter, */ personSorter, entryIdSorter],
        "person": [personSorter, entryIdSorter],
        "organisation": [organisationNameSorter, personSorter, entryIdSorter],
        "organisationName": [organisationNameSorter, personSorter, entryIdSorter],
        "organisationCountryCode": [getSortFunction(o => (o.result.organisation ?? {}).countryCode), organisationNameSorter, personSorter, entryIdSorter],
        "class": [getSortFunction(o => o.result.cl.sequence), personSorter, entryIdSorter],
        "courseLength": [getSortFunction(o => o.result.cl.courseLength), personSorter, entryIdSorter],
        "elapsedTimePerKilometer": [getSortFunction(o => getElapsedTimePerKilometer(o.result, o.raceSample)), primaryPlaceSorter, personSorter, entryIdSorter],
        "punchingCardNumber": [getSortFunction(o => parseInt(o.result.punchingCardNumber) || o.result.punchingCardNumber), personSorter, entryIdSorter],
        "bibNumber": [bibNumberSorter, startTimeSorter, personSorter, entryIdSorter]
    };
    
    const liveSampleSorter = (a, b) => {
        const ac = a.comparisonElapsedTime;
        const bc = b.comparisonElapsedTime;
        if(ac !== undefined && bc === undefined) {
            return -1;
        }
        if(ac === undefined && bc !== undefined) {
            return 1;
        }
        if(ac === undefined && bc === undefined) {
            return 0;
        }
        
        let diff = ac - bc;
        if(diff !== 0) {
            return diff;
        }
        // being a non-live time should be decisive in case of a tie
        return !a.enabled && b.enabled
            ? -1 
            : (a.enabled && !b.enabled ? 1 : 0);
    };

    const log = (message, data) => {
        if(window.location.hostname !== "localhost") {
            const nowString = new Date().toISOString();
            if (typeof data === "object") {
                // eslint-disable-next-line no-console
                console.log(nowString + " " + message, data);
            } else {
                data = nowString + ": " + message + " " + data;
                // eslint-disable-next-line no-console
                console.log(message, data);
            }
        }
    };
    
    init();

    // **************************************************************************** PUBLIC FUNCTIONS ****************************************************************************

    return {
        refresh,
        destroy,
        refreshMenuButton
    };
};
