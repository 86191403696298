import React from "react";
import { withLocalize, Translate } from "react-localize-redux";
import PropTypes from "prop-types";

const reload = () => window.location.reload(true);

const ErrorMessage = ({ error }) => {

    return (
        <div id="error-message">
            <h1>
                <Translate id="kiosk.anErrorOccurred" />
            </h1>
            <h2>{error.message}</h2>
            <p>
                <button type="button" className="btn" onClick={reload}>
                    <Translate id="kiosk.reload" />
                </button>
            </p>
            <pre>{error.stack}</pre>
        </div>
    );
};

ErrorMessage.propTypes = {
    error: PropTypes.object.isRequired
};

export default withLocalize(ErrorMessage);
