export const mergeProperties = (target, source, propertiesToExclude = []) => {
    // loops through properties in 'target'
    // properties in 'source' but not in 'target' are ignored
    let updated = false;
    Object.keys(source).forEach(key => {
        if(propertiesToExclude.indexOf(key) === -1) {
            updated = updated || target[key] !== source[key];
            target[key] = source[key];
        }
    });
    return updated;
};

export const propertiesAreEqual = (target, source, propertiesToExclude = []) => {
    // loops through properties in 'target'
    // properties in 'source' but not in 'target' are ignored
    let equal = true;
    Object.keys(source).forEach(key => {
        if(propertiesToExclude.indexOf(key) === -1) {
            equal = equal && target[key] === source[key];
        }
    });
    return equal;
};