import ListType from "./models/ListType";
import { sortedArraysAreEqual } from "./ArrayUtil";
import queryString from "query-string";
import { getOrganisationKey } from "./OrganisationUtil";
import { Page } from "./LoggingUtil";
import { isFirstRace } from "./ClassUtil";
import { getExternalUrlForClasses, getCurrentRaceId, getCurrentTimeForEvent, hasOverallResults } from "./EventUtil";
import { startsWith } from "./StringUtil";
import { startOfDay } from "./TimeUtil";

export const parseListFromLocation = (location, event) => {
    return parseList(location.search, event);
};

export const parseListFromUrl = (url, event) => {
    const pos = url.indexOf("?");
    const search = pos !== -1
        ? url.substr(pos)
        : "";
    return parseList(search, event);
};

// builds a canonical URL for a list
export const getUrlForList = (event, list, includeControlName = true, includeSorting = true) => {
    if(!event || !list) {
        return undefined;
    }

    const clonedList = { ...list };

    if(clonedList.classIds && clonedList.classIds.length && clonedList.raceId) {
        const externalUrl = getExternalUrlForClasses(event, clonedList.classIds, clonedList.raceId, clonedList.listType);
        if(externalUrl) {
            return {
                url: externalUrl,
                isExternal: true
            };
        }
    }


    const params = {};
    let url = `/${encodeURIComponent(event.slug)}/list`;

    if(clonedList.raceId) {
        params.raceId = clonedList.raceId;
    }
    if(clonedList.classIds && clonedList.classIds.length) {
        clonedList.classIds.sort(o => o);
        params.classIds = clonedList.classIds.join("_");
    }
    if(clonedList.organisationKey) {
        params.organisation = clonedList.organisationKey;
    }
    if(clonedList.entryIds && clonedList.entryIds.length) {
        clonedList.entryIds.sort(o => o);
        params.entryIds = clonedList.entryIds.join("_");
    }
    if(clonedList.tag) {
        params.tag = clonedList.tag;
    }
    if(includeControlName) {
        params.control = clonedList.controlName;
    }
    params.type = listTypeToString(clonedList.listType);
    
    if(includeSorting) {
        params.orderBy = getOrderByForList(clonedList);
    }

    const q = queryString.stringify(params);
    if(q) {
        url += "?" + q;
    }

    return {
        url,
        isExternal: false
    };
};

export const getOrderByForList = list => {
    if(list.orderBy) {
        return list.orderBy + (list.direction < 0 ? "-desc" : "");
    }
    return undefined;
};

export const listsAreEqual = (list1, list2) => {
    return list1.raceId === list2.raceId &&
        sortedArraysAreEqual(list1.classIds, list2.classIds) &&
        toLower(list1.organisationKey) === toLower(list2.organisationKey) &&
        sortedArraysAreEqual(list1.entryIds, list2.entryIds) &&
        toLower(list1.tag) === toLower(list2.tag) &&
        list1.listType === list2.listType &&
        list1.controlName === list2.controlName &&
        list1.orderBy === list2.orderBy &&
        list1.direction === list2.direction;
};

export const getDefaultOrderByForListType = (event, raceId, listType) => {
    if(raceId === "all") {
        switch(listType) {
            case ListType.startList: return `race-${getCurrentRaceId(event)}`;
            default: return `overall-${getCurrentRaceId(event)}`;
        }
    }
    switch(listType) {
        case ListType.startList: return "startTime";
        default: return "primaryPlace";
    }
};

export const getCurrentListType = event => {
    if(!event) {
        return undefined;
    }
    const now = getCurrentTimeForEvent(event);
    const races = Object.values(event.races);
    const earlierAndCurrentRaces = races.filter(r => startOfDay(r.startTime, event.timeZone) < now);

    if(earlierAndCurrentRaces.length === 0) {
        return ListType.startList;
    }
    if(earlierAndCurrentRaces.length === races.length) {
        return ListType.overallResultList;
    }
    return ListType.raceResultList;
};

export const getListTypeLabelKey = (listType, isMultiRace) => {
    switch(listType) {
        case ListType.startList: return "startList";
        case ListType.raceResultList: return isMultiRace ? "raceResultList" : "resultList";
        case ListType.overallResultList: return "overallResultList";
        case ListType.resultList: return "resultList";
    }
};

export const getUrlForEntry = (eventSlug, entryId) => {
    return entryId 
        ? `/${encodeURIComponent(eventSlug)}/competitors/${entryId}`
        : undefined;
};

export const getUrlForCompetitors = (eventSlug, q) => {
    return q 
        ? `/${encodeURIComponent(eventSlug)}/competitors?q=${encodeURIComponent(q)}`
        : undefined;
};

export const getUrlForTags = (eventSlug, q) => {
    return q 
        ? `/${encodeURIComponent(eventSlug)}/tags?q=${encodeURIComponent(q)}`
        : undefined;
};

export const getUrlForSplitTimes = (eventSlug, raceId, classIds, listType, orderBy, direction) => {
    const orderByString = encodeURIComponent(orderBy + (direction < 0 ? "-desc" : ""));
    return `/${encodeURIComponent(eventSlug)}/splits?raceId=${encodeURIComponent(raceId)}&classIds=${encodeURIComponent(classIds.join("_"))}&type=${listTypeToString(listType)}&orderBy=${orderByString}`;
};

export const getFavoriteEntryIds = eventId => {
    if (window.localStorage) {
        const item = window.localStorage[getFavoriteKey(eventId)];
        if(item) {
            try {
                const parsedItem = JSON.parse(item);
                if(Array.isArray(parsedItem)) {
                    return parsedItem;
                }
    
            } catch(e) {
                return [];
            }
        }
    }
    return [];
};

export const setFavoriteEntryIds = (eventId, entryIds) => {
    if (window.localStorage) {
        // save to local storage
        window.localStorage[getFavoriteKey(eventId)] = JSON.stringify(entryIds);
    }
};

export const getHasFavoriteSubscription = eventId => {
    if (window.localStorage) {
        const item = window.localStorage[getHasFavoriteSubscriptionKey(eventId)];
        if(item) {
            try {
                return JSON.parse(item);
            } catch(e) {
                return false;
            }
        }
        return false;
    }
};

export const setHasFavoriteSubscription = (eventId, value) => {
    if (window.localStorage) {
        // save to local storage
        window.localStorage[getHasFavoriteSubscriptionKey(eventId)] = JSON.stringify(value);
    }
};

export const resultBelongsToList = (resultObject, list) => {
    const result = resultObject.result;
    if(list.raceId && result.raceId !== list.raceId) {
        return false;
    }
    if(list.classIds && list.classIds.indexOf(result.cl.classId) === -1) {
        return false;
    }
    if(list.organisationKey && toLower(getOrganisationKey(result.organisation)) !== toLower(list.organisationKey)) {
        return false;
    }
    if(list.entryIds && list.entryIds.indexOf(result.entryId) === -1) {
        return false;
    }
    if(list.tag && (result.tags || []).filter(t => toLower(t) === toLower(list.tag)).length === 0) {
        return false;
    }
    return true;
};

export const setHighlightedResultId = resultId => window.highlightedResultId = resultId;

export const getHighlightedResultId = () => {
    const highlightedResultId = window.highlightedResultId;
    if(highlightedResultId) {
        window.highlightedResultId = undefined;
    }
    return highlightedResultId;
};

export const getPageFromList = list => {
    switch(list.raceId) {
        case "all":
            switch(list.listType) {
                case ListType.startList:
                    if(list.classIds) {
                        return Page.classStartListAllRaces;
                    }
                    if(list.organisationKey) {
                        return Page.organisationStartListAllRaces;
                    }
                    if(list.entryIds) {
                        return Page.favoriteStartListAllRaces;
                    }
                    if(list.tag) {
                        return Page.tagStartListAllRaces;
                    }
                    break;
        
                case ListType.resultList:
                    if(list.classIds) {
                        return Page.classResultListAllRaces;
                    }
                    if(list.organisationKey) {
                        return Page.organisationResultListAllRaces;
                    }
                    if(list.entryIds) {
                        return Page.favoriteResultListAllRaces;
                    }
                    if(list.tag) {
                        return Page.tagResultListAllRaces;
                    }
                    break;
            }
            break;
        
        default: // race
            switch(list.listType) {
                case ListType.startList:
                    if(list.classIds) {
                        return Page.classStartList;
                    }
                    if(list.organisationKey) {
                        return Page.organisationStartList;
                    }
                    if(list.entryIds) {
                        return Page.favoriteStartList;
                    }
                    if(list.tag) {
                        return Page.tagStartList;
                    }
                    break;
        
                case ListType.resultList:
                case ListType.raceResultList:
                    if(list.classIds) {
                        return Page.classRaceResultList;
                    }
                    if(list.organisationKey) {
                        return Page.organisationRaceResultList;
                    }
                    if(list.entryIds) {
                        return Page.favoriteRaceResultList;
                    }
                    if(list.tag) {
                        return Page.tagRaceResultList;
                    }
                    break;
            
                case ListType.overallResultList:
                    if(list.classIds) {
                        return Page.classOverallResultList;
                    }
                    if(list.organisationKey) {
                        return Page.organisationOverallResultList;
                    }
                    if(list.entryIds) {
                        return Page.favoriteOverallResultList;
                    }
                    if(list.tag) {
                        return Page.tagOverallResultList;
                    }
                    break;
            }
            break;            
    }
};

const validOrderBysForAllRaces = [ "person", "organisation", "class" ];

export const sanitizeList = (list, event) => {
    const clonedList = { ...list };
    if(clonedList.raceId === "all") {
        // orderBy: 
        //   - {race|overall}-{raceId}
        //   - person
        //   - organisation
        //   - organisationName
        //   - organisationCountryCode
        //   - class
        // listType: {startList|resultList}
        if(clonedList.listType !== ListType.startList) {
            clonedList.listType = ListType.resultList;
        }
        if(!startsWith(clonedList.orderBy, "race-") &&
           !startsWith(clonedList.orderBy, "overall-") && 
           validOrderBysForAllRaces.indexOf(clonedList.orderBy) === -1) {
            clonedList.orderBy = getDefaultOrderByForListType(event, clonedList.raceId, clonedList.listType);
        }
    } else {
        const singleClass = clonedList.classIds?.length === 1
            ? event.classes[clonedList.classIds[0]]
            : undefined;
        if(clonedList.listType === ListType.resultList) {
            const race = clonedList.raceId
                ? event.races[clonedList.raceId]
                : undefined;
            if(isFirstRace(singleClass, race, event)) {
                clonedList.listType = ListType.raceResultList;
            } else {
                clonedList.listType = ListType.overallResultList;                
            }
        }
        if(clonedList.listType === ListType.overallResultList && singleClass && !singleClass.hasOverallResults) {
            clonedList.listType = ListType.raceResultList;
        }
        if(startsWith(clonedList.orderBy, "race-") || startsWith(clonedList.orderBy, "overall-")) {
            clonedList.orderBy = getDefaultOrderByForListType(event, clonedList.raceId, clonedList.listType);
        }
    }
    return clonedList;
};

const parseList = (search, event) => {
    const params = queryString.parse(search);
    const listType = parseListType(params.type);
    const raceId = params.raceId === "all" ? "all" : ((params.raceId ? parseInt(params.raceId) : undefined) || undefined);
    const { orderBy, direction } = parseOrderByAndDirection(event, raceId, params.orderBy, listType);

    const list = {
        raceId,
        classIds: params.classIds ? params.classIds.split("_").map(o => parseInt(o)).filter(o => o) : undefined,
        organisationKey: params.organisation || undefined,
        entryIds: params.entryIds ? params.entryIds.split("_").map(o => parseInt(o)).filter(o => o) : undefined,
        tag: params.tag || undefined,
        controlName: params.control,
        listType,
        orderBy,
        direction,
        highlightedResultId: (params.resultId ? parseInt(params.resultId) : undefined) || undefined
    };

    // overall result for first race? no, that's a race result
    const singleClass = list.classIds?.length === 1
        ? event.classes[list.classIds[0]]
        : undefined;
    const race = list.raceId
        ? event.races[list.raceId]
        : undefined;
    if(list.listType === ListType.overallResultList && 
        isFirstRace(singleClass, race, event)) {
        list.listType = ListType.raceResultList;
    }

    if(list.classIds || list.organisationKey || list.entryIds || list.tag) {
        return list;
    }

    return undefined;
};

const getFavoriteKey = eventId => `favorites_event_${eventId}`;

const getHasFavoriteSubscriptionKey = eventId => `hasFavoriteSubscription_event_${eventId}`;

export const parseListType = listTypeString => {
    switch(listTypeString?.toLowerCase()) {
        case "startlist": return ListType.startList;
        case "raceresultlist": return ListType.raceResultList;
        case "overallresultlist": return ListType.overallResultList;
        default: return ListType.resultList;
    }
};

const listTypeToString = listType => {
    switch(listType) {
        case ListType.startList: return "startList";
        case ListType.raceResultList: return "raceResultList";
        case ListType.overallResultList: return "overallResultList";
        default: return "resultList";
    }
};

const parseOrderByAndDirection = (event, raceId, orderByString, listType) => {
    const atoms = (orderByString ?? "").split("-");
    if(atoms.length === 3 || (atoms.length === 2 && atoms[1] !== "asc" && atoms[1] !== "desc")) {
        // handle race-x, race-x-asc etc
        atoms[0] = `${atoms[0]}-${atoms[1]}`;
        atoms[1] = atoms[2];
    }
    const orderBy = atoms[0] || getDefaultOrderByForListType(event, raceId, listType);
    const direction = atoms[atoms.length - 1] === "desc" ? -1 : 1;
    return { orderBy, direction };
};

const toLower = str => str
    ? str.toLowerCase()
    : str;
