import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { formatClockTime } from "../../../logic/TimeUtil";
import queryString from "query-string";

const LogModal = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const logItems = useSelector(state=> state.eventPage.logItems);

    const handleOpened = () => {
        setIsOpen(true);
    };

    const handleClosed = () => {
        setIsOpen(false);
    };

    const params = queryString.parse(location.search);

    if(!params.showLog) {
        return null;
    }

    const reversedLogItems = logItems.map(o=>o);
    reversedLogItems.reverse();
    let i = 0;
    return (
        <div id="log-modal-wrapper">
            <button onClick={handleOpened}>Open log modal</button>
            <Modal id="log-modal" isOpen={isOpen} onOpened={handleOpened} onClosed={handleClosed}>
                <ModalHeader>Log</ModalHeader>
                <ModalBody>
                    <ul className="list-unstyled">
                        {
                            reversedLogItems.map(logItem => 
                                (
                                    <li key={i++}>
                                        {formatClockTime(logItem.time, undefined, true)}
                                        {": "}
                                        {logItem.text}
                                        {logItem.data ? " " + JSON.stringify(logItem.data).substring(0, 100) : ""}
                                    </li>
                                )
                            )
                        }
                    </ul>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleClosed}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default LogModal;
