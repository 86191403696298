import React from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";

import MenuIcon from "./MenuIcon";

const MenuButtonCell = ({ result, favoriteEntryIds, onFavoriteButtonClick, translate }) => {
    const personName = `${result.person.firstName} ${result.person.lastName}`;
    const isFavorite = favoriteEntryIds.indexOf(result.entryId) !== -1; 
    const toggleFavoriteButtonText = isFavorite
        ? translate("removeFavorite") 
        : translate("addFavorite");

    const handleFavoriteButtonClick = () => onFavoriteButtonClick(result.entryId, !isFavorite);

    return (
        <div className="menu">
            <div className="btn-group">
                <button className="menu-button" type="button" data-toggle="dropdown" data-boundary="viewport" aria-haspopup="true" aria-expanded="false">
                    <MenuIcon result={result} favoriteEntryIds={favoriteEntryIds} />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                    <h6 className="dropdown-header">
                        {personName}
                    </h6>
                    <div className="dropdown-divider"/>
                    <button className="dropdown-item toggle-favorite-button btn btn-sm" type="button" onClick={handleFavoriteButtonClick}>
                        {toggleFavoriteButtonText}
                    </button>
                </div>
            </div>
        </div>
    );
};
    
MenuButtonCell.propTypes = {
    result: PropTypes.object.isRequired,
    favoriteEntryIds: PropTypes.array,
    onFavoriteButtonClick: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired
};

export default withLocalize(MenuButtonCell);